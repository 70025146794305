import { Observable, of } from 'rxjs';
import { filter, mergeMap, catchError, tap, map, ignoreElements } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';

import { IPayloadAction, ILemonAction } from '@src/service/business/common/types';
import ILoginData from '@src/service/business/login/ILoginData';
import { IResetPasswordRequest, IToken, IResetPassword } from '@src/service/business/login/IPassword';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import LoginApiService from '@src/service/api/login/LoginApiService';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import { IUserInfo } from '@src/model/user/User';
import { actionThunk } from '@src/service/util/observable/operators';
import { ActionThunkHelper } from '@src/service/util/action/thunk';
import StoreService from '@src/service/business/StoreService';
import { reportCaughtMessage, stopGlobalProgress, startGlobalProgress } from '@src/service/util/observable/operators/userFeedback';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import IRegistrationData from '@src/service/business/login/IRegistrationData';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('loginBusinessStore');

// -
// -------------------- Selectors

const hasUserToken = (): boolean => !!AuthTokenManager.getToken();

const getCurrentUser = (store: any): IUserInfo => store.currentUser;

const isUserLoggedIn = (store: any): boolean => store.currentUser != null;

// -
// -------------------- Actions

const Actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  VALIDATE_RESET_TOKEN: 'VALIDATE_RESET_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REGISTRATION: 'REGISTRATION',
  VALIDATE_ACTIVATION_TOKEN: 'VALIDATE_ACTIVATION_TOKEN',
  CURRENT_USER_TOKEN_STORE: 'USER_TOKEN',
  CURRENT_USER_FETCH: 'CURRENT_USER_FETCH',
  CURRENT_USER_STORE: 'CURRENT_USER_STORE',
  CURRENT_USER_CLEAR: 'CURRENT_USER_CLEAR',
};

const doLogin = (username: string, password: string): IPayloadAction<ILoginData> => {
  return {
    type: Actions.LOGIN,
    payload: { username, password },
  };
};

const doLogout = (): IPayloadAction<null> => {
  return {
    type: Actions.LOGOUT,
    payload: null,
  };
};

const doResetPasswordRequest = (email: string): IPayloadAction<IResetPasswordRequest> => {
  return {
    type: Actions.RESET_PASSWORD_REQUEST,
    payload: { email },
  };
};

const doValidateResetToken = (token: string): IPayloadAction<IToken> => {
  return {
    type: Actions.VALIDATE_RESET_TOKEN,
    payload: { token },
  };
};

const doResetPassword = (token: string, password: string): IPayloadAction<IResetPassword> => {
  return {
    type: Actions.RESET_PASSWORD,
    payload: { token, password },
  };
};

const doRegistration = (data: IRegistrationData): IPayloadAction<IRegistrationData> => {
  return {
    type: Actions.REGISTRATION,
    payload: data,
  };
};

const doValidateActivationToken = (token: string): IPayloadAction<IToken> => {
  return {
    type: Actions.VALIDATE_ACTIVATION_TOKEN,
    payload: { token },
  };
};

const fetchCurrentUser = (): ILemonAction => {
  return {
    type: Actions.CURRENT_USER_FETCH,
  };
};

const clearCurrentUser = (): ILemonAction => {
  return {
    type: Actions.CURRENT_USER_CLEAR,
  };
};

const storeCurrentUser = (user: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.CURRENT_USER_STORE,
    payload: user,
  };
};

// -
// -------------------- Side-effects

const doLoginEffect = (action$: Observable<IPayloadAction<ILoginData>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOGIN;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      LOGGER.info(`Do login for username "${payload.username}"`);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).login(payload.username, payload.password).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // persist token
    tap((token) => {
      LOGGER.info(`User logged in`);

      AuthTokenManager.saveToken(token);
    }),

    // returns nothing because it redirects after login
    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LOGIN.ERROR', 'LOGIN.ERROR.GENERAL_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doLogoutEffect = (action$: ActionsObservable<IPayloadAction<ILoginData>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOGOUT;
    }),

    startGlobalProgress(),

    // persist token
    mergeMap((action) => {
      LOGGER.info(`Do logout for current user`);

      AuthTokenManager.deleteToken();

      return of(action).pipe(
        // purge store after logout but BEFORE action thunk confirmation because it will do a redirect which can prevent purge from happening
        tap(() => {
          StoreService.getPersistor().purge();
        }),

        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doResetPasswordRequestEffect = (action$: Observable<IPayloadAction<IResetPasswordRequest>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.RESET_PASSWORD_REQUEST;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      // LOGGER.info('Request password reset for email', payload.email);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).password(payload.email).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doValidateResetTokenEffect = (action$: Observable<IPayloadAction<IToken>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VALIDATE_RESET_TOKEN;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      // LOGGER.info('Validate reset token', payload.token);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).validateResetToken(payload.token).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doResetPasswordEffect = (action$: Observable<IPayloadAction<IResetPassword>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.RESET_PASSWORD;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      // LOGGER.info('Reset password to', payload.password);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).resetPassword(payload.token, payload.password).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doRegistrationEffect = (action$: Observable<IPayloadAction<IRegistrationData>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REGISTRATION;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      // LOGGER.info('Request password reset for email', payload.email);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).registration(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'USERNAME_ALREADY_IN_USE', 'REGISTRATION.USERNAME_ALREADY_IN_USE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const doValidateActivationTokenEffect = (action$: Observable<IPayloadAction<IToken>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VALIDATE_ACTIVATION_TOKEN;
    }),

    startGlobalProgress(),

    // authenticate user aka. do login
    mergeMap((action) => {
      const payload = action.payload;

      // LOGGER.info('Validate reset token', payload.token);

      return (EntityApiServiceRegistry.getService('LOGIN') as LoginApiService).validateActivationToken(payload.token).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // persist token
    tap((token) => {
      LOGGER.info(`User logged in`);

      AuthTokenManager.saveToken(token);
    }),

    map(() => {
      // TODO: fetching user here is useless since after login user is redirected to home page, this action is canceled and entire app is reloaded (it even results with cancelled ajax request error)
      return fetchCurrentUser();
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.info();

      return o;
   })
 );
};

const fetchCurrentUserEffect = (action$: ActionsObservable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CURRENT_USER_FETCH;
    }),

    startGlobalProgress(),

    filter((action) => {
      if (AuthTokenManager.getToken() != null && AuthTokenManager.getToken() !== '') {
        return true;
      }
      else {
        LOGGER.info('Cannot fetch current user, auth token is empty.');

        // we must complete action thunk manually since this represents error
        ActionThunkHelper.error(action, 'Auth token is empty');

        return false;
      }
    }),

    // fetch current user
    mergeMap((action) => {
      LOGGER.info(`Fetching current user`);

      return (EntityApiServiceRegistry.getService('User')).fetchMethod('current').pipe(
        actionThunk(action)
      );
    }),

    // store user
    map((user) => {
      return storeCurrentUser(user);
    }),

    stopGlobalProgress(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching current user', error);

      return o;
   })
 );
};

// -
// -------------------- Reducers

/** Dummy login check. Untila proper user is resolved. */
const currentUser = (state: any = null, action: IPayloadAction<IUserInfo>) => {
  if (action.type === Actions.CURRENT_USER_STORE) {
    return action.payload;
  }
  else if (action.type === Actions.CURRENT_USER_CLEAR) {
    return null;
  }

  return state;
};

// --
// ----- Export store object

export const LoginBusinessStore = {
  actions: {
    doLogin, doLogout,
    doResetPasswordRequest, doValidateResetToken, doResetPassword,
    doRegistration, doValidateActivationToken,
    fetchCurrentUser, storeCurrentUser, clearCurrentUser,
  },

  selectors: {
    isUserLoggedIn, getCurrentUser, hasUserToken,
  },

  effects: {
    doLoginEffect, doLogoutEffect,
    doResetPasswordRequestEffect, doValidateResetTokenEffect, doResetPasswordEffect,
    doRegistrationEffect, doValidateActivationTokenEffect,
    fetchCurrentUserEffect,
  },

  reducers: {
    currentUser,
  },
};
