import { Observable } from 'rxjs';
import { CoreOptions } from 'request';

import AuthTokenManager from '@src/service/util/AuthTokenManager';
import HttpRestClient, { HttpMethod } from '@src/service/api/rest/HttpRestClient';

/** HTTP REST client which automatically and unconditionally adds authentication header. */
export default class AuthHttpRestClient extends HttpRestClient {

  // tslint:disable-next-line:type-literal-delimiter
  protected doRequest(request: { uri: string, method: HttpMethod, options?: CoreOptions }): Observable<any> {
    // populate request obj with auth token
    request.options = {
      ...(request.options || {}),
      // auth data
      ...{
        auth: {
          bearer: AuthTokenManager.getToken(),
        },
      },
    };

    return super.doRequest(request);
  }

}
