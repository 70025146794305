import { ILemonAction } from '@src/service/business/common/types';
import { ITrackableObject, createTrackableObject } from '@src/service/util/tracking/tracking';


// callback action object type
export type ITrackableAction<T = any> = ILemonAction & ITrackableObject<T>;

/** Enhance action object with event tracker. */
export const createTrackableAction = (action: ILemonAction, trackingParent?: any): ITrackableAction => {
  return createTrackableObject<ILemonAction>(action, trackingParent);
};
