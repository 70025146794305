import React from 'react';

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { classNames } from '@src/components/common/util/classNames';
import IFile from '@src/model/file/File';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';

const CSS_CLASS_BASE = 'lemon-image__thumbnail';
const CSS_CLASS_SMALL = 'lemon-image__thumbnail--sm';
const CSS_CLASS_LARGE = 'lemon-image__thumbnail--lg';
const CSS_CLASS_XLARGE = 'lemon-image__thumbnail--xl';

export type ThumbnailSize = 'base' | 'small' | 'large' | 'xlarge';

// -- Prop types
// ----------

// -- Public component props
export interface ILemonThumbnailOwnProps {
  file: IFile;
  fallbackIconName?: keyof ILemonApplicationIconSet;
  className?: string;
  size?: ThumbnailSize;
}
// -- Complete component props
type ILemonThumbnailProps = ILemonThumbnailOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component state
interface ILemonThumbnailState {
  hasImageLoadError: boolean;
}

// -- Component
// ----------

/**
 * Show thumbnail either as local image, remote URL or icon.
 *
 * Images are displayed as "LemonImage" component. Icons are rendered as "LemonIcon" component and icon is resolved from configured icon set.
 *
 * Component provides fallback if provided image cannot load (ie. invalid URL). Fallback can be an icon from ILemonApplicationIconSet.
 */
class LemonThumbnail extends React.Component<ILemonThumbnailProps, ILemonThumbnailState> {
  constructor(props: ILemonThumbnailProps) {
    super(props);

    this.state = {
      hasImageLoadError: false,
    };
  }

  componentDidUpdate(prevProps: ILemonThumbnailProps) {
    if (this.props !== prevProps) {
      // reset error state if props have changed
      this.setState({
        hasImageLoadError: false,
      });
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.shouldRenderImage() && <LemonImage className={this.getClassName()} imagePath={this.getImageLink(this.props.file)} thumbnail={true} onError={this.imageLoadErrorHandler} />}
        {this.shouldRenderIcon(this.props.fallbackIconName) && (
          <div className={this.getClassName()}>
            <LemonIcon name={this.props.fallbackIconName} />
          </div>
        )}
      </React.Fragment>
    );
  };

  getImageLink(file: IFile): string {
    return FileUtils.getFileLink(file);
  }

  imageLoadErrorHandler = () => {
    this.setState({
      hasImageLoadError: true,
    });
  };

  shouldRenderImage() {
    return !this.state.hasImageLoadError && FileUtils.isFile(this.props.file);
  }

  shouldRenderIcon(iconName: string | undefined): iconName is keyof ILemonApplicationIconSet {
    return !this.shouldRenderImage() && iconName != null;
  }

  getClassName() {
    return classNames({
      [CSS_CLASS_BASE]: true,
      [CSS_CLASS_SMALL]: this.getSize() === 'small',
      [CSS_CLASS_LARGE]: this.getSize() === 'large',
      [CSS_CLASS_XLARGE]: this.getSize() === 'xlarge',
      [this.props.className || '']: true,
    });
  }

  getSize(): ThumbnailSize {
    return this.props.size != null ? this.props.size : 'base';
  }
}

export default withPathResolver(withLocalize<ILemonThumbnailOwnProps>(LemonThumbnail as any));
