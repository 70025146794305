import ApiResponseErrorException, { ApiResponseErrorCode } from '@src/service/api/ApiResponseErrorException';
import StoreService from '@src/service/business/StoreService';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import { createActionThunk } from '@src/service/util/action/thunk';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('apiServiceErrorHandler');

/** Handle SESSION_EXPIRED API response error and redirect user to login page. */
export function sessionExpiredApiResponseErrorHandler(error: any): boolean {
  if (error instanceof ApiResponseErrorException) {
    if (error.code === ApiResponseErrorCode.SESSION_EXPIRED) {
      LOGGER.info(`API session expired detected. Logout user and redirect to login page.`);

      clearCurrentUser();

      return false;
    }
  }

  return true;
}

/** Handle AUTHENTICATION_ERROR API response error and redirect user to login page. */
export function userAuthenticationApiResponseErrorHandler(error: any): boolean {
  if (error instanceof ApiResponseErrorException) {
    if (error.code === ApiResponseErrorCode.AUTHENTICATION_ERROR) {
      LOGGER.info(`Error authenticating current user. Logout user and redirect to login page.`);

      clearCurrentUser();

      return false;
    }
  }

  return true;
}


function clearCurrentUser() {
  StoreService.dispatchAction(createActionThunk(LoginBusinessStore.actions.doLogout(), {
    success: (data) => {
      // redirect after timeout to give user a chance to see API service response errors
      // TODO: show user info that s/he will be redirected
      setTimeout(() => {
        StaticPageRoutingHelper.routeToLoginPage();
      }, 1000);
    },
  }));
}