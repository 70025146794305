import { of, Observable } from 'rxjs';
import { tap, mergeMap, catchError } from 'rxjs/operators';

import ServiceStatusProvider from '@src/service/status/ServiceStatusProvider';
import { ServiceStatusProviderType } from '@src/service/status/types';
import VideoServiceStatusProvider from '@src/service/status/provider/VideoServiceStatusProvider';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('videoServiceInitializer');


/** Video service initializer funciton. */
export const videoServiceInitializer = (): Observable<any> => {
  return of(true).pipe(
    mergeMap(() => {
      LOGGER.info('Initializing video ...');

      const provider = VideoServiceStatusProvider.create();

      // initialize service
      return provider.serviceInstance().initialize().pipe(
        tap(() => {
          ServiceStatusProvider.instance().registerServiceProvider(ServiceStatusProviderType.VideoService, provider);

          // artifical timeout for starting service - this should be done systematically by scheduling this or requested when feature is used
          /* disabled scheduling of service start
          setTimeout(() => {
            LOGGER.info('Starting video service ...');

            // callback
            const requireFn = () => ServiceStatusProvider.instance().requireService(ServiceStatusProviderType.VideoService).subscribe();

            // initial call
            requireFn();

            // setup periodic interval
            setInterval(() => {
              requireFn();
            }, generateRecurringInterval());
          }, generateInitialInterval());
          */
        })
      );
    }),


    tap(() => {
      LOGGER.info('Video service initialized');
    }),

    catchError((err) => {
      LOGGER.error('Error initializing video service', err);

      // do not break pipeline, just return ok
      return of(true);
    })
  );
};
