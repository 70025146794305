import { Observable } from 'rxjs';
import { StateObservable } from 'redux-observable';
import { filter, catchError, map, mergeMap, ignoreElements, tap } from 'rxjs/operators';

import { IPayloadAction, ILemonAction, IIdPayload } from '@src/service/business/common/types';
import { IRegistrationTokenPayload } from '@src/service/business/messaging/types';
import { IMessagingServiceRawMessage } from '@src/service/service/messaging/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import NotificationApiService from '@src/service/api/messaging/NotificationApiService';
import { actionThunk } from '@src/service/util/observable/operators';
import { IMessagingMessage, MessagingMessageType, ISessionIncomingNoticeMessagingMessage } from '@src/model/messaging/messages';
import ComponentMessagingService from '@src/service/util/pubsub/ComponentMessagingService';
import { noop } from '@src/service/business/common/actions';
import MessagingHelper from '@src/service/business/messaging/MessagingHelper';
import { getLogger } from '@src/service/util/logging/logger';
import StoreService from '@src/service/business/StoreService';


const LOGGER = getLogger('messagingBusinessStore');


// -
// -------------------- Selectors

/** Get list of messages. */
const getMessageList = <D>(store: any): IMessagingMessage[] => store.messagingMessageList;


// -
// -------------------- Actions

const Actions = {
  MESSAGING_RESTORE_MESSAGE: 'MESSAGING_RESTORE_MESSAGE',
  MESSAGING_RECIEVE_MESSAGE: 'MESSAGING_RECIEVE_MESSAGE',
  MESSAGING_STORE_MESSAGE: 'MESSAGING_STORE_MESSAGE',
  MESSAGING_REMOVE_MESSAGE: 'MESSAGING_REMOVE_MESSAGE',
  MESSAGING_REMOVE_ALL_MESSAGES: 'MESSAGING_REMOVE_ALL_MESSAGES',
  MESSAGING_REGISTER_TOKEN: 'MESSAGING_REGISTER_TOKEN',
};

/** Restore stored messaging raw message. Messages are stored to some persitant store to survive refresh.  */
const restoreMessage = <D>(message: IMessagingServiceRawMessage): IPayloadAction<IMessagingMessage> => {
  return {
    type: Actions.MESSAGING_RESTORE_MESSAGE,
    payload: message.data,
  };
};

/** Recieve messaging raw message directly from messaging service and prepare them for adding to store.  */
const receiveMessage = <D>(message: IMessagingServiceRawMessage): IPayloadAction<IMessagingMessage> => {
  return {
    type: Actions.MESSAGING_RECIEVE_MESSAGE,
    payload: message.data,
  };
};

/** Store prepared messaging message. */
const storeMessage = <D>(message: IMessagingMessage): IPayloadAction<IMessagingMessage> => {
  return {
    type: Actions.MESSAGING_STORE_MESSAGE,
    payload: message,
  };
};

/** Remove/dismiss message from store. */
const removeMessage = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.MESSAGING_REMOVE_MESSAGE,
    payload: { id },
  };
};

/** Remove all messages from store. */
const removeAllMessages = (): ILemonAction => {
  return {
    type: Actions.MESSAGING_REMOVE_ALL_MESSAGES,
  };
};

/** Register messaging token with our backend. */
const registerToken = (registrationToken: string): IPayloadAction<IRegistrationTokenPayload> => {
  return {
    type: Actions.MESSAGING_REGISTER_TOKEN,
    payload: {
      registrationToken,
    },
  };
};

// -
// -------------------- Side-effects

const restoreMessageEffect = (action$: Observable<IPayloadAction<IMessagingMessage>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.MESSAGING_RESTORE_MESSAGE;
    }),

    map((action) => {
      const message = action.payload;

      if (MessagingHelper.isDisplayableMessage(message)) {
        return storeMessage(message);
      }
      else {
        LOGGER.info(`Cannot restore messaging message of type ${message.type} (#${message.id})`);
        return noop();
      }
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error restoring messaging message', error);

      return o;
    })
  );
};

const receiveMessageEffect = (action$: Observable<IPayloadAction<IMessagingMessage>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.MESSAGING_RECIEVE_MESSAGE;
    }),

    // check if message is duplicate, sometimes, we get messages on two sides from Firebase - this avoids duplicate side-effect
    filter((action) => {
      const newMessage = action.payload;
      const isDuplicate = getMessageList(state$.value).find((message) => message.id === newMessage.id) != null;
      return !isDuplicate;
    }),

    // map directly to payload
    map((action) => {
      // TODO: add message preprocessing?
      return action.payload;
    }),

    // send to component messaging
    tap((message) => {
      const componentMessageType = MessagingHelper.mapMessagingTypeToComponentType(message.type);
      if (componentMessageType) {
        ComponentMessagingService.instance().publish({
          type: componentMessageType,
          payload: message,
        });
      }
      else {
        LOGGER.warn(`Messaging message type unmappable to component messages: ${message.type}`);
      }
    }),

    // ----- remove existing incoming session messages for the same session
    // TODO: this overwriting should be done based on BE's "key" property and not manually
    tap((message) => {
      if (MessagingMessageType.SESSION_INCOMING_NOTICE === message.type) {
        const typedMessage = message as ISessionIncomingNoticeMessagingMessage;
        // find messages of the same time and for the same session - and remove them
        (getMessageList(StoreService.getStore().getState()) || []).filter((item) => {
          // same message type
          if (MessagingMessageType.SESSION_INCOMING_NOTICE === item.type) {
            const typedItem = item as ISessionIncomingNoticeMessagingMessage;
            // same session
            if (typedItem.sessionId === typedMessage.sessionId) {
              StoreService.dispatchAction(removeMessage(typedItem.id));
            }
          }
        });
      }
    }),

    // store for displayin as notifications
    map((message) => {
      if (MessagingHelper.isDisplayableMessage(message)) {
        return storeMessage(message);
      }
      else {
        LOGGER.info(`Not storing messaging message of type ${message.type} (#${message.id})`);
        return noop();
      }
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error receiving messaging message', error);

      return o;
    })
  );
};

const registerTokenEffect = (action$: Observable<IPayloadAction<IRegistrationTokenPayload>>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.MESSAGING_REGISTER_TOKEN;
    }),

    mergeMap((action) => {
      const payload = action.payload;

      LOGGER.debug(`Register messaging token #${payload.registrationToken}`);

      return (EntityApiServiceRegistry.getService('Notification') as NotificationApiService).registrationToken(payload).pipe(
        actionThunk(action)
      );
    }),

    ignoreElements(),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error receiving messaging message', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

// NOTE: this reducer key is persisted in root store (pay attention if changing this name)
const messagingMessageList = (state: IMessagingMessage[] = [], action: IPayloadAction<IMessagingMessage | IIdPayload>) => {
  if (action.type === Actions.MESSAGING_STORE_MESSAGE) {
    const payload = action.payload as IMessagingMessage;
    const existing = state.find((item) => item.id === payload.id);
    if (existing == null) {
      return [
        ...state,
        payload,
      ]
        .sort(sortMessagingMessageList); // sort elements when adding a new one
    }
    else {
      return state;
    }
  }

  else if (action.type === Actions.MESSAGING_REMOVE_MESSAGE) {
    const payload = action.payload as IIdPayload;
    const messageId = payload.id;
    return state.filter((item) => item.id !== messageId);
  }

  else if (action.type === Actions.MESSAGING_REMOVE_ALL_MESSAGES) {
    return [];
  }

  return state;
};

function sortMessagingMessageList(a: IMessagingMessage, b: IMessagingMessage) {
  return a.sentDateTime < b.sentDateTime ? 1 : (a.sentDateTime === b.sentDateTime ? 0 : -1);
}


// --
// -------------------- Business Store

export const MessagingBusinessStore = {
  actions: {
    restoreMessage, receiveMessage, storeMessage, removeMessage, removeAllMessages,
    registerToken,
  },

  selectors: {
    getMessageList,
  },

  effects: {
    restoreMessageEffect,
    receiveMessageEffect,
    registerTokenEffect,
  },

  reducers: {
    messagingMessageList,
  },
};

// --
// ----- Exports

export default MessagingBusinessStore;
