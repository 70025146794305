import ITutoringSession from '@src/model/session/TutoringSession';

export const SESSION_CALENDAR_LIST_DATE_KEY_FORMAT = 'YYYY-MM-DD';

export interface ISessionCalendarListItem {
  dateKey: string;
  dayName: string;
  weekName: string | null;
  data: ITutoringSession[];
}
