import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import ITutoringSession from '@src/model/session/TutoringSession';


export default class TutoringSessionApiService extends EntityApiService<ITutoringSession> {

  static METHODS = {
    SEND_FEEDBACK: 'sendfeedback',
    PARTICIPATE: 'participate',
  };

  static create(entityName: string, api: IAbstractEntityApi<any, any>): TutoringSessionApiService {
    return new TutoringSessionApiService(entityName, api);
  }

  /** Send tutor session feedback to participant's parent. */
  sendTutorFeedback(tutoringSessionId: string): Observable<any> {
    return this.entityApi.updateEntityMethod(this.entityName, tutoringSessionId, TutoringSessionApiService.METHODS.SEND_FEEDBACK, {}).pipe(
      // check return value
      map((data) => {
        return data.payload;
      })
    );
  }


  /** Confirm participation in session. */
  participate(participationId: string): Observable<ITutoringSession> {
    return this.entityApi.updateEntityMethod(this.entityName, participationId, TutoringSessionApiService.METHODS.PARTICIPATE, {}).pipe(
      // check return value
      map((data) => {
        return data.payload;
      })
    );
  }
}
