import { IEducationArea } from '@src/model/user/EducationArea';

/**
 * EducationArea model helper.
 *
 * NOTE: All methods rely on the fact that area list contains ALL possible education areas with valid parent-child relationships. Iow. do now use partial education area lists!
 */
export default class EducationAreaModelHelper {

  /** Returns area object by give ID. */
  static getAreaById(id: string, areaList: IEducationArea[]): IEducationArea | undefined {
    return areaList.find(item => item.id === id);
  }

  /** Get root area for given education area. Returns given area if it's a root area. */
  static getRoot(area: IEducationArea, areaList: IEducationArea[]): IEducationArea {
    // check if we're already root
    if (area.parent == null) {
      return area;
    }

    let root = area;
    // iterate through entire list until root is found
    while (root.parent != null) {
      const parentArea = EducationAreaModelHelper.getParent(root, areaList);
      if (parentArea == null) {
        break;
      }
      root = parentArea;
    }

    return root;
  }

  /** Returns list of root areas (ie. areas with no parent). */
  static getRoots(areaList: IEducationArea[]): IEducationArea[] {
    return areaList.filter((item) => item.parent == null);
  }

  /** Returns list of direct children area for given education area. */
  static getChildren(area: IEducationArea, areaList: IEducationArea[]): IEducationArea[] {
    // iterate through entire list until root is found
    return areaList.filter((item) => item.parent != null && item.parent.id === area.id);
  }

  /** Returns direct parent area for given education area. */
  static getParent(area: IEducationArea, areaList: IEducationArea[]): IEducationArea | undefined {
    // check if we're already at the root
    if (area.parent == null) {
      return;
    }

    return EducationAreaModelHelper.getAreaById(area.parent.id, areaList);
  }

  /**
   * Get list of child areas with entire children tree.
   *
   * NOTE: makes copies of original objects because it needs to modify them
   */
  static getChildrenTree(area: IEducationArea, areaList: IEducationArea[]): IEducationArea[] {
    return areaList
      .filter((item) => item.parent != null && item.parent.id === area.id)
      .map<IEducationArea>((item) => {
        return {
          ...item,
          children: EducationAreaModelHelper.getChildrenTree(item, areaList),
        };
      });
  }


  /**
   * Get tree of parent areas. Tree can include/start with give area if parameter "includeOriginalArea" is true (default is true).
   * If give area has no parents and it is NOT included in the tree, undefined is returned.
   *
   * NOTE: makes copies of original objects because it needs to modify them
   */
  static getParentTree(area: IEducationArea, areaList: IEducationArea[], includeOriginalArea: boolean = true): IEducationArea | undefined {
    // get direct parent of given area which serves as a starting point of tree
    const directParent = EducationAreaModelHelper.getParent(area, areaList);
    if (directParent == null) {
      return includeOriginalArea ? { ...area } : undefined;
    }

    const tree = includeOriginalArea ? { ...area } : { ...directParent };
    let root = tree;
    while (root.parent != null) {
      const parent = EducationAreaModelHelper.getParent(root, areaList);
      if (parent != null) {
        tree.parent = { ...parent };
        root = tree.parent;
      }
    }

    return tree;
  }


  /** Sort education area list using default sort (by name). */
  static sortEducationAreas(a1: IEducationArea, a2: IEducationArea): number {
    return EducationAreaModelHelper.sortEducationAreasByName(a1, a2);
  }

  /** Sort function that sorts education areas by name. */
  static sortEducationAreasByName(a1: IEducationArea, a2: IEducationArea): number {
    return a1.name.localeCompare(a2.name);
  }

}
