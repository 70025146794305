import React from 'react';
import { connect } from 'react-redux';

import LocalePickerContainer from '@src/components/app/locale/LocalePickerContainer';
import LoginMenu from '@src/components/login/loginmenu/LoginMenu';
import UserMenu from '@src/components/login/usermenu/UserMenu';
import { IMessagingMessage } from '@src/model/messaging/messages';
import { IUserInfo } from '@src/model/user/User';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import { MessagingBusinessStore } from '@src/service/business/messaging/messagingBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import NotificationMenu from '../usermenu/NotificationMenu';

// -- Prop types
// ----------
export interface ILoginMenuContainerPublicProps {}
interface ILoginMenuContainerStateProps {
  currentUser: IUserInfo;
  isUserLoggedIn: boolean;
  messageList: IMessagingMessage[];
}
interface ILoginMenuContainerDispatchProps {
  removeMessage: (id: string) => void;
  removeAllMessages: () => void;
  doLogout: (thunkMap: IActionThunkMap) => void;
}
type ILoginMenuContainerProps = ILoginMenuContainerPublicProps & ILoginMenuContainerStateProps & ILoginMenuContainerDispatchProps;

// --
// ----- Component
class LoginMenuContainer extends React.Component<ILoginMenuContainerProps> {
  render() {
    return (
      <div className="lemon-loginMenuContainer">
        {this.props.isUserLoggedIn && (
          <React.Fragment>
            <NotificationMenu messages={this.props.messageList} onNotificationDismiss={this.onNotificationDismiss} onNotificationDismissAll={this.onNotificationDismissAll} />

            <UserMenu user={this.props.currentUser} onDoLogout={this.onDoLogout} />
          </React.Fragment>
        )}
        {!this.props.isUserLoggedIn && (
          <React.Fragment>
            <LocalePickerContainer standalone={true} />
            <LoginMenu />
          </React.Fragment>
        )}
      </div>
    );
  }

  onNotificationDismiss = (id: string) => {
    this.props.removeMessage(id);
  };

  onNotificationDismissAll = () => {
    this.props.removeAllMessages();
  };

  onDoLogout = () => {
    this.props.doLogout({
      success: (data) => {
        StaticPageRoutingHelper.routeToApp();
      },
    });
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ILoginMenuContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
  messageList: MessagingBusinessStore.selectors.getMessageList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILoginMenuContainerDispatchProps => ({
  removeMessage: (id: string) => dispatch(MessagingBusinessStore.actions.removeMessage(id)),
  removeAllMessages: () => dispatch(MessagingBusinessStore.actions.removeAllMessages()),
  doLogout: (thunkMap: IActionThunkMap) => dispatch(createActionThunk(LoginBusinessStore.actions.doLogout(), thunkMap)),
});

export default connect<ILoginMenuContainerStateProps, ILoginMenuContainerDispatchProps, ILoginMenuContainerPublicProps>(mapStateToProps, mapDispatchToProps)(LoginMenuContainer);
