import React from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SessionRejectionForm, { ISessionRejectionFormSubmitEvent, SessionRejectionFormType } from '@src/components/session/common/SessionRejectionForm';
import ITutoringSession from '@src/model/session/TutoringSession';
import { IUserInfo } from '@src/model/user/User';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';

// -- Prop types
// ----------
interface ISessionRejectionContainerOwnProps {
  tutoringSession: ITutoringSession;
  rejectionType: SessionRejectionFormType;

  // event handlers
  onOk?: (message?: string) => void;
  onCancel?: () => void;
}
// combine props base with already provided WithRouterProps
interface ISessionRejectionContainerStateProps {
  currentUser: IUserInfo;
}
type ISessionRejectionContainerProps = ISessionRejectionContainerOwnProps & ISessionRejectionContainerStateProps & WithRouterProps & IWithLocalizeOwnProps;


// -- Component
// ----------
/** Container component for creating tutoring session. */
class SessionRejectionContainer extends React.Component<ISessionRejectionContainerProps> {
  render() {
    return (
      <Modal
        visible={this.props.tutoringSession != null}
        closable={false}
        maskClosable={false}
        className="lemon-modal__modal--md"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer="" /* disable footer */
      >
        <SessionRejectionForm
          tutoringSession={this.props.tutoringSession}
          type={this.props.rejectionType}
          currentUser={this.props.currentUser}

          onFormSubmit={this.handleFormSubmit}
          onFormCancel={this.handleFormCancel}
        />
      </Modal>
    );
  }

  handleOk = () => {
    this.closeDialog();
  }

  handleCancel = () => {
    this.closeDialog();
  }

  handleFormCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
      this.closeDialog();
    }
  }

  handleFormSubmit = (value: ISessionRejectionFormSubmitEvent) => {
    if (this.props.onOk) {
      this.props.onOk(value.message);
      this.closeDialog();
    }
  }

  closeDialog = () => {
    // this.props.router.goBack();
  }

}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISessionRejectionContainerOwnProps): ISessionRejectionContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// TODO: improve component public props API when using third party HOCs
// type assertion to 'any' to because 'withRouter' doesn't do type reduction so injected props types are appearing in public props API
export default connect<ISessionRejectionContainerStateProps, any, ISessionRejectionContainerOwnProps>(mapStateToProps)(withRouter(withLocalize(SessionRejectionContainer)) as any);
