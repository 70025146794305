import React from 'react';
import { Layout } from 'antd';

import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';
import AdminHeader from './AdminHeader';

const AppLayout: React.SFC = (props: any) => {
  return (
    <Layout className="lemon-adminLayout__layout">
      <Layout.Header className="lemon-adminLayout__header">
        <LemonErrorBoundary showDefaultError={true}>
          <AdminHeader />
        </LemonErrorBoundary>
      </Layout.Header>

      <Layout.Content className="lemon-adminLayout__content">
        <LemonErrorBoundary>
          {props.children}
        </LemonErrorBoundary>
      </Layout.Content>
    </Layout>
  );
};

export default AppLayout;
