import { Observable, of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

import { IReview } from '@src/model/common/Review';
import IFile from '@src/model/file/File';
import ITutoringSession from '@src/model/session/TutoringSession';
import { TutoringSessionEndStatusEnum } from '@src/model/session/TutoringSessionEndStatus';
import { TutoringSessionStatusEnum } from '@src/model/session/TutoringSessionStatus';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import TutoringSessionApiService from '@src/service/api/session/TutoringSessionApiService';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { INewMessage } from '@src/service/business/session/timelineBusinessStore';
import LocalizeService from '@src/service/localize/LocalizeService';
import { getLogger } from '@src/service/util/logging/logger';
import { actionThunk, trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';

const LOGGER = getLogger('sessionBusinessStore');

// -
// -------------------- Types&Consts

export interface ISessionListFilter {
  startDateTime: string;
  endDateTime: string;
  tutoringSessionStatuses?: TutoringSessionStatusEnum[];
  tutoringSessionEndStatuses?: TutoringSessionEndStatusEnum[];
  tutoringSessionParticipantStatuses?: string;
  educationArea?: string;
  participant?: string;
  minItems?: number;
  tutoringRoomName?: string;
}

export interface ITutoringSessionStatusPayload {
  session: IIdPayload;
  message?: INewMessage;
}

export interface ITutoringSessionCreatePayload {
  session: ITutoringSession;
  files: IFile[];
}

export enum SessionCalendarListFilterStatus {
  ALL = 'ALL',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  PAST = 'PAST',
}

export interface ISessionReviewListFilter {
  [key: string]: any;
}

export interface ISessionCalendarListStatusFilter {
  status?: SessionCalendarListFilterStatus;
}

const SESSION_LIST_FILTER = '@@SESSION_LIST_FILTER';
const SESSION_CALENDAR_LIST_STATUS_FILTER = '@@SESSION_CALENDAR_LIST_STATUS_FILTER';
const SESSION_REVIEW_LIST_FILTER_NAME = '@@SESSION_REVIEW_LIST_FILTER_NAME';

// -
// -------------------- Selectors

/** Returns session from store. */
const getSession = (store: any): ITutoringSession => store.tutoringSession;

/** Returns review list of sessions from store. */
const getReviewSessionList = (store: any): ICollectionData<IReview> => store.reviewSessionList;

/** Returns session review list filter. */
const getReviewListFilter = (store: any): ISessionReviewListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SESSION_REVIEW_LIST_FILTER_NAME);

/** Returns list of sessions from store. */
const getSessionList = (store: any): ICollectionData<ITutoringSession> => store.tutoringSessionList;

/** Returns list of sessions from store. Duplicate of getSessionList for places where two lists are needed */
const getAditionalSessionList = (store: any): ICollectionData<ITutoringSession> => store.aditionalTutoringSessionList;

/** Returns session list filter. */
const getSessionListFilter = (store: any): ISessionListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SESSION_LIST_FILTER);

/** Returns session calendar filter. */
const getSessionCalendarListFilter = (store: any): ISessionCalendarListStatusFilter => ListFilterBusinessStore.selectors.getListFilter(store, SESSION_CALENDAR_LIST_STATUS_FILTER);

/** Returns list of sessions for display on user's calendar. Can be filtered if session calendar filter. */
const getSessionCalendarList = (store: any): ITutoringSession[] => {
  const sessionCalendarList = getSessionList(store);
  const sessionCalendarFilter = getSessionCalendarListFilter(store);

  return ((sessionCalendarList && sessionCalendarList.content) || []).filter((session) => {
    let statusShow = false;
    const statusId = session.sessionStatus && session.sessionStatus.id;

    // ----- check status
    if (sessionCalendarFilter != null) {
      // all future (confirmed and unconfirmed)
      if ((sessionCalendarFilter.status == null || sessionCalendarFilter.status === SessionCalendarListFilterStatus.ALL) && (statusId === TutoringSessionStatusEnum.CONFIRMED || statusId === TutoringSessionStatusEnum.IN_PROGRESS || statusId === TutoringSessionStatusEnum.SCHEDULED)) {
        statusShow = true;
      }
      // confirmed
      else if (sessionCalendarFilter.status === SessionCalendarListFilterStatus.CONFIRMED && (statusId === TutoringSessionStatusEnum.CONFIRMED || statusId === TutoringSessionStatusEnum.IN_PROGRESS)) {
        statusShow = true;
      }
      // unconfirmed (awaiting confirmation)
      else if (sessionCalendarFilter.status === SessionCalendarListFilterStatus.UNCONFIRMED && statusId === TutoringSessionStatusEnum.SCHEDULED) {
        statusShow = true;
      }
      // past (archive)
      else if (sessionCalendarFilter.status === SessionCalendarListFilterStatus.PAST && (statusId === TutoringSessionStatusEnum.DECLINED || statusId === TutoringSessionStatusEnum.ENDED || statusId === TutoringSessionStatusEnum.UNRESPONDED)) {
        statusShow = true;
      }
    }

    return statusShow;
  });
};

// -
// -------------------- Actions

const Actions = {
  TUTOR_SESSION_FETCH: 'TUTOR_SESSION_FETCH',
  TUTOR_SESSION_LOAD: 'TUTOR_SESSION_LOAD',
  TUTOR_SESSION_CLEAR: 'TUTOR_SESSION_CLEAR',

  TUTOR_SESSION_CREATE: 'TUTOR_SESSION_CREATE',
  TUTOR_SESSION_UPDATE: 'TUTOR_SESSION_UPDATE',
  TUTOR_SESSION_CONFIRM: 'TUTOR_SESSION_CONFIRM',
  TUTOR_SESSION_DECLINE: 'TUTOR_SESSION_DECLINE',
  TUTOR_SESSION_CANCEL: 'TUTOR_SESSION_CANCEL',
  TUTOR_SESSION_PARTICIPATE: 'TUTOR_SESSION_PARTICIPATE',

  TUTOR_SESSION_SEND_TUTOR_FEEDBACK: 'TUTOR_SESSION_SEND_FEEDBACK',

  TUTOR_SESSION_LIST_FETCH: 'TUTOR_SESSION_LIST_FETCH',
  TUTOR_SESSION_LIST_LOAD: 'TUTOR_SESSION_LIST_LOAD',
  TUTOR_SESSION_LIST_CLEAR: 'TUTOR_SESSION_LIST_CLEAR',

  ADITIONAL_TUTOR_SESSION_LIST_FETCH: 'ADITIONAL_TUTOR_SESSION_LIST_FETCH',
  ADITIONAL_TUTOR_SESSION_LIST_LOAD: 'ADITIONAL_TUTOR_SESSION_LIST_LOAD',
  ADITIONAL_TUTOR_SESSION_LIST_CLEAR: 'ADITIONAL_TUTOR_SESSION_LIST_CLEAR',

  REVIEW_SESSION_LIST_FETCH: 'REVIEW_SESSION_LIST_FETCH',
  REVIEW_SESSION_LIST_LOAD: 'REVIEW_SESSION_LIST_LOAD',
  REVIEW_SESSION_LIST_CLEAR: 'REVIEW_SESSION_LIST_CLEAR',
};

const fetchSession = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_SESSION_FETCH,
    payload: params,
  };
};

const loadSession = (data: ITutoringSession): IPayloadAction<ITutoringSession> => {
  return {
    type: Actions.TUTOR_SESSION_LOAD,
    payload: data,
  };
};

const clearSessionData = (): ILemonAction => {
  return {
    type: Actions.TUTOR_SESSION_CLEAR,
  };
};

const createSession = (data: ITutoringSessionCreatePayload): IPayloadAction<ITutoringSessionCreatePayload> => {
  return {
    type: Actions.TUTOR_SESSION_CREATE,
    payload: data,
  };
};

const updateSession = (data: ITutoringSession): IPayloadAction<ITutoringSession> => {
  return {
    type: Actions.TUTOR_SESSION_UPDATE,
    payload: data,
  };
};

const confirmSession = (session: IIdPayload, message?: INewMessage | undefined): IPayloadAction<ITutoringSessionStatusPayload> => {
  return {
    type: Actions.TUTOR_SESSION_CONFIRM,
    payload: {
      session,
      message,
    },
  };
};

const declineSession = (session: IIdPayload, message: INewMessage | undefined): IPayloadAction<ITutoringSessionStatusPayload> => {
  return {
    type: Actions.TUTOR_SESSION_DECLINE,
    payload: {
      session,
      message,
    },
  };
};

const cancelSession = (session: IIdPayload, message?: INewMessage | undefined): IPayloadAction<ITutoringSessionStatusPayload> => {
  return {
    type: Actions.TUTOR_SESSION_CANCEL,
    payload: {
      session,
      message,
    },
  };
};

const sessionParticipate = (session: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_SESSION_PARTICIPATE,
    payload: session,
  };
};

const sendTutorFeedback = (sessionId: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_SESSION_SEND_TUTOR_FEEDBACK,
    payload: { id: sessionId },
  };
};

const fetchSessionList = (params: ICollectionFetchPayload<ISessionListFilter>): IPayloadAction<ICollectionFetchPayload<ISessionListFilter>> => {
  return {
    type: Actions.TUTOR_SESSION_LIST_FETCH,
    payload: params,
  };
};

const loadSessionList = (data: ICollectionData<ITutoringSession>): IPayloadAction<ICollectionData<ITutoringSession>> => {
  return {
    type: Actions.TUTOR_SESSION_LIST_LOAD,
    payload: data,
  };
};

const clearSessionList = (): ILemonAction => {
  return {
    type: Actions.TUTOR_SESSION_LIST_CLEAR,
  };
};

const fetchAditionalSessionList = (params: ICollectionFetchPayload<ISessionListFilter>): IPayloadAction<ICollectionFetchPayload<ISessionListFilter>> => {
  return {
    type: Actions.ADITIONAL_TUTOR_SESSION_LIST_FETCH,
    payload: params,
  };
};

const loadAditionalSessionList = (data: ICollectionData<ITutoringSession>): IPayloadAction<ICollectionData<ITutoringSession>> => {
  return {
    type: Actions.ADITIONAL_TUTOR_SESSION_LIST_LOAD,
    payload: data,
  };
};

const clearAditionalSessionList = (): ILemonAction => {
  return {
    type: Actions.ADITIONAL_TUTOR_SESSION_LIST_CLEAR,
  };
};

const storeSessionListFilter = (listFilter: ISessionListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SESSION_LIST_FILTER, listFilter);
};

const storeSessionCalendarListFilter = (listFilter: ISessionCalendarListStatusFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SESSION_CALENDAR_LIST_STATUS_FILTER, listFilter);
};

const fetchReviewSessionList = (filter: ISessionReviewListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<ISessionReviewListFilter>> => {
  return {
    type: Actions.REVIEW_SESSION_LIST_FETCH,
    payload: {
      filter,
      page,
      size,
      sort,
    },
  };
};

const loadReviewSessionList = (data: ICollectionData<IReview>): IPayloadAction<ICollectionData<IReview>> => {
  return {
    type: Actions.REVIEW_SESSION_LIST_LOAD,
    payload: data,
  };
};

const clearReviewSessionList = (): ILemonAction => {
  return {
    type: Actions.REVIEW_SESSION_LIST_CLEAR,
  };
};

// -
// -------------------- Side-effects
const fetchReviewSessionListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISessionReviewListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REVIEW_SESSION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = action.payload;

      return EntityApiServiceRegistry.getService('Review')
        .fetchObjectList('sessions', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadReviewSessionList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching review session list', error);
      return o;
    })
  );
};

const fetchSessionEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSession(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TUTORING_SESSION_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching session', error);
      return o;
    })
  );
};

const createSessionEffect = (action$: Observable<IPayloadAction<ITutoringSessionCreatePayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const sessionData = action.payload.session;
      const sessionFiles = action.payload.files;

      let createdSession: ITutoringSession;
      let sessionId: string;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .createEntity(sessionData)
        .pipe(
          tap((data) => {
            createdSession = data;
            sessionId = createdSession.id;
          }),

          mergeMap(() => {
            let obsrv;
            if (sessionFiles.length) {
              obsrv = EntityApiServiceRegistry.getService('TutoringSession')
                .createSubentityList(sessionId, 'File', sessionFiles)
                .pipe(map(() => true));
            } else {
              obsrv = of(true);
            }

            return obsrv.pipe(
              reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('SESSION_CREATE.ERROR.ADD_FILES_TO_SESSION_FAILED')),
              catchError((error: any, o: Observable<any>) => {
                LOGGER.error('Error adding files to session', error);
                return of(error);
              })
            );
          }),

          map(() => {
            return createdSession;
          }),

          actionThunk(action)
        );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('SESSION_CREATE.MESSAGE.SESSION_CREATED'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SESSION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error creating session', error);
      return o;
    })
  );
};

const updateSessionEffect = (action$: Observable<IPayloadAction<ITutoringSession>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSession(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SESSION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating session', error);
      return o;
    })
  );
};

const confirmSessionEffect = (action$: Observable<IPayloadAction<ITutoringSessionStatusPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_CONFIRM;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const sessionId = action.payload.session.id;
      const confirmMessage = action.payload.message;

      let updatedSession: ITutoringSession;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .updateEntityMethod(sessionId, 'confirm', {})
        .pipe(
          // save updated session so we don't have to pull it through next entire rxjs stream
          tap((data) => {
            updatedSession = data;
          }),

          // send confirmation message
          mergeMap(() => {
            let obsrv;
            if (confirmMessage) {
              obsrv = EntityApiServiceRegistry.getService('Message').createEntity(confirmMessage);
            } else {
              obsrv = of(true);
            }

            return obsrv.pipe(
              reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTORING_SESSION_REJECTION.ERROR.MESSAGE_SENDING_FAILED')),

              catchError((error: any, o: Observable<any>) => {
                LOGGER.error('Error sending session confirm message', error);
                return of(o);
              })
            );
          }),

          actionThunk(action),

          map(() => updatedSession)
        );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CONFIRMED'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    map((data) => {
      return loadSession(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SESSION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error confirming session', error);
      return o;
    })
  );
};

const declineSessionEffect = (action$: Observable<IPayloadAction<ITutoringSessionStatusPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_DECLINE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const sessionId = action.payload.session.id;
      const declineMessage = action.payload.message;

      let updatedSession: ITutoringSession;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .updateEntityMethod(sessionId, 'decline', {})
        .pipe(
          // save updated session so we don't have to pull it through next entire rxjs stream
          tap((data) => {
            updatedSession = data;
          }),

          // send confirmation message
          mergeMap(() => {
            let obsrv;
            if (declineMessage) {
              obsrv = EntityApiServiceRegistry.getService('Message').createEntity(declineMessage);
            } else {
              obsrv = of(true);
            }

            return obsrv.pipe(
              reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTORING_SESSION_REJECTION.ERROR.MESSAGE_SENDING_FAILED')),

              catchError((error: any, o: Observable<any>) => {
                LOGGER.error('Error sending session decline message', error);
                return of(o);
              })
            );
          }),

          actionThunk(action),

          map(() => updatedSession)
        );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_DECLINED'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    map((data) => {
      return loadSession(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error declining session', error);
      return o;
    })
  );
};

const cancelSessionEffect = (action$: Observable<IPayloadAction<ITutoringSessionStatusPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_CANCEL;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const sessionId = action.payload.session.id;
      const cancelMessage = action.payload.message;

      let updatedSession: ITutoringSession;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .updateEntityMethod(sessionId, 'cancel', {})
        .pipe(
          // save updated session so we don't have to pull it through next entire rxjs stream
          tap((data) => {
            updatedSession = data;
          }),

          // send confirmation message
          mergeMap(() => {
            let obsrv;
            if (cancelMessage) {
              obsrv = EntityApiServiceRegistry.getService('Message').createEntity(cancelMessage);
            } else {
              obsrv = of(true);
            }

            return obsrv.pipe(
              reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTORING_SESSION_REJECTION.ERROR.MESSAGE_SENDING_FAILED')),

              catchError((error: any, o: Observable<any>) => {
                LOGGER.error('Error sending session cancel message', error);
                return of(o);
              })
            );
          }),

          actionThunk(action),

          map(() => updatedSession)
        );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CANCELED'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    map((data) => {
      return loadSession(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error canceling session', error);
      return o;
    })
  );
};

const sessionParticipateEffect = (action$: Observable<IPayloadAction<IIdPayload>>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_PARTICIPATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession') as TutoringSessionApiService).participate(data.id).pipe(
        trackAction(action),
        tap(() => LOGGER.info(`User is participating tutoring session ${data.id}`))
      );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('TUTORING_SESSION_PARTICIPATE.INFO_MESSAGE.PARTICIPATION_CONFIRMED'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    ignoreElements(), // no need to load returned data

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TUTORING_SESSION_PARTICIPATE.ERROR', 'TUTORING_SESSION_PARTICIPATE.ERROR.GENERAL')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating session', error);
      return o;
    })
  );
};

const sendTutorFeedbackEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_SEND_TUTOR_FEEDBACK;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession') as TutoringSessionApiService).sendTutorFeedback(data.id).pipe(
        trackAction(action),

        // service returns no usable data so we'll simply map ti to original payload
        map(() => {
          return data;
        })
      );
    }),

    stopGlobalProgress(),

    reportMessage((value: any) => ({
      message: LocalizeService.translate('TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_FEEDBACK_SENT'),
      type: UserFeedbackMessageType.USER,
      severity: UserFeedbackMessageSeverity.SUCCESS,
    })),

    map((data) => {
      // data contains original action payload ie. session ID object
      return fetchSession(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('SESSION_TUTOR_FEEDBACK.ERROR_MESSAGE.SEND_TUTOR_FEEDBACK')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating session', error);
      return o;
    })
  );
};

const fetchSessionListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISessionListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSessionList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching session list', error);
      return o;
    })
  );
};

const fetchAditionalSessionListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISessionListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ADITIONAL_TUTOR_SESSION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('TutoringSession')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadAditionalSessionList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching session list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const reviewSessionList = (state: ICollectionData<IReview> | null = null, action: IPayloadAction<ICollectionData<IReview>>) => {
  if (action.type === Actions.REVIEW_SESSION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.REVIEW_SESSION_LIST_CLEAR) {
    return null;
  }

  return state;
};

const tutoringSession = (state: ITutoringSession | null = null, action: IPayloadAction<ITutoringSession>) => {
  if (action.type === Actions.TUTOR_SESSION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_SESSION_CLEAR) {
    return null;
  }

  return state;
};

const tutoringSessionList = (state: ICollectionData<ITutoringSession> | null = null, action: IPayloadAction<ICollectionData<ITutoringSession>>) => {
  if (action.type === Actions.TUTOR_SESSION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_SESSION_LIST_CLEAR) {
    return null;
  }

  return state;
};

const aditionalTutoringSessionList = (state: ICollectionData<ITutoringSession> | null = null, action: IPayloadAction<ICollectionData<ITutoringSession>>) => {
  if (action.type === Actions.ADITIONAL_TUTOR_SESSION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ADITIONAL_TUTOR_SESSION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const SessionBusinessStore = {
  actions: {
    fetchSession,
    loadSession,
    clearSessionData,
    createSession,
    updateSession,
    confirmSession,
    cancelSession,
    sessionParticipate,
    declineSession,
    sendTutorFeedback,
    fetchSessionList,
    loadSessionList,
    clearSessionList,
    fetchAditionalSessionList,
    loadAditionalSessionList,
    clearAditionalSessionList,
    storeSessionListFilter,
    storeSessionCalendarListFilter,
    fetchReviewSessionList,
    loadReviewSessionList,
    clearReviewSessionList,
  },

  selectors: {
    getSession,
    getSessionList,
    getAditionalSessionList,
    getSessionListFilter,
    getSessionCalendarListFilter,
    getSessionCalendarList,
    getReviewSessionList,
    getReviewListFilter,
  },

  effects: {
    fetchSessionEffect,
    createSessionEffect,
    updateSessionEffect,
    confirmSessionEffect,
    cancelSessionEffect,
    declineSessionEffect,
    sessionParticipateEffect,
    sendTutorFeedbackEffect,
    fetchSessionListEffect,
    fetchAditionalSessionListEffect,
    fetchReviewSessionListEffect,
  },

  reducers: {
    tutoringSession,
    tutoringSessionList,
    aditionalTutoringSessionList,
    reviewSessionList,
  },
};

// --
// ----- Exports

export default SessionBusinessStore;
