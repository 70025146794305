import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RxHR, CoreOptions, RxHttpRequestResponse } from '@akanass/rx-http-request';

import IHttpRestClient from './IHttpRestClient';
import HttpErrorException from './HtppErrorException';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export default class HttpRestClient implements IHttpRestClient {

  // TODO: replace default options with request interceptors
  constructor(private defaultOptions?: any) {
  }

  // ---------- Resource CRUD methods

  fetchResource(url: string, resource: string, id: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  createResource(url: string, resource: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.POST,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  updateResource(url: string, resource: string, id: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.PUT,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  deleteResource(url: string, resource: string, id: string): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}`,
      method: HttpMethod.DELETE,
      options: {
        ...(this.useDefaultOptions()),
      },
    });
  }

  updateResourceMethod(url: string, resource: string, id: string, method: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${method}`,
      method: HttpMethod.PUT,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  // ---------- Resource list CRUD methods

  fetchResourceList(url: string, resource: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  createResourceList(url: string, resource: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.POST,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  deleteResourceList(url: string, resource: string, body: object[]): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.DELETE,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  // ---------- Subresource API

  fetchSubresource(url: string, resource: string, id: string, subresource: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  createSubresource(url: string, resource: string, id: string, subresource: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.POST,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  updateSubresource(url: string, resource: string, id: string, subresource: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.PUT,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  deleteSubresource(url: string, resource: string, id: string, subresource: string, body: object[]): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.DELETE,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  // ---------- Subresource list API

  fetchSubresourceList(url: string, resource: string, id: string, subresource: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  createSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[]): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.POST,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  updateSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[]): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.PUT,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  deleteSubresourceList(url: string, resource: string, id: string, subresource: string, body: object[]): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${id}/${subresource}`,
      method: HttpMethod.DELETE,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  // ---------- Custom resource method methods

  fetchMethod(url: string, resource: string, method: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  fetchNoMethod(url: string, resource: string, queryParams?: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}`,
      method: HttpMethod.GET,
      options: {
        ...(this.useDefaultOptions()),
        qs: queryParams,
      },
    });
  }

  createMethod(url: string, resource: string, method: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.POST,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  updateMethod(url: string, resource: string, method: string, body: object): Observable<any> {
    return this.doRequest({
      uri: `${url}/${resource}/${method}`,
      method: HttpMethod.PUT,
      options: {
        ...(this.useDefaultOptions()),
        body,
      },
    });
  }

  // ---------- REST API methods

  useDefaultOptions() {
    return (this.defaultOptions || {});
  }

  // tslint:disable-next-line:type-literal-delimiter
  protected doRequest(request: { uri: string, method: HttpMethod, options?: CoreOptions }): Observable<any> {
    request.options = {
      ...(request.options || {}),
    };

    return (RxHR as any)[request.method](request.uri, request.options).pipe(
      // extract response content
      map((httpResponse: RxHttpRequestResponse) => {
        // throw error to trigger error handlers downstream
        if (httpResponse.response.statusCode !== 200) {
          throw new HttpErrorException(httpResponse.response.statusCode, httpResponse.response.statusMessage);
        }

        return httpResponse.body;
      })
    );
  }

}
