import ConfigManager from '@src/service/util/config/ConfigManager';
import { DefaultConfigValueContainer } from '@src/service/util/config/ConfigValueContainer';
import IApiService from '@src/service/util/api/IApiService';
import IApiServiceRegistry from '@src/service/util/api/IApiServiceRegistry';
import LemonError from '@src/service/common/LemonError';

/** Interface for registry entries. */
export interface IApiServiceRegistryEntry {
  service: IApiService;
}

/** Interface for API service factory functions. */
export type ApiServiceFactoryFn = (entityName: string) => IApiService;

/** Container for holding registered API services. */
export default class ApiServiceRegistry extends ConfigManager<IApiServiceRegistryEntry> implements IApiServiceRegistry {

  constructor() {
    super('ApiServiceRegistry', new DefaultConfigValueContainer<any>({}));
  }

  getService<R>(name: string, failIfNotFound: boolean = true): any {
    const entry = this.getValue(name, failIfNotFound);

    return entry != null ? entry.service : null;
  }

  registerService(name: string, service: IApiService): void {
    // prevent overwriting previous service
    if (this.getService(name, false) != null) {
      // TODO: add specialized exception
      throw new LemonError(`Api service ${name} already registered!`);
    }

    this.setValue('', name, {
      service,
    } as IApiServiceRegistryEntry);
  }

  registerServiceFactory(name: string, factory: ApiServiceFactoryFn): void {
    const service = factory(name);

    this.setValue('', name, {
      service,
    } as IApiServiceRegistryEntry);
  }

}
