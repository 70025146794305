import React, { ReactNode } from 'react';

import ComponentMessagingService, { IComponentMessage, IComponentMessageListener, IComponentMessagePublisher } from '@src/service/util/pubsub/ComponentMessagingService';


// create new React context
const Context = React.createContext({} as IComponentMessagingContextValue);


// ----- types

// reexport types
export { IComponentMessage, IComponentMessagePublisher, IComponentMessageListener };

/** Describes component messaging React context value. */
export interface IComponentMessagingContextValue {
  messageSubscriber: (listener: IComponentMessageListener) => (() => void);
  messageProvider: IComponentMessagePublisher;
}


// -- ComponentMessaging context provider
// --------------------

export interface IComponentMessagingContextProviderPublicProps {
}
type IComponentMessagingContextProviderProps = IComponentMessagingContextProviderPublicProps;

interface IComponentMessagingContextProviderState {
  contextValue: IComponentMessagingContextValue;
}

class ComponentMessagingContextProvider extends React.Component<IComponentMessagingContextProviderProps, IComponentMessagingContextProviderState> {
  constructor(props: IComponentMessagingContextProviderProps) {
    super(props);

    this.state = {
      // value of context provided to cosumers
      contextValue: {
        messageSubscriber: this.messageSubscriber.bind(this),
        messageProvider: this.messageProvider.bind(this),
      },
    };
  }

  render() {
    return (
      <Context.Provider value={this.state.contextValue}>
        {this.props.children}
      </Context.Provider>
    );
  }

  /** Send component message to consumers. */
  messageProvider<T>(message: IComponentMessage<T>) {
    ComponentMessagingService.instance().publish(message);
  }

  /** Subscribe cosumer listener to messages pipeline */
  messageSubscriber(listener: IComponentMessageListener) {
    return ComponentMessagingService.instance().subscribe(listener);
  }
}


// -- ComponentMessaging context consumer
// --------------------

export interface IComponentMessagingContextConsumerPublicProps {
  context: IComponentMessagingContextValue;
  listener?: IComponentMessageListener;
  children: (value: IComponentMessagingContextValue) => ReactNode;
}
type IComponentMessagingContextConsumerProps = IComponentMessagingContextConsumerPublicProps;

interface IComponentMessagingContextConsumerState {
  unsubscriber: (() => void) | null;
}

// tslint:disable-next-line:max-classes-per-file - context needs two classes (provider and consumer)
class ComponentMessagingContextConsumer extends React.Component<IComponentMessagingContextConsumerProps, IComponentMessagingContextConsumerState> {
  constructor(props: IComponentMessagingContextConsumerProps) {
    super(props);

    this.state = {
      unsubscriber: null,
    };
  }

  componentDidMount() {
    this.subscribeListener(this.props.context);
  }

  componentWillUnmount() {
    if (this.state.unsubscriber != null) {
      this.state.unsubscriber();
    }
  }

  render() {
    return (
      <Context.Consumer>
        {(value: IComponentMessagingContextValue) => {
          return this.props.children(value);
        }}
      </Context.Consumer>
    );
  }

  private subscribeListener(value: IComponentMessagingContextValue) {
    if (this.state.unsubscriber == null && this.props.listener) {
      this.setState({
        unsubscriber: value.messageSubscriber(this.props.listener),
      });
    }
  }
}


// -- ComponentMessaging context consumer wrapper
// -- Injects messaging context into consumer component
// --------------------

export interface IComponentMessagingContextConsumerWrapperPublicProps {
  /**
   * Component message listener function.
   * TS has problems when infering types on generic functions so we cannot use IComponentMessageListener type because it fails in components using it
   */
  listener?: (message: IComponentMessage<any>) => void;

  // implicite children
  children: (value: IComponentMessagingContextValue) => ReactNode;
}
type IComponentMessagingContextConsumerWrapperProps = IComponentMessagingContextConsumerWrapperPublicProps;

const ComponentMessagingContextConsumerWrapper = (props: IComponentMessagingContextConsumerWrapperProps) => {
  return (
    <Context.Consumer>
      {(value) => (
        <ComponentMessagingContextConsumer listener={props.listener} context={value}>
          {props.children}
        </ComponentMessagingContextConsumer>
      )}
    </Context.Consumer>
  );
};


// ----- exports

const ComponentMessagingContext = {
  Provider: ComponentMessagingContextProvider,
  Consumer: ComponentMessagingContextConsumerWrapper,
};

export default ComponentMessagingContext;
