import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminMenu from '@src/components/common/admin/adminmenu/AdminMenu';

// -- Prop types
// ----------
export interface IAadminHeaderPublicProps {
}
type IAdminHeaderProps = IAadminHeaderPublicProps & IWithLocalizeOwnProps;

// --
// ----- Component
class AdminHeader extends React.Component<IAdminHeaderProps> {
  render() {
    return (
      <AdminMenu />
    );
  }
}

export default withLocalize<IAadminHeaderPublicProps>(AdminHeader as any);
