import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faArrowsAlt,
  faBars,
  faBell,
  faBook,
  faCalendar,
  faCalendarPlus,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faCloud,
  faCoffee,
  faCog,
  faCommentDots,
  faCompress,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExpand,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFolder,
  faHighlighter,
  faHome,
  faInfoCircle,
  faLemon,
  faLock,
  faMinus,
  faMobileAlt,
  faPaperclip,
  faPaperPlane,
  faPencilAlt,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faSave,
  faSearch,
  faSignOutAlt,
  faSync,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnlock,
  faUser,
  faMicrophone,
  faMicrophoneSlash,
  faVideoSlash,
  faUsers,
  faUserPlus,
  faStar,
  faVolumeMute,
  faVolumeDown,
  faVolumeOff,
  faVolumeUp,
  faVideo,
  faComment,
  faPause,
  faStop,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowsAlt,
  faCoffee,
  faBars,
  faBell,
  faBook,
  faCalendar,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircle,
  faClock,
  faTimes,
  faTimesCircle,
  faCloud,
  faCompress,
  faTrashAlt,
  faChevronDown,
  faDownload,
  faEdit,
  faEllipsisH,
  faSignOutAlt,
  faEye,
  faEyeSlash,
  faMicrophone,
  faMicrophoneSlash,
  faFile,
  faFileAlt,
  faFolder,
  faHighlighter,
  faHome,
  faInfoCircle,
  faLemon,
  faCalendarPlus,
  faChevronLeft,
  faChevronCircleLeft,
  faLock,
  faEnvelope,
  faCommentDots,
  faMinus,
  faMobileAlt,
  faPaperclip,
  faPencilAlt,
  faAddressCard,
  faPaperPlane,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faChevronRight,
  faChevronCircleRight,
  faSave,
  faSearch,
  faExpand,
  faCog,
  faSync,
  faUnlock,
  faChevronUp,
  faUser,
  faVideo,
  faVideoSlash,
  faUsers,
  faUserPlus,
  faStar,
  faVolumeMute,
  faVolumeOff,
  faVolumeDown,
  faVolumeUp,
  faVideo,
  faComment,
  faPause,
  faStop
);

/** Lemon application fontawesome icon set. */
export const FontAwesomeIconSet: ILemonApplicationIconSet = {
  // ----- actions
  actionSend: 'paper-plane',
  navMobileMenu: 'bars',
  bell: 'bell',
  book: 'book',
  calendar: 'calendar',
  camera: 'video',
  cameraOff: 'video-slash',
  caretRight: 'caret-right',
  check: 'check',
  checkCircle: 'check-circle',
  clockCircle: 'clock',
  close: 'times',
  closeCircle: 'times-circle',
  cloud: 'cloud',
  avatarFallback: 'user',
  delete: 'trash-alt',
  down: 'chevron-down',
  collapse: 'compress',
  downCircle: 'chevron-circle-down',
  download: 'download',
  edit: 'edit',
  ellipsis: 'ellipsis-h',
  export: 'sign-out-alt',
  eye: 'eye',
  eyeInvisible: 'eye-slash',
  file: 'file',
  fileText: 'file-alt',
  folder: 'folder',
  highlight: 'highlighter',
  home: 'home',
  infoCircle: 'info-circle',
  sessionCreated: 'calendar-plus',
  left: 'chevron-left',
  lock: 'lock',
  mail: 'envelope',
  message: 'comment-dots',
  microphone: 'microphone',
  microphoneOff: 'microphone-slash',
  minus: 'minus',
  mobile: 'mobile-alt',
  notificationIndicator: 'circle',
  pan: 'arrows-alt',
  paperClip: 'paperclip',
  pencil: 'pencil-alt',
  picLeft: 'address-card',
  playCircle: 'play-circle',
  plus: 'plus',
  plusCircle: 'plus-circle',
  right: 'chevron-right',
  rollback: 'undo',
  rate: 'lemon',
  save: 'save',
  search: 'search',
  star: 'star',
  expand: 'expand',
  setting: 'cog',
  sync: 'sync',
  unlock: 'unlock',
  up: 'chevron-up',
  upCircle: 'chevron-circle-up',
  user: 'user',
  videoCamera: 'video',
  userAdd: 'user-plus',
  users: 'users',
  volumeMute: 'volume-mute',
  volumeOff: 'volume-off',
  volumeDown: 'volume-down',
  volumeUp: 'volume-up',
  video: 'video',
  comment: 'comment',
  pause: 'pause',
  stop: 'stop',
};
