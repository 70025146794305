import React from 'react';
import { Row, Col } from 'antd';

import LoginMenuContainer from '@src/components/login/loginmenu/LoginMenuContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NavMenuContainer from '@src/components/login/navmenu/NavMenuContainer';


// -- Prop types
// ----------
export interface IAppHeaderPublicProps {
}
type IAppHeaderProps = IAppHeaderPublicProps & IWithLocalizeOwnProps;


// --
// ----- Component
class AppHeader extends React.Component<IAppHeaderProps> {
  render() {
    return (
      <Row className="lemon-appLayout__headerContent">
        <Col xs={8} sm={18} md={17} lg={19} xxl={20}>
          <NavMenuContainer />
        </Col>

        <Col xs={16} sm={6} md={7} lg={5} xxl={4}>
          <LoginMenuContainer />
        </Col>
      </Row>
    );
  }

}

export default withLocalize<IAppHeaderPublicProps>(AppHeader as any);
