import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NotificationInfoBarListItem from '@src/components/login/notificationinfo/NotificationInfoBarListItem';
import { IMessagingMessage } from '@src/model/messaging/messages';
import { INotificationInfoAppMessage } from '@src/service/business/common/notificationInfoBusinessStore';

/**
 * Render callback for rendering messaging messages (ie. messages from BE).
 *
 * Return values:
 *  - ReactNode - if callback return ReactNode, that node is rendered
 *  - null - if callback returns null, nothing is rendered
 *  - undefined - if callback retuns undefined, default renderer is used
 */
export type MessagingMessageRenderFn = (message: IMessagingMessage) => React.ReactNode;

/**
 * Render callback for rendering notification info messages (ie. various UI messsages).
 *
 * Return values:
 *  - ReactNode - if callback return ReactNode, that node is rendered
 *  - null - if callback returns null, nothing is rendered
 *  - undefined - if callback retuns undefined, default renderer is used
 */
export type NotificationInfoMessageRenderFn = (message: INotificationInfoAppMessage) => React.ReactNode;

/**
 * Render list of static messages. Return a list of ReactNodes to be rendered as messages.
 */
export type StaticMessagesRenderFn = () => React.ReactNode[];

// --
// ---------- Prop types

export interface INotificationInfoBarListPublicProps {
  staticMessageList?: React.ReactNode[];
  messagingMessageList?: IMessagingMessage[];
  notificationInfoMessageList?: INotificationInfoAppMessage[];

  renderMessagingMessage?: MessagingMessageRenderFn;
  renderNotificationInfoMessage?: NotificationInfoMessageRenderFn;
}
type INotificationInfoBarListProps = INotificationInfoBarListPublicProps & IWithLocalizeOwnProps;

// --
// ---------- Component

/**
 * Displays notification info bar message lists.
 *
 * Notification info bar supports messages from various sources. Those message have different structures which cannot be distiguished in runtime (eg. no common type property).
 * Because of that, component currently takes all those lists as separate arguments and renders using appropriate item component.
 */
class NotificationInfoBarList extends React.Component<INotificationInfoBarListProps> {
  render() {
    return (
      <React.Fragment>
        {/* static messages */}
        {this.props.staticMessageList &&
          this.props.staticMessageList.length > 0 &&
          this.props.staticMessageList.map((message) => {
            return message;
          })}

        {/* messaging messages */}
        {this.props.messagingMessageList &&
          this.props.messagingMessageList.length > 0 &&
          this.props.messagingMessageList.map((message) => {
            const result = this.props.renderMessagingMessage != null ? this.props.renderMessagingMessage(message) : undefined;
            // when undefined, render using default renderer - NO renderer for theses messages
            if (result === undefined) {
              return null;
            } else {
              return <React.Fragment key={message.id}>{result}</React.Fragment>;
            }
          })}

        {/* notification info messages */}
        {this.props.notificationInfoMessageList &&
          this.props.notificationInfoMessageList.length > 0 &&
          this.props.notificationInfoMessageList.map((message) => {
            const result = this.props.renderNotificationInfoMessage != null ? this.props.renderNotificationInfoMessage(message) : undefined;
            // when undefined, render using default renderer
            if (result === undefined) {
              return <NotificationInfoBarListItem key={message.id}>{message.data.message}</NotificationInfoBarListItem>;
            } else {
              return result;
            }
          })}
      </React.Fragment>
    );
  }
}

export default withLocalize<INotificationInfoBarListPublicProps>(NotificationInfoBarList as any);
