import { notification } from 'antd';
import moment from 'moment-timezone';
import { RedirectFunction, RouterState } from 'react-router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, share, tap } from 'rxjs/operators';

import CollectionHelperService from '@src/model/common/CollectionHelperService';
import { sessionExpiredApiResponseErrorHandler, userAuthenticationApiResponseErrorHandler } from '@src/service/api/error/apiServiceErrorHandlers';
import LoginApiService from '@src/service/api/login/LoginApiService';
import NotificationApiService from '@src/service/api/messaging/NotificationApiService';
import { registerAPiServiceErrorHandler } from '@src/service/api/registry/entity/EntityApiService';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import TutoringRoomApiService from '@src/service/api/room/TutoringRoomApiService';
import TutoringSessionApiService from '@src/service/api/session/TutoringSessionApiService';
import { hasUserToken, initializeCurrentUser, isUserLoggedIn } from '@src/service/business/login/loginBusinessService';
import AppConfigService from '@src/service/common/AppConfigService';
import { FontAwesomeIconSet } from '@src/service/common/icon/FontAwesomeIconSet';
import { LemonApplicationIconResolver } from '@src/service/common/icon/LemonApplicationIconResolver';
import { authServiceInitializer, publicServiceInitializer } from '@src/service/common/initialize/initializer';
import NumberFormatService from '@src/service/common/numberformat/NumberFormatService';
import { NUMERAL_LOCALES } from '@src/service/i18n/numeral';
import { getLogger } from '@src/service/util/logging/logger';

const LOGGER = getLogger('appInit');

// ----- hotjar doesn't have TS typings and declares global object so we have to include it like this
// tslint:disable-next-line:no-var-requires
const { hotjar } = require('react-hotjar');

/** App initialization promise - used when caller needs to wait for app initialization event. */
const PUBLIC_APP_INITIALIZED_OBSERVABLE = new BehaviorSubject<boolean | null>(null);
const AUTH_APP_INITIALIZED_OBSERVABLE = new BehaviorSubject<boolean | null>(null);

/** Synchronous method that returns true if app is already initialized and false otherwise. */
export function isPublicAppInitialized(): boolean {
  return PUBLIC_APP_INITIALIZED_OBSERVABLE.value === true;
}
export function isAuthAppInitialized(): boolean {
  return AUTH_APP_INITIALIZED_OBSERVABLE.value === true;
}

/** Asynchronous method that returns promise that is resolved when app is initialized. */
export function publicAppInitialization(): Observable<boolean | null> {
  return PUBLIC_APP_INITIALIZED_OBSERVABLE.asObservable();
}
export function authAppInitialization(): Observable<boolean | null> {
  return AUTH_APP_INITIALIZED_OBSERVABLE.asObservable();
}

/** Public app observable. */
const PUBLIC_APP_OBSRV = of(true).pipe(
  // ----- load initial data
  tap(() => {
    LOGGER.info('Initializing public app ...');
  }),

  // ----- initialize icon set
  tap(() => {
    LemonApplicationIconResolver.initialize(FontAwesomeIconSet);
    LOGGER.info('Initialized icon set');
  }),

  // ----- initialize entity services
  tap(() => {
    // register API service error handlers
    registerAPiServiceErrorHandler(sessionExpiredApiResponseErrorHandler);
    registerAPiServiceErrorHandler(userAuthenticationApiResponseErrorHandler);

    // register API services
    EntityApiServiceRegistry.registerService('LOGIN', 'AUTH', LoginApiService.create);
    EntityApiServiceRegistry.registerAuthService('User');
    EntityApiServiceRegistry.registerAuthService('Tutor');
    EntityApiServiceRegistry.registerService('Role');
    EntityApiServiceRegistry.registerService('StudentClass');
  }),

  // fetch collections
  mergeMap(() => {
    return CollectionHelperService.loadCollections(['Role', 'StudentClass']);
  }),

  // setup default datetime timezone
  tap(() => {
    moment.tz.setDefault(AppConfigService.getValue('app.defaultTimeZone'));
  }),

  // register number format locales (Numeral.js)
  tap(() => {
    const locales = AppConfigService.getValue('app.locales');
    locales.forEach((locale: string) => {
      const localeData: NumeralJSLocale = NUMERAL_LOCALES[locale];
      if (localeData) {
        NumberFormatService.register('locale', locale, localeData);
        LOGGER.info(`Data for locale '${locale}' registered.`);
      } else {
        LOGGER.error(`Data for locale '${locale}' not found!`);
      }
    });
  }),

  // initialize current user
  mergeMap(() => {
    if (hasUserToken()) {
      return initializeCurrentUser();
    } else {
      return of(true);
    }
  }),

  // initialize GUI component default config
  // TODO: could we move this to service initializers? (although components should not be in *service* initializers)
  tap(() => {
    // ----- Ant notifications
    const NOTIFICATION_DEFAULT_CONFIG = AppConfigService.getValue('components.notification.defaultConfig') || {};
    notification.config(NOTIFICATION_DEFAULT_CONFIG);
  }),

  mergeMap(() => {
    LOGGER.info('Starting public initializers ...');
    return publicServiceInitializer();
  }),

  // ----- initialize hotjar tracking SW
  tap(() => {
    const hotjarEnabled: boolean = !!AppConfigService.getValue('tracking.hotjar.enabled');
    if (hotjarEnabled) {
      LOGGER.info('Initializing hotjar ...');
      hotjar.initialize(AppConfigService.getValue('tracking.hotjar.siteId'), AppConfigService.getValue('tracking.hotjar.snippetVersion'));
    } else {
      LOGGER.info('Hotjar disabled ...');
    }
  }),

  // app initialized
  map(() => {
    LOGGER.info('Public app initialized succesfully');

    // return true after successful init
    return true;
  }),

  // concat auth observable if user is already logged in
  mergeMap(() => {
    if (isUserLoggedIn()) {
      return AUTH_APP_OBSRV;
    } else {
      return of(true);
    }
  }),

  // handle errors
  catchError((error: any, caught: Observable<any>) => {
    LOGGER.error('Public app initialization error', error);

    PUBLIC_APP_INITIALIZED_OBSERVABLE.next(false);

    return throwError(error);
  }),

  finalize(() => {
    PUBLIC_APP_INITIALIZED_OBSERVABLE.next(true);
  }),

  // share subscriptions so this chain gets executed ONLY once!
  share()
);

/** Auth app observable. */
const AUTH_APP_OBSRV = of(true).pipe(
  // ----- load initial data
  tap(() => {
    LOGGER.info('Initializing auth app ...');
  }),

  // ----- initialize entity services
  tap(() => {
    EntityApiServiceRegistry.registerAuthService('EducationLevel');
    EntityApiServiceRegistry.registerAuthService('EducationArea');
    EntityApiServiceRegistry.registerAuthService('StudentGrade');
    EntityApiServiceRegistry.registerAuthService('TutoringSessionGoal');
    EntityApiServiceRegistry.registerAuthService('TutoringSession', 'Session', TutoringSessionApiService.create);
    EntityApiServiceRegistry.registerAuthService('TutoringSessionEndStatus');
    EntityApiServiceRegistry.registerAuthService('TutoringSessionParticipantRole');
    EntityApiServiceRegistry.registerAuthService('TutoringSessionParticipantStatus');
    EntityApiServiceRegistry.registerAuthService('TutoringSessionStatus');
    EntityApiServiceRegistry.registerAuthService('Timeline');
    EntityApiServiceRegistry.registerAuthService('TutoringRoom', 'Session', TutoringRoomApiService.create);
    EntityApiServiceRegistry.registerAuthService('Message');
    EntityApiServiceRegistry.registerAuthService('Review');
    EntityApiServiceRegistry.registerAuthService('Folder');
    EntityApiServiceRegistry.registerAuthService('Notification', undefined, NotificationApiService.create);
    EntityApiServiceRegistry.registerAuthService('Tag');
    EntityApiServiceRegistry.registerAuthService('Room');
  }),

  // fetch collections
  mergeMap(() => {
    return CollectionHelperService.loadCollections([
      'EducationLevel',
      'EducationArea',
      'StudentGrade',
      'TutoringSessionGoal',
      // 'TutoringSessionEndStatus', 'TutoringSessionParticipantRole', 'TutoringSessionParticipantStatus', 'TutoringSessionStatus',
    ]);
  }),

  mergeMap(() => {
    LOGGER.info('Starting auth initializers ...');
    return authServiceInitializer();
  }),

  // app initialized
  // tslint:disable-next-line: no-identical-functions
  map(() => {
    LOGGER.info('Auth app initialized succesfully');
    // return true after successful init
    return true;
  }),

  // handle errors
  catchError((error: any, caught: Observable<any>) => {
    LOGGER.error('App initialization error', error);

    AUTH_APP_INITIALIZED_OBSERVABLE.next(false);

    return throwError(error);
  }),

  finalize(() => {
    AUTH_APP_INITIALIZED_OBSERVABLE.next(true);
  }),

  // share subscriptions so this chain gets executed ONLY once!
  share()
);

/**
 * Public application init sequence.
 */
export function initPublicApp(nextState: RouterState, replace: RedirectFunction, callback?: (error?: any) => any) {
  // wait for app init
  if (!isPublicAppInitialized()) {
    PUBLIC_APP_OBSRV.subscribe(
      (data: any) => {
        // next value
      },
      (err: any) => {
        if (callback != null) {
          callback(err);
        }
        LOGGER.error('Error initializing application', err);
      },
      () => {
        if (callback != null) {
          callback();
        }
      }
    );
  }
  // already initialized, proceed
  else {
    if (callback != null) {
      callback();
    }
  }
}

/**
 * Auth application init sequence.
 */
export function initAuthApp(nextState: RouterState, replace: RedirectFunction, callback?: (error?: any) => any) {
  // wait for app init
  if (!isAuthAppInitialized()) {
    AUTH_APP_OBSRV.subscribe(
      (data: any) => {
        // next value
      },
      // tslint:disable-next-line: no-identical-functions
      (err: any) => {
        if (callback != null) {
          callback(err);
        }
        LOGGER.error('Error initializing application', err);
      },
      // tslint:disable-next-line: no-identical-functions
      () => {
        if (callback != null) {
          callback();
        }
      }
    );
  }
  // already initialized, proceed
  else {
    if (callback != null) {
      callback();
    }
  }
}
