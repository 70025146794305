import React from 'react';

import { IUserApproval } from '@src/service/business/userapproval/types';
import UserApprovalListItem from './UserApprovalListItem';

// --
// ---------- Prop types

export interface IUserApprovalListPublicProps {
  items: IUserApproval[];
  onUserApprovalGrant?: (approval: IUserApproval) => void;
  onUserApprovalDismiss?: (approval: IUserApproval) => void;
}
type IUserApprovalListProps = IUserApprovalListPublicProps;

export default class UserApprovalList extends React.PureComponent<IUserApprovalListProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.items.map(item => (
          <UserApprovalListItem key={item.id} item={item} onUserApprovalGrant={this.onUserApprovalGrant} onUserApprovalDismiss={this.onUserApprovalDismiss} />
        ))}
      </React.Fragment>
    );
  }

  onUserApprovalDismiss = (event: IUserApproval) => {
    if (this.props.onUserApprovalDismiss) {
      this.props.onUserApprovalDismiss(event);
    }
  }

  onUserApprovalGrant = (event: IUserApproval) => {
    if (this.props.onUserApprovalGrant) {
      this.props.onUserApprovalGrant(event);
    }
  }

}
