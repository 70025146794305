import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------
export interface ILoginMenuPublicProps {}
type ILoginMenuProps = ILoginMenuPublicProps & IWithLocalizeOwnProps;

// --
// ----- Component
class LoginMenu extends React.Component<ILoginMenuProps> {
  render() {
    return (
      <div className="lemon-loginMenu__loginMenu" data-test-id="lemon-loginMenu__loginMenu">
        <Link data-test-id="lemon-loginMenu__loginLink" to="/login">
          <Button className="lemon-loginMenu__button" type="link">
            {this.props.translate('LOGIN_MENU.LOGIN_LABEL')}
          </Button>
        </Link>
      </div>
    );
  }
}

export default withLocalize<ILoginMenuPublicProps>(LoginMenu as any);
