import { Empty, Table } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { publicFolder } from '@src/components/repository/UserFileRepositoryContainer';
import IFolder from '@src/model/file/Folder';

// min width taken by a column, lower number is ignored, without a number it would justify space
const minColumnWidth = 30;

export interface IFolderListOwnProps {
  folders: IFolder[];
  selected?: IFolder;
  onFolderSelect: (folder: IFolder) => void;
  onPageChange: (page: number, pageSize?: number) => void;
  onSelectedChange: (data: IFolder | undefined) => void;
}
type IFolderListProps = IFolderListOwnProps & IWithLocalizeOwnProps;

interface IFolderListState { }

class FolderList extends React.Component<IFolderListProps, IFolderListState> {
  rowSelection() {
    return {
      selections: false,
      hideDefaultSelections: true,
      selectedRowKeys: this.props.selected ? [this.props.selected.id] : [],
      onSelect: (record: IFolder) => {
        this.props.onSelectedChange(record);
      },
      getCheckboxProps: (record: IFolder) => ({
        // disabled: ,
        name: record.name,
      }),
    };
  }

  render() {
    const columns = [
      {
        key: 'type',
        width: minColumnWidth,
        render: (record: IFolder) => {
          return record.id === publicFolder.id ? <LemonIcon name="cloud" /> : <LemonIcon name="folder" />; /* TODO: icon theme="outlined" */
        },
      },
      {
        title: this.props.translate('FILE_LIST.NAME_LABEL'),
        key: 'name',
        render: (record: IFolder) => {
          return (
            <a onClick={() => this.props.onFolderSelect(record)} data-test-id={`lemon-fileRepository__fileLink_openFolder_${record.id}`}>
              {record.name}
            </a>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Table
          className="lemon-repository__folderListTable"
          columns={columns}
          dataSource={this.props.folders}
          rowSelection={this.rowSelection()}
          rowKey={(record) => record.id}
          size="small"
          scroll={{ x: true }}
          locale={{
            emptyText: <Empty description={this.props.translate('FILE_LIST.EMPTY_FOLDER_TEXT')} />,
          }}
          onRow={this.onRow}
          pagination={{
            // TODO: define paging as the component doesn't show full list from BE
            hideOnSinglePage: true,
            showSizeChanger: true,
            // total: this.props.files.page.totalElements,
            // pageSize: this.props.files.page.size,
            onChange: this.props.onPageChange,
            onShowSizeChange: this.props.onPageChange,
          }}
        />
      </React.Fragment>
    );
  }

  onRow = (record: IFolder) => ({
    onClick: () => {
      if (this.props.selected && this.props.selected.id === record.id) {
        this.props.onSelectedChange(undefined);
      } else {
        this.props.onSelectedChange(record);
      }
    },
  });
}

export default withLocalize<IFolderListOwnProps>(FolderList as any);
