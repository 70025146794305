import { Empty, Table, Typography } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import AppConfigService from '@src/service/common/AppConfigService';

import FileDropdownMenu from '@src/components/common/file/FileDropdownMenu';
import FileItemPreview from '@src/components/common/file/FileItemPreview';
import LemonIcon from '@src/components/common/image/LemonIcon';
import TagList from '@src/components/repository/common/TagList';

import FileUtils from '@src/components/common/file/FileUtils';
import IFileSystemElement from '@src/model/file/FileSystemElement';
import IFolder from '@src/model/file/Folder';
import { ITag, ITagCreate } from '@src/model/file/Tag';
import { ICollectionDataCount } from '@src/service/business/common/types';
import { IFileListTagFilter } from '@src/service/business/file/fileListBusinessStore';
import {hasSorter, sortOrder} from '@src/service/util/config/TableUtils';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { getLogger } from '@src/service/util/logging/logger';
import { ColumnProps } from 'antd/es/table';
import { Link } from 'react-router';

const LOGGER = getLogger('FileList');
const DEFAULT_SORT = AppConfigService.getValue('components.repository.sort.type.ascend.id');

const DATE_WITH_YEAR_FORMAT = AppConfigService.getValue('dateTimeFormat.dateWithYear');

export interface IFileListOwnProps {
  selected: IFileSystemElement[];
  files: IFileSystemElement[];
  pagination: ICollectionDataCount;
  tagList: ITag[];
  foldersSelectable?: boolean;
  showActionMenu?: boolean;
  isRepositoryRoute?: boolean;
  onFolderSelect: (folder: IFolder) => void;
  onDelete: (record: IFileSystemElement[]) => void;
  onRename: (record: IFileSystemElement, newName: string) => void;
  onMove: (record: IFileSystemElement[]) => void;
  onSelectedChange: (data: IFileSystemElement[]) => void;
  onSortChange: (order: string) => void;
  onPageChange: (page: number, pageSize?: number) => void;
  onTagAdd: (record: IFileSystemElement, tag: ITag | ITagCreate) => void;
  onTagRemove: (record: IFileSystemElement, tag: ITag) => void;
  onTagSearchEvent: (event?: ILemonEvent<IFileListTagFilter>) => void;
}

type IFileListProps = IFileListOwnProps & IWithLocalizeOwnProps & IWithNumberFormatOwnProps;

interface IFileListState { }

class FileList extends React.Component<IFileListProps, IFileListState> {
  private columns: Array<ColumnProps<IFileSystemElement>> = [
    {
      width: '6%',
      key: 'type',
      title: this.props.translate('FILE_LIST.TYPE_LABEL'),
      align: 'center',
      sorter: hasSorter('type'),
      defaultSortOrder: sortOrder(DEFAULT_SORT),
      render: (record: IFileSystemElement) => {
        return FileUtils.isFile(record) ? <LemonIcon name="file" size="xlarge" /> : <LemonIcon name="folder" size="xlarge" />; /* TODO: icon theme="outlined" */
      },
    },

    {
      key: 'name',
      width: '30%',
      title: this.props.translate('FILE_LIST.NAME_LABEL'),
      ellipsis: true,
      sorter: hasSorter('name'),
      render: (record: IFileSystemElement) => {
        if (FileUtils.isFile(record)) {
          return(
            <FileItemPreview record={record} showPreviewTooltip={true} title={record.name} >
              <Typography.Text ellipsis={true}>{record.name}</Typography.Text>
            </FileItemPreview >
          );
        } else if (FileUtils.isFolder(record)) {
          return this.props.isRepositoryRoute ? (
            <Link to={`/repository/${record.id}`} onClick={() => this.props.onFolderSelect(record)} data-test-id={`lemon-fileRepository__fileLink_openFolder_${record.id}`}>
              {record.name}
            </Link>
          ) : (
            <a onClick={() => this.props.onFolderSelect(record)} data-test-id={`lemon-fileRepository__fileLink_openFolder_${record.id}`}>
              {record.name}
            </a>
          );
        } else {
          LOGGER.error('Element is neither file nor folder');
          return null;
        }
      },
    },

    {
      key: 'size',
      width: '10%',
      title: this.props.translate('FILE_LIST.SIZE_LABEL'),
      align: 'right',
      sorter: hasSorter('size'),
      render: (record: IFileSystemElement) => (FileUtils.isFile(record) ? this.props.formatBytes(record.size) : '-'),
    },

    {
      key: 'date',
      width: '10%',
      title: this.props.translate('FILE_LIST.DATE_LABEL'),
      align: 'right',
      sorter: hasSorter('date'),
      render: (record: IFileSystemElement) => (record ? moment(record.addedDateTime).format(DATE_WITH_YEAR_FORMAT) : '-'),
    },

    {
      width: '30%',
      key: 'tags',
      sorter: hasSorter('tags'),
      title: this.props.translate('FILE_LIST.TAGS_LABEL'),
      /*<TagsSelect
        tagList={this.props.tagList}
        fileListFilter={this.props.fileListFilter}
        onTagAdd={this.props.onTagAdd}
        onTagRemove={this.props.onTagRemove}
        onTagSearchEvent={this.props.onTagSearchEvent}
        onTagSelectChange={this.handleTagSelectChange} />*/
      render: (record: IFileSystemElement) => <TagList record={record} hasPermissionToEdit={this.canEdit([record])} tagList={this.props.tagList} onTagAdd={this.props.onTagAdd} onTagRemove={this.props.onTagRemove} onTagSearchEvent={this.props.onTagSearchEvent} />,
    },
  ];

  componentDidMount() {
    this.props.onSortChange(DEFAULT_SORT);
  }

  /*searchInputRef: Search | null = null;

  searchNameInput = (confirm: () => void) => {
    return <Search
      placeholder={this.props.translate('FILE_LIST.SEARCH_FILES_INPUT_PLACEHOLDER')}
      onSearch={(value: string, event: SyntheticEvent | undefined) => {
        if (event) { event.stopPropagation(); }
        confirm();
        this.props.onFileFilterChange({ ...this.props.fileListFilter, name: value });
      }}
      ref={element => this.searchInputRef = element}
      enterButton={true}
      allowClear={true}
      size="large"
    />;
  }*/

  rowSelection = () => {
    return {
      selectedRowKeys: this.props.selected.map((item: IFileSystemElement) => this.getRowKey(item)),
      onSelect: (record: IFileSystemElement, selected: boolean, selectedRows: any) => {
        // type for selectedRows is any because table doesn't allow it to be set to IFileSystemElement[]
        if (FileUtils.isFolder(record) && !this.props.foldersSelectable) {
          LOGGER.error('Selected a folder when folders are not selectable');
        } else {
          this.props.onSelectedChange(selectedRows);
        }
      },
      onSelectAll: (selected: boolean, selectedRows: IFileSystemElement[]) => {
        this.props.onSelectedChange(selectedRows);
      },
      getCheckboxProps: (record: IFileSystemElement) => ({
        disabled: (FileUtils.isFolder(record) && !this.props.foldersSelectable),
        name: record.name,
      }),
    };
  };

  render() {
    // when adding materials in session, action column can't be seen, so this code adds action column if component is not displayed in session
    if (this.props.showActionMenu && this.columns[this.columns.length - 1].key !== 'action') {
      this.columns.push({
        key: 'action',
        title: this.props.selected.length && this.canEdit(this.props.selected) ? (
          <FileDropdownMenu record={this.props.selected} canRename={false} canDelete={true} canMove={true} canOpen={false} onDelete={this.props.onDelete} onMove={this.handleMove}>
            <LemonIcon name="ellipsis" className="lemon-fileList__dropDownIcon" />
          </FileDropdownMenu>
        ) : null,
        width: '5%',
        render: (record: IFileSystemElement) => {
          const canEdit = this.canEdit([record]);
          const isFile = FileUtils.isFile(record);
          return !this.props.selected.length && (canEdit || isFile) ? (
            <FileDropdownMenu record={[record]} canRename={canEdit} canDelete={canEdit} canMove={canEdit} onDelete={this.props.onDelete} onRename={this.props.onRename} onMove={this.props.onMove} canOpen={isFile}>
              <LemonIcon name="ellipsis" className="lemon-fileList__dropDownIcon" />
            </FileDropdownMenu>
          ) : null;
        },
      });
    }

    return (
      <Table
        className="lemon-repository__fileListTable"
        columns={this.columns}
        dataSource={this.props.files}
        rowSelection={this.rowSelection()}
        rowKey={(record) => this.getRowKey(record)}
        onChange={this.handleTableChange}
        scroll={{ x: true }}
        sortDirections={['ascend', 'descend', 'ascend']} // sortDirections prop doesn't even exist in antd Table documentation and usage to disable option "cancel sort" wasn't found, in this way only ascend|descend are toggled
        locale={{
          emptyText: <Empty description={this.props.translate('FILE_LIST.EMPTY_FOLDER_TEXT')} />,
        }}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: this.props.pagination.totalElements,
          pageSize: this.props.pagination.size,
          // onChange: this.props.onPageChange,
          onShowSizeChange: this.props.onPageChange,
        }}
      />
    );
  }

  /** Returns userPermission boolean for given files/folders */
  canEdit = (record: IFileSystemElement[]): boolean => {
    if (record.length > 1) {
      return record.every((item) => item.userPermissions != null && item.userPermissions.WRITE === true);
    } else {
      return record[0].userPermissions ? record[0].userPermissions.WRITE : false; // only public and root don't have userPermission prop, so returns false
    }
  };

  handleMove = (value: IFileSystemElement[]) => {
    this.props.onMove(value);
  };

  handleTableChange = (pagination: PaginationConfig, filters: Record<'id' | 'name' | 'addedDateTime' | 'creator' | 'mimeType', string[]>, sorter: SorterResult<IFileSystemElement>) => {
    if (pagination.current) {
      this.props.onPageChange(pagination.current, pagination.pageSize);
    }
    if (sorter) {
      if (AppConfigService.getValue(`components.repository.sort.${sorter.columnKey}.${sorter.order}.id`)) {
        this.props.onSortChange(AppConfigService.getValue(`components.repository.sort.${sorter.columnKey}.${sorter.order}.id`));
      } else {
        this.props.onSortChange(DEFAULT_SORT);
      }
    }
  };

  /*handleTagSelectChange = (value: string[]) => {
    if (value.length) {
      this.props.onFileFilterChange({ ...this.props.fileListFilter, tag: value });
    }
    else {
      this.props.onFileFilterChange();
    }
  }

  private handleOpenSearch = (visible: boolean) => {
    if (visible) {
      setTimeout(() => {
        if (this.searchInputRef) {
          this.searchInputRef.focus();
        }
      });
    }
  }*/

  private getRowKey = (record: IFileSystemElement) => {
    return FileUtils.isFile(record) ? `file_${record.id}` : `folder_${record.id}`;
  };
}

export default withNumberFormat(withLocalize<IFileListOwnProps>(FileList as any));
