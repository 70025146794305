
import { Globals } from '@src/service/util/Globals';


export type DigestAlgorith = 'SHA-256' | 'SHA-384' | 'SHA-512';


/**
 * Collection of cryptographic utilities.
 *
 * Based upon browsers SubtleCrypt API.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
 */
export default class CryptoUtils {

  /**
   * Generates a digest of the given message using give algorithm.
   * Digest is returned as Base 64 encoded string.
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
   */
  static digest(message: string, algorith: DigestAlgorith): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    return new Promise<string>((resolve, reject) => {
      try {
        Globals.window.crypto.subtle.digest(algorith, data)
          .then(
            (buffer: ArrayBuffer) => {
              try {
                resolve(CryptoUtils.bufferToBase64(buffer));
              }
              catch (err) {
                reject(err);
              }
            },
            (err: any) => reject(err));
      }
      catch (err) {
        reject(err);
      }
    });
  }

  /** Convert array buffer to base 64 encoded string. */
  private static bufferToBase64(buffer: ArrayBuffer): string {
    return Globals.window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
  }
}
