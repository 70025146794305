
export default interface ITutoringSessionParticipantRole {
  id: string;
  name: string;
  description: string;
}

export enum TutoringSessionParticipantRole {
  OWNER = '1',
  PARTICIPANT = '2',
}
