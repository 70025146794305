import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map, ignoreElements } from 'rxjs/operators';

import { IPayloadAction, IIdPayload, ICollectionData, ILemonAction } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ITimelineActivity from '@src/model/timeline/TimelineActivity';
import { actionThunk, startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import IIdRef from '@src/model/common/IdRef';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('timelineBusinessStore');


// --
// ----- Types&Consts

/** Data structure for sending new messages. */
export interface INewMessage {
  messageBody: string;
  receiverId: string;
}


// -
// -------------------- Selectors

const getTimelineList = (store: any): ICollectionData<ITimelineActivity> => store.timeline;


// -
// -------------------- Actions

const Actions = {
  // timeline
  TIMELINE_FETCH: 'TIMELINE_FETCH',
  TIMELINE_LOAD: 'TIMELINE_LOAD',
  TIMELINE_CLEAR: 'TIMELINE_CLEAR',

  // messages
  TUTOR_SESSION_MESSAGE_SEND: 'TUTOR_SESSION_MESSAGE_SEND',
};

const fetchTimelineList = (user: IIdRef<string>): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TIMELINE_FETCH,
    payload: user,
  };
};

const loadTimelineList = (data: ICollectionData<ITimelineActivity>): IPayloadAction<ICollectionData<ITimelineActivity>> => {
  return {
    type: Actions.TIMELINE_LOAD,
    payload: data,
  };
};

const clearTimelineList = (): ILemonAction => {
  return {
    type: Actions.TIMELINE_CLEAR,
  };
};

const sendMessage = (data: INewMessage): IPayloadAction<INewMessage> => {
  return {
    type: Actions.TUTOR_SESSION_MESSAGE_SEND,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchTimelineListEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TIMELINE_FETCH;
    }),

    mergeMap((action) => {
      const payload = action.payload;

      // TODO: timeline API does not comply with API spec (again!?) - see we BE can this be changed?!
      return (EntityApiServiceRegistry.getService('Timeline')).fetchMethod(payload.id).pipe(
        actionThunk(action)
      );
    }),

    map((data) => {
      return loadTimelineList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching timeline', error);
      return o;
    })
  );
};


const sendMessageEffect = (action$: Observable<IPayloadAction<INewMessage>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SESSION_MESSAGE_SEND;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('Message')).createEntity(payload).pipe(
        trackAction(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTORING_SESSION_VIEW.ERROR_MESSAGE.MESSAGE_SEND')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error sending message', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const timeline = (state: ICollectionData<ITimelineActivity> | null = null, action: IPayloadAction<ICollectionData<ITimelineActivity>>) => {
  if (action.type === Actions.TIMELINE_LOAD) {
    return {
      ...action.payload,
    };
  }
  else if (action.type === Actions.TIMELINE_CLEAR) {
    return null;
  }

  return state;
};


// --
// -------------------- Business Store

export const TimelineBusinessStore = {
  actions: {
    fetchTimeline: fetchTimelineList, loadTimelineList, clearTimeline: clearTimelineList,
    sendMessage,
  },

  selectors: {
    getTimeline: getTimelineList,
  },

  effects: {
    fetchTimelineListEffect,
    sendMessageEffect,
  },

  reducers: {
    timeline,
  },
};

// --
// ----- Exports

export default TimelineBusinessStore;
