import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import ISessionStatistics from '@src/model/statistics/SessionStatistics';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { ISessionStatisticsListFilter } from '@src/service/business/statistics/types';
import { getLogger } from '@src/service/util/logging/logger';
import { actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';

const LOGGER = getLogger('statisticsBusinessStore');

// -
// -------------------- Types&Consts

const SESSION_STATISTICS_LIST_FILTER_NAME = 'SESSION_STATISTICS_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getUserSessionStatisticsList = (store: any): ISessionStatistics[] => store.userSessionStatisticsList;

const getUserSessionStatisticsListFilter = (store: any): ISessionStatisticsListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SESSION_STATISTICS_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  USER_SESSION_STATISTICS_LIST_FETCH: 'USER_SESSION_STATISTICS_LIST_FETCH',
  USER_SESSION_STATISTICS_LIST_LOAD: 'USER_SESSION_STATISTICS_LIST_LOAD',
  USER_SESSION_STATISTICS_LIST_CLEAR: 'USER_SESSION_STATISTICS_LIST_CLEAR',
  ADMIN_STATISTICS_LIST_FETCH: 'ADMIN_STATISTICS_LIST_FETCH',
};

const fetchUserSessionStatisticsList = (params: ISessionStatisticsListFilter): IPayloadAction<ISessionStatisticsListFilter> => {
  return {
    type: Actions.USER_SESSION_STATISTICS_LIST_FETCH,
    payload: params,
  };
};

const loadUserSessionStatisticsList = (data: ISessionStatistics[]): IPayloadAction<ISessionStatistics[]> => {
  return {
    type: Actions.USER_SESSION_STATISTICS_LIST_LOAD,
    payload: data,
  };
};

const clearUserSessionStatisticsList = (): ILemonAction => {
  return {
    type: Actions.USER_SESSION_STATISTICS_LIST_CLEAR,
  };
};

const storeUserSessionStatisticsListFilter = (listFilter: ISessionStatisticsListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SESSION_STATISTICS_LIST_FILTER_NAME, listFilter);
};

const fetchAdminStatisticsList = (params: ISessionStatisticsListFilter): IPayloadAction<ISessionStatisticsListFilter> => {
  return {
    type: Actions.ADMIN_STATISTICS_LIST_FETCH,
    payload: params,
  };
};

// -
// -------------------- Side-effects

const fetchUserSessionStatisticsEffect = (action$: Observable<IPayloadAction<ISessionStatisticsListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SESSION_STATISTICS_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      // Read the current user from store
      const currentUser = state$.value.currentUser.id;

      return EntityApiServiceRegistry.getService('User')
        .fetchSubobject(currentUser, 'sessionsstatistics', payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserSessionStatisticsList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching statistics', error);
      return o;
    })
  );
};

const fetchAdminStatisticsEffect = (action$: Observable<IPayloadAction<ISessionStatisticsListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ADMIN_STATISTICS_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = action.payload;

      // return EntityApiServiceRegistry.getService('User').fetchSubobject(currentUser, 'sessionsstatistics', payload).pipe(
      return EntityApiServiceRegistry.getService('User')
        .fetchMethod('sessionsstatistics', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserSessionStatisticsList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching statistics', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const userSessionStatisticsList = (state: ICollectionData<ISessionStatistics[]> | null = null, action: IPayloadAction<ICollectionData<ISessionStatistics[]>>) => {
  if (action.type === Actions.USER_SESSION_STATISTICS_LIST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.USER_SESSION_STATISTICS_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const StatisticsBusinessStore = {
  actions: {
    fetchUserSessionStatisticsList,
    loadUserSessionStatisticsList,
    clearUserSessionStatisticsList,
    storeUserSessionStatisticsListFilter,
    fetchAdminStatisticsList,
  },

  selectors: {
    getUserSessionStatisticsList,
    getUserSessionStatisticsListFilter,
  },

  effects: {
    fetchUserSessionStatisticsEffect,
    fetchAdminStatisticsEffect,
  },

  reducers: {
    userSessionStatisticsList,
  },
};

// --
// ----- Exports

export default StatisticsBusinessStore;
