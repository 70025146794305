import { of, Observable } from 'rxjs';

import StoreService from '@src/service/business/StoreService';
import { MessagingBusinessStore } from '@src/service/business/messaging/messagingBusinessStore';
import { getStorage } from '@src/service/util/persist/storage';
import AppConfigService from '@src/service/common/AppConfigService';
import { IMessagingServiceRawMessage } from '@src/service/service/messaging/types';
import ServiceStatusProvider from '@src/service/status/ServiceStatusProvider';
import { ServiceStatusProviderType } from '@src/service/status/types';
import MessagingServiceStatusProvider from '@src/service/status/provider/MessagingServiceStatusProvider';
import { generateInitialInterval } from '@src/service/common/initialize/util';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import { IUserInfo } from '../../../../model/user/User';
import { tap, catchError, mergeMap, map } from 'rxjs/operators';
import { getLogger } from '@src/service/util/logging/logger';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LocalizeService from '@src/service/localize/LocalizeService';
import { UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';


const LOGGER = getLogger('messagingServiceInitializer');


/** Messaging service initializer function. */
export const messagingServiceInitializer = (): Observable<any> => {
  return of(true).pipe(
    mergeMap(() => {
      LOGGER.info('Initializing messaging ...');

      const provider = MessagingServiceStatusProvider.create();

      // initialize service
      return provider.serviceInstance().initialize(tokenStoreHandler).pipe(
        map((isInitialized) => {
          if (isInitialized) {
            // restore messages from bg handler
            backgroundMessageRestoreHandler();

            // listen for incoming messages
            provider.serviceInstance().observeMessages().subscribe(
              onMessageHandler,
              (err) => LOGGER.error('Error observing messaging messages', err)
            );

            // listen for background messages
            navigator.serviceWorker.addEventListener('message', (event) => {
              LOGGER.info('Receiving background message event');
              LOGGER.debug('Background message event', event);

              // NOTE: sometimes event data containes another subkey cotaining service worker name
              const data = event.data[AppConfigService.getValue('messaging.backgroundWorkerName')] || event.data;

              onMessageHandler(data);
            });

            ServiceStatusProvider.instance().registerServiceProvider(ServiceStatusProviderType.MessagingService, provider);

            // artifical timeout for starting service - this should be done systematically by scheduling this or requested when feature is used
            setTimeout(() => {
              LOGGER.info('Starting messaging service ...');

              // callback
              const requireFn = () => ServiceStatusProvider.instance().requireService(ServiceStatusProviderType.MessagingService).subscribe();

              // initial call
              requireFn();

              /* disabled scheduling of service start
              // setup periodic interval
              setInterval(() => {
                requireFn();
              }, generateRecurringInterval());*/
            }, generateInitialInterval());
          } else {
            displayFirebaseErrorMessage();
          }

        })
      );
    }),

    tap(() => {
      LOGGER.info('Messaging service initialized');
    }),

    catchError((err) => {
      LOGGER.error('Error initializing messaging service', err);

      displayFirebaseErrorMessage();

      // do not break pipeline, just return ok
      return of(true);
    })
  );
};

// message handler
function onMessageHandler(message: IMessagingServiceRawMessage) {
  const currentUser = LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());
  if (shouldReceiveMessage(message, currentUser)) {
    StoreService.dispatchAction(MessagingBusinessStore.actions.receiveMessage(message));
  }
  else {
    LOGGER.debug('Ignoring message, not ours');
  }
}


// token store handler
function tokenStoreHandler(token: string) {
  LOGGER.info(`Store messaging token to server`);

  return of(StoreService.dispatchAction(MessagingBusinessStore.actions.registerToken(token)));
}

// move background messages to the store
function backgroundMessageRestoreHandler() {
  LOGGER.info(`Restoring background messages`);
  const storage = getStorage();
  return storage.getItem(AppConfigService.getValue('messaging.backgroundMessagesStoreKey'))
    .then((item) => {
      // check if messages are intended for this user
      const currentUser = LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());

      const messages = (item as []) || [];
      let restoredMessageCount = 0;
      messages.forEach((msg) => {
        if (shouldReceiveMessage(msg, currentUser)) {
          StoreService.dispatchAction(MessagingBusinessStore.actions.restoreMessage(msg));
          restoredMessageCount++;
        }
      });
      LOGGER.info(`Restored #${restoredMessageCount} background messages.`);

      // clear restored messages (if any)
      if (messages.length > 0) {
        LOGGER.info(`Clearing restored background messages`);
        return storage.removeItem(AppConfigService.getValue('messaging.backgroundMessagesStoreKey'));
      }
      else {
        return Promise.resolve();
      }
    });
}

/**
 * Check if we can receive this message. Currently it only checks recipient.
 * Background SW is registered for entire app thus receives messages for all users because their messaging tokens are still valid
 */
function shouldReceiveMessage(message: IMessagingServiceRawMessage, currentUser: IUserInfo): boolean {
  let should = false;

  // chek if it's a message for currently logged user
  if (currentUser != null && message.data != null && message.data.recipientId === currentUser.id) {
    should = true;
  }

  return should;
}

function displayFirebaseErrorMessage () {
  const translatedMessage = LocalizeService.translate('GENERAL_MESSAGE.GENERAL_NOTIFICATION_ERROR');
  return StoreService.dispatchAction(UserFeedbackBusinessStore.actions.reportMessage({ severity: UserFeedbackMessageSeverity.ERROR, message: translatedMessage, type: UserFeedbackMessageType.USER, timeout: 20000 }));
}
