import React from 'react';

import LocalePickerContainer from '@src/components/app/locale/LocalePickerContainer';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { getLogger } from '@src/service/util/logging/logger';
import { Menu } from 'antd';
import { withRouter, WithRouterProps } from 'react-router';

const LOGGER = getLogger('UserMenu');

// -- Types&Interfaces
// ----------

enum MenuKeys {
  Admin,
  Dashboard,
  MyProfile,
  MyPublicProfile,
  ChangeLanguage,
  Logout,
}

// cannot import ClickParam from antd so we recreate it only with things we need
interface MenuItemClickEvent {
  key: string;
}

// -- Prop types
// ----------
export interface IUserMenuPublicProps {
  user: IUserInfo;

  onDoLogout?: () => void;
}
type IUserMenuProps = IUserMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserMenuState {
  isMenuOpened: boolean;
  isAdmin: boolean;
  isTutor: boolean;
}

// TODO: protection against long user names (some container with overflow and ellipsis)

// --
// ----- Component
class UserMenu extends React.Component<IUserMenuProps, IUserMenuState> {
  state: IUserMenuState = {
    isMenuOpened: false,
    isAdmin: this.props.user.roles.some((item) => item.id === UserRoleEnum.ADMINISTRATOR),
    isTutor: this.props.user.roles.some((item) => item.id === UserRoleEnum.TUTOR),
  };

  render() {
    // TODO: default menu open/close icon appears only in iniline menu - this one is only a quick replacement
    const subMenuTitle = (
      <div className="lemon-userMenu__menuContainer" title={this.props.user.displayName}>
        <AppUserAvatar imagePath={this.props.user.profileImageUrl} size="small" />
        &nbsp;
        <span className="lemon-userMenu__userName">
          {this.props.user.displayName}
          &nbsp;&nbsp;
          {this.state.isMenuOpened ? <LemonIcon name="up" className="lemon-userMenu__menuOpener" /> : <LemonIcon name="down" className="lemon-userMenu__menuOpener" />}
        </span>
      </div>
    );

    return (
      <div className="lemon-userMenu">
        <Menu mode="horizontal" selectable={false} onClick={this.onMenuClick} onOpenChange={this.onMenuOpenChange} data-test-id="lemon-userMenu">
          <Menu.SubMenu title={subMenuTitle}>
            {this.state.isAdmin && (
              <Menu.Item key={MenuKeys.Admin} data-test-id="lemon-userMenu__itemAdmin">
                {this.props.translate('USER_MENU.ADMIN_LABEL')}
              </Menu.Item>
            )}
            {/*
              <Menu.Item key={MenuKeys.Dashboard} data-test-id="lemon-userMenu__itemDashboard">{this.props.translate('USER_MENU.DASHBOARD_LABEL')}</Menu.Item>
            */}
            <Menu.Item key={MenuKeys.MyProfile} data-test-id="lemon-userMenu__itemMyProfile">
              {this.props.translate('USER_MENU.MY_PROFILE_LABEL')}
            </Menu.Item>
            {this.state.isTutor && (
              <Menu.Item key={MenuKeys.MyPublicProfile} data-test-id="lemon-userMenu__itemMyPublicProfile">
                {this.props.translate('USER_MENU.MY_PUBLIC_PROFILE_LABEL')}
              </Menu.Item>
            )}
            <Menu.Item key={MenuKeys.ChangeLanguage}>
              <LocalePickerContainer />
            </Menu.Item>
            <Menu.Item key={MenuKeys.Logout} data-test-id="lemon-userMenu__itemLogout">
              {this.props.translate('USER_MENU.LOGOUT_LABEL')}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    );
  }

  onMenuOpenChange = (openKeys: string[]) => {
    this.setState({
      isMenuOpened: openKeys.length > 0,
    });
  };

  onMenuClick = (event: MenuItemClickEvent) => {
    const eventKey = Number.parseInt(event.key, undefined);

    // -- Admin
    if (MenuKeys[MenuKeys.Admin] === MenuKeys[eventKey]) {
      this.props.router.push('/admin');
    }
    // -- Logout
    else if (MenuKeys[MenuKeys.Logout] === MenuKeys[eventKey]) {
      this.handleLogout();
    }
    // -- Dashboard
    else if (MenuKeys[MenuKeys.Dashboard] === MenuKeys[eventKey]) {
      this.props.router.push('/dashboard');
    }
    // -- My Profile
    else if (MenuKeys[MenuKeys.MyProfile] === MenuKeys[eventKey]) {
      this.props.router.push('/myprofile');
    }
    // -- My Public Profile
    else if (MenuKeys[MenuKeys.MyPublicProfile] === MenuKeys[eventKey]) {
      this.props.router.push(`/tutors/${this.props.user.id}`);
    }
    // sanity check
    else {
      LOGGER.warn(`Unhandled menu item clicked: ${event.key}`);
    }
  };

  handleLogout = () => {
    if (this.props.onDoLogout != null) {
      this.props.onDoLogout();
    }
  };
}

export default withLocalize<IUserMenuPublicProps>(withRouter(UserMenu as any));
