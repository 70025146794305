import React from 'react';

import LemonDefaultError from '@src/components/common/error/LemonDefaultError';
import { getLogger } from '@src/service/util/logging/logger';

const LOGGER = getLogger('LemonErrorBoundary');

export interface ILemonErrorBoundaryPublicProps {
  showDefaultError?: boolean;
  hideErrorOutput?: boolean;
}
type ILemonErrorBoundaryProps = ILemonErrorBoundaryPublicProps;

interface ILemonErrorBoundaryState {
  hasError: boolean;
}

class LemonErrorBoundary extends React.Component<ILemonErrorBoundaryProps, ILemonErrorBoundaryState> {
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props: ILemonErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    LOGGER.error(error, info);

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.hideErrorOutput) {
        return null;
      }
      // render defaultError
      else if (this.props.showDefaultError) {
        return <LemonDefaultError />;
      }
      // warning sign
      else {
        return <div>&#9888;</div>;
      }
    }

    return this.props.children;
  }
}

export default LemonErrorBoundary;
