// import { Observable } from 'rxjs';
// import { filter, mergeMap, catchError, map } from 'rxjs/operators';
// import { StateObservable } from 'redux-observable';

import { IPayloadAction, IIdPayload, ILemonAction } from '@src/service/business/common/types';
import { IUserSettings, IUserSettingsItem } from '@src/service/business/usersettings/types.ts';
// import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
// import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
// import { actionThunk } from '@src/service/util/observable/operators';
// import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
// import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';

// -
// -------------------- Types&Consts

// -
// -------------------- Selectors

/** Returns UserSettings from store. */
const getUserSettings = (store: any): IUserSettings => store.userSettingsView;

const getUserSettingByKey = <T extends keyof IUserSettings>(store: any, key: T): IUserSettings[T] | undefined => (store.userSettingsView[key]);

// -
// -------------------- Actions

const Actions = {
  USER_SETTINGS_FETCH: 'USER_SETTINGS_FETCH',
  USER_SETTINGS_LOAD: 'USER_SETTINGS_LOAD',
  USER_SETTINGS_CLEAR: 'USER_SETTINGS_CLEAR',
  USER_SETTING_CREATE: 'USER_SETTING_CREATE',
};

/** Fetch UserSettings. */
const fetchUserSettings = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  // console.log('prop: ', prop);
  return {
    type: Actions.USER_SETTINGS_FETCH,
    payload: params,
  };
};

/** Load UserSettings to store. */
const loadUserSettings = (data: IUserSettings): IPayloadAction<IUserSettings> => {
  return {
    type: Actions.USER_SETTINGS_LOAD,
    payload: data,
  };
};

/** Clear UserSettings from store. Eg. when leaving view. */
const clearUserSettingsData = (): ILemonAction => {
  return {
    type: Actions.USER_SETTINGS_CLEAR,
  };
};

/** Create new UserSettings. */
const createUserSettings = (key: keyof IUserSettings, value: IUserSettingsItem['value']): IPayloadAction<IUserSettingsItem> => {
  return {
    /** temp */
    type: Actions.USER_SETTING_CREATE,
    payload: {
      key,
      value,
    },
  };
};

// -
// -------------------- Reducers

const userSettingsView = (state: IUserSettings = {}, action: IPayloadAction<IUserSettingsItem | IUserSettings>) => {
  if (action.type === Actions.USER_SETTINGS_LOAD) {
    const settings = action.payload as IUserSettings;
    return {
      ...settings,
    };
  } else if (action.type === Actions.USER_SETTING_CREATE) {
    const setting = action.payload as IUserSettingsItem;

    return {
      ...state,
      [setting.key]: setting.value,
    };
  } else if (action.type === Actions.USER_SETTINGS_CLEAR) {
    return {};
  }

  return state;
};

// --
// -------------------- Business Store

export const UserSettingsBusinessStore = {
  actions: {
    fetchUserSettings, loadUserSettings, clearUserSettingsData, createUserSettings,
  },

  selectors: {
    getUserSettings, getUserSettingByKey,
  },

  effects: {},

  reducers: {
    userSettingsView,
  },
};

// --
// export business store
export default UserSettingsBusinessStore;
