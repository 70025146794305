import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { IServiceStatusProvider, IServiceStatus } from '@src/service/status/ServiceStatusProvider';
import StoreService from '@src/service/business/StoreService';
import UserApprovalBusinessStore from '@src/service/business/userapproval/userApprovalBusinessStore';
import { ServiceStatusProviderType } from '@src/service/status/types';
import VideoService from '@src/service/service/video/VideoService';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('VideoServiceStatusProvider');

/** Provides simple provider to controlling video service. */
export default class VideoServiceStatusProvider implements IServiceStatusProvider<VideoService> {

  static create() {
    return new VideoServiceStatusProvider();
  }

  serviceInstance(): VideoService {
    return VideoService.instance();
  }

  requireService(): Observable<IServiceStatus> {
    return Observable.create((observer: Observer<IServiceStatus>) => {
      VideoService.instance().shouldCheckUserApproval()
        .subscribe(
          // TODO: API should also return info if check has already been performed and REJECTED
          (check) => {
            if (check) {
              StoreService.dispatchAction(UserApprovalBusinessStore.actions.showUserApproval({
                id: ServiceStatusProviderType.VideoService,
                serviceId: ServiceStatusProviderType.VideoService,
                grantCallback: () => {
                  this.startService();
                },
              }));
            }
            else {
              LOGGER.info('Video approval not required');
              if (!this.getServiceStatus().running) {
                this.startService();
              }
            }
          },
          (err) => observer.error(err),
          () => observer.complete()
        );
    });
  }

  watchServiceStatus(): Observable<IServiceStatus> {
    return VideoService.instance().watchStarted().pipe(
      map((started) => ({ running: started }))
    );
  }

  getServiceStatus(): IServiceStatus {
    return {
      running: VideoService.instance().isStarted(),
    };
  }

  startService(): Observable<IServiceStatus> {
    return VideoService.instance().start().pipe(
      map(() => ({ running: true }))
    );
  }
}
