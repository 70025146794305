import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Menu } from 'antd';
import { withRouter, WithRouterProps } from 'react-router';

// -- Types&Interfaces
// ----------

enum MenuKeys {
  UserList = '/admin/users',
  Activities = '/admin/activities',
  SessionList = '/admin/sessions',
  Statistics = '/admin/statistics',
  Reviews = '/admin/reviews',
}

// cannot import ClickParam from antd so we recreate it only with things we need
export interface MenuItemClickEvent {
  key: string;
}

// -- Prop types
// ----------
export interface IAdminMenuPublicProps { }
type IAdminMenuProps = IAdminMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps;

interface IAdminMenuState {
  selectedKey: string; // { [s: number]: string } | undefined;
}

// --
// ----- Component
class AdminMenu extends React.Component<IAdminMenuProps, IAdminMenuState> {
  state = {
    selectedKey: this.props.location.pathname,
  };

  render() {
    return (
      <div className="lemon-adminMenu">
        <Menu mode="horizontal" selectedKeys={[this.state.selectedKey]} onClick={this.onMenuClick} data-test-id="lemon-adminMenu">
          <Menu.Item key={MenuKeys.UserList} data-test-id="lemon-adminMenu__itemUserList">
            {this.props.translate('ADMIN_MENU.USER_LIST_LABEL')}
          </Menu.Item>
          <Menu.Item key={MenuKeys.Activities} data-test-id="lemon-adminMenu__itemActivities">
            {this.props.translate('ADMIN_MENU.ACTIVITIES_LABEL')}
          </Menu.Item>
          <Menu.Item key={MenuKeys.SessionList} data-test-id="lemon-adminMenu__itemSessionList">
            {this.props.translate('ADMIN_MENU.SESSION_LIST_LABEL')}
          </Menu.Item>
          {/* <Menu.Item key={MenuKeys.Statistics} data-test-id="lemon-adminMenu__itemStatistics">
            {this.props.translate('ADMIN_MENU.STATISTICS_LABEL')}
          </Menu.Item> */}
          <Menu.Item key={MenuKeys.Reviews} data-test-id="lemon-adminMenu__itemReviewSessionList">
            {this.props.translate('ADMIN_MENU.REVIEW_SESSION_LIST_LABEL')}
          </Menu.Item>
        </Menu>
      </div>
    );
  }

  onMenuClick = (event: MenuItemClickEvent) => {
    this.props.router.push(event.key);
    this.setState({ selectedKey: event.key });
  };
}

export default withLocalize<IAdminMenuPublicProps>(withRouter(AdminMenu as any));
