import { setDefaultLevel, addLoggerPlugin } from '@src/service/util/logging/logger';
import AppConfigService from '@src/service/common/AppConfigService';

import { applyPlugin as applyDecoratorPlugin } from '@src/service/util/logging/loglevelDecoratorPlugin';
import { applyPlugin as applySentryPlugin } from '@src/service/util/logging/loglevelSentryPlugin';


const LOGGING_LEVEL = AppConfigService.getValue('logging.logLevel');
const LOGGING_SENTRY_ENABLED = !!AppConfigService.getValue('logging.sentry.enabled');


// ---------- Configure logging statically

// set log level
setDefaultLevel(LOGGING_LEVEL);

// ---- configure plugins
addLoggerPlugin(applyDecoratorPlugin);
if (LOGGING_SENTRY_ENABLED) {
  addLoggerPlugin(applySentryPlugin);
}

/* ----- logger test
const logger = getLogger('TestLogger');

logger.info('Ovo je prva logging poruka', 'pa jos jedna', { a: 11 });
logger.warn('Evo nekakvo upozorenje 222!');
try {
  throw new ApiResponseErrorException('Ovo je jedna testna greska');
}
catch (err) {
  logger.error('Evo neka greska', err, 'i jos malo opisa greske', 123456789);
}
*/