
import { ILemonAction } from '@src/service/business/common/types';

// ---------- Comomn actions

const Actions = {
  COMMON_NOOP: 'COMMON_NOOP',
};

/** Empty noop action. Eg. can be used when effect has no further actions to perform, but they need to return some action (that's how redux-observable works).  */
export function noop(): ILemonAction {
  return {
    type: Actions.COMMON_NOOP,
  };
}

