const sl = {
  // ---------- Template labels
  'MODULE.LABEL_NAME': 'Template for adding labels',

  // ---------- Test labels
  'TEST.DEMO_LABEL': 'Ovo je testni string ... ',

  // ---------- Locale labels
  'LOCALE_LABEL.HR': 'Hrvatski',
  'LOCALE_LABEL.SL': 'Slovenščina',

  // ---------- Application
  'APPLICATION.APP_NAME': 'Lemon',
  'APPLICATION.APP_LOGO_TITLE': 'Lemon – Iztisni znanje',
  'APPLICATION.APP_DESCRIPTION': 'Platforma za inštrukcije',

  // ---------- Footer
  'APPLICATION_FOOTER.PRIVACY_POLICY': 'Pravilnik o zasebnosti',
  'APPLICATION_FOOTER.CONTACT': 'Kontakt',

  // ---------- COMMON ACTIONS
  'COMMON.ACTION_SAVE': 'Shrani',
  'COMMON.ACTION_EDIT': 'Uredi',
  'COMMON.ACTION_OK': 'OK',
  'COMMON.ACTION_CANCEL': 'Prekliči',
  'COMMON.ACTION_ADD': 'Dodaj',
  'COMMON.ACTION_ADD_NEW': 'Dodaj nov zapis',
  'COMMON.ACTION_SEARCH': 'Najdi',
  'COMMON.ACTION_RESET': 'Resetirati',
  'COMMON.ACTION_LOGOUT': 'Odjava',
  'COMMON.ACTION_LOAD_MORE_ITEMS': 'Pokaži več',
  'COMMON.ACTION_CLOSE': 'Zapri',
  'COMMON.ACTION_SHOW_MORE_CONTENT': 'pokaži več',
  'COMMON.ACTION_SHOW_LESS_CONTENT': 'skrij',
  'COMMON.ACTION_DELETE': 'Izbriši',
  'COMMON.ACTION_CLEAR': 'Očisti',
  'COMMON.ACTION_CONTINUE': 'Nadaljuj',
  'COMMON.ACTION_DONE': 'Konec',
  'COMMON.ACTION_SUBMIT': 'Pošlji',
  'COMMON.IN_PROGRESS': 'V obdelavi…',

  'COMMON.FILL_REQUIRED_FIELD': 'Izpolnite zahetvane podatke',
  'COMMON.FILL_MAX_LENGTH_REACHED': 'Prosimo, skrajšajte vnos',
  'COMMON.FILL_ENTER_VALID_PHONE_NUMBER': 'Prosimo, vnesite veljavno telefonsko številko',
  'COMMON.FILL_MIN_PHONE_LENGTH': 'Telefonska številka mora imeti najmanj 8 številk',

  // ---------- General Messages
  'GENERAL_MESSAGE.GENERAL_FETCH_ERROR': 'Napaka pri prenosu podatkov!',
  'GENERAL_MESSAGE.GENERAL_SEND_ERROR': 'Napaka pri pošiljanju podatkov!',
  'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR': 'Napaka pri shranjevanju podatkov!',
  'GENERAL_MESSAGE.GENERAL_DELETE_ERROR': 'Napaka pri brisanju podatkov!',

  'GENERAL_MESSAGE.GENERAL_FETCH_SUCCESS': 'Podatki uspešno prejeti.',
  'GENERAL_MESSAGE.GENERAL_SEND_SUCCESS': 'Podatki uspešno poslani.',
  'GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS': 'Podatki so uspešno shranjeni.',
  'GENERAL_MESSAGE.GENERAL_DELETE_SUCCESS': 'Podatki uspešno izbrisani.',
  'GENERAL_MESSAGE.GENERAL_NOTIFICATION_ERROR': 'Brskalnik ne podpira pomožnih sporočil ter ne bo osvežil sporočil in dejavnosti drugih uporabnikov na vaši učni uri.  Na operacijskih sistemih Linux, Android ali Windows se priporočajo brskalniki Chrome in Firefox.',

  // ---------- API Messages (API response error messages) - messages that should be valid for every API call that shows API errors
  // 'API_RESPONSE_ERROR.<ERROR_CODE>': '<error message>',

  // ---------- Login Form
  'LOGIN.USERNAME_LABEL': 'Uporabniško ime',
  'LOGIN.USERNAME_PLACEHOLDER': 'Vnesite uporabniško ime',
  'LOGIN.PASSWORD_LABEL': 'Geslo',
  'LOGIN.PASSWORD_PLACEHOLDER': 'Vnesite geslo',
  'LOGIN.LOGIN_BUTTON_LABEL': 'Prijava',
  'LOGIN.FORGOT_PASSWORD_LABEL': 'Se pozabili geslo',
  'LOGIN.REGISTRATION_LABEL': 'Nimate uporabniškega računa? ',
  'LOGIN.REGISTRATION_LINK': 'Včlanitev',
  // errors
  'LOGIN.ERROR.GENERAL_ERROR': 'Napaka pri prijavi v sistem',
  // API errors
  'LOGIN.ERROR.BAD_CREDENTIALS': 'Prijava ni uspela. Prosimo, preverite podatke za prijavo in poskusite znova.',

  // ---------- Login Menu
  'LOGIN_MENU.LOGIN_LABEL': 'Prijava',

  // ---------- App navigation
  'APP_NAVIGATION.FAVOURITES_STUDENTS_LABEL': 'Študenti',
  'APP_NAVIGATION.FAVOURITES_TUTORS_LABEL': 'Moji inštruktorji',
  'APP_NAVIGATION.ALL_TUTORS_LABEL': 'Iskalnik inštruktorjev',
  'APP_NAVIGATION.INSTRUCTIONS_LABEL': 'Navodila',
  // tslint:disable-next-line: no-duplicate-string
  'APP_NAVIGATION.MY_REPOSITORY_LABEL': 'Moji materiali',
  'APP_NAVIGATION.MY_CALENDAR_LABEL': 'Koledar',
  'APP_NAVIGATION.REPORTS_LABEL': 'Poročila',

  // ---------- User Menu
  'USER_MENU.ADMIN_LABEL': 'Administrator',
  'USER_MENU.MY_PROFILE_LABEL': 'Nastavitve uporabniškega računa',
  'USER_MENU.MY_PUBLIC_PROFILE_LABEL': 'Moj javni profil',
  'USER_MENU.CHANGE_PASSWORD_LABEL': 'Sprememba gesla',
  'USER_MENU.LOGOUT_LABEL': 'Odjava',
  'USER_MENU.CHANGE_LANGUAGE_LABEL': 'Spremenite jezik',

  // ---------- Admin Menu
  'ADMIN_MENU.USER_LIST_LABEL': 'Upravljanje uporabnikov',
  'ADMIN_MENU.SESSION_LIST_LABEL': 'Pregled seje',
  'ADMIN_MENU.STATISTICS_LABEL': 'Statistika seje',
  'ADMIN_MENU.ACTIVITIES_LABEL': 'Aktivnosti uporabnika',
  'ADMIN_MENU.REVIEW_SESSION_LIST_LABEL': 'Ocene ure',

  // ---------- Welcome
  'WELCOME.MESSAGE_1': 'Ponudite in poiščite znanje v <span class="lemon-welcome__applogo">edulab lemon</span> platformi, digitalnem omrežju znanja, ki podpira učitelje, študente in starše!',
  'WELCOME.MESSAGE_2': 'Brezplačna uporaba edulab Lemon, medtem ko traja pandemija koronavirusa, ,<br /> ker smo tu za vas!',

  // ---------- Admin Activities Menu
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_TIME_CHANGE': 'Sprememba predavanja',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CONFIRMATION': 'Inštrukcije potrjene',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CANCELATION': 'Odpoved inštrukcij',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_DECLINED': 'Inštrukcije zavrnjene',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CREATED': 'Nova inštrukcijska ura',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_STARTED': 'Inštrukcijska ura se je začela',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_ENDED': 'Inštrukcijska ura se je končala',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_MESSAGE_SENT': 'Sporočilo je poslano',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_FILE_ADDED': 'Datoteka dodana na uro',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_FILE_REMOVED': 'Datoteka izbrisana iz ure',
  'ADMIN_ACTIVITIES_FILTER_LABEL.REVIEW_CREATED': 'Nova recenzija za inštruktorja',
  'ADMIN_ACTIVITIES_FILTER_LABEL.ALL_TYPES': 'Vse aktivnosti',
  'ADMIN_ACTIVITIES_FILTER_LABEL.START_DATE_LABEL': 'Datum od',
  'ADMIN_ACTIVITIES_FILTER_LABEL.END_DATE_LABEL': 'Datum do',
  'ADMIN_ACTIVITIES_FILTER_LABEL.ACTIVITY_TYPE_LABEL': 'Vrsta aktivnosti',
  'ADMIN_ACTIVITIES_LIST.EMPTY_DATA_LABEL': 'Ni aktivnosti',
  'ADMIN_ACTIVITIES_LIST.TIME_COLUMN_TITLE': 'Datum',
  'ADMIN_ACTIVITIES_LIST.TIME_TARGET_USER_COLUMN_TITLE': 'Glede na uporabnika',
  'ADMIN_ACTIVITIES_LIST.TIME_CREATOR_COLUMN_TITLE': 'Od uporabnika',
  'ADMIN_ACTIVITIES_LIST.ACTIVITY_COLUMN_TITLE': 'Aktivnost',

  // ---------- Change Password
  'CHANGE_PASSWORD.OLD_PASSWORD_LABEL': 'Staro geslo',
  'CHANGE_PASSWORD.OLD_PASSWORD_PLACEHOLDER': 'Vnesite staro geslo',
  'CHANGE_PASSWORD.NEW_PASSWORD_LABEL': 'Novo geslo',
  'CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER': 'Vnesite novo geslo',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL': 'Potrdite novo geslo',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER': 'Ponovno vnesite novo geslo',
  'CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_LABEL': 'Spremeni geslo',
  'CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Geslo mora imeti vsaj 8 znakov',
  'CHANGE_PASSWORD.PASSWORD_NOT_NEW_ERROR': 'Novo geslo ne sme biti eno od vaših že prej uporabljenih gesel',
  'CHANGE_PASSWORD.PASSWORDS_DIFFERENT_ERROR': 'Nova gesla nista enaka.',
  'CHANGE_PASSWORD.CHANGE_DONE': 'Uspešno ste spremenili vaše geslo',
  'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS': 'Napačno staro geslo',
  'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD': 'Napaka pri spremembi gesla',

  // ---------- Reset Password & Request
  'RESET_PASSWORD_REQUEST.INFO_TEXT_1': 'Zahtevek za ponastavitev gesla je bil uspešno poslan na e-poštni naslov',
  'RESET_PASSWORD_REQUEST.INFO_TEXT_2': 'Preverite svojo e-pošto in sledite navodilom',
  'RESET_PASSWORD_REQUEST.EMAIL_LABEL': 'Elektronska pošta',
  'RESET_PASSWORD_REQUEST.EMAIL_PLACEHOLDER': 'Vnesite svoj elektronski naslov',
  'RESET_PASSWORD_REQUEST.RESET_PASSWORD_BUTTON_LABEL': 'Pošlji zahtevo za novo geslo',
  'RESET_PASSWORD_REQUEST.EMAIL_VALIDATION_TEXT': 'Vnesite veljaven elektronski naslov',

  'RESET_PASSWORD.MISSING_TOKEN': 'Neveljavna zahteva za novo geslo',
  'RESET_PASSWORD.INVALID_TOKEN': 'Zahteva za novo geslo je potekla',
  'RESET_PASSWORD.SEND_NEW_REQUEST': 'Pošljite novo zahtevo',
  'RESET_PASSWORD.RESET_DONE': 'Uspešno ste spremenili vaše geslo',
  'RESET_PASSWORD.LINK_TO_LOGIN': 'Prijava',

  // ---------- User registration & activation
  'REGISTRATION.STEP_ONE_TITLE': 'Vrsta uporabnika',
  'REGISTRATION.STEP_TWO_TITLE': 'Osebni podatki',
  'REGISTRATION.STEP_THREE_TITLE': 'Podatki za prijavo',
  'REGISTRATION.ROLE_LABEL': 'Želim odpreti uporabniški račun kot',
  'REGISTRATION.ROLE_STUDENT_LABEL': 'Študent (ali starši za otroke, mlajše od 16 let)',
  'REGISTRATION.ROLE_TUTOR_LABEL': 'Inštruktor',
  'REGISTRATION.USER_TYPE_REQUIRED': 'Izberite vrsto uporabnika',
  'REGISTRATION.BUTTON_PREV_LABEL': 'Predhodni korak',
  'REGISTRATION.BUTTON_NEXT_LABEL': 'Naslednji korak',
  'REGISTRATION.BUTTON_SUBMIT_LABEL': 'Pošljite zahtevo za uporabniški račun',
  'REGISTRATION.CHECK_BOX_TERMS_OF_USE': 'Pogoji uporabe',
  'REGISTRATION.TUTOR_CHECK_BOX_GDPR': 'Potrjujem, da sem starejši od 16 let, in sprejemam politiko zasebnosti',
  'REGISTRATION.STUDENT_CHECK_BOX_GDPR': 'Potrjujem, da sem starejši od 16 let, ali da sem starš ali zastopnik, ki je izpolnil podatke za otroka, mlajšega od 16 let in sprejemam politiko zasebnosti',
  'REGISTRATION.INFO_TEXT_1': 'Zahteva za uporabniški račun je poslana na elektronski naslov',
  'REGISTRATION.INFO_TEXT_2': 'Preverite svojo e-pošto in sledite navodilom',
  'REGISTRATION.EMAIL_LABEL': 'Elektronski naslov za preverjanje računa in prijavo',
  'REGISTRATION.NEW_PASSWORD_LABEL': 'Geslo',
  'REGISTRATION.CONFIRM_PASSWORD_LABEL': 'Potrdi geslo',
  'REGISTRATION.NEW_PASSWORD_PLACEHOLDER': 'Vnesite geslo',
  'REGISTRATION.CONFIRM_PASSWORD_PLACEHOLDER': 'Ponovno vnesite geslo',
  'REGISTRATION.USERNAME_ALREADY_IN_USE': 'Ta elektronski naslov je že uporabljen. prosimo, vnesite drugi elektronski naslov',
  'ACTIVATION.MISSING_TOKEN': 'Neveljavna zahteva za aktivacijo uporabniškega računa',
  'ACTIVATION.INVALID_TOKEN': 'Zahteva za aktivacijo uporabniškega računa je potekla.',
  'ACTIVATION.SEND_NEW_REQUEST': 'Ustvari novo zahtevo',
  'ACTIVATION.ACTIVATION_DONE': 'Uporabniški račun uspešno aktiviran',
  'ACTIVATION.TUTOR_LINK': 'Izpolnite svoj profil, da vas bodo študenti lahko našli',
  'ACTIVATION.STUDENT_LINK': 'Lahko poiščete inštruktorje',

  // ---------- Tutor List & ListItem
  'TUTOR_LIST_FILTER.FILTER_TITLE': 'Filtriranje seznama',
  'TUTOR_LIST_FILTER.RESET_FORM_LABEL': 'Prekliči',
  'TUTOR_LIST_FILTER.EDUCATION_AREA_LABEL': 'Predmeti',
  'TUTOR_LIST_FILTER.ANY_EDUCATION_AREA_LABEL': 'Vsi predmeti',
  'TUTOR_LIST_FILTER.EDUCATION_LEVEL_LABEL': 'Šolska raven',
  'TUTOR_LIST_FILTER.ANY_EDUCATION_LEVEL_LABEL': 'Vse šolske ravni',
  'TUTOR_LIST_FILTER.AVAILABILITY_TITLE': 'Razpoložljivost inštruktorja',
  'TUTOR_LIST_ITEM.DAYS_OF_WEEK_TITLE_LABEL': 'Dnevi v tednu',
  'TUTOR_LIST_ITEM.TIME_RANGES_LABEL': 'Časovni razponi',
  'TUTOR_LIST_FILTER.AVAILABILITY_LABEL': 'Razpoložljivost inštruktorja',
  'TUTOR_LIST_FILTER.ANY_AVAILABILITY_LABEL': 'Kadar koli',
  'TUTOR_LIST.TUTORS_FOUND_PREFIX_LABEL': 'Najdeno',
  'TUTOR_LIST.TUTORS_FOUND_SUFFIX_LABEL': 'Inštruktorja z izbranimi kriteriji',
  'TUTOR_LIST_SORT.RELEVANCE': 'Po pomembnosti',
  'TUTOR_LIST_SORT.HOURLY_RATE': 'Cena šolske ure od najmanjše',
  'TUTOR_LIST_SORT.HOURLY_RATE_DESCENDING': 'Cena šolske ure od največje',
  'TUTOR_LIST_ITEM.SESSIONS_NUMBER_LABEL': 'Število šolskih ur',
  'TUTOR_LIST_ITEM.REVIEWS_NUMBER_LABEL': 'Ocena',
  'TUTOR_LIST_ITEM.HOURLY_RATE_LABEL': 'Cena šolske ure',
  'TUTOR_LIST_ITEM.DETAILS_LINK_LABEL': 'Podrobnosti',
  'TUTOR_LIST_ITEM.CREATE_SESSION_BUTTON_LABEL': 'Si rezerviraj uro',

  // ---------- Tutor profile
  'TUTOR_PROFILE.SESSION_NUMBER_LABEL': '%{sessionNumber} inštrukcijskih ur',
  'TUTOR_PROFILE.AVERAGE_GRADE_LABEL': '%{averageGrade} ocena',

  // ---------- User List
  'USER_LIST_ITEM.ACTIVATION_LABEL': 'Omogoči uporabnika',
  'USER_LIST_ITEM.DEACTIVATION_LABEL': 'Onemogoči uporabnika',
  'USER_LIST_HEADER.FIRST_LAST_NAME': 'Ime in priimek',
  'USER_LIST_HEADER.USER_NAME': 'Uporabniško ime',
  'USER_LIST_HEADER.DISPLAY_NAME': 'Vzdevek',
  'USER_LIST_HEADER.PROFILE_PICTURE': 'Profilna slika',
  'USER_LIST_HEADER.ACTIONS': 'Akcije',
  'USER_LIST_FILTER.FIRST_NAME': 'Ime',
  'USER_LIST_FILTER.LAST_NAME': 'Priimek',
  'USER_LIST_FILTER.USER_NAME': 'Uporabniško ime',
  'USER_LIST_FILTER.ROLES': 'Vloge',
  'USER_LIST_FILTER.ALL': 'Vsi',
  'USER_LIST_FILTER.ACTIVE': 'Aktiven',
  'USER_LIST_FILTER.INACTIVE': 'Neaktiven',
  'USER_LIST_FILTER.STATUS': 'Status',
  'USER_LIST_FILTER.VERIFICATION_STATUS': 'Verifikacija',
  'USER_LIST_FILTER.VERIFIED': 'Verificiran',
  'USER_LIST_FILTER.UNVERIFIED': 'Neverificiran',
  'USER_LIST_ITEM.USER_VERIFIED_LABEL': 'Uporabnik verificiran',
  'USER_LIST_ITEM.VERIFY_LABEL': 'Verificiraj uporabnika',
  'USER_LIST_ITEM.REGISTRATION_DATE_LABEL': 'Datum včlanitve',

  // ---------- Review Session List
  'REVIEW_SESSION_LIST.CREATOR_LABEL': 'Uporabnik',
  'REVIEW_SESSION_LIST.DATE_LABEL': 'Datum',
  'REVIEW_SESSION_LIST.TEXT_LABEL': 'Komentar',
  'REVIEW_SESSION_LIST.GENERIC_GRADE_LABEL': 'Generalna ocena',
  'REVIEW_SESSION_LIST.AUDIO_QUALITY_LABEL': 'Kvaliteta zvoka',
  'REVIEW_SESSION_LIST.VIDEO_QUALITY_LABEL': 'Kvaliteta video zapisa',

  // ---------- Tutor & User Profile
  'TUTOR_PROFILE_INSTRUCTIONS.MESSAGE': 'Na tej strani lahko najdete navodila za uporabo lemona, za vsa vprašanja smo na voljo na ',

  'TUTOR_PROFILE.MY_PROFILE_LABEL_PREFIX': 'Za spremembe svojega profila, uredite svoje',

  'TUTOR_PROFILE_CALENDAR.TITLE': 'RASPORED DOSTUPNOSTI', // TODO: nema prijevoda?
  'TUTOR_PROFILE_CALENDAR.DAY_MONDAY': 'Ponedeljek',
  'TUTOR_PROFILE_CALENDAR.DAY_TUESDAY': 'Torek',
  'TUTOR_PROFILE_CALENDAR.DAY_WEDNESDAY': 'Sreda',
  'TUTOR_PROFILE_CALENDAR.DAY_THURSDAY': 'Četrtek',
  'TUTOR_PROFILE_CALENDAR.DAY_FRIDAY': 'Petek',
  'TUTOR_PROFILE_CALENDAR.DAY_SATURDAY': 'Sobota',
  'TUTOR_PROFILE_CALENDAR.DAY_SUNDAY': 'Nedelja',
  'TUTOR_PROFILE_CALENDAR.NO_SCHEDULE': 'Ni termina',

  'TUTOR_PROFILE_CALENDAR.TIMETABLE_EVENT_TOOLTIP': 'Rezerviraj uro v tem času',

  // errors
  'TUTOR_PROFILE_CALENDAR.ERROR_MESSAGE.TUTOR_CALENDAR_UPDATE': 'Napaka pri shranjevanju koledarja',
  'TUTOR_PROFILE_CALENDAR.EDIT_ERROR.PERIOD_TOO_SHORT': 'Minimalno trajanje ure je 45 minut',
  'TUTOR_PROFILE_CALENDAR.EDIT_ERROR.TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING': 'Vnseni termin se prekrivajo. prosimo, vnesite termine, ki se ne prekrivajo',

  'TUTOR_PROFILE_DETAILS.ABOUT_TITLE': 'O INŠTRUKTORJU',
  'TUTOR_PROFILE_DETAILS.ABOUT_ME_LABEL': 'O SEBI',
  'TUTOR_PROFILE_DETAILS.ABOUT_ME_PLACEHOLDER': 'Vnesite več podatkov o sebi',
  'TUTOR_PROFILE_DETAILS.AREAS_AND_LEVELS_LABEL': 'Predmeti in ravni zahtevnosti',
  'TUTOR_PROFILE_DETAILS.AREAS_AND_LEVELS_PLACEHOLDER': 'Izberite vsaj en predmet in ustrezno raven',
  'TUTOR_PROFILE_DETAILS.EXPERIENCE_PERIOD_LABEL': 'Obdobje',
  'TUTOR_PROFILE_DETAILS.EDUCATION_LABEL': 'IZOBRAŽEVANJE',
  'TUTOR_PROFILE_DETAILS.EDUCATION_NAME_LABEL': 'Poklic',
  'TUTOR_PROFILE_DETAILS.EDUCATION_NAME_PLACEHOLDER': 'Vnesite poklic',
  'TUTOR_PROFILE_DETAILS.EDUCATION_INSTITUTION_LABEL': 'Ime izobraževalne ustanove',
  'TUTOR_PROFILE_DETAILS.EDUCATION_INSTITUTION_PLACEHOLDER': 'Vnesite ime izobraževalne ustanove',
  'TUTOR_PROFILE_DETAILS.EDUCATION_DESCRIPTION_LABEL': 'Opis izobraževanja',
  'TUTOR_PROFILE_DETAILS.EDUCATION_DESCRIPTION_PLACEHOLDER': 'Vnesite več podatkov o izobraževanju (smer, projekti, sodelovanja, usposabljanja…)',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_LABEL': 'DELOVNE IZKUŠNJE',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_NAME_LABEL': 'Naziv delovnega mesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_NAME_PLACEHOLDER': 'Vnesite naziv delovnega mesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_INSTITUTION_LABEL': 'Ime podjetja ali ustanove',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_INSTITUTION_PLACEHOLDER': 'Vnesite ime podjetja ali ustanove',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_DESCRIPTION_LABEL': 'Opis delovnega mesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_DESCRIPTION_PLACEHOLDER': 'Vnesite več podatkov o delovnem mestu (uporabljenje tehnologije, projekti, specializacije…)',
  // errors
  'TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE': 'Napaka pri shranjevanju podatkov o uporabniku.',

  'TUTOR_PROFILE_REVIEWS.TUTOR_NOT_RATED': 'Ta inštruktor še ni ocenjen',
  'TUTOR_PROFILE_REVIEWS.SECTION_TITLE': 'RECENZIJE',
  'TUTOR_PROFILE_REVIEWS.ADD_REVIEW_BUTTON': 'Vnesite novo recenzijo',
  'TUTOR_PROFILE_REVIEWS.BUTTON_DISABLED_TOOLTIP': 'Recenzije lahko vnesejo samo uporabniki, ki so imeli lekcijo s tem inštruktorjem',
  'TUTOR_PROFILE_REVIEWS.MODAL.TITLE': 'Nova recenzija',
  'TUTOR_PROFILE_REVIEWS.MODAL.GRADE_LABEL': 'Ocena',
  'TUTOR_PROFILE_REVIEWS.MODAL.GRADE_VALIDATION_TEXT': 'Ocena je obvezna',
  'TUTOR_PROFILE_REVIEWS.MODAL.TEXTAREA_LABEL': 'Dodaj komentar z oceno',
  // errors
  'TUTOR_PROFILE_REVIEWS.ERROR_MESSAGE.REVIEW_SEND': 'Napaka pri shranjevanju recenzije o inštruktorju. prosimo, poskusite znova kasneje.',

  'USER_PROFILE.PUBLIC_PROFILE_LINK_LABEL': 'Oglejte si svoj profil, kot ga vidijo drugi',
  'USER_PROFILE.PERSONAL_DATA_LABEL': 'Osebni podatki',
  'USER_PROFILE.CONTACT_DATA_LABEL': 'Kontaktni podatki',
  'USER_PROFILE.CONTACT_DATA_INFO_MESSAGE': 'Ti podatki niso vidni drugim uporabnikom sistema.',
  'USER_PROFILE.PARENT_DATA_LABEL': 'Podatki o starših',
  'USER_PROFILE.CALENDAR_LABEL': 'Dostopnost',
  'USER_PROFILE.DETAILS_LABEL': 'Podrobnosti o meni',
  'USER_PROFILE.PAYMENT_DATA_LABEL': 'Podatki o plačilu',
  'USER_PROFILE.CHANGE_PASSWORD_LABEL': 'Sprememba gesla',
  'USER_PROFILE.EDIT_PERSONAL_DATA_LABEL': 'Uredite osebne podatke',
  'USER_PROFILE.EDIT_CONTACT_DATA_LABEL': 'Uredite kontaktne podatke',
  'USER_PROFILE.EDIT_PARENT_DATA_LABEL': 'Uredite podatke o starših',
  'USER_PROFILE.EDIT_CALENDAR_INFO_MESSAGE': 'Vnesite termine, ko ste običajno prosti. Študenti in starši bodo te podatke videli v vašem profilu, lahko pa tudi predlagajo drugačen termin.',
  'USER_PROFILE.EDIT_CALENDAR_LABEL': 'Uredite koledar',
  'USER_PROFILE.EDIT_DETAILS_INFO_MESSAGE': 'Vnesite čim več informacij o sebi, da se čim bolje predstavite otrokom in staršem, ki iščejo inštrukcije.',
  'USER_PROFILE.EDIT_DETAILS_LABEL': 'Uredite podrobnosti',

  'USER_PROFILE.DELETE_PERIOD_LABEL': 'Izbriši obdobje',
  'USER_PROFILE.ADD_PERIOD_LABEL': 'Dodaj obdobje',
  'USER_PROFILE.DELETE_EDUCATION_LABEL': 'Izbriši izobraževanje',
  'USER_PROFILE.ADD_EDUCATION_LABEL': 'Dodaj izobraževanje',
  'USER_PROFILE.DELETE_WORK_EXPERIENCE_LABEL': 'Izbriši delovne izkušnje',
  'USER_PROFILE.ADD_WORK_EXPERIENCE_LABEL': 'Dodaj delovne izkušnje',

  // errors
  'USER_PROFILE.ERROR_MESSAGE.USER_PROFILE_UPDATE': 'Napaka pri shranjevanju podatkov o uporabniku',
  'USER_PROFILE.ERROR_MESSAGE.PARENT_DETAILS_UPDATE': 'Napaka pri shranjevanju podatkov o straših',

  'USER_PROFILE_PERSONAL_DATA.FIRST_NAME_LABEL': 'Ime',
  'USER_PROFILE_PERSONAL_DATA.FIRST_NAME_PLACEHOLDER': 'Vnesite svoje ime',
  'USER_PROFILE_PERSONAL_DATA.LAST_NAME_LABEL': 'Priimek',
  'USER_PROFILE_PERSONAL_DATA.LAST_NAME_PLACEHOLDER': 'Vnesite svoj priimek',
  'USER_PROFILE_PERSONAL_DATA.TUTOR_DISPLAY_NAME_LABEL': 'Ime za uporabnike',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_DISPLAY_NAME_LABEL': 'Ime, ki bo prikazano inštruktorju',
  'USER_PROFILE_PERSONAL_DATA.DISPLAY_NAME_PLACEHOLDER': 'Vnesite ime, ki bo prikazano inštruktorju',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_SCHOOL_LABEL': 'Šola',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_SCHOOL_PLACEHOLDER': 'Vnesite ime šole, ki jo obiskujete',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_CLASS_LABEL': 'Razred',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_CLASS_PLACEHOLDER': 'Izberite trenutni razred',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL': 'Spol',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL_M': 'Moški',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL_F': 'Ženski',
  'USER_PROFILE_PERSONAL_DATA.DOB_LABEL': 'Datum rojstva',

  'USER_PROFILE_PERSONAL_DATA.AVATAR.LABEL': 'Uporabniška slika',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_TITLE': 'Povlecite novo sliko ali jo prenesite iz računalnika',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_SAVE_BUTTON': 'Shrani',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_CANCEL_BUTTON': 'Prekliči',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_UPLOAD_BUTTON': 'Prenesi iz računalnika',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.EDIT_BUTTON': 'Spremeni',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.ERROR_FILE_TYPE': 'Izbrati je mogoče samo jpg. ali .png datoteke!',

  'USER_PROFILE_CONTACT_DATA.ADDRESS_LABEL': 'Naslov',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_STREET_LABEL': 'Ulica',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_STREET_PLACEHOLDER': 'Vnesite ime ulice',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_HOME_NUMBER_LABEL': 'Hišna številka',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_HOME_NUMBER_PLACEHOLDER': 'Vnesite hišno številko',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_ZIP_LABEL': 'Poštna številka',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_ZIP_PLACEHOLDER': 'Vnesite poštno številko',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_CITY_LABEL': 'Mesto',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_CITY_PLACEHOLDER': 'Vnesite ime mesta',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_COUNTRY_LABEL': 'Država',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_COUNTRY_PLACEHOLDER': 'Vnesite ime države',
  'USER_PROFILE_CONTACT_DATA.EMAIL_LABEL': 'Elektronski naslov',
  'USER_PROFILE_CONTACT_DATA.EMAIL_VALIDATION_TEXT': 'Vnesite veljaven elektronski naslov',
  'USER_PROFILE_CONTACT_DATA.EMAIL_PLACEHOLDER': 'Vnesite svoj elektronski naslov',
  'USER_PROFILE_CONTACT_DATA.PHONE_LABEL': 'Telefon',
  'USER_PROFILE_CONTACT_DATA.PHONE_PLACEHOLDER': 'Vnesite svojo telefosko številko',

  'USER_PROFILE_PARENT_DATA.PARENT_FIRST_NAME_LABEL': 'Ime starša',
  'USER_PROFILE_PARENT_DATA.PARENT_FIRST_NAME_PLACEHOLDER': 'Vnesite ime svojega starša',
  'USER_PROFILE_PARENT_DATA.PARENT_LAST_NAME_LABEL': 'Priimek starša',
  'USER_PROFILE_PARENT_DATA.PARENT_LAST_NAME_PLACEHOLDER': 'Vnesite priimek svojega starša',
  'USER_PROFILE_PARENT_DATA.PARENT_EMAIL_LABEL': 'Elektronski naslov starša',
  'USER_PROFILE_PARENT_DATA.PARENT_EMAIL_PLACEHOLDER': 'Vnesite elektronski naslov svojega starša',

  'USER_PROFILE.CALENDAR_NO_DATA': 'Za ta dan ni podatkov',

  'USER_PROFILE_DETAILS.HOURLY_RATE_LABEL': 'Cena šolske ure',
  'USER_PROFILE_DETAILS.HOURLY_RATE_PLACEHOLDER': 'Vnesite ceno šolske ure',
  'USER_PROFILE_DETAILS.SLOGAN_LABEL': 'Slogan',
  'USER_PROFILE_DETAILS.SLOGAN_PLACEHOLDER': 'Vnesite svoj slogan',

  // ---------- SessionCreate Form
  'SESSION_CREATE.TITLE': 'Zahteva za rezervacijo termina',
  'SESSION_CREATE.BUTTON_PREV_LABEL': 'Predhodni korak',
  'SESSION_CREATE.BUTTON_NEXT_LABEL': 'Naslednji korak',
  'SESSION_CREATE.BUTTON_SUBMIT_LABEL': 'Pošlji zahtevo za rezervacijo termina',
  'SESSION_CREATE.STEP_ONE_TITLE': 'Vaš želeni termin',
  'SESSION_CREATE.STEP_TWO_TITLE': 'Kaj se želiš naučiti',
  'SESSION_CREATE.STEP_THREE_TITLE': 'Informacija za inštruktorja',
  'SESSION_CREATE.SESSION_START_DATE_LABEL': 'Datum termina',
  'SESSION_CREATE.SESSION_START_TIME_LABEL': 'Čas termina',
  'SESSION_CREATE.SESSION_DURATION_LABEL': 'Trajanje termina',
  'SESSION_CREATE.DURATION_ONE_HOUR': 'Ena šolska ura',
  'SESSION_CREATE.DURATION_TWO_HOURS': 'Dve šolski uri',
  'SESSION_CREATE.DESCRIPTION_LABEL': 'Opis ure',
  'SESSION_CREATE.DESCRIPTION_PLACEHOLDER': 'Vpišite dodatni opis ure',
  'SESSION_CREATE.TUTOR_EDUCATION_AREA_LABEL': 'Predmet',
  'SESSION_CREATE.TUTOR_EDUCATION_AREA_PLACEHOLDER': 'Izberite predmet',
  'SESSION_CREATE.EDUCATION_AREA_LABEL': 'Tema',
  'SESSION_CREATE.EDUCATION_AREA_PLACEHOLDER': 'Izberite temo pouka',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_LABEL': 'Cilj',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_PLACEHOLDER': 'Izberite cilj pouka',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_DEADLINE_LABEL': 'Rok za dosego cilja',
  'SESSION_CREATE.SYLLABUS_IMAGES_LABEL': 'Slike učnega gradiva',
  'SESSION_CREATE.TUTORING_SESSION_DESCRIPTION_LABEL': 'Sporočilo inštruktorju',
  'SESSION_CREATE.NO_AVAILABLE_ROOMS_LABEL': 'V želenem času ni na voljo nobene sobe. Prosimo, izberite drug termin!',

  // messages
  'SESSION_CREATE.MESSAGE.SESSION_CREATED': 'Zahteva za rezervacijo termina je poslana.',
  // errors
  'SESSION_CREATE.ERROR.MESSAGE_SENDING_FAILED': 'Napaka pri pošiljanju sporočila, prosimo poskusite znova.',
  'SESSION_CREATE.ERROR.ADD_FILES_TO_SESSION_FAILED': 'Napaka pri prenosu datotek, prosimo poskusite znova.',
  // API errors
  'SESSION_CREATE.ERROR.ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD': 'V izbranem času že obstaja potrjena ura pouka. Poskusite izbrati drug čas.',
  'SESSION_CREATE.ERROR.TUTORING_SESSION_START_END_TIME_NOT_REGULAR': 'Zahtevani čas inštrukcijske ure ni dovoljen.',
  'SESSION_CREATE.ERROR.NO_TUTORING_ROOMS_AVAILABLE_IN_SPECIFIED_PERIOD': 'V želenem času ni na voljo nobene sobe. Prosimo, izberite drug termin!',

  // ---------- SessionView
  'TUTORING_SESSION_VIEW.NO_SESSIONS_LABEL': 'Za izbrani mesec z izbranim študentom / inštruktorjem nimate ur pouka. Tu bodo prikazane zahteve za predavanja, ki jih prejmete od študentov, in informacije o izvedenih urah.',
  'TUTORING_SESSION_VIEW.STUDENT_NOT_FOUND': 'Študent ni najden. Poskusite najti želenega študenta med ',
  'TUTORING_SESSION_VIEW.STUDENT_NOT_FOUND_LINK': 'študenti',
  'TUTORING_SESSION_VIEW.INSTRUCTOR_NOT_FOUND': 'Inštruktor ni najden. Poskusite najti želenega inštruktorja med ',
  'TUTORING_SESSION_VIEW.INSTRUCTOR_NOT_FOUND_LINK': 'inštruktorji',
  'TUTORING_SESSION_VIEW.NON_EXISTING_TUTORING_SESSION': 'Inštrukcijska ura ni najdena. Poskusite najti želeno uro v ',
  'TUTORING_SESSION_VIEW.NON_EXISTING_TUTORING_SESSION_CALENDAR': 'svojem koledarju',
  'TUTORING_SESSION_VIEW.UPDATE.SESSION_START_LABEL': 'Termin',
  'TUTORING_SESSION_VIEW.UPDATE.SESSION_START_PLACEHOLDER': 'Izberite termin',
  'TUTORING_SESSION_VIEW.THEME_LABEL': 'Tema',
  'TUTORING_SESSION_VIEW.DEADLINE_LABEL': 'Rok',
  'TUTORING_SESSION_VIEW.EDUCATION_AREA_LABEL': 'Predmet',
  'TUTORING_SESSION_VIEW.TUTORING_SESSION_GOAL_LABEL': 'Cilj',
  'TUTORING_SESSION_VIEW.ACTIONS.CONFIRM': 'Potrdite termin',
  'TUTORING_SESSION_VIEW.ACTIONS.DECLINE': 'Zavrni termin',
  'TUTORING_SESSION_VIEW.ACTIONS.CANCEL': 'Prekliči termin',
  'TUTORING_SESSION_VIEW.ACTIONS.ABORT': 'Prekliči',
  'TUTORING_SESSION_VIEW.ACTIONS.EDIT': 'Spremeni termin',
  'TUTORING_SESSION_VIEW.ACTIONS.EDIT_SAVE': 'Pošlji zahtjev',
  'TUTORING_SESSION_VIEW.MESSAGE': 'Vnesite poruku',
  'TUTORING_SESSION_VIEW.TITLE': 'Inštrukcijska ura',
  'TUTORING_SESSION_VIEW_FORM.SAVE_BUTTON_LABEL': 'Shrani',
  'TUTORING_SESSION_VIEW.SESSION_START_LABEL': 'Termin',
  'TUTORING_SESSION_VIEW.ACTIONS.UPLOAD_FILE': 'Dodaj gradivo iz računalnika',
  'TUTORING_SESSION_VIEW.ACTIONS.ADD_FROM_REPOSITORY': 'Dodaj iz mojih materialov',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_ROOM': 'Odprite sobo',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_WHITEROOM': 'Odpri sobo za pisanje na telefonu',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_MOBILE_APP': 'Odpri mobilno aplikacijo',
  'TUTORING_SESSION_VIEW.ACTIONS.ROOM_OPTIONS_TITLE': 'Izvajanje pouka',
  'TUTORING_SESSION_VIEW.ACTIONS.PC_OPTIONS_TEXT': 'Odprite sobo v računalniku z naslednjim gumbom: ',
  'TUTORING_SESSION_VIEW.ACTIONS.MOBILE_OPTIONS_TEXT': 'Če na uri veliko pišete, na telefonu ali na tabličnem računalniku, odprite sobo za pisanje -  lahko pišete po zaslonu ali po papirju, lahko slikate in z enim klikom pošljete sliko na tablo',
  'TUTORING_SESSION_VIEW.OPEN_WHITEROOM_SECTION_TITLE': '',
  'TUTORING_SESSION_VIEW.OPEN_MOBILE_APP_SECTION_TITLE': ' ali pišite po papirju,  z mobilno aplikacijo Lemon pa enostavne slikate kar ste napisali in pošljete na tablo',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_TIME_CHANGE': '%{name} je zahteval/a spremembo termina %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CONFIRMATION': '%{name} je potrdil/a inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CANCELATION': '%{name} je odpovedal/a inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_DECLINED': '%{name} je zavrnil/a inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CREATED': '%{name} je zahteval/a inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_STARTED': 'Inštrukcijska ura se je začela',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_ENDED': 'Inštrukcijska ura se je končala',
  // tslint:disable-next-line: no-duplicate-string
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_FILE_ADDED': 'Datoteka %{filename} dodana',
  // tslint:disable-next-line: no-duplicate-string
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_MESSAGE_SENT': '%{name} je poslal/a novo sporočilo',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.REVIEW_CREATED': '%{name} je dodal recenzijo',

  // personalized messages for myself

  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_TIME_CHANGE.MYSELF': 'Zahtevali ste spremembo termina %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CONFIRMATION.MYSELF': 'Potrdili ste inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CANCELATION.MYSELF': 'Odpovedali ste inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_DECLINED.MYSELF': 'Odpovedali ste inštrukcijsko uro %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CREATED.MYSELF': 'Zahtevali ste inštrukcijsko uro  %{date} v %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_MESSAGE_SENT.MYSELF': 'Poslali ste novo sporočilo',

  'TUTORING_SESSION_VIEW.TIMELINE_TABS.ACTIVITY_LIST_TITLE': 'Aktivnosti',
  'TUTORING_SESSION_VIEW.TIMELINE_TABS.FILE_LIST_TITLE': 'Datoteke',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_ADDED': 'Datoteka %{filename} dodana',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_UPLOAD_ERROR': 'Napaka pri prenosu datoteke %{filename}',
  'TUTORING_SESSION_VIEW.FILE_LIST.TUTOR_OWN_FILES_TITLE': 'Moji materiali so pripravljeni za uro',
  'TUTORING_SESSION_VIEW.FILE_LIST.TUTOR_FILES_TITLE': 'Inštruktorjevi materiali',
  'TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_FILES_TITLE': 'Študentovi materiali',
  'TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_OWN_FILES_TITLE': 'Moji materiali',
  'TUTORING_SESSION_VIEW.FILE_LIST.HOMEWORK_FILES_TITLE': 'Domača naloga',
  'TUTORING_SESSION_VIEW.FILE_LIST.SCREENSHOT_FILES_TITLE': 'Skupni zapiski',
  'TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_VISIBLE': 'Pokaži študentu',
  'TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_HIDDEN': 'Skrij pred študentom',
  'TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_TITLE': 'Potrdite polje ob želenih datotekah in jih dodajte na inštrukcijsko uro',
  'TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_OK_BUTTON_TEXT': 'Dodaj na inštrukcijsko uro',
  'TUTORING_SESSION_VIEW.DROP_FILE_HERE': 'Spustite daoteku sem',
  // messages
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CONFIRMED': 'Inštrukcijska ura je potrjena',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CANCELED': 'Inštrukcijska ura je odpovedana',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_DECLINED': 'Inštrukcijska ura je zavrnjena',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_FEEDBACK_SENT': 'Pregled je bil uspešno poslan staršu',
  // errors
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.UNCONFIRMED_TUTORING_SESSION': 'Vstop v sobo je mogoč šele, ko vsi udeleženci potrdijo inštrukcije.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.JOIN_TUTORING_SESSION_TOO_EARLY': 'Videti je, da se vam je nekoliko mudilo? :-) Vstop v sobo je možen 30 minut pred dogovorjenim začetkom inštrukcij. Poskusite znova ob določenem času.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.SESSION_STATUS_ENDED': 'Ura se je končala ali je odpovedana',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.DEFAULT': 'Prišlo je do napake. :-( Prosimo, poskusite poslati sporočilo. Prosimo, poskusite znova.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.TIMELINE_FETCH': 'Napaka pri prenosu aktivnosti z inštrukcijske ure',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.MESSAGE_SEND': 'Napaka pri pošiljanju sporočila. Prosimo, poskusite znova.',
  // API errors
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.NON_EXISTING_TUTORING_SESSION': 'Inštrukcijska ura ni najdena',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.FINISHED_TUTORING_SESSION_UPDATE_NOT_POSSIBLE': 'Ura se je končala, spremembe niso več mogoče.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.SESSION_RESCHEDULING_NOT_POSSIBLE': 'Sprememba časa inštrukcijske ure več ni možna.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD': 'Ob zahtevanem času  že obstaja potrjena inštrukcijska ura. Poskusite premakniti uro na drug čas.',

  // ---------- Session Callendar
  'SESSION_CALENDAR.WEEK_LABEL': 'Teden',
  'SESSION_CALENDAR.EMPTY_DAY': 'Brez inštrukcijske ure',
  'SESSION_CALENDAR.EMPTY_LIST_TUTOR': 'Nimate inštrukcijskih ur za izbrane datume in stanja. Tu bodo prikazane zahteve za ure, ki jih prejmete od študentov.',
  'SESSION_CALENDAR.EMPTY_LIST_STUDENT_TEXT': 'Nimate inštrukcijskih ur za izbrane datume in stanja.',
  'SESSION_CALENDAR.EMPTY_LIST_STUDENT_LINK': 'Poiščite inštruktorja v iskalniku',
  'SESSION_CALENDAR.FILTER_LABEL_ALL': 'Vsi termini',
  'SESSION_CALENDAR.FILTER_LABEL_CONFIRMED': 'Dogovorjeni termini',
  'SESSION_CALENDAR.FILTER_LABEL_UNCONFIRMED': 'Čakajo na potrdilo',
  'SESSION_CALENDAR.FILTER_LABEL_PAST': 'Arhiva',
  'SESSION_CALENDAR.REFRESH_BUTTON_TOOLTIP': 'Osveži termine',

  'SESSION_CALENDAR.UPDATE_SESSION.CURRENT_SESSION': 'TRENUTNI TERMIN',
  'SESSION_CALENDAR.UPDATE_SESSION.SESSION_LIST_TITLE': 'Na izbrani datum imate naslednje termine:',
  'SESSION_CALENDAR.UPDATE_SESSION.EMPTY_LIST': 'Na izbrani datum nimate inštrukcijskih ur',

  // ---------- Session Rejection
  'TUTORING_SESSION_REJECTION.TITLE_CANCEL': 'Odpoved termina!',
  'TUTORING_SESSION_REJECTION.TITLE_DECLINE': 'Zavračaš zahtevo za rezervacijo termina!',
  'TUTORING_SESSION_REJECTION.TUTOR_MESSAGE_LABEL': 'Sporočilo za inštruktorja',
  'TUTORING_SESSION_REJECTION.STUDENT_MESSAGE_LABEL': 'Sporočilo za študenta',
  // errors
  'TUTORING_SESSION_REJECTION.ERROR.MESSAGE_SENDING_FAILED': 'Napaka pri pošiljanju sporočila, prosimo poskusite znova.',

  // ----------- File List
  'FILE_LIST.BREADCRUMB_HOME_LABEL': 'Baza znanja',
  'FILE_LIST.PUBLIC_FOLDER': 'Javne datoteke',
  'FILE_LIST.PRIVATE_FOLDER': 'Moje datoteke',
  'FILE_LIST.TYPE_LABEL': 'Tip',
  'FILE_LIST.NAME_LABEL': 'Naziv',
  'FILE_LIST.SIZE_LABEL': 'Velikost',
  'FILE_LIST.DATE_LABEL': 'Datum ažuriranja',
  'FILE_LIST.TAGS_LABEL': 'Oznake',
  'FILE_LIST.TITLE_SEARCH_RESULTS_NAME_LABEL': 'Rezultati iskanja (%{items} rezultata)',
  'FILE_LIST.TITLE_TAGS_LABEL': 'Oznake',
  'FILE_LIST.TITLE_SIZE_LABEL': 'Velikost',
  'FILE_LIST.TITLE_ACTIONS_LABEL': 'Akcije',
  'FILE_LIST.DELETE_CONFIRM_TITLE': 'Izbrisati?',
  'FILE_LIST.ACTIONS_OPEN_LABEL': 'Odpri',
  'FILE_LIST.ACTIONS_DOWNLOAD_LABEL': 'Prenesi',
  'FILE_LIST.ACTIONS_RENAME_LABEL': 'Preimenuj',
  'FILE_LIST.ACTIONS_DELETE_LABEL': 'Izbriši',
  'FILE_LIST.ACTIONS_MOVE_LABEL': 'Premakni',
  'FILE_LIST.SEARCH_FILES_INPUT_PLACEHOLDER': 'Iskanje datotek',
  'FILE_LIST.ADD_NEW_BUTTON': 'Dodaj',
  'FILE_LIST.UPLOAD_FILE_BUTTON': 'Nova datoteka',
  'FILE_LIST.NEW_FOLDER_BUTTON': 'Nova mapa',
  'FILE_LIST.EMPTY_FOLDER_TEXT': 'Ta mapa je prazna',
  'FILE_LIST.NAME_INPUT_PLACEHOLDER': 'Vnesite ime',
  'FILE_LIST.NAME_INPUT_TITLE_CREATE_FOLDER': 'Dodaj novo mapo',
  'FILE_LIST.NAME_INPUT_TITLE_RENAME_FOLDER': 'Preimenuj mapo',
  'FILE_LIST.NAME_INPUT_TITLE_RENAME_FILE': 'Preimenuj datoteko',
  'FILE_LIST.NAME_INPUT_INVALID_ENTRY': 'Neveljavno ime datoteke',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_ADDED': 'Datoteka %{filename} dodana',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_UPLOAD_ERROR': 'Napaka pri dodavanju datoteke %{filename}',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'FILE_LIST.ROOT_FOLDER_DISPLAY_NAME': 'Moji materijali',
  'FILE_LIST.MOVE_TO_FOLDER_SELECTED_NAME_DISPLAY': 'Premakni v mapo: %{folderName}',
  'FILE_LIST.MOVE_TO_FOLDER_FROM_FOLDER_SELECTED_TEXT': 'Datoteke so že v tej mapi!',
  'FILE_LIST.MOVE_TO_FOLDER_MODAL_TITLE': 'Izberi mapo',
  'FILE_LIST.ADD_NEW_TAG_BUTTON_LABEL': 'Dodaj',
  'FILE_LIST.TITLE_ALREADY_IN_USE': 'Mapa z imenom, ki ste ga vnesli, že obstaja!',
  'FILE_LIST.MESSAGE.FILE_SIZE_TOO_LARGE': 'Datoteka, ki ste jo vnesli, je prevelika. Prosimo, izberite datoteko do %{maxSizeFormated}',
  'FILE_LIST.MESSAGE.FILE_NAME_TOO_LONG': 'Ime vnesene datoteke je predolgo. Izberite datoteko z imenom do %{maxNameLenght} znakov',

  // ----------- Tutoring Room
  'TUTORING_ROOM.ACTION_LEAVE_ROOM': 'Zaključi uro',
  'TUTORING_ROOM.ACTION_PAUSE_ROOM': 'Zaustavi uro',
  'TUTORING_ROOM.ACTION_CREATE_SESSION': 'Dogovorite naslednjo uro',
  'TUTORING_ROOM.WAITING_MESSAGE': 'Povezujemo se v sobo. Prosimo, počakajte trenutek ....',
  'TUTORING_ROOM.WAITING_OTHER_PARTICIPANTS_MESSAGE': 'Čakamo na ostale udeležence ....',
  // info messages
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_END_NOTICE': 'Inštrukcijska ura se konča za %{endPeriod}min',
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_ENDED.TITLE': 'Ura se je končala',
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_ENDED.TEXT': 'Ura je potekla. Soba se samodejno zapre.',
  'TUTORING_ROOM.INFO_MESSAGE.PARTICIPANT_LEFT.TITLE': 'Druga stran je končala uro',
  'TUTORING_ROOM.INFO_MESSAGE.PARTICIPANT_LEFT.TEXT': 'Uporabnik %{participant} je zaključil lekcijo. Prosimo, izberite gumb „Zaključi lekciju“, da se bo lekcija uspešno zaprla.',
  // errors
  'TUTORING_ROOM.ERROR_MESSAGE.DEFAULT': 'Ups, nismo mogli v sobo. :-( Prosim poskusite kasneje.',
  'TUTORING_ROOM.ERROR_MESSAGE.LEAVE_ROOM': 'Napaka pri zapuščanju sobe.',
  'TUTORING_ROOM.ERROR_MESSAGE.SESSION_FILE_LIST_FETCH': 'Napaka pri prenosu datotek iz inštrukcijske ure',
  // API errors
  'TUTORING_ROOM.ERROR_MESSAGE.NON_EXISTING_TUTORING_SESSION': 'Inštrukcijska ura ni najdena? :-/',
  'TUTORING_ROOM.ERROR_MESSAGE.UNCONFIRMED_TUTORING_SESSION': 'Vstop v sobo je mogoč šele, ko vsi udeleženci potrdijo inštrukcije.',
  'TUTORING_ROOM.ERROR_MESSAGE.JOIN_TUTORING_SESSION_TOO_EARLY': 'Videti je, da se vam je nekoliko mudilo? :-) Vstop v sobo je možen 30 minut pred dogovorjenim začetkom inštrukcij. Poskusite znova ob določenem času.',
  'TUTORING_ROOM.ERROR_MESSAGE.TUTORING_SESSION_ALREADY_FINISHED': 'Inštrukcije so se že končale, vendar lahko dogovorite nove  :-)',
  'TUTORING_ROOM.ERROR_MESSAGE.REQUEST_START_END_TIME_NOT_REGULAR': 'Zahtevani čas inštrukcijske ure ni dovoljen.',

  'TUTORING_ROOM.SESSION_FILE_LIST.TITLE': 'Datoteke',
  'TUTORING_ROOM.SESSION_FILE_LIST.ITEM_TITLE': 'Povlecite povezavo na tablo, da lahko dodate sliko na tablo',
  'TUTORING_ROOM.SESSION_FILE_LIST.ADD_FILE_BUTTON_TITLE': 'Dodaj sliko na tablo',
  'TUTORING_ROOM.SESSION_FILE_LIST.OPEN_FILE': 'Odpri datoteko v novem oknu',

  'TUTORING_ROOM.DRAWER_TRIGGER_TITLE': 'Materiali in zapiski',
  'TUTORING_ROOM.PREVIOUS_SESSION_DIVIDER_TITLE': 'Prejšnja ura (%{date})',

  // ---------- Whiteboard room
  'WHITEBOARD_ROOM.FILE_UPLOAD_INFO_TITLE': 'Dodajanje datoteke na tablo',
  'WHITEBOARD_ROOM.FILE_UPLOAD_INFO_DESCRIPTION': 'Postavite sliko na želeno mesto na tabli, po potrebi spremenite velikost in rotacijo ter kliknite gumb "Prilepi na tablo".',

  // ---------- Whiteboard toolbar
  'WHITEBOARD_TOOLBAR.SELECT_TOOLTIP': 'Izberi',
  'WHITEBOARD_TOOLBAR.UNDO_TOOLTIP': 'Prekliči',
  'WHITEBOARD_TOOLBAR.COLOR_TOOLTIP': 'Izberi barvo',
  'WHITEBOARD_TOOLBAR.PEN_TOOLTIP': 'Svinčnik',
  'WHITEBOARD_TOOLBAR.MARKER_TOOLTIP': 'Marker',
  'WHITEBOARD_TOOLBAR.LINE_TOOLTIP': 'Linija',
  'WHITEBOARD_TOOLBAR.ERASER_TOOLTIP': 'Brisanje',
  'WHITEBOARD_TOOLBAR.PEN_SIZE': 'Debelina svinčnika',
  'WHITEBOARD_TOOLBAR.SCISSORS_TOOLTIP': 'Rezanje',
  'WHITEBOARD_TOOLBAR.CLEAR_TOOLTIP': 'Očisti',
  'WHITEBOARD_TOOLBAR.RECTANGLE_TOOLTIP': 'Pravokotnik',
  'WHITEBOARD_TOOLBAR.RECTANGLE_FILLED_TOOLTIP': 'Zapolnjen pravokotnik',
  'WHITEBOARD_TOOLBAR.ELLIPSE_TOOLTIP': 'Elipsa',
  'WHITEBOARD_TOOLBAR.ELLIPSE_FILLED_TOOLTIP': 'Zapolnjena elipsa',
  'WHITEBOARD_TOOLBAR.TEXT_TOOLTIP': 'Besedilo',
  'WHITEBOARD_TOOLBAR.ERASER_SIZE': 'Debelina brisanja',
  'WHITEBOARD_TOOLBAR.STROKE_THICKNESS': 'Debelina linije',
  'WHITEBOARD_TOOLBAR.PLACE_IMAGE': 'Prilepi na tablo',
  'WHITEBOARD_TOOLBAR.UPLOAD': 'Dodaj',
  'WHITEBOARD_TOOLBAR.UPLOAD_IMAGE': 'Dodaj sliko iz računalnika',
  'WHITEBOARD_TOOLBAR.SEND_USER_POINTER': 'Pošlji moj kazalec drugim uporabnikom',
  'WHITEBOARD_TOOLBAR.SHOW_USER_POINTER': 'Prikaži kazalce drugih uporabnikov',

  // ---------- User approvals
  'USER_APPROVAL.GRANT_BUTTON_LABEL': 'Dodaj dovoljenja',
  'USER_APPROVAL.APPROVAL_TITLE.MessagingService': 'Dovoljenja za obveščanje',
  'USER_APPROVAL.APPROVAL_MESSAGE.MessagingService': 'Da vas bomo lahko pravočasno obvestili o spremembah glede vaših inštrukcij, prosimo vas, da v brskalniku dovolite prejem obvestil ("Notifications").',
  'USER_APPROVAL.APPROVAL_TITLE.VideoService': 'Dovoljenja za kamere in mikrofone',
  'USER_APPROVAL.APPROVAL_MESSAGE.VideoService': 'Če želite v celoti izkoristiti možnosti inštrukcij, prosimo vas, da dovolite dostop do kamere in mikrofona v vašem brskalniku.',

  // ---------- User info notifications
  'USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_LABEL': 'Baza znanja',
  'USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_MESSAGE':
    'Tu lahko sistematizirate in shranite gradiva, ki jih uporabljate pri pouku. Vam bodo na voljo med pripravo na uro in med samo uro. Za uporabo na tabli, morajo biti materiali v slikovni obliki. Vključili smo že nekaj javnih datotek, ki bi lahko bile koristne - če vam ustrezajo, jih uporabite!',

  // ---------- Messaging notifications
  'NOTIFICATION_MENU.COMMON.LIST_EMPTY': 'Trenutno nimate nobenih obvestil',
  'NOTIFICATION_MENU.COMMON.DISMISS_ALL_BUTTON_LABEL': 'Odstrani vsa obvestila',
  'NOTIFICATION_MENU.COMMON.DISMISS_BUTTON_TITLE': 'Odstrani sporočilo',
  'NOTIFICATION_MENU.COMMON.USER_DEFAULT': 'Uporabnik',
  'NOTIFICATION_MENU.SESSION_INCOMING_NOTICE.TITLE': 'Inštrukcijska ura se začne za %{incomingPeriod}min',
  'NOTIFICATION_MENU.SESSION_INCOMING_NOTICE_NOW.TITLE': 'Inštrukcijska ura se začne',
  'NOTIFICATION_MENU.SESSION_END_NOTICE.TITLE': 'Inštrukcijska ura se konča za %{endPeriod}min',
  'NOTIFICATION_MENU.SESSION_TIME_CHANGE.TITLE': 'Zahteva za spremembo termina',
  'NOTIFICATION_MENU.SESSION_CONFIRMATION.TITLE': 'Predlog termina potrjen',
  'NOTIFICATION_MENU.SESSION_CANCELATION.TITLE': 'Termin odpovedan',
  'NOTIFICATION_MENU.SESSION_DECLINED.TITLE': 'Predlog termina zavrnjen',
  'NOTIFICATION_MENU.SESSION_CREATED.TITLE': 'Zahteva za rezervacijo termina',
  'NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TITLE': 'Novo sporočilo',
  'NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TEXT': 'je poslal/a sporočilo:',
  'NOTIFICATION_MENU.SESSION_FILE_ADDED.TITLE': 'Nova datoteka',
  'NOTIFICATION_MENU.SESSION_FILE_ADDED.TEXT': 'dodal/a je datoteke:',
  'NOTIFICATION_MENU.INCOMPLETE_PROFILE': 'Da vas bodo študentje lahko našli, v profil vnesite podrobnosti o sebi (vaši predmeti, cena lekcije) in vsaj en razpoložljiv datum.',
  'NOTIFICATION_MENU.INCOMPLETE_PROFILE_LINK_TEXT': 'Pojdite na izpolnjevanje...',

  // ---------- Notification Info Bar
  'NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.PREFIX_LABEL': 'Termin inštrukcij se začne za',
  'NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.SUFFIX_LABEL': 'minuta',
  'NOTIFICATION_INFO_BAR.SESSION_IN_PROGRESS_NOTICE': 'Inštrukcijska ura poteka',
  'NOTIFICATION_INFO_BAR.SESSION_INFO.STUDENTS_LABEL': 'Študent/ka',
  'NOTIFICATION_INFO_BAR.SESSION_INFO.TUTOR_LABEL': 'Predavatelj/ica',
  'NOTIFICATION_INFO_BAR.JOIN_SESSION_LABEL': 'Pridruži se uri',

  // ---------- Error page

  'ERROR_PAGE.DEFAULT_ERROR_TITLE': 'Ups, nekaj se ni prikazalo',
  'ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION': 'Prosimo, poskusite znova ali nam sporočite, da bomo težavo poskušali rešiti skupaj. Hvala!',

  // ---------- Calendar navigation

  'CALENDAR_NAVIGATION.TODAY': 'Danes',
  'CALENDAR_NAVIGATION.PREVIOUS_MONTH': 'Prejšnji mesec (%{month})', // tooltip
  'CALENDAR_NAVIGATION.NEXT_MONTH': 'Naslednji mesec (%{month})', // tooltip
  'CALENDAR_NAVIGATION.PREVIOUS_YEAR': 'Prejšnje leto (%{year})', // tooltip
  'CALENDAR_NAVIGATION.NEXT_YEAR': 'Naslednje leto (%{year})', // tooltip
  'CALENDAR_NAVIGATION.MODE_YEAR': 'Leto',
  'CALENDAR_NAVIGATION.MODE_MONTH': 'Mesec',

  // ---------- Dashboard
  'DASHBOARD.UNCONFIRMED_SESSIONS_TITLE': 'Inštrukcijske ure, ki čakajo na potrditev',
  'DASHBOARD.UNCONFIRMED_SESSIONS_COUNT': 'Število inštrukcijskih ur, ki čakajo na potrditev: {count}',
  'DASHBOARD.UNCONFIRMED_SESSIONS_NONE': 'Nimate inštrukcijskih ur, ki čakajo na potrditev',
  'DASHBOARD.ACTIVITIES_TITLE': 'Aktivnosti',
  'DASHBOARD.CALENDAR_TITLE': 'Prihajajoče inštrukcijske ure',
  'DASHBOARD.MY_TUTORS_TITLE': 'Moji inštruktorji',
  'DASHBOARD.MY_STUDENTS_TITLE': 'Moji študenti',
  'DASHBOARD.ADD_FILE_FROM_REPOSITORY_SUCCESS': 'Dodajanje v inštrukcijsko uro iz mojega gradiva uspešno končano',
  'DASHBOARD.ADD_FILE_FROM_REPOSITORY_ERROR': 'Napaka pri dodajanju iz Mojega gradiva',

  // ---------- Statitics
  'STATISTICS.ROLE_LABEL': 'Statistika kot',
  'STATISTICS.ROLE_SELECT_TUTOR': 'Inštruktor',
  'STATISTICS.ROLE_SELECT_STUDENT': 'Študent',
  'STATISTICS.ROLE_SELECT_ADMINISTRATOR': 'Administrator',
  'STATISTICS.GROUPING_LABEL': 'Prikaz po:',
  'STATISTICS.GROUPING_WEEK': 'Teden',
  'STATISTICS.GROUPING_MONTH': 'Mesec',
  'STATISTICS.GROUPING_YEAR': 'Leto',
  'STATISTICS.TOGGLE_SHOW_EARNINGS_LABEL': 'Prikaži prihodke',
  'STATISTICS.EDUCATION_AREA_LABEL': 'Predmet',
  'STATISTICS.ANY_EDUCATION_AREA_LABEL': 'Vsi predmeti',
  'STATISTICS.STUDENT_LEVEL_LABEL': 'Raven',
  'STATISTICS.ANY_EDUCATION_LEVEL_LABEL': 'Vse ravni šole',
  'STATISTICS.DATA_TITLE_DATE_PERIOD': 'Obdobje',
  'STATISTICS.DATA_TITLE_DATE_FROM': 'Datum od',
  'STATISTICS.DATA_TITLE_DATE_TO': 'Datum do',
  'STATISTICS.DATA_TITLE_HOURS_TOTAL': 'Zahtevane ure',
  'STATISTICS.DATA_TITLE_HOURS_SUCCESSFUL': 'Narejene ure',
  'STATISTICS.DATA_TITLE_HOURS_CANCELED': 'Odpovedane ure',
  'STATISTICS.DATA_TITLE_HOURS_DECLINED': 'Zavrnjene ure',
  'STATISTICS.DATA_TITLE_EARNINGS': 'Dohodek',
  'STATISTICS.NO_DATA': 'V izbranem obdobju ni bilo nobenih učnih ur',

  // ---------- Admin Session List
  'ADMIN_SESSION_LIST.DURATION_LABEL': 'Trajanje',
  'ADMIN_SESSION_LIST.DESCRIPTION_LABEL': 'Ime ure',
  'ADMIN_SESSION_LIST.TUTOR_LABEL': 'Inštruktor',
  'ADMIN_SESSION_LIST.STUDENTS_LABEL': 'Študenti',
  'ADMIN_SESSION_LIST.STATUS_LABEL': 'Stanje',
  'ADMIN_SESSION_LIST.END_STATUS_LABEL': 'End Status',

  // ---------- Favourites View
  'FAVOURITES_VIEW.NAME_TITLE': 'Ime in priimek',
  'FAVOURITES_VIEW.CLASS_TITLE': 'Razred/leto',
  'FAVOURITES_VIEW.SCHOOL_TITLE': 'Izobraževalna ustanova',
  'FAVOURITES_VIEW.LAST_SESSION_TITLE': 'Zadnji termin',
  'FAVOURITES_VIEW.STUDENT_NO_DATA_TEXT': 'Še niste delali z nobenim inštruktorjem. ',
  'FAVOURITES_VIEW.STUDENT_NO_DATA_LINK': 'Poiščite si inštruktorja v iskalniku.',
  'FAVOURITES_VIEW.TUTOR_NO_DATA_TEXT': 'Še niste zahtevali uro. Študenti, s katerimi boste imeli lekcijo, bodo prikazani na tem seznamu.',
  'FAVOURITES_FILTER.LABEL': 'Filtriranje',
  'FAVOURITES_FILTER.SUCCESSFUL': 'Zadnja narejena ura',
  'FAVOURITES_FILTER.CANCELED': 'Zadnja odpovedana ura',
  'FAVOURITES_FILTER.LAST': 'Zadnja ura',

  // ---------- Session Review
  'SESSION_REVIEW_FORM.FORM_TITLE': 'Ocenite pravkar končano uro',
  'SESSION_REVIEW_FORM.AUDIO_QUALITY_LABEL': 'Kvaliteta zvoka',
  'SESSION_REVIEW_FORM.VIDEO_QUALITY_LABEL': 'Kvaliteta videa',
  'SESSION_REVIEW_FORM.OVERALL_RATING_LABEL': 'Skupna ocena ure',
  'SESSION_REVIEW_FORM.GENERAL_COMMENT_LABEL': 'Dodatni komentar',
  'SESSION_REVIEW_FORM.GENERAL_COMMENT_PLACEHOLDER': 'Vnesite dodatni komentar',

  // ---------- SessionTutor Feedback
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_LABEL': 'Opombe o uri',
  'SESSION_TUTOR_FEEDBACK.TUTOR_FEEDBACK_TOOLTIP_TEXT': 'Opombe vaših lekcij, ki jih želite deliti z učencem in / ali starši',
  'SESSION_TUTOR_FEEDBACK.PRIVATE_FEEDBACK_LABEL': 'Privatne opombe o uri',
  'SESSION_TUTOR_FEEDBACK.TUTOR_PRIVATE_FEEDBACK_TOOLTIP_TEXT': 'Vaše privatne opombe o uri (Vidne samo vam)',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_LABEL_PLACEHOLDER': 'Vnesite opombe o uri',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_ALREADY_SENT_MESSAGE': 'Opombe so poslane staršu %{feedbackSentTime}',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_MISSING': 'Opombe o uri še niso vnesene',
  'SESSION_TUTOR_FEEDBACK.ACTIONS.SEND_TUTOR_FEEDBACK': 'Pošljite mnenje staršu po eletronski pošti (ko pošljete mnenje, bo viden učencu)', // tooltip
  // errors
  'SESSION_TUTOR_FEEDBACK.ERROR_MESSAGE.SEND_TUTOR_FEEDBACK': 'Napaka pri pošiljanju mnenja o uri',

  // ---------- Tooltip help messages SESSION_STATUS_UNRESPONDED
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_INVITED': 'Na to zahtevo za uro se morate odzvati',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_ACCEPTED': 'Čakanje na potrditev zahteve za uro',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_CONFIRMED': 'Dogovorjeni termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_FINISHED': 'Dokončani termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_DECLINED': 'Odpovedani termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNRESPONDED': 'Na to zahtevo za uro ni bilo odgovorjeno pred začetkom ure',
  'LEMON_HELPER_TOOLTIP.SESSION.WHITEBOARD_ROOM_BUTTON_DESCRIPTION': 'Ta soba je namenjena pisanju in risanju z uporabo mobilne naprave',
  'LEMON_HELPER_TOOLTIP.SESSION.MOBILE_APP_BUTTON_DESCRIPTION': 'Prenesite našo mobilno aplikacijo!',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.OWN_FILES_DESCRIPTION': 'Materiali, ki ste jih dodali za uporabo pri pouku',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.TUTOR_FILES_DESCRIPTION': 'Materiali, ki jih je inštruktor dodal za uporabo pri pouku',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.STUDENT_FILES_DESCRIPTION': 'Materiali, ki jih je študent dodal za uporabo pri pouku',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.HOMEWORK_FILES_DESCRIPTION': 'Materiali, ki so dodani kot domača naloga za študenta po pouku',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.SCREENSHOT_FILES_DESCRIPTION': 'Zapiski, ki so zapisani na tabli med poukom',
  'LEMON_HELPER_TOOLTIP.CALENDAR.UNCONFIRMED_SESSIONS_INFO': 'Imate termine, ki čakajo na potrditev',
  'LEMON_HELPER_TOOLTIP.CALENDAR.CONFIRMED_SESSIONS_INFO': 'Imate dogovorjene termine',
  'LEMON_HELPER_TOOLTIP.FILES.ADD_NEW_RESOURCE': 'Dodaj novi material',
  'LEMON_HELPER_TOOLTIP.ROOM.PAUSE_BUTTON_DESCRIPTION': 'Začasno zapusti sobo, ne da bi končal pouk',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_MUTE_ON': 'Izklopi video',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_MUTE_OFF': 'Vklopi video',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_FULL_ON': 'Povečaj prikaz videa',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_FULL_OFF': 'Zmanjšaj prikaz videa',

  // ---------- Onboarding
  'ONBOARDING_TOOLTIP.COMMON.BACK_BUTTON': 'Predhodno',
  'ONBOARDING_TOOLTIP.ROOM.WELCOME_TITLE': 'Kako uporabljati učilnico?',
  'ONBOARDING_TOOLTIP.ROOM.WELCOME_CONTENT': 'Pred vami je tabla, na katero lahko pišete. Vse, kar napišete, vidi tudi drugi udeleženec.',
  'ONBOARDING_TOOLTIP.ROOM.TOOLS_TITLE': 'Pisanje in brisanje',
  'ONBOARDING_TOOLTIP.ROOM.TOOLS_CONTENT': 'Tu se nahajajo svinčnik, radirka in druga pisalna orodja.',
  'ONBOARDING_TOOLTIP.ROOM.MULTIPAGE_TITLE': 'Strani',
  'ONBOARDING_TOOLTIP.ROOM.MULTIPAGE_CONTENT': 'Dodajte novo virtualno stran z "+". Na straneh vam ni treba "varčevati", dodajte jih toliko, kot jih potrebujete.',
  'ONBOARDING_TOOLTIP.ROOM.ZOOM_PAN_TITLE': 'Zoom in premikanje',
  'ONBOARDING_TOOLTIP.ROOM.ZOOM_PAN_CONTENT': 'Po tabli se lahko premikate v vseh smereh, lahko tudi povečate ali zmanšate pregled table.',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_CLOSED_TITLE': 'Materiali in zapiski...',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_CLOSED_CONTENT': '...So na dosegu roke.',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_OPEN_TITLE': 'Zapiski',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_OPEN_CONTENT': 'V zapiske lahko zapišete svoje menje o uri in o načrtih za naslednjo lekcijo. Lahko jih pošljete tudi staršem!',
  'ONBOARDING_TOOLTIP.ROOM.FILES_TITLE': 'Skupna raba materialov',
  'ONBOARDING_TOOLTIP.ROOM.FILES_CONTENT': 'Povlecite sliko na želeno mesto na tabli ter kliknite gumb "Prilepi na tablo". Ona bo vidna tudi drugim udeležencem inštrukcijske ure.',
  'ONBOARDING_TOOLTIP.ROOM.VIDEO_CHAT_TITLE': 'Video in sporočila',
  'ONBOARDING_TOOLTIP.ROOM.VIDEO_CHAT_CONTENT': 'Ko se drugi udeleženec poveže, ga boste videli pred kamero, med poukom pa si lahko izmenjate sporočila.',
};

export default sl;
