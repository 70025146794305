import React from 'react';
import { connect } from 'react-redux';

import NotificationInfoBarList, { MessagingMessageRenderFn, NotificationInfoMessageRenderFn } from '@src/components/login/notificationinfo/NotificationInfoBarList';
import { IMessagingMessage, MessagingMessageType } from '@src/model/messaging/messages';
import { IEducationArea } from '@src/model/user/EducationArea';
import { CollectionBusinessStore } from '@src/service/business/common/collectionBusinessStore';
import NotificationInfoBusinessStore, { INotificationInfoAppMessage } from '@src/service/business/common/notificationInfoBusinessStore';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import MessagingBusinessStore from '@src/service/business/messaging/messagingBusinessStore';

const SUPPORTED_MESSAGING_MESSAGE_TYPES = [MessagingMessageType.SESSION_INCOMING_NOTICE];

// -- Prop types
// ----------
export interface INotificationInfoBarContainerPublicProps {
  renderStaticMessageList?: () => React.ReactNode[];

  renderMessagingMessage?: MessagingMessageRenderFn;

  renderNotificationInfoMessage?: NotificationInfoMessageRenderFn;
}
interface INotificationInfoBarContainerStateProps {
  isUserLoggedIn: boolean;
  messagingMessageList: IMessagingMessage[];
  notificationInfoMessageList: INotificationInfoAppMessage[];
  educationAreaList: IEducationArea[];
}
interface INotificationInfoBarContainerDispatchProps {
  removeMessage: (id: string) => void;
}
type INotificationInfoBarContainerProps = INotificationInfoBarContainerPublicProps & INotificationInfoBarContainerStateProps & INotificationInfoBarContainerDispatchProps;

interface INotificationInfoBarState {
  supportedMessagingMessageList: IMessagingMessage[];
}

// --
// ----- Component
class NotificationInfoBarContainer extends React.Component<INotificationInfoBarContainerProps, INotificationInfoBarState> {
  state: INotificationInfoBarState = {
    supportedMessagingMessageList: [],
  };

  componentDidMount = () => {
    this.filterMessages();
  };

  componentDidUpdate = (prevProps: INotificationInfoBarContainerProps) => {
    // filter messages only if source list has changed
    if (prevProps.messagingMessageList !== this.props.messagingMessageList) {
      this.filterMessages();
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* notification info bar makes sense only for logged users */}
        {this.props.isUserLoggedIn && (
          <React.Fragment>
            {/* static messages */}
            {this.props.renderStaticMessageList && <NotificationInfoBarList staticMessageList={this.props.renderStaticMessageList()} />}

            {/* messaging messages */}
            {this.state.supportedMessagingMessageList && this.state.supportedMessagingMessageList.length > 0 && <NotificationInfoBarList messagingMessageList={this.state.supportedMessagingMessageList} renderMessagingMessage={this.props.renderMessagingMessage} />}

            {/* notification info messages */}
            {this.props.notificationInfoMessageList && this.props.notificationInfoMessageList.length > 0 && <NotificationInfoBarList notificationInfoMessageList={this.props.notificationInfoMessageList} renderNotificationInfoMessage={this.props.renderNotificationInfoMessage} />}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  filterMessages = () => {
    const appMessageList = this.props.messagingMessageList.filter((message: IMessagingMessage) => {
      return SUPPORTED_MESSAGING_MESSAGE_TYPES.includes(message.type);
    });

    this.setState({
      supportedMessagingMessageList: appMessageList,
    });
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): INotificationInfoBarContainerStateProps => ({
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
  messagingMessageList: MessagingBusinessStore.selectors.getMessageList(state),
  notificationInfoMessageList: NotificationInfoBusinessStore.selectors.getMessages(state),
  educationAreaList: CollectionBusinessStore.selectors.getCollectionContent(state, 'EducationArea'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): INotificationInfoBarContainerDispatchProps => ({
  removeMessage: (id: string) => dispatch(NotificationInfoBusinessStore.actions.removeMessage(id)),
});

export default connect<INotificationInfoBarContainerStateProps, INotificationInfoBarContainerDispatchProps, INotificationInfoBarContainerPublicProps>(mapStateToProps, mapDispatchToProps)(NotificationInfoBarContainer as any);
