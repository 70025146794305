import React from 'react';
import { Link } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// --
// ----- Component
class ProfileIncompleteMessage extends React.Component<IWithLocalizeOwnProps> {
  render = () => {
    return (
      <div className="lemon-notificationInfoBar__itemContainer panel">
        <div className="notification__text">
          {this.props.translate('NOTIFICATION_MENU.INCOMPLETE_PROFILE')}&nbsp;
          <Link to={'/myprofile'}>{this.props.translate('NOTIFICATION_MENU.INCOMPLETE_PROFILE_LINK_TEXT')}</Link>
        </div>
      </div>
    );
  };
}

export default withLocalize(ProfileIncompleteMessage as any);
