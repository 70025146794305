import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ITutoringSession from '@src/model/session/TutoringSession';
import { IUserInfo } from '@src/model/user/User';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import SessionCalendarContainer from '@src/components/session/calendar/SessionCalendarContainer';
import SessionBusinessStore, { ISessionListFilter } from '@src/service/business/session/sessionBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ICollectionFetchPayload, ICollectionData } from '@src/service/business/common/types';
// import AppConfigService from '@src/service/common/AppConfigService';

// const PARTICIPANT_MAX_COUNT = AppConfigService.getValue('components.session.participantMaxCount');


// -- Prop types
// ----------
interface ISessionParticipateContainerOwnProps {
  participationId: string;
}
// combine props base with already provided WithRouterProps
interface ISessionParticipateContainerStateProps {
  tutoringSessionList: ICollectionData<ITutoringSession>;
  currentUser: IUserInfo;
}
interface IMultisessionViewContainerDispatchProps {
  fetchTutoringSession: (filter: ICollectionFetchPayload<ISessionListFilter>) => ITrackableAction;
  sessionParticipate: (id: string) => ITrackableAction;
}
type ISessionParticipateContainerProps = ISessionParticipateContainerOwnProps & ISessionParticipateContainerStateProps & IMultisessionViewContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;


// -- Component
// ----------

// NOTE: all disabled lines are because we currently cannot fetch session we're not already a participant of, but maybe, in the future

/** Container component for creating tutoring session. */
class SessionParticipateContainer extends React.Component<ISessionParticipateContainerProps> {

  componentDidMount() {
    // TODO: disabled
    // this.fetchTutoringSession();
    this.handleParticipate();
  }

  // TODO: disabled
  // componentDidUpdate(prevProps: ISessionParticipateContainerProps) {
  //   if (prevProps.participationId !== this.props.participationId) {
  //     this.fetchTutoringSession();
  //   }
  // }

  render() {
    // TODO: disabled
    // const session = this.getTutoringSession();
    // const canAddParticipant = session != null && this.canParticipateInSession(session);

    return (
      <React.Fragment>
        {/*session && canAddParticipant && (*/
          <React.Fragment>
            <SessionCalendarContainer />

            {/* TODO: disabled  */}
            {/* <SessionParticipateForm
              tutoringSession={session}
              currentUser={this.props.currentUser}

              onParticipate={this.handleParticipate}
              onCancel={this.handleCancel}
            /> */}
          </React.Fragment>
        /*)*/}
      </React.Fragment>
    );
  }

  handleCancel = () => {
    this.closeDialog();
  }

  handleParticipate = () => {
    // TODO: disabled
    // const session = this.getTutoringSession();
    // if (session) {
    if (this.props.participationId) {
      this.props.sessionParticipate(this.props.participationId).track()
        .subscribe(
          () => this.closeDialog(),
          () => this.closeDialog()
        );
    }
  }

  // getTutoringSession = (): ITutoringSession | undefined => {
  //   return this.props.tutoringSessionList?.content.slice(0, 1).shift();
  // }

  // TODO: disabled
  // canParticipateInSession = (session: ITutoringSession): boolean => {
  //   return TutoringSessionModelHelper.canAddParticipants(session, PARTICIPANT_MAX_COUNT) // can participant be added
  //     && !TutoringSessionModelHelper.isUserInSession(session, this.props.currentUser.id); // I should not already be in this sesion
  // }

  closeDialog = () => {
    // use replace to avoid returning to this URL again
    this.props.router.replace('/calendar');
  }

  // TODO: disabled
  // fetchTutoringSession() {
  //   if (this.props.participationId) {
  //     const filter: ISessionListFilter = {
  //       // TODO: searching session +/- 1 year is akward but currently these dates are mandatory in filter
  //       startDateTime: moment().subtract(1, 'year').toISOString(),
  //       endDateTime: moment().add(1, 'year').toISOString(),
  //       tutoringRoomName: this.props.participationId,
  //     };

  //     this.props.fetchTutoringSession({
  //       filter,
  //       page: 0,
  //       sort: [],
  //       size: 1,
  //     });
  //   } else {
  //     LOGGER.warn('Cannot fetch session, participation ID is empty');
  //     this.closeDialog();
  //   }
  // }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISessionParticipateContainerOwnProps): ISessionParticipateContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  tutoringSessionList: SessionBusinessStore.selectors.getSessionList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IMultisessionViewContainerDispatchProps => ({
  fetchTutoringSession: (filter: ICollectionFetchPayload<ISessionListFilter>) => dispatch(createTrackableAction(SessionBusinessStore.actions.fetchSessionList(filter))),
  sessionParticipate: (participationId: string) => dispatch(createTrackableAction(SessionBusinessStore.actions.sessionParticipate({ id: participationId }))),
});

// TODO: improve component public props API when using third party HOCs
// type assertion to 'any' to because 'withRouter' doesn't do type reduction so injected props types are appearing in public props API
export default connect<ISessionParticipateContainerStateProps, any, ISessionParticipateContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(withLocalize(SessionParticipateContainer)) as any);
