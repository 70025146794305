
/** Class that provides API for resolving icon types from provided application icon set. */
export class IconResolver<T> {

  constructor(private _iconSet: T) {
  }

  /** Returns icon type for given icon name. */
  resolveIcon(name: keyof T): T[keyof T] {
    return this._iconSet[name];
  }

  /** Returns configure applicaiton icon set. */
  applicationIconSet(): T {
    return this._iconSet;
  }

}
