import Pagination from './rc-pagination/lib/locale/hr_HR';
import DatePicker from './date-picker/locale/hr_HR';
import TimePicker from './time-picker/locale/hr_HR';
import Calendar from './calendar/locale/hr_HR';

export default {
  locale: 'hr',
  Pagination,
  DatePicker,
  TimePicker,
  Calendar,
  Table: {
    filterTitle: 'Filter',
    filterConfirm: 'Uredu',
    filterReset: 'Poništi',
    emptyText: 'Nema podataka',
    selectAll: 'Odaberi sve ',
    selectInvert: 'Obrni trenutni odabir',
    sortTitle: 'Poredaj',
  },
  Modal: {
    okText: 'Uredu',
    cancelText: 'Odbaci',
    justOkText: 'Uredu',
  },
  Popconfirm: {
    okText: 'Uredu',
    cancelText: 'Odbaci',
  },
  Transfer: {
    titles: ['', ''],
    notFoundContent: 'Nema rezultata',
    searchPlaceholder: 'Pretraživanje',
    itemUnit: 'stavka',
    itemsUnit: 'stavke',
  },
  Select: {
    notFoundContent: 'Nema rezultata',
  },
  Upload: {
    uploading: 'Učitavanje...',
    removeFile: 'Ukloni datoteku',
    uploadError: 'Greška pri učitavanju',
    previewFile: 'Pregled datoteke',
  },
};
