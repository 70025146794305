const LEMON_APP_CONFIG = {
  app: {
    environment: 'dev',

    defaultLocale: 'hr',
    locales: ['hr', 'sl'],
    defaultTimeZone: 'CET',

    baseUrl: '',

    progressStatusDelay: 1000,

    androidAppLink: 'https://play.google.com/store/apps/details?id=hr.edulab.lemon',
  },

  // ---------- REST API config
  api: {
    // base URL
    baseUrl: 'http://localhost:8080', // DEV
    // baseUrl: 'https://ld-test.int.sedamit.hr', // TEST
    // baseUrl: 'http://10.7.80.116:8080', // Jelena

    // API source url parts
    // sourceUrl: '/api',
    sourceUrl: '/api', // mock

    // default URL for fetching files
    filesUrl: '/api/files',

    // API by default returns limited number of list items (eg 20), so if we want to fetch them all we have to workaround it by setting limit high enough
    collectionDefaultLimit: 1000,

    // default params sent with every request (this is only a workaround for the lack of proper mechanism eg. request interceptors)
    // these values are an API of current HTTP client implementation, "soooo, it is not a nice ..."
    defaultParams: {
      headers: {
        TenantID: 'EduLab',
      },
      json: true,
      qsStringifyOptions: {
        arrayFormat: 'repeat',
      },
    },

    paging: {
      smallPageSize: 5,
      defaultPageSize: 20,
      maxPageSize: 1000,
    },
  },

  files: {
    maxSizeInBytes: 10 * 1024 * 1024, // 10 MB
  },

  logging: {
    logLevel: 'debug', // see loglevel LogLevelDesc for details

    redux: {
      enabled: true,
      // See: https://github.com/LogRocket/redux-logger#options
      config: {
        collapsed: true,
      },
    },

    sentry: {
      enabled: false,
      config: {
        dsn: '<DSN>',
      },
    },
  },

  // ---------- Authentication config
  authentication: {
    token: {
      name: 'sid',
      duration: 365 * 24 * 60 * 60 * 1000, // 1 year
    },
  },

  // ---------- Cookie config
  cookies: {
    locale: {
      name: 'locale',
      duration: 30 * 24 * 60 * 60 * 1000, // 1 month
    },
  },

  // ---------- Routing config

  routing: {
    // routes allowed for anonymous users
    publicRoutes: ['/login', '/welcome'],
    // default route for anonymous users
    publicDefaultRoute: '/welcome',
    // default route for logged users
    defaultRoute: '/calendar',

    loginRoute: '/login',

    // these should be prefixed with "application.staticBaseUrl" value
    loginPage: '/login.html',
    errorPage: '/error.html',
  },

  // ----- Validation config

  validation: {
    length: {
      number: 8,
      short: 32,
      file: 250,
      medium: 256,
      long: 2048,
      unlimited: 20000,
    },
    number: {
      // check for any phonenumber format
      phoneNumber: /^[\+\-\ \/\d]+$/g,
    },
  },

  // TODO: make formats linked to defaultLocale

  // ---------- Date and time formats
  dateTimeFormat: {
    // TODO: unify backend date format, make all with or without timezone
    backendDate: 'YYYY-MM-DDTHH:mm:ss',
    backendDateWithTimezone: 'YYYY-MM-DDTHH:mm:ssZ', // used in UserProfileDetails
    backendQueryParamDateFormat: 'YYYY-MM-DD',
    dateWithTime: 'DD.MM.YYYY. HH:mm',
    dateWithYear: 'DD.MM.YYYY.',
    dateWithoutDay: 'MM-YYYY.',
    monthNameWithYear: 'MMM YYYY',
    fullMonthNameWithYear: 'MMMM YYYY.',
    year: 'YYYY',
    yearWithMonth: 'YYYY-MM',
    date: 'DD.MM.',
    time: 'HH:mm',
    shortTime: 'm:ss',
    dayOfWeekName: 'dddd',
    dateDayOfWeekName: 'DD. dddd',
    dayOfMonthAndMonthName: 'DD. MMMM',
  },

  // ---------- Number formats
  numberFormat: {
    decimalSeparator: ',', // used in antd InputNumber
    currency: '0.00 $',
    bytes: '0 b',
    percentage: '0%',
    time: '00:00:00',
    exponential: '0e+0',
  },

  // ---------- Currency and their formats
  currencyFormat: {
    currencySymbol: 'kn', // TODO: remove this after refctoring to (with)FormatNumber(Service)
  },

  messaging: {
    backgroundWorkerName: 'firebase-messaging-msg-data',
    backgroundMessagesStoreKey: 'backgroundMessages',
    firebase: {
      config: {
        apiKey: 'AIzaSyCzgCwNcp_TpWuzpyH9L163KeE3EG-Rfa8',
        authDomain: 'lemon-326f9.firebaseapp.com',
        databaseURL: 'https://lemon-326f9.firebaseio.com',
        projectId: 'lemon-326f9',
        storageBucket: 'lemon-326f9.appspot.com',
        messagingSenderId: '217898014142',
      },
      vapid: 'BKQyS489W0JCftcemr_7nV8I5s4pjxHss3GhQouQve8nLwmsy1I9ekQD02UQNvVbTCd7yXq1raY8JU_bBTqEkok',
    },
  },

  support: {
    paldesk: {
      widget: {
        routeBlacklist: ['/tutoringroom', '/whiteroom'],
        apiKey: '3fdb6f8c908b287b298f59f8bbf6a703', // croatian widget TODO: paldesk localization is done by having multimple widgets/apiKeys
      },
    },
  },

  tracking: {
    // ----- hotjar tracking widget (real data is configured on evironment server)
    hotjar: {
      enabled: false,
      siteId: 0,
      snippetVersion: 0,
    },

    // ----- Google Analytics config (configed on evironment server)
    // See here for more details and docs: https://github.com/react-ga/react-ga
    googleAnalytics: {
      enabled: false,
      trackingId: '<TRACKING ID>',

      initializeOptions: {
        // debug: true,
      },
    },
  },

  tutoringRoom: {
    whiteboard: {
      enableZoom: true,
      defaultZoomLevel: 100,
      whiteroomZoomLevel: 200,
      multiPage: true,
      showUserPointers: false,
    },
    twilio: {
      video: {
        width: 1280,
        height: 720,
        frameRate: 24,
      },
    },
  },

  onboarding: {
    zIndex: 200, // sync with LESS variable @stack-zindex-panelOnboarding
  },

  // ----- Various UI configs for components. Keys should follow component folders.
  components: {
    session: {
      // session durations - NOTE: be very, very(!) careful when changing this because BE relies and counts on these values
      sessionCanonicalDuration: 45, // duration of 1 session (in minutes)
      minSessionStartTimeOffset: 15, // minimal time offset before new session can be requested (in minutes)
      sessionStartTimeStep: 15, // allowed session time steps (in minutes)

      timeToEnterWhiteroom: 30, // time in minutes before session start when it is allowed to enter whiteroom

      participantMaxCount: 4, // max number of participants in group rooms

      calendar: {
        // oldest point in time for session list calendar
        calendarStartDate: '2018-08-01',
      },
    },

    sessionCalendar: {
      // default sort order for session calendar list
      defaultSortOrder: 'startDateTime,asc',
    },

    user: {
      profile: {
        minZipValue: 10000,
        maxZipValue: 99999,
      },
    },

    // TutorList
    tutorlist: {
      // default sort order in TutorList
      defaultSortOrder: 'tutorDetails_relevance,desc',

      // used for availability filter on tutor search
      availabilities: {
        time: [
          {
            displayValue: '0-3',
            filterValue: '00:00-03:00',
          },
          {
            displayValue: '3-6',
            filterValue: '03:00-06:00',
          },
          {
            displayValue: '6-9',
            filterValue: '06:00-09:00',
          },
          {
            displayValue: '9-12',
            filterValue: '09:00-12:00',
          },
          {
            displayValue: '12-15',
            filterValue: '12:00-15:00',
          },
          {
            displayValue: '15-18',
            filterValue: '15:00-18:00',
          },
          {
            displayValue: '18-21',
            filterValue: '18:00-21:00',
          },
          {
            displayValue: '21-24',
            filterValue: '21:00-23:59',
          },
        ],
      },
    },

    // TutorList
    adminSessionList: {
      // default sort order in AdminSessionList
      // endDateTime commented out because showinf the default sort order on the Table in AdminSessionList, which (for now) can use just 1 key
      defaultSortOrder: ['startDateTime,desc' /*, 'endDateTime,desc'*/],
    },

    // UserReviewList
    reviewSessionList: {
      // default sort order in AdminSessionList
      // Table can also be sorted by id
      defaultSortOrder: ['addedDateTime,asc'],
    },

    // Multisession View
    multisessionView: {
      // default sort order in Multisession View
      defaultSortOrder: ['startDateTime,desc'],
    },

    // Favourites List
    favouritesList: {
      // default sort order in Favourites List
      defaultSortOrder: ['user_displayName,asc'],
    },

    // Calendar
    calendar: {
      dayStartTime: 6,
      dayEndTime: 25, // after ranging these numbers with lodash we will get an array from 6 to 24, lodash.range omits the last specified param
    },

    // ----- notifications

    notification: {
      defaultConfig: {
        duration: 4, // notification duration (in sec)
        top: 70, // notification top offset (take header into account)
      },

      userApprovalNotification: {
        show: true,
      },

      userNotification: {
        show: false,
      },
    },

    // ----- User file repository
    repository: {
      debounceTime: 300,
      sort: {
        date: {
          ascend: {
            id: 'metadata_createdDateTime,asc',
          },
          descend: {
            id: 'metadata_createdDateTime,desc',
          },
        },
        size: {
          ascend: {
            id: 'metadata_contentLength,asc',
          },
          descend: {
            id: 'metadata_contentLength,desc',
          },
        },
        type: {
          ascend: {
            id: 'metadata_mimeType,asc',
          },
          descend: {
            id: 'metadata_mimeType,desc',
          },
        },
      },
    },
  },
};

export default LEMON_APP_CONFIG;
