declare const require: any;

export const imagePathContext = require.context('@src/assets/img', true);

/** Resolve image path against webpack context so their paths can be injected with generated file hash. */
const imagePathResolver = (fileName: string): string => {
  return imagePathContext(`./${fileName}`, true)?.default;
};

export default imagePathResolver;
