import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import StoreService from '@src/service/business/StoreService';
import { ILemonAction } from '@src/service/business/common/types';

/** Call argument function and dispatch action returned by that function. */
export const dispatchAction = (actionFn: (value: any) => ILemonAction) => {
  return <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(
      tap((value) => {
        StoreService.dispatchAction(actionFn(value));
      })
    );
  };
};
