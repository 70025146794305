import React from 'react';

import { message, Upload } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { UploadProps } from 'antd/lib/upload';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import IFile from '@src/model/file/File';
import AppConfigService from '@src/service/common/AppConfigService';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import { getLogger } from '@src/service/util/logging/logger';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
// tslint:disable-next-line:no-submodule-imports
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

const FILE_NAME_MAX_LENGTH = AppConfigService.getValue('validation.length.file');
const FILE_NAME_MAX_SIZE = AppConfigService.getValue('files.maxSizeInBytes');

const LOGGER = getLogger('FileRepositoryUpload');

// -- Prop types
// ----------

export interface IFileRepositoryUploadOwnProps {
  uploadProps?: UploadProps;
  dragger?: boolean;
  handleUploadFile?: (fileList: IFile[]) => void;
  onChange?: (fileList: UploadFile[]) => void;
}
type IFileRepositoryUploadProps = IFileRepositoryUploadOwnProps & IWithLocalizeOwnProps & IWithNumberFormatOwnProps;

interface IFileRepositoryUploadState {}

class FileRepositoryUpload extends React.Component<IFileRepositoryUploadProps, IFileRepositoryUploadState> {
  handleChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const filename = file.name;

    if (file.status === 'done') {
      LOGGER.info(`Uploaded file ${filename}`);
      if (this.props.handleUploadFile) {
        this.props.handleUploadFile([file.response.payload]);
      }

      message.success(this.props.translate('FILE_LIST.MESSAGE.REPOSITORY_FILE_ADDED', { filename }));
    } else if (file.status === 'error') {
      LOGGER.error(`Error uploading file ${filename}`, file.error);
      message.error(this.props.translate('FILE_LIST.MESSAGE.REPOSITORY_FILE_UPLOAD_ERROR', { filename }));
    } else if (file.status === 'removed') {
      // TODO: it gets to status removed when BE refuses upload
      LOGGER.info(`Removed file ${filename}`);
      message.success(this.props.translate('FILE_LIST.MESSAGE.REPOSITORY_FILE_REMOVED', { filename }));
    }

    if (this.props.onChange && file.status !== 'uploading') {
      this.props.onChange(fileList);
    }
  };

  handleUploadValidation = (file: RcFile) => {
    const filename = file.name;
    if (filename.length >= FILE_NAME_MAX_LENGTH) {
      LOGGER.info(`Filename ${filename} too long`);
      message.error(this.props.translate('FILE_LIST.MESSAGE.FILE_NAME_TOO_LONG', { maxNameLenght: FILE_NAME_MAX_LENGTH }));
      return false;
    } else if (file.size >= FILE_NAME_MAX_SIZE) {
      LOGGER.info(`File size too large`, file.size);
      message.error(this.props.translate('FILE_LIST.MESSAGE.FILE_SIZE_TOO_LARGE', { maxSizeFormated: this.props.formatBytes(FILE_NAME_MAX_SIZE) }));
      return false;
    } else {
      return true;
    }
  };

  render() {
    const UploadType = !this.props.dragger ? Upload : Upload.Dragger;

    const props: UploadProps = {
      showUploadList: false,
      multiple: true,
      action: UrlBuilderFactory.createApiFileBuilder().build(),
      headers: {
        authorization: `Bearer ${AuthTokenManager.getToken()}`,
        // remove X-Requested-With header because CORS complains it is not in header
        // but this generates an error: Type 'null' is not assignable to type 'string' because of --strictNullChecks
        // TODO: open issue on https://github.com/ant-design/ant-design/issues
        'X-Requested-With': null as any,
      },
      onChange: this.handleChange,
      beforeUpload: this.handleUploadValidation,
    };

    return (
      <UploadType {...props} {...this.props.uploadProps}>
        {this.props.children}
      </UploadType>
    );
  }
}

export default withLocalize<IFileRepositoryUploadOwnProps>(withNumberFormat(FileRepositoryUpload as any));
