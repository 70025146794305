import React from 'react';
import { Layout } from 'antd';

import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';

/** Page layout without header/footer, only content. Intended for full screen pages. */
const FullPageLayout: React.SFC = (props) => {
  return (
    <Layout className="lemon-fullPageLayout__layout">
      <Layout.Content className="lemon-fullPageLayout__content">
        <LemonErrorBoundary showDefaultError={true}>
          {props.children}
        </LemonErrorBoundary>
      </Layout.Content>
    </Layout>
  );
};

export default FullPageLayout;
