import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// --
// ---------- Prop types

export interface INotificationInfoBarListItemPublicProps {}
type INotificationInfoBarListItemProps = INotificationInfoBarListItemPublicProps & IWithLocalizeOwnProps;

// --
// ---------- Component

/** Displays notification info bar notification info message item. */
class NotificationInfoBarListItem extends React.Component<INotificationInfoBarListItemProps> {
  render() {
    return <div className="lemon-notificationInfoBar__itemContainer">{this.props.children}</div>;
  }
}

export default withLocalize<INotificationInfoBarListItemPublicProps>(NotificationInfoBarListItem as any);
