import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map, ignoreElements } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';

import { IPayloadAction, ICollectionData, ICollectionFetchPayload, ILemonAction, IIdDataPayload, IIdPayload } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import IFile from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';
import IFolder, { IFolderCreate } from '@src/model/file/Folder';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';
import { createStaticMessageUserFeedbackError, createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { ITag, ITagCreate } from '@src/model/file/Tag';
import trackAction from '@src/service/util/observable/operators/trackAction';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('fileListBusinessStore');

// -
// -------------------- Types&Consts

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface IFileListFilter {
  searchTerm?: string;
  tag?: string[];
  [key: string]: any;
  // TODO: set specific filter props
}

export interface IFileListTagFilter {
  name?: string;
  [key: string]: any;
  // TODO: set specific filter props
}

export interface ISessionFilesStoreData {
  [key: string]: ICollectionData<IFile>;
}

const FILE_LIST_FILTER_NAME = 'FILE_LIST_FILTER_NAME';
const TAG_LIST_FILTER_NAME = 'TAG_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getRepositoryFileList = (store: any): ICollectionData<IFileSystemElement> => store.repositoryFileList;

const getRepositoryTagList = (store: any): ICollectionData<ITag> => store.repositoryTagList;

const getRepositoryFileListSelectedItems = (store: any): IFileSystemElement[] => store.repositoryFileListSelectedItems;

const getSessionFileList = (store: any, sessionId: string): ICollectionData<IFile> => store.sessionFileList[sessionId];

const getFileListFilter = (store: any): IFileListFilter => ListFilterBusinessStore.selectors.getListFilter(store, FILE_LIST_FILTER_NAME);

const getRepositoryTagListFilter = (store: any): IFileListTagFilter => ListFilterBusinessStore.selectors.getListFilter(store, TAG_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  USER_FILE_LIST_FETCH: 'USER_FILE_LIST_FETCH',
  REPOSITORY_FILE_LIST_FETCH: 'REPOSITORY_FILE_LIST_FETCH',
  REPOSITORY_AUX_FILE_LIST_FETCH: 'REPOSITORY_AUX_FILE_LIST_FETCH',
  REPOSITORY_NAME_SEARCH_LIST_FETCH: 'REPOSITORY_NAME_SEARCH_LIST_FETCH',
  REPOSITORY_FILE_LIST_LOAD: 'REPOSITORY_FILE_LIST_LOAD',
  REPOSITORY_FILE_LIST_CLEAR: 'REPOSITORY_FILE_LIST_CLEAR',
  REPOSITORY_FILE_LIST_REMOVE_FILES: 'REPOSITORY_FILE_LIST_REMOVE_FILES',
  REPOSITORY_FILE_LIST_ADD_FILES: 'REPOSITORY_FILE_LIST_ADD_FILES',
  REPOSITORY_FILE_LIST_MOVE_FILES: 'REPOSITORY_FILE_LIST_MOVE_FILES',
  REPOSITORY_FILE_LIST_UPDATE_FILE: 'REPOSITORY_FILE_LIST_UPDATE_FILE',
  REPOSITORY_FILE_LIST_SELECTED_ITEMS_STORE: 'REPOSITORY_FILE_LIST_SELECTED_ITEMS_STORE',
  REPOSITORY_FILE_LIST_SELECTED_ITEMS_CLEAR: 'REPOSITORY_FILE_LIST_SELECTED_ITEMS_CLEAR',
  REPOSITORY_FILE_LIST_FOLDER_FETCH: 'REPOSITORY_FILE_LIST_FOLDER_FETCH',
  REPOSITORY_FILE_LIST_FOLDER_CREATE: 'REPOSITORY_FILE_LIST_FOLDER_CREATE',
  REPOSITORY_FILE_LIST_FOLDER_UPDATE: 'REPOSITORY_FILE_LIST_FOLDER_UPDATE',
  REPOSITORY_FILE_LIST_FOLDER_DELETE: 'REPOSITORY_FILE_LIST_FOLDER_DELETE',
  REPOSITORY_TAG_LIST_FETCH: 'REPOSITORY_TAG_LIST_FETCH',
  REPOSITORY_TAG_LIST_LOAD: 'REPOSITORY_TAG_LIST_LOAD',
  REPOSITORY_TAG_LIST_CLEAR: 'REPOSITORY_TAG_LIST_CLEAR',
  REPOSITORY_TAG_LIST_CREATE_TAG: 'REPOSITORY_TAG_LIST_CREATE_TAG',
  REPOSITORY_TAG_LIST_UPDATE_TAG: 'REPOSITORY_TAG_LIST_UPDATE_TAG',
  SESSION_FILE_LIST_FETCH: 'SESSION_FILE_LIST_FETCH',
  SESSION_FILE_LIST_LOAD: 'SESSION_FILE_LIST_LOAD',
  SESSION_FILE_LIST_CLEAR: 'SESSION_FILE_LIST_CLEAR',
  SESSION_FILE_LIST_ADD_FILES: 'SESSION_FILE_LIST_ADD_FILES',
  SESSION_FILE_LIST_REMOVE_FILES: 'SESSION_FILE_LIST_REMOVE_FILES',
  SESSION_FILE_LIST_UPDATE_FILE: 'SESSION_FILE_LIST_UPDATE_FILE',

};

const fetchUserFileList = (userId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.USER_FILE_LIST_FETCH,
    payload: {
      id: userId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

const fetchRepositoryFileList = (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_FETCH,
    payload: {
      id: folderId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

const fetchAuxRepositoryFileList = (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.REPOSITORY_AUX_FILE_LIST_FETCH,
    payload: {
      id: folderId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

const fetchRepositorySearchList = (listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IFileListFilter>> => {
  return {
    type: Actions.REPOSITORY_NAME_SEARCH_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadRepositoryFileList = (data: ICollectionData<IFileSystemElement>): IPayloadAction<ICollectionData<IFileSystemElement>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_LOAD,
    payload: data,
  };
};

const clearRepositoryFileList = (): ILemonAction => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_CLEAR,
  };
};

const fetchSessionFileList = (sessionId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.SESSION_FILE_LIST_FETCH,
    payload: {
      id: sessionId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

const loadSessionFileList = (sessionId: string, data: ICollectionData<IFile>): IPayloadAction<IIdDataPayload<ICollectionData<IFile>>> => {
  return {
    type: Actions.SESSION_FILE_LIST_LOAD,
    payload: {
      id: sessionId,
      data,
    },
  };
};

const clearSessionFileList = (sessionId: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SESSION_FILE_LIST_CLEAR,
    payload: sessionId,
  };
};

const addSessionFile = (sessionId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.SESSION_FILE_LIST_ADD_FILES,
    payload: {
      id: sessionId,
      data,
    },
  };
};

const updateSessionFile = (sessionId: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.SESSION_FILE_LIST_UPDATE_FILE,
    payload: {
      id: sessionId,
      data,
    },
  };
};

const removeSessionFile = (sessionId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.SESSION_FILE_LIST_REMOVE_FILES,
    payload: {
      id: sessionId,
      data,
    },
  };
};

const addRepositoryFile = (folderId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_ADD_FILES,
    payload: {
      id: folderId,
      data,
    },
  };
};

const moveRepositoryFile = (folderId: string, data: IFileSystemElement[]): IPayloadAction<IIdDataPayload<IFileSystemElement[]>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_MOVE_FILES,
    payload: {
      id: folderId,
      data,
    },
  };
};

const removeRepositoryFile = (folderId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_REMOVE_FILES,
    payload: {
      id: folderId,
      data,
    },
  };
};

const updateRepositoryFile = (folderId: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_UPDATE_FILE,
    payload: {
      id: folderId,
      data,
    },
  };
};

const fetchRepositoryFolder = (folderId: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_FOLDER_FETCH,
    payload: folderId,
  };
};

const createRepositoryFolder = (data: IFolderCreate): IPayloadAction<IFolderCreate> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_FOLDER_CREATE,
    payload: data,
  };
};

const updateRepositoryFolder = (folderId: string, data: IFolder): IPayloadAction<IIdDataPayload<IFolder>> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_FOLDER_UPDATE,
    payload: {
      id: folderId,
      data,
    },
  };
};

const deleteRepositoryFolder = (data: IFolder[]): IPayloadAction<IFolder[]> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_FOLDER_DELETE,
    payload: data,
  };
};

const storeFileListFilter = (listFilter: IFileListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(FILE_LIST_FILTER_NAME, listFilter);
};

const storeRepositoryFileListSelectedItems = (data: IFileSystemElement[]): IPayloadAction<IFileSystemElement[]> => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_SELECTED_ITEMS_STORE,
    payload: data,
  };
};

const clearRepositoryFileListSelectedItems = (): ILemonAction => {
  return {
    type: Actions.REPOSITORY_FILE_LIST_SELECTED_ITEMS_CLEAR,
  };
};

const fetchRepositoryTagList = (listFilter: IFileListTagFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IFileListTagFilter>> => {
  return {
    type: Actions.REPOSITORY_TAG_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadRepositoryTagList = (data: ICollectionData<ITag>): IPayloadAction<ICollectionData<ITag>> => {
  return {
    type: Actions.REPOSITORY_TAG_LIST_LOAD,
    payload: data,
  };
};

const clearRepositoryTagList = (): ILemonAction => {
  return {
    type: Actions.REPOSITORY_TAG_LIST_CLEAR,
  };
};

const storeRepositoryTagFilter = (listFilter: IFileListTagFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(TAG_LIST_FILTER_NAME, listFilter);
};

const createRepositoryTag = (data: ITagCreate): IPayloadAction<ITagCreate> => {
  return {
    type: Actions.REPOSITORY_TAG_LIST_CREATE_TAG,
    payload: data,
  };
};

const updateRepositoryTag = (tagId: string, data: ITag): IPayloadAction<IIdDataPayload<ITag>> => {
  return {
    type: Actions.REPOSITORY_TAG_LIST_UPDATE_TAG,
    payload: {
      id: tagId,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchUserFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User').fetchSubentityList(id, 'File', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadRepositoryFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching user file list', error);
      return o;
    })
  );
};

const fetchSessionFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SESSION_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('TutoringSession').fetchSubentityList(id, 'File', data).pipe(
        actionThunk(action),

        map((response) => {
          return { id, response };
        })
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSessionFileList(data.id, data.response);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching session file list', error);
      return o;
    })
  );
};

const addFileToSessionEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SESSION_FILE_LIST_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession')).createSubentityList(id, 'File', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error adding file to session', error);
      return o;
    })
  );
};

const updateSessionFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SESSION_FILE_LIST_UPDATE_FILE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession')).updateSubobject(id, data.id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating session file', error);
      return o;
    })
  );
};

const removeFileFromSessionEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SESSION_FILE_LIST_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession')).deleteSubentityList(id, 'File', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error removing file from session', error);
      return o;
    })
  );
};

const fetchRepositoryFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).fetchSubentityList(id, 'children', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadRepositoryFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching folder content list', error);

      return o;
    })
  );
};

const fetchAuxRepositoryFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_AUX_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).fetchSubentityList(id, 'children', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching folder content list', error);

      return o;
    })
  );
};

const fetchRepositorySearchListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IFileListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_NAME_SEARCH_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).fetchEntityList(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadRepositoryFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching folder content list', error);

      return o;
    })
  );
};

const addRepositoryFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).createSubentityList(id, 'children', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error adding file to repository', error);
      return o;
    })
  );
};

const moveRepositoryFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFileSystemElement[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_MOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).updateSubentityList(id, 'children', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error moving file', error);
      return o;
    })
  );
};

const removeRepositoryFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).deleteSubentityList(id, 'children', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error removing file from repository', error);
      return o;
    })
  );
};

const updateRepositoryFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_UPDATE_FILE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).updateSubobject(id, data.id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating repository file', error);
      return o;
    })
  );
};

const fetchFolderEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_FOLDER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).fetchEntity(id).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching folder', error);
      return o;
    })
  );
};

const createFolderEffect = (action$: Observable<IPayloadAction<IFolderCreate>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_FOLDER_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).createEntity(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => {
      return createApiResponseUserFeedbackError(error, 'FILE_LIST', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR');
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error creating folder', error);
      return o;
    })
  );
};

const updateFolderEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFolder>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_FOLDER_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).updateEntity(id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating folder', error);
      return o;
    })
  );
};


const deleteFolderEffect = (action$: Observable<IPayloadAction<IFolder[]>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_FILE_LIST_FOLDER_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('Folder')).deleteEntityList(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error deleting folders', error);
      return o;
    })
  );
};

const fetchRepositoryTagListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IFileListTagFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_TAG_LIST_FETCH;
    }),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Tag').fetchEntityList(payload).pipe(
        trackAction(action)
      );
    }),

    map((data) => {
      return loadRepositoryTagList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching repository tag list', error);
      return o;
    })
  );
};

const createRepositoryTagEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_TAG_LIST_CREATE_TAG;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('Tag')).createEntity(data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error creating tag', error);
      return o;
    })
  );
};

const updateRepositoryTagEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ITag>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REPOSITORY_TAG_LIST_UPDATE_TAG;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Tag')).updateEntity(id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating tag', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const repositoryFileList = (state: ICollectionData<IFileSystemElement> | null = null, action: IPayloadAction<ICollectionData<IFileSystemElement>>) => {
  if (action.type === Actions.REPOSITORY_FILE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }
  else if (action.type === Actions.REPOSITORY_FILE_LIST_CLEAR) {
    return null;
  }

  return state;
};

const repositoryFileListSelectedItems = (state: IFileSystemElement[] = [], action: IPayloadAction<IFileSystemElement[]>) => {
  if (action.type === Actions.REPOSITORY_FILE_LIST_SELECTED_ITEMS_STORE) {
    return action.payload;
  }
  else if (action.type === Actions.REPOSITORY_FILE_LIST_SELECTED_ITEMS_CLEAR) {
    return [];
  }

  return state;
};

const sessionFileList = (state: ISessionFilesStoreData = {}, action: IPayloadAction<IIdDataPayload<ICollectionData<IFile>>>) => {

  if (action.type === Actions.SESSION_FILE_LIST_LOAD) {
    const { id, data } = action.payload;
    return {
      ...state,
      [id]: data,
    };
  }
  else if (action.type === Actions.SESSION_FILE_LIST_CLEAR) {
    const { id } = action.payload;
    const { [id]: removedFiles, ...newState } = state;
    return newState;
  }

  return state;
};

const repositoryTagList = (state: ICollectionData<ITag> | null = null, action: IPayloadAction<ICollectionData<ITag>>) => {
  if (action.type === Actions.REPOSITORY_TAG_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }
  else if (action.type === Actions.REPOSITORY_TAG_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const FileListBusinessStore = {
  actions: {
    fetchUserFileList,
    fetchSessionFileList, loadSessionFileList, clearSessionFileList,
    addSessionFile, updateSessionFile, removeSessionFile,
    fetchRepositoryFileList, fetchAuxRepositoryFileList, fetchRepositorySearchList, loadRepositoryFileList, clearRepositoryFileList, storeFileListFilter,
    addRepositoryFile, moveRepositoryFile, removeRepositoryFile, updateRepositoryFile,
    fetchRepositoryFolder, createRepositoryFolder, updateRepositoryFolder, deleteRepositoryFolder,
    storeRepositoryFileListSelectedItems, clearRepositoryFileListSelectedItems,
    fetchRepositoryTagList, loadRepositoryTagList, clearRepositoryTagList, storeRepositoryTagFilter,
    createRepositoryTag, updateRepositoryTag,
  },

  selectors: {
    getRepositoryFileList, getSessionFileList, getFileListFilter, getRepositoryFileListSelectedItems, getRepositoryTagList, getRepositoryTagListFilter,
  },

  effects: {
    fetchUserFileListEffect,
    fetchSessionFileListEffect, addFileToSessionEffect, updateSessionFileEffect, removeFileFromSessionEffect,
    fetchRepositoryFileListEffect, fetchAuxRepositoryFileListEffect, fetchRepositorySearchListEffect,
    fetchFolderEffect, createFolderEffect, updateFolderEffect, deleteFolderEffect,
    addRepositoryFileEffect, moveRepositoryFileEffect, removeRepositoryFileEffect, updateRepositoryFileEffect,
    fetchRepositoryTagListEffect, createRepositoryTagEffect, updateRepositoryTagEffect,
  },

  reducers: {
    repositoryFileList, sessionFileList, repositoryFileListSelectedItems, repositoryTagList,
  },
};

// --
// ----- Exports

export default FileListBusinessStore;
