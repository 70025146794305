import AppConfigService from '@src/service/common/AppConfigService';
import imagePathResolver from '@src/service/util/imagePathResolver';
import UrlBuilder from '@src/service/util/UrlBuilder';
import { EnvironmentUtils } from '@src/service/util/EnvironmentUtils';

/**
 * Factory class with convenience methods for creating resource and api URL builders. Each methods prepopulates
 * URL builder with appropriate config values (eg. base URL).
 *
 * See {@link #UrlBuilder} for more info.
 */
export default class UrlBuilderFactory {
  /** Create application base URL builder. */
  static createAbsoluteApplicationBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(EnvironmentUtils.getAppUrl());
  }

  /** Create application base URL builder. */
  static createApplicationBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(AppConfigService.getValue('app.baseUrl'));
  }

  /** Create API base URL builder. */
  static createApiBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(AppConfigService.getValue('api.baseUrl'));
  }

  /** Create API files URL builder. */
  static createApiFileBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(AppConfigService.getValue('api.filesUrl'));
  }

  /** Create 'asset' base URL builder. */
  static createAssetBuilder(): UrlBuilder {
    return UrlBuilderFactory.createApplicationBuilder().urlPart('assets');
  }

  /** Build URL from given image path. */
  static buildImageUrl(imageName: string): string {
    return UrlBuilderFactory.createApplicationBuilder()
      .urlPart(imagePathResolver(imageName))
      .build();
  }

  /** Build URL from given API path. */
  static buildApiUrl(resourcePath: string): string {
    return UrlBuilderFactory.createApiBuilder()
      .urlPart(resourcePath)
      .build();
  }

  /** Build file URL from given file object. */
  static buildApiFileUrl(fileId: string): string {
    return UrlBuilderFactory.createApiFileBuilder()
      .urlPart(fileId)
      .build();
  }
}
