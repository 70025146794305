import { Alert, Card } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileListBreadcrumb from '@src/components/repository/FileListBreadcrumb';
import FileRepositoryHeader from '@src/components/repository/FileRepositoryHeader';
import FileRepositoryUpload from '@src/components/repository/FileRepositoryUpload';
import FileList from '@src/components/repository/files/list/FileList';
import FolderSelectModalContainer from '@src/components/repository/folders/FolderSelectModalContainer';
import { IPath } from '@src/model/common/Path';
import IFile from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';
import IFolder, { IFolderCreate } from '@src/model/file/Folder';
import { ITag, ITagCreate } from '@src/model/file/Tag';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import { FileListBusinessStore, IFileListFilter, IFileListTagFilter } from '@src/service/business/file/fileListBusinessStore';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { getLogger } from '@src/service/util/logging/logger';
import RoleUtils from '@src/service/util/role/RoleUtils';
import Dropzone from 'react-dropzone';
import { withRouter, WithRouterProps } from 'react-router';

const LOGGER = getLogger('UserFileRepositoryContainer');

export const rootFolder: IPath = { id: 'root', name: 'root', child: null };

export const publicFolder: IPath = { id: 'public', name: 'public', child: null };

// search results behave as a separate mode from root
export const searchRouteElement: IPath = { id: 'search', name: 'search', child: null };

/** Checks routes and returns true if in public folder */
export const isInPublicFolder = (routes: IPath) => {
  if (routes.child) {
    return routes.child.id === publicFolder.id;
  } else {
    return false;
  }
};

/** Checks routes and returns true if in "search" folder */
export const isInSearchFolder = (routes: IPath) => {
  if (routes.child) {
    return routes.child.id === searchRouteElement.id;
  } else {
    return false;
  }
};

// --
// ----- Prop types
interface IUserFileRepositoryContainerOwnProps {
  foldersSelectable?: boolean;
  showActionMenu?: boolean;
  isRepositoryRoute?: boolean;
}

interface IUserFileRepositoryContainerStateProps {
  params?: { folderId: string | null };
  fileList: ICollectionData<IFileSystemElement>;
  fileListFilter: IFileListFilter;
  repositoryFileListSelectedItems: IFileSystemElement[];
  tagList: ICollectionData<ITag>;
  tagListFilter: IFileListTagFilter;
  currentUser: IUserInfo;
}
interface IUserFileRepositoryContainerDispatchProps {
  fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => any;
  fetchRepositorySearchList: (listFilter: IFileListFilter, page: number, size: number, sort: string[]) => any;
  storeFileFilter: (listFilter: IFileListFilter) => any;
  clearFileList: () => void;
  storeSelectedItems: (data: IFileSystemElement[]) => any;
  clearSelectedItems: () => void;
  fetchFolder: (folderId: string, callback: IActionThunkMap) => void;
  createFolder: (data: IFolderCreate, callback: IActionThunkMap) => void;
  updateFolder: (folderId: string, data: IFolder, callback: IActionThunkMap) => void;
  deleteFolder: (data: IFolder[], thunkMap: IActionThunkMap) => void;
  addRepositoryFile: (folderId: string, data: IFile[], callback: IActionThunkMap) => void;
  removeRepositoryFile: (folderId: string, data: IFile[], callback: IActionThunkMap) => void;
  moveRepositoryFile: (folderId: string, data: IFile[], callback: IActionThunkMap) => void;
  updateRepositoryFile: (folderId: string, data: IFile, thunkMap: IActionThunkMap) => void;
  fetchRepositoryTagList: (listFilter: IFileListTagFilter, page: number, size: number, sort: string[]) => void;
  fetchRepositoryTagListWithEvent: (listFilter: IFileListTagFilter, page: number, size: number, sort: string[], parentEvent: ILemonEvent<IFileListTagFilter>) => ITrackableAction;
  clearRepositoryTagList: () => void;
  storeRepositoryTagFilter: (listFilter: IFileListTagFilter) => any;
  createRepositoryTag: (data: ITagCreate, callback: IActionThunkMap) => void;
  updateRepositoryTag: (tagId: string, data: ITag, callback: IActionThunkMap) => void;
}
type IUserFileRepositoryContainerProps = IUserFileRepositoryContainerOwnProps & IUserFileRepositoryContainerStateProps & IUserFileRepositoryContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;
// --
// ----- State types
interface IUserFileRepositoryContainerState {
  routes: IPath;
  currentFolder: Pick<IPath, 'id' | 'name'>;
  openModal: boolean;
  selectedElements: IFileSystemElement[];
  sort: string;
  zoneActive: boolean;
  page?: number;
  pageSize?: number;
}

class UserFileRepositoryContainer extends React.Component<IUserFileRepositoryContainerProps, IUserFileRepositoryContainerState> {
  state = {
    routes: rootFolder,
    currentFolder: rootFolder,
    openModal: false,
    selectedElements: [],
    sort: AppConfigService.getValue('components.repository.sort.type.ascend.id'),
    zoneActive: false,
    page: 0,
    pageSize: AppConfigService.getValue('api.paging.defaultPageSize'),
  };

  componentDidMount = () => {
    // initial list update
    if (this.props.params.folderId != null) {
      if (this.props.params.folderId === 'public') {
        this.handleFolderSelect(publicFolder);
      } else {
        this.fetchFolder(this.props.params.folderId);
      }
    } else {
      this.updateRepositoryList();
    }
  };

  componentDidUpdate = (prevProps: IUserFileRepositoryContainerStateProps, prevState: IUserFileRepositoryContainerState) => {
    if (this.props !== prevProps && this.props.fileListFilter !== prevProps.fileListFilter) {
      this.props.clearFileList();
      this.updateRepositoryList();
    }
    if (this.state.page !== prevState.page || this.state.pageSize !== prevState.pageSize || this.state.sort !== prevState.sort) {
      this.updateRepositoryList(this.state.currentFolder.id, this.state.page, this.state.pageSize, this.state.sort);
    }
    if (this.props !== prevProps && this.props.tagListFilter !== prevProps.tagListFilter) {
      this.updateTagList();
    }
    if (prevProps.params != null && this.props.params.folderId !== prevProps.params.folderId && this.props.params.folderId != null) {
      if (this.props.params.folderId === 'public') {
        this.handleFolderSelect(publicFolder);
      } else {
        this.fetchFolder(this.props.params.folderId);
      }
    }
  };

  componentWillUnmount = () => {
    // clear fileList from store
    this.props.clearFileList();
    this.props.clearSelectedItems();
  };

  render() {
    return (
      <React.Fragment>
        <div className="lemon-notificationInfoBar__tooltip">
          <Alert message={this.props.translate('USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_LABEL')} description={this.props.translate('USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_MESSAGE')} type="info" showIcon={true} />
        </div>
        <div className="lemon-repository__breadcrumb">
          <FileListBreadcrumb onFolderSelect={this.handleFolderSelect} routes={this.state.routes} searchResultsCount={this.state.currentFolder.id === searchRouteElement.id && this.props.fileList ? this.props.fileList.page.totalElements : undefined} isRepositoryRoute={this.props.isRepositoryRoute} />
        </div>
        <Dropzone onDragEnter={this.handleDragOver} onDragLeave={this.handleDragLeave} onDrop={this.handleDragLeave}>
          {({ getRootProps }) => (
            <div {...getRootProps()} className={`lemon-repository__dropzone ${this.state.zoneActive ? 'lemon-repository__dropzone--active' : ''}`}>
              <FileRepositoryUpload handleUploadFile={this.handleUploadFile} uploadProps={{ openFileDialogOnClick: false, disabled: this.isUploadDisabled() }}>
                <div className="lemon-repository__dropzoneMask">
                  <div className="lemon-repository__dropzoneMessage">
                    <div>{this.props.translate('TUTORING_SESSION_VIEW.DROP_FILE_HERE')}</div>
                    <LemonIcon name="download" />
                  </div>
                </div>
                <Card
                  className={`lemon-repository__content ${this.props.repositoryFileListSelectedItems.length > 0 ? 'items-selected' : ''}`}
                  title={<FileRepositoryHeader routes={this.state.routes} fileListFilter={this.props.fileListFilter} onFilterChange={this.handleFileFilterChange} onFolderCreate={this.handleFolderCreate} onUploadFile={this.handleUploadFile} isUploadDisabled={this.isUploadDisabled()} onFolderSelect={this.handleFolderSelect} />}
                >
                  {this.props.fileList && (
                    <FileList
                      files={this.props.fileList ? this.props.fileList.content : []}
                      pagination={this.props.fileList.page}
                      tagList={this.props.tagList ? this.props.tagList.content : []}
                      selected={this.props.repositoryFileListSelectedItems}
                      foldersSelectable={this.props.foldersSelectable}
                      showActionMenu={this.props.showActionMenu}
                      onFolderSelect={this.handleFolderSelect}
                      onRename={this.handleRename}
                      onDelete={this.handleDelete}
                      onSelectedChange={this.handleSelectedChange}
                      onMove={this.handleMove}
                      onPageChange={this.handlePageChange}
                      onSortChange={this.handleSortChange}
                      onTagRemove={this.handleTagRemove}
                      onTagAdd={this.handleTagAdd}
                      onTagSearchEvent={this.handleTagSearchEvent}
                      isRepositoryRoute={this.props.isRepositoryRoute}
                    />
                  )}
                </Card>
              </FileRepositoryUpload>
            </div>
          )}
        </Dropzone>

        {this.state.openModal && <FolderSelectModalContainer selectedElements={this.state.selectedElements} closeModal={this.handleModalClose} fromFolder={this.state.currentFolder} showPublicFolder={this.isAdmin()} />}
      </React.Fragment>
    );
  }

  handleDragLeave = (): void => {
    if (this.state.zoneActive) {
      this.setState({
        zoneActive: false,
      });
    }
  };

  handleDragOver = (): void => {
    if (!this.state.zoneActive) {
      this.setState({
        zoneActive: true,
      });
    }
  };

  isUploadDisabled = (): boolean => {
    return isInSearchFolder(this.state.routes) || (isInPublicFolder(this.state.routes) && !this.isAdmin());
  };

  isAdmin = (): boolean => {
    return RoleUtils.isAdmin(this.props.currentUser.roles);
  };

  toggleFolderSelectModalVisible = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  handleModalClose = () => {
    this.toggleFolderSelectModalVisible();
    this.props.clearSelectedItems();
    this.updateRepositoryList(this.state.currentFolder.id);
  };

  handleFolderSelect = (folder: IFolder | IPath) => {
    if (folder.id !== this.state.currentFolder.id) {
      this.props.storeSelectedItems([]);
      this.updateRepositoryList(folder.id);

      if (folder.id === rootFolder.id) {
        this.setState({
          routes: rootFolder,
          currentFolder: rootFolder,
        });
      } else if (folder.id === publicFolder.id) {
        this.setState({
          routes: { ...rootFolder, child: publicFolder },
          currentFolder: publicFolder,
        });
      } else {
        if ('fullPath' in folder) {
          if (folder.fullPath.id === publicFolder.id) {
            this.setState({
              routes: { ...rootFolder, child: folder.fullPath },
              currentFolder: folder,
            });
          } else {
            this.setState({
              routes: { ...folder.fullPath },
              currentFolder: folder,
            });
          }
        } else {
          if (this.props.params.folderId != null && this.props.params.folderId !== 'public') {
            this.fetchFolder(this.props.params.folderId);
          } else {
            this.fetchFolder(folder.id);
          }
        }
      }
    }
  };

  handleRename = (record: IFileSystemElement, newName: string) => {
    if (FileUtils.isFile(record)) {
      this.props.updateRepositoryFile(
        this.state.currentFolder.id,
        { ...record, name: newName },
        {
          success: () => {
            this.updateRepositoryList();
          },
        }
      );
    } else if (FileUtils.isFolder(record)) {
      this.props.updateFolder(
        record.id,
        { ...record, name: newName },
        {
          success: () => {
            this.updateRepositoryList();
          },
        }
      );
    } else {
      LOGGER.error('Element is neither file nor folder');
    }
  };

  handleFolderCreate = (name: string) => {
    const newFolder: IFolderCreate = { name };
    newFolder.parent = this.state.currentFolder;
    this.props.createFolder(newFolder, {
      success: () => {
        this.updateRepositoryList();
      },
    });
  };

  handleUploadFile = (data: IFile[]) => {
    const folder = this.state.currentFolder.id !== searchRouteElement.id ? this.state.currentFolder.id : rootFolder.id;
    this.props.addRepositoryFile(folder, data, {
      success: () => {
        this.updateRepositoryList();
      },
    });
  };

  handleDelete = (record: IFileSystemElement[]) => {
    const files = [] as IFile[];
    const folders = [] as IFolder[];
    record.forEach((item) => {
      if (FileUtils.isFile(item)) {
        files.push(item);
      } else if (FileUtils.isFolder(item)) {
        folders.push(item);
      } else {
        LOGGER.error('Element is neither file nor folder');
      }
    });
    if (files.length) {
      this.props.removeRepositoryFile(this.state.currentFolder.id, files, {
        success: () => {
          this.updateRepositoryList();
        },
      });
    }
    if (folders.length) {
      this.props.deleteFolder(folders, {
        success: () => {
          this.updateRepositoryList();
          this.handleSelectedChange([]);
        },
      });
    }
  };

  handleMove = (record: IFileSystemElement[]) => {
    this.setState({
      selectedElements: record,
      openModal: true,
    });
  };

  handleTagSearchEvent = (event?: ILemonEvent<IFileListTagFilter>) => {
    if (event) {
      this.updateTagListWithEvent(event, 0, AppConfigService.getValue('api.paging.smallPageSize'));
    } else {
      this.props.clearRepositoryTagList();
    }
  };

  handleTagCreate = (name: string) => {
    const newTag: ITagCreate = { name };

    this.props.createRepositoryTag(newTag, {
      /*       success: () => {
              this.updateTagList();
            }, */
    });
  };

  handleTagRename = (record: ITag, newName: string) => {
    // not yet implemented
    this.props.updateRepositoryTag(
      record.id,
      { ...record, name: newName },
      {
        success: () => {
          this.updateTagList();
        },
      }
    );
  };

  handleTagRemove = (record: IFileSystemElement, tag: ITag) => {
    LOGGER.info('removed tag from file:', record, tag);
    const tagList = record.tags ? [...record.tags] : [];
    tagList.splice(
      tagList.findIndex((item) => item.id === tag.id),
      1
    );

    this.updateElementTags(record, tagList);
  };

  handleTagAdd = (record: IFileSystemElement, tag: ITag | ITagCreate) => {
    LOGGER.info('added tag to file:', record, tag);
    const tagList = record.tags ? [...record.tags] : [];
    if ('id' in tag) {
      tagList.push(tag);
      this.updateElementTags(record, tagList);
    } else {
      this.props.createRepositoryTag(tag, {
        success: (data) => {
          tagList.push(data);
          this.updateElementTags(record, tagList);
        },
      });
    }
  };

  handleSelectedChange = (data: IFileSystemElement[]) => {
    this.props.storeSelectedItems(data);
  };

  handleFileFilterChange = (filter?: IFileListFilter) => {
    if (filter) {
      if (this.state.currentFolder.id !== searchRouteElement.id) {
        this.props.clearFileList();
      }
      this.setState(
        {
          routes: { ...rootFolder, child: searchRouteElement },
          currentFolder: searchRouteElement,
        },
        () => {
          this.props.storeFileFilter(filter);
        }
      );
    } else {
      this.setState(
        {
          routes: rootFolder,
          currentFolder: rootFolder,
        },
        () => {
          this.props.storeFileFilter({});
        }
      );
    }
  };

  handleTagFilterChange = (filter: IFileListTagFilter) => {
    LOGGER.info('Tag list filter change', filter);
    this.props.storeRepositoryTagFilter(filter);
  };

  handleSortChange = (sort: string) => {
    if (this.state.sort !== sort) {
      LOGGER.info('File list sort change', sort);
      this.setState({
        sort,
      });
    }
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({
      page: page - 1,
      pageSize,
    });
    // this.updateRepositoryList(this.state.currentFolder.id, page - 1, pageSize ? pageSize : this.props.fileList.page.size);
  };

  private updateElementTags = (record: IFileSystemElement, tagList: ITag[]) => {
    if (FileUtils.isFile(record)) {
      this.props.updateRepositoryFile(
        this.state.currentFolder.id,
        { ...record, tags: tagList },
        {
          success: () => {
            this.updateRepositoryList();
          },
        }
      );
    } else if (FileUtils.isFolder(record)) {
      this.props.updateFolder(
        record.id,
        { ...record, tags: tagList },
        {
          success: () => {
            this.updateRepositoryList();
          },
        }
      );
    } else {
      LOGGER.error('Element is neither file nor folder');
    }
  };

  private updateRepositoryList = (folder: string = this.state.currentFolder.id, page: number = 0, size: number = AppConfigService.getValue('api.paging.defaultPageSize'), sort: string = this.state.sort) => {
    if (folder !== searchRouteElement.id) {
      this.props.fetchFileList(folder, {}, page, size, [sort]);
    } else {
      this.props.fetchRepositorySearchList({ ...this.props.fileListFilter }, page, size, [sort]);
    }
  };

  private updateTagList = (filter: IFileListTagFilter = this.props.tagListFilter, page: number = 0, pageSize: number = AppConfigService.getValue('api.paging.defaultPageSize'), sort: string[] = []) => {
    this.props.fetchRepositoryTagList(filter, page, pageSize, sort);
  };

  private updateTagListWithEvent = (event: ILemonEvent<IFileListTagFilter>, page: number = 0, pageSize: number = AppConfigService.getValue('api.paging.defaultPageSize'), sort: string[] = []) => {
    this.props.fetchRepositoryTagListWithEvent(event.data, page, pageSize, sort, event).track();
  };

  private fetchFolder = (id: string) => {
    this.props.fetchFolder(id, {
      success: (response) => {
        if (response.fullPath.id === publicFolder.id) {
          this.setState({
            routes: { ...rootFolder, child: response.fullPath },
            currentFolder: response,
          });
          this.updateRepositoryList();
        } else {
          this.setState({
            routes: { ...response.fullPath },
            currentFolder: response,
          });
          this.updateRepositoryList(response.id);
        }
      },
    });
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by 'type inference' to infer the type of returned props
const mapStateToProps = (state: IUserFileRepositoryContainerState, ownProps: IUserFileRepositoryContainerOwnProps): IUserFileRepositoryContainerStateProps => ({
  fileList: FileListBusinessStore.selectors.getRepositoryFileList(state),
  fileListFilter: FileListBusinessStore.selectors.getFileListFilter(state),
  repositoryFileListSelectedItems: FileListBusinessStore.selectors.getRepositoryFileListSelectedItems(state),
  tagList: FileListBusinessStore.selectors.getRepositoryTagList(state),
  tagListFilter: FileListBusinessStore.selectors.getRepositoryTagListFilter(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IUserFileRepositoryContainerDispatchProps => ({
  fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchRepositoryFileList(folderId, listFilter, page, size, sort)),
  fetchRepositorySearchList: (listFilter: IFileListFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchRepositorySearchList(listFilter, page, size, sort)),
  clearFileList: () => dispatch(FileListBusinessStore.actions.clearRepositoryFileList()),
  storeFileFilter: (listFilter: IFileListFilter) => dispatch(FileListBusinessStore.actions.storeFileListFilter(listFilter)),

  addRepositoryFile: (folderId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.addRepositoryFile(folderId, data), thunkMap)),
  removeRepositoryFile: (folderId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.removeRepositoryFile(folderId, data), thunkMap)),
  moveRepositoryFile: (folderId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.moveRepositoryFile(folderId, data), thunkMap)),
  updateRepositoryFile: (folderId: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.updateRepositoryFile(folderId, data), thunkMap)),

  fetchFolder: (folderId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.fetchRepositoryFolder({ id: folderId }), thunkMap)),
  createFolder: (data: IFolderCreate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.createRepositoryFolder(data), thunkMap)),
  updateFolder: (folderId: string, data: IFolder, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.updateRepositoryFolder(folderId, data), thunkMap)),
  deleteFolder: (data: IFolder[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.deleteRepositoryFolder(data), thunkMap)),

  storeSelectedItems: (data: IFileSystemElement[]) => dispatch(FileListBusinessStore.actions.storeRepositoryFileListSelectedItems(data)),
  clearSelectedItems: () => dispatch(FileListBusinessStore.actions.clearRepositoryFileListSelectedItems()),

  fetchRepositoryTagList: (listFilter: IFileListTagFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchRepositoryTagList(listFilter, page, size, sort)),
  fetchRepositoryTagListWithEvent: (listFilter: IFileListTagFilter, page: number, size: number, sort: string[], sourceEvent: ILemonEvent<IFileListTagFilter>) => dispatch(createTrackableAction(FileListBusinessStore.actions.fetchRepositoryTagList(listFilter, page, size, sort), sourceEvent)),
  clearRepositoryTagList: () => dispatch(FileListBusinessStore.actions.clearRepositoryTagList()),
  storeRepositoryTagFilter: (listFilter: IFileListTagFilter) => dispatch(FileListBusinessStore.actions.storeRepositoryTagFilter(listFilter)),

  createRepositoryTag: (data: ITagCreate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.createRepositoryTag(data), thunkMap)),
  updateRepositoryTag: (tagId: string, data: ITag, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.updateRepositoryTag(tagId, data), thunkMap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IUserFileRepositoryContainerProps>(UserFileRepositoryContainer)) as any);
