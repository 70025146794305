import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';

import { IPayloadAction, ICollectionData, ICollectionFetchPayload, ILemonAction } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import ITutoringSession from '@src/model/session/TutoringSession';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import ITimelineActivity from '@src/model/timeline/TimelineActivity';
import { ISessionListFilter } from '@src/service/business/session/sessionBusinessStore';
import IUserDetails from '@src/model/user/UserDetails';
import { getLogger } from '@src/service/util/logging/logger';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import IFavourite from '@src/model/favourites/Favourite';


const LOGGER = getLogger('dashboardBusinessStore');

// -
// -------------------- Types&Consts

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface IActivityListFilter {
  // id: string;
  [key: string]: any;
  // TODO: set specific filter props
}

export interface IFavouriteListFilter {
  // id: string;
  // [key: string]: any;
  tutoringSessionStatuses?: string;
  tutoringSessionEndStatuses?: string;
}

const FAVOURITE_LIST_FILTER = 'FAVOURITE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns count of sessions from store. */
const getUnconfirmedSessionList = (store: any): ICollectionData<ITutoringSession> => store.unconfirmedSessionList;

/** Returns list of activities from store. */
const getActivityList = (store: any): ICollectionData<ITimelineActivity> => store.activityList;

/** Returns list of activities from store. */
const getFavouriteList = (store: any): ICollectionData<IFavourite> => store.favouriteList;

/** Returns favourite list filter */
const getFavouriteListFilter = (store: any): IFavouriteListFilter => ListFilterBusinessStore.selectors.getListFilter(store, FAVOURITE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  UNCONFIRMED_SESSION_LIST_FETCH: 'UNCONFIRMED_SESSION_LIST_FETCH',
  UNCONFIRMED_SESSION_LIST_LOAD: 'UNCONFIRMED_SESSION_LIST_LOAD',
  UNCONFIRMED_SESSION_LIST_CLEAR: 'UNCONFIRMED_SESSION_LIST_CLEAR',
  ACTIVITY_LIST_FETCH: 'ACTIVITY_LIST_FETCH',
  ACTIVITY_LIST_LOAD: 'ACTIVITY_LIST_LOAD',
  ACTIVITY_LIST_CLEAR: 'ACTIVITY_LIST_CLEAR',
  FAVOURITE_LIST_FETCH: 'FAVOURITE_LIST_FETCH',
  FAVOURITE_LIST_LOAD: 'FAVOURITE_LIST_LOAD',
  FAVOURITE_LIST_CLEAR: 'FAVOURITE_LIST_CLEAR',
};

const fetchUnconfirmedSessionList = (params: ICollectionFetchPayload<ISessionListFilter>): IPayloadAction<ICollectionFetchPayload<ISessionListFilter>> => {
  return {
    type: Actions.UNCONFIRMED_SESSION_LIST_FETCH,
    payload: params,
  };
};

const loadUnconfirmedSessionList = (data: ICollectionData<ITutoringSession>): IPayloadAction<ICollectionData<ITutoringSession>> => {
  return {
    type: Actions.UNCONFIRMED_SESSION_LIST_LOAD,
    payload: data,
  };
};

const clearUnconfirmedSessionList = (): ILemonAction => {
  return {
    type: Actions.UNCONFIRMED_SESSION_LIST_CLEAR,
  };
};

const fetchActivityList = (params: ICollectionFetchPayload<IActivityListFilter>): IPayloadAction<ICollectionFetchPayload<IActivityListFilter>> => {
  return {
    type: Actions.ACTIVITY_LIST_FETCH,
    payload: params,
  };
};

const loadActivityList = (data: ICollectionData<ITimelineActivity>): IPayloadAction<ICollectionData<ITimelineActivity>> => {
  return {
    type: Actions.ACTIVITY_LIST_LOAD,
    payload: data,
  };
};

const clearActivityList = (): ILemonAction => {
  return {
    type: Actions.ACTIVITY_LIST_CLEAR,
  };
};

const fetchFavouriteList = (listFilter: IFavouriteListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IFavouriteListFilter>> => {
  return {
    type: Actions.FAVOURITE_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadFavouriteList = (data: ICollectionData<IUserDetails>): IPayloadAction<ICollectionData<IUserDetails>> => {
  return {
    type: Actions.FAVOURITE_LIST_LOAD,
    payload: data,
  };
};

const clearFavouriteList = (): ILemonAction => {
  return {
    type: Actions.FAVOURITE_LIST_CLEAR,
  };
};

const storeFavouriteListFilter = (listFilter: IFavouriteListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(FAVOURITE_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchUnconfirmedSessionListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISessionListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.UNCONFIRMED_SESSION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringSession')).fetchEntityList(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUnconfirmedSessionList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching unconfirmed session list', error);
      return o;
    })
  );
};

const fetchActivityListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IActivityListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ACTIVITY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      const currentUserId = state$.value.currentUser.id;

      return EntityApiServiceRegistry.getService('User').fetchSubentityList(currentUserId, 'Activity', payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadActivityList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching activity list', error);
      return o;
    })
  );
};

const fetchFavouriteListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IFavouriteListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.FAVOURITE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      const currentUserId = state$.value.currentUser.id;

      return EntityApiServiceRegistry.getService('User').fetchSubentityList(currentUserId, 'Favourite', payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadFavouriteList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching activity list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const unconfirmedSessionList = (state: ICollectionData<ITutoringSession> | null = null, action: IPayloadAction<ICollectionData<ITutoringSession>>) => {
  if (action.type === Actions.UNCONFIRMED_SESSION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.UNCONFIRMED_SESSION_LIST_CLEAR) {
    return null;
  }

  return state;
};

const activityList = (state: ICollectionData<ITimelineActivity> | null = null, action: IPayloadAction<ICollectionData<ITimelineActivity>>) => {
  if (action.type === Actions.ACTIVITY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ACTIVITY_LIST_CLEAR) {
    return null;
  }

  return state;
};

const favouriteList = (state: ICollectionData<IFavourite> | null = null, action: IPayloadAction<ICollectionData<IFavourite>>) => {
  if (action.type === Actions.FAVOURITE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.FAVOURITE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const DashboardBusinessStore = {
  actions: {
    fetchUnconfirmedSessionList, loadUnconfirmedSessionList, clearUnconfirmedSessionList,
    fetchActivityList, loadActivityList, clearActivityList,
    fetchFavouriteList, loadFavouriteList, clearFavouriteList, storeFavouriteListFilter,
  },

  selectors: {
    getUnconfirmedSessionList, getActivityList, getFavouriteList, getFavouriteListFilter,
  },

  effects: {
    fetchUnconfirmedSessionListEffect, fetchActivityListEffect, fetchFavouriteListEffect,
  },

  reducers: {
    unconfirmedSessionList, activityList, favouriteList,
  },
};

// --
// ----- Exports

export default DashboardBusinessStore;
