import { Button, Col, Row, Tooltip } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AddFileFromRepositoryModal from '@src/components/session/common/AddFileFromRepositoryModal';
import IFile from '@src/model/file/File';
import ITutoringSession from '@src/model/session/TutoringSession';
import ITutoringSessionParticipant from '@src/model/session/TutoringSessionParticipant';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import { LangUtils } from '@src/service/util/LangUtils';
import { ButtonSize } from 'antd/lib/button';

export type IButtonType = 'confirm' | 'decline' | 'cancel' | 'update' | 'opentutoringroom' | 'openwhiteroom' | 'addfilefromrepository' | 'openMobileApp';

export interface ITutoringSessionActionsPublicProps {
  session: ITutoringSession;
  currentParticipant?: ITutoringSessionParticipant;
  buttonTypes?: IButtonType | IButtonType[];
  showButtonText?: boolean;
  buttonSize?: ButtonSize;

  // event handlers
  onConfirm?: () => void;
  onDecline?: () => void;
  onCancel?: () => void;
  onUpdate?: () => void;
  onOpenTutoringRoom?: () => void;
  onOpenWhiteroom?: () => void;
  onAddFileFromRepository?: (file: IFile[]) => void;
}
type ITutoringSessionActionsProps = ITutoringSessionActionsPublicProps & IWithLocalizeOwnProps;

export interface ITutoringSessionActionsState {
  openRepositoryModal: boolean;
}

class TutoringSessionActions extends React.Component<ITutoringSessionActionsProps, ITutoringSessionActionsState> {
  state = {
    openRepositoryModal: false,
  };

  render = () => {
    // session is only scheduled and current user needs to accept/decline
    const sessionInvited = TutoringSessionModelHelper.isSessionInvited(this.props.session, this.props.currentParticipant);
    // current user (re)scheduled and other participant needs to accept
    const sessionAccepted = TutoringSessionModelHelper.isSessionAccepted(this.props.session, this.props.currentParticipant);
    // session is confirmed
    const sessionConfirmed = TutoringSessionModelHelper.isSessionConfirmed(this.props.session);
    // session is in progress
    const sessionInProgress = TutoringSessionModelHelper.isSessionInProgress(this.props.session);

    // is the currentParticipant OWNER of this Session
    const isCurrentParticipantOwner = TutoringSessionModelHelper.isOwner(this.props.session, this.props.currentParticipant && this.props.currentParticipant.user);

    return (
      <React.Fragment>
        {/* Do not show any buttons if we don't have a participant - TODO: we should allow admins to see appropriate buttons */}
        {this.props.currentParticipant && (
          <Row type="flex" gutter={6}>
            {sessionInvited && (
              <React.Fragment>
                {this.shouldShowButtonType(this.props.buttonTypes, 'confirm') && (
                  <Col>
                    <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.CONFIRM')}>
                      <Button data-test-id="lemon-sessionPage__actions-confirmButton" type="primary" htmlType="submit" size={this.props.buttonSize} onClick={() => this.props.onConfirm && this.props.onConfirm()}>
                        <LemonIcon name="check" />
                        {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.CONFIRM')}
                      </Button>
                    </Tooltip>
                  </Col>
                )}
                {this.shouldShowButtonType(this.props.buttonTypes, 'decline') && (
                  <Col>
                    <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.DECLINE')}>
                      <Button data-test-id="lemon-sessionPage__actions-declineButton" htmlType="submit" size={this.props.buttonSize} type="danger" onClick={() => this.props.onDecline && this.props.onDecline()}>
                        <LemonIcon name="close" />
                        {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.DECLINE')}
                      </Button>
                    </Tooltip>
                  </Col>
                )}
              </React.Fragment>
            )}

            {(sessionInvited || sessionAccepted) && this.shouldShowButtonType(this.props.buttonTypes, 'update') && (
              <Col>
                <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.EDIT')}>
                  <Button data-test-id="lemon-sessionPage__updateSessionButton" htmlType="submit" size={this.props.buttonSize} onClick={() => this.props.onUpdate && this.props.onUpdate()}>
                    <LemonIcon name="sync" />
                    {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.EDIT')}
                  </Button>
                </Tooltip>
              </Col>
            )}

            {(sessionConfirmed || sessionAccepted) && this.shouldShowButtonType(this.props.buttonTypes, 'cancel') && (
              <Col>
                <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.CANCEL')}>
                  <Button data-test-id="lemon-sessionPage__actions-cancelButton" htmlType="submit" size={this.props.buttonSize} type="danger" onClick={() => this.props.onCancel && this.props.onCancel()}>
                    <LemonIcon name="close" />
                    {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.CANCEL')}
                  </Button>
                </Tooltip>
              </Col>
            )}

            {(sessionConfirmed || sessionInProgress) && (
              <React.Fragment>
                {this.shouldShowButtonType(this.props.buttonTypes, 'opentutoringroom') && (
                  <Col>
                    <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.OPEN_ROOM')}>
                      <Button data-test-id="lemon-sessionPage__actions-openTutoringRoomButton" type="primary" size={this.props.buttonSize} htmlType="submit" onClick={() => this.props.onOpenTutoringRoom && this.props.onOpenTutoringRoom()}>
                        {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.OPEN_ROOM')}
                        <LemonIcon name="playCircle" />
                      </Button>
                    </Tooltip>
                  </Col>
                )}

                {this.shouldShowButtonType(this.props.buttonTypes, 'openwhiteroom') && (
                  <Col>
                    <Tooltip title={this.props.translate('LEMON_HELPER_TOOLTIP.SESSION.WHITEBOARD_ROOM_BUTTON_DESCRIPTION')}>
                      <Button data-test-id="lemon-sessionPage__actions-openWhiteRoomButton" type="primary" htmlType="submit" size={this.props.buttonSize} onClick={() => this.props.onOpenWhiteroom && this.props.onOpenWhiteroom()}>
                        {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.OPEN_WHITEROOM')}
                        <LemonIcon name="pencil" />
                      </Button>
                    </Tooltip>
                  </Col>
                )}

                {/* {this.shouldShowButtonType(this.props.buttonTypes, 'openMobileApp') && (
                  <Col>
                    <Tooltip title={this.props.translate('LEMON_HELPER_TOOLTIP.SESSION.MOBILE_APP_BUTTON_DESCRIPTION')}>
                      <Button data-test-id="lemon-sessionPage__actions-openWhiteRoomButton" type="primary" htmlType="submit" size={this.props.buttonSize} href={ANDROID_APP_LINK} target="_blank">
                        {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.OPEN_MOBILE_APP')}
                        <LemonIcon name="mobile" />
                      </Button>
                    </Tooltip>
                  </Col>
                )} */}
              </React.Fragment>
            )}

            {isCurrentParticipantOwner && this.shouldShowButtonType(this.props.buttonTypes, 'addfilefromrepository') && (
              <Col>
                <Tooltip title={this.props.showButtonText ? null : this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.ADD_FROM_REPOSITORY')}>
                  <Button onClick={this.toggleRepositoryModalVisible} size={this.props.buttonSize}>
                    <LemonIcon name="paperClip" />
                    {this.props.showButtonText && this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.ADD_FROM_REPOSITORY')}
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        )}
        <AddFileFromRepositoryModal closeModal={this.toggleRepositoryModalVisible} onSubmit={this.handleAddFileFromRepository} visible={this.state.openRepositoryModal} />
      </React.Fragment>
    );
  };

  private shouldShowButtonType = (allowedTypes: IButtonType | IButtonType[] | undefined, type: IButtonType) => {
    return !allowedTypes || (LangUtils.isArray(allowedTypes) ? allowedTypes.find((t) => t === type) != null : allowedTypes === type);
  };

  private handleAddFileFromRepository = (file: IFile[]) => {
    if (this.props.onAddFileFromRepository) {
      this.props.onAddFileFromRepository(file);
    }
    this.toggleRepositoryModalVisible();
  };

  private toggleRepositoryModalVisible = () => {
    this.setState({
      openRepositoryModal: !this.state.openRepositoryModal,
    });
  };
}

export default withLocalize<ITutoringSessionActionsPublicProps>(TutoringSessionActions as any);
