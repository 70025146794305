
/** Component messaging types map. */
export const ComponentMessageType = {

  // ----- tutoring room
  TUTORING_ROOM_FILE_ADD: 'TUTORING_ROOM_FILE_ADD',

  // ----- messaging service
  MESSAGING_SESSION_INCOMING_NOTICE: 'MESSAGING_SESSION_INCOMING_NOTICE',
  MESSAGING_SESSION_END_NOTICE: 'MESSAGING_SESSION_END_NOTICE',
  MESSAGING_SESSION_TIME_CHANGE: 'MESSAGING_SESSION_TIME_CHANGE',
  MESSAGING_SESSION_CREATED: 'MESSAGING_SESSION_CREATED',
  MESSAGING_SESSION_CONFIRMATION: 'MESSAGING_SESSION_CONFIRMATION',
  MESSAGING_SESSION_CANCELATION: 'MESSAGING_SESSION_CANCELATION',
  MESSAGING_SESSION_DECLINED: 'MESSAGING_SESSION_DECLINED',
  MESSAGING_SESSION_ENDED: 'MESSAGING_SESSION_ENDED',
  MESSAGING_SESSION_MESSAGE_SENT: 'MESSAGING_SESSION_MESSAGE_SENT',
  MESSAGING_SESSION_FILE_ADDED: 'MESSAGING_SESSION_FILE_ADDED',
  MESSAGING_SESSION_FILE_REMOVED: 'MESSAGING_SESSION_FILE_REMOVED',
  MESSAGING_WHITEBOARD_SCREENSHOTS_CREATED: 'MESSAGING_WHITEBOARD_SCREENSHOTS_CREATED',
  MESSAGING_SESSION_PARTICIPANT_LEFT: 'SESSION_PARTICIPANT_LEFT',

};
