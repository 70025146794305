import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import IFile from '@src/model/file/File';
import { Modal } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';

// -- Prop types
// ----------

export interface IFilePreviewModalOwnProps {
  visible: boolean;
  closeModal: () => void;
  record: IFile;
}
type IFilePreviewModalProps = IFilePreviewModalOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps;

interface IFilePreviewModalState {}

// -- Component
// ----------

/** Describe your component ... */
class FilePreviewModal extends React.Component<IFilePreviewModalProps, IFilePreviewModalState> {
  getItemLink = (record: IFile) => {
    return (
      <a href={this.props.resolveApiPath(`/api/files/${record.id}`)} download={record.name} data-test-id={`lemon-fileRepository__fileLink_downloadFile_${record.id}`}>
        {record.name}
      </a>
    );
  };

  getItemImage = (id: string) => {
    return <LemonImage imagePath={`/api/files/${id}`} />;
  };

  render() {
    return (
      <Modal
        title={this.getItemLink(this.props.record)}
        visible={this.props.visible}
        mask={true}
        centered={true}
        footer={null}
        onCancel={this.props.closeModal}
        transitionName="fade" // defining transitionName is needed, as by default setup positioning of the modal header doesn't work correctly
        className="lemon-repository__previewImageModal"
      >
        {this.getItemImage(this.props.record.id)}
      </Modal>
    );
  }
}

export default withPathResolver(withLocalize<IFilePreviewModalOwnProps>(FilePreviewModal as any));
