import LemonError from '@src/service/common/LemonError';

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

// List of words that should not be adapted (irregulars, special words, ...)
// TODO: this should be dynamically provided to adapter insted of hardcoded
const IRREGULAR_PLURALS: { [key: string]: string } = {
  auth: 'auth',
  tutoringroom: 'tutoringroom',
  cancel: 'cancel',
  confirm: 'confirm',
  decline: 'decline',
  children: 'children',
};

/** Adapt entity names in singular to plural in std. english. */
export default function entityNameAdapter(entityName: string) {
  if (entityName == null) {
    throw new LemonError('Invalid entity name: ' + entityName);
  }

  const lowercasedName = entityName.toLowerCase();
  if (IRREGULAR_PLURALS[lowercasedName] != null) {
    return IRREGULAR_PLURALS[lowercasedName];
  }
  else if (lowercasedName.substr(-1) === 'y') {
    // vowel + 'y'
    if (contains(VOWELS, lowercasedName.substr(-2, 1))) {
      return lowercasedName + 's';
    }
    // consonant + 'y'
    else {
      return lowercasedName.substr(0, lowercasedName.length - 1) + 'ies';
    }
  }
  // s, x, sh, ch
  else if (contains(['s', 'x'], lowercasedName.substr(-1)) || contains(['sh', 'ch'], lowercasedName.substr(-1))) {
    return lowercasedName + 'es';
  }
  // z
  else if (contains(['z'], lowercasedName.substr(-1))) {
    return lowercasedName + 'zes';
  }
  // o
  else if (contains(['o'], lowercasedName.substr(-1))) {
    // vowel + 's'
    if (contains(VOWELS, lowercasedName.substr(-2, 1))) {
      return lowercasedName + 's';
    }
    else {
      return lowercasedName + 'es';
    }
  }
  else {
    return lowercasedName + 's';
  }

}

function contains(haystack: string[], needle: string): boolean {
  return haystack.indexOf(needle) !== -1;
}
