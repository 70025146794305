import React from 'react';

import withLazyLoad from '@src/components/common/lazy/withLazyLoad';
import ProgressStatus from '@src/components/common/userfeedback/ProgressStatus';


/** Page specific lazy loading component. Shows specific fallback until page component has been loaded */
const withPageLazyLoad = <P extends object>(Component: React.ComponentType<P>) => {
  return withLazyLoad(Component,
    <ProgressStatus />
  );
};

export default withPageLazyLoad;
