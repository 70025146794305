import { RouterState, RedirectFunction } from 'react-router';

import AppConfigService from '@src/service/common/AppConfigService';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('AuthRouteGuard');

function isAllowed(): boolean {
  const isUserLoggedIn: boolean = !!AuthTokenManager.getToken();
  const publicRoutes: string[] = AppConfigService.getValue('routing.publicRoutes');

  return isUserLoggedIn || publicRoutes.indexOf(location.pathname) !== -1;
}

/** Protect routes that require authenticated user. Function is implemented as "onEnter" router callback. */
export function authRouteGuard(nextState: RouterState, replace: RedirectFunction, callback?: (error?: any) => any) {
  if (!isAllowed()) {
    LOGGER.info(`Route ${nextState.location.pathname} not allowed.`);

    replace({
      pathname: AppConfigService.getValue('routing.loginRoute'),
      query: {
        [StaticPageRoutingHelper.RETURN_ROUTE_URL_PARAMETER]: `${nextState.location.pathname}${nextState.location.search}`,
      },
    });
  }

  if (callback) {
    callback();
  }
}
