import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';

import { IPayloadAction, INamedPayload, ICollectionData } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('collectionBusinessStore');

// -
// -------------------- Types

export interface ICollectionDataPayload<T, M> extends INamedPayload {
  name: string;
  collection: ICollectionData<T, M>;
}

// -
// -------------------- Selectors

const getCollection = (store: any, name: string): ICollectionData<any> => store.collections[name];

const getCollectionContent = (store: any, name: string): any[] => store.collections[name] && (store.collections[name] as ICollectionData<any>).content;

// -
// -------------------- Actions

const Actions = {
  COLLECTION_FETCH: 'COLLECTION_FETCH',
  COLLECTION_LOAD: 'COLLECTION_LOAD',
};

const fetchCollection = (entityName: string): IPayloadAction<INamedPayload> => {
  return {
    type: Actions.COLLECTION_FETCH,
    payload: { name: entityName },
  };
};

const loadCollection = (name: string, collection: ICollectionData<any>): IPayloadAction<ICollectionDataPayload<any, any>> => {
  return {
    type: Actions.COLLECTION_LOAD,
    payload: {
      name,
      collection,
    },
  };
};

// -
// -------------------- Side-effects

const fetchCollectionEffect = (action$: Observable<IPayloadAction<INamedPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COLLECTION_FETCH;
    }),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService(payload.name)).fetchEntityList().pipe(
        map((collection) => {
          return loadCollection(payload.name, collection as ICollectionData<any>);
        })
      );
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching collection', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const collections = (state: { [name: string]: ICollectionData<any> } = {}, action: IPayloadAction<ICollectionDataPayload<any, any>>) => {
  if (action.type === Actions.COLLECTION_LOAD) {
    return {
      ...state,
      [action.payload.name]: action.payload.collection,
    };
  }

  return state;
};

// --
// ----- Export store object

export const CollectionBusinessStore = {
  actions: {
    fetchCollection, loadCollection,
  },

  selectors: {
    getCollection, getCollectionContent,
  },

  effects: {
    fetchCollectionEffect,
  },

  reducers: {
    collections,
  },
};
