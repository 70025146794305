import { Observable, Subject, BehaviorSubject, from, of, Observer } from 'rxjs';
import { filter, map, switchAll, tap, mergeMap, catchError } from 'rxjs/operators';

// tslint:disable-next-line:no-submodule-imports - this is the only way to import firebase app
import * as firebase from 'firebase/app';
// These imports load individual services into the firebase namespace
// tslint:disable-next-line:no-submodule-imports no-import-side-effect - this is the only way to import firebase components
import 'firebase/messaging';

import AppConfigService from '@src/service/common/AppConfigService';
import LemonError from '@src/service/common/LemonError';
import { ITokenStoreHandler, IMessagingServiceRawMessage } from '@src/service/service/messaging/types';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('MessagingService');


let INSTANCE: MessagingService;
let FIREBASE_MESSAGING_INSTANCE: firebase.messaging.Messaging;
const PERMISSIONS_OBSERVABLE = new BehaviorSubject<boolean | undefined>(undefined);
const STARTED_OBSERVABLE = new BehaviorSubject<boolean>(false);
const MESSAGES_OBSERVABLE = new Subject<IMessagingServiceRawMessage>();

// callback for storing token on server
let TOKEN_STORE_HANDLER: ITokenStoreHandler;

export default class MessagingService {
  static instance() {
    if (INSTANCE == null) {
      INSTANCE = new MessagingService();
    }

    return INSTANCE;
  }

  /** Returns observable which will stream new messages as they come in. */
  observeMessages(): Observable<IMessagingServiceRawMessage> {
    return STARTED_OBSERVABLE.asObservable().pipe(
      filter((value) => value),

      map(() => {
        return MESSAGES_OBSERVABLE.asObservable();
      }),
      switchAll()
    );
  }

  /** Initialize Firebase messaging app client and setup listeners. */
  initialize(tokenStoreHandler: ITokenStoreHandler): Observable<boolean> {
      return Observable.create((observer: Observer<boolean>): void => {
        try {
          if (firebase.messaging.isSupported()) {
            if (FIREBASE_MESSAGING_INSTANCE != null) {
              LOGGER.warn('Firebase messaging already initialized!');
              return;
            }
            TOKEN_STORE_HANDLER = tokenStoreHandler;

            firebase.initializeApp(AppConfigService.getValue('messaging.firebase.config'));
            FIREBASE_MESSAGING_INSTANCE = firebase.messaging();

            // Add the public key generated from the console here.
            FIREBASE_MESSAGING_INSTANCE.usePublicVapidKey(AppConfigService.getValue('messaging.firebase.vapid'));

            FIREBASE_MESSAGING_INSTANCE.onTokenRefresh(this.onTokenRefresh);

            FIREBASE_MESSAGING_INSTANCE.onMessage(this.onMessage);

            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        }
        catch (err) {
          observer.error(err);
        }
      });
  }

  /** Check if messaging has already been initialized allowed and connected. */
  watchStarted(): Observable<boolean> {
    return STARTED_OBSERVABLE.asObservable();
  }

  /** Check if messaging has already been initializedm allowed and connected. */
  isStarted(): boolean {
    return STARTED_OBSERVABLE.getValue();
  }

  /** Check if messaging has been allowed by the user. This can be used to show user a message about why and how should notifications be allowed. */
  shouldCheckUserApproval(): Observable<boolean> {
    return of(PERMISSIONS_OBSERVABLE.getValue() == null && Notification.permission !== 'granted' || PERMISSIONS_OBSERVABLE.getValue() != null && !PERMISSIONS_OBSERVABLE.getValue());
  }

  /** Check required permissions, request new token and start receiving messages. */
  start() {
    LOGGER.info('Starting messaging');
    if (FIREBASE_MESSAGING_INSTANCE == null) {
      throw new LemonError('Messaging not initialize. Try calling initialize first.');
    }

    this.requestPermission().pipe(
      mergeMap(() => this.getToken()),

      mergeMap((token) => this.storeToken(token)),

      tap(() => STARTED_OBSERVABLE.next(true)),

      catchError((err) => {
        LOGGER.error('Error starting messaging', err);
        STARTED_OBSERVABLE.next(false);

        return of(err);
      })
    )
      .subscribe();

    return STARTED_OBSERVABLE.asObservable();
  }

  // ---------- private

  private onMessage = (message: IMessagingServiceRawMessage) => {
    LOGGER.info('Messaging new message event', message);
    MESSAGES_OBSERVABLE.next(message);
  }

  private onTokenRefresh = () => {
    LOGGER.info('Messaging token refresh event');
    FIREBASE_MESSAGING_INSTANCE.getToken()
      .then((refreshedToken) => {
        LOGGER.info(`Messaging token refreshed.`);

        this.storeToken(refreshedToken);
      })
      .catch((err) => {
        LOGGER.warn('Error refreshing messaging token.', err);
      });
  }

  private requestPermission(): Observable<void> {
    return from(FIREBASE_MESSAGING_INSTANCE.requestPermission()).pipe(
      map(() => {
        LOGGER.info('Firebase messaging permissions granted');
        PERMISSIONS_OBSERVABLE.next(true);
      }),

      catchError((err: any) => {
        PERMISSIONS_OBSERVABLE.next(false);

        throw err;
      })
    );
  }

  private getToken(): Observable<string | null> {
    return from(FIREBASE_MESSAGING_INSTANCE.getToken()).pipe(
      tap((token) => {
        LOGGER.debug('Firebase messaging token', token);
      })
    );
  }

  private storeToken(token: string | null): Observable<any> {
    if (token) {
      if (TOKEN_STORE_HANDLER != null) {
        return from(TOKEN_STORE_HANDLER(token));
      }
      else {
        LOGGER.warn('Messaging token store handler is not defined!? Server will not be able to send us a message without that token.');
      }
    }
    else {
      LOGGER.warn('Messaging firebase token is null. What to do?!?! Notify backend server, rerequest permissions, ...?');
    }

    return of(true);
  }
}
