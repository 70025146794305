import React from 'react';

import { connect } from 'react-redux';
import { IUserInfo } from '@src/model/user/User';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import { UserRoleEnum } from '@src/model/user/UserRole';
import RoleUtils from '@src/service/util/role/RoleUtils';


// --
// ----- Prop types

export interface IWithRolesOwnProps {
  allowedRoles: (roles: UserRoleEnum[]) => boolean;
  isInRoles: (roles: UserRoleEnum[]) => boolean;
  isSuperAdmin: () => boolean;
}
export interface IWithRolesStateProps {
  currentUser: IUserInfo;
}
export interface IWithRolesDispatchProps {
}
type IWithRolesProps = IWithRolesOwnProps & IWithRolesStateProps;

// --
// ----- Component

/** Higher order component injects utility functions for checking current user's roles. */
const withRoles = <P extends object>(Component: React.ComponentType<P>) => {
  // create a wrapper component class
  class WithRolesWrapper extends React.Component<P & IWithRolesProps> {
    render() {
      // TODO: force IWithRolesOwnProps type on wrapped component
      return <Component {...this.props}
        allowedRoles={this.allowedRoles}
        isInRoles={this.isInRoles}
        isSuperAdmin={this.isSuperAdmin}
      />;
    }

    /** Check if user has that role in it's list. If current user is empty, returns false. */
    isInRoles = (roles: UserRoleEnum[]): boolean => {
      if (this.props.currentUser == null) {
        return false;
      }

      return RoleUtils.isInRoles(roles, this.props.currentUser.roles);
    }

    /** Check if user has superadmin role in it's list. If current user is empty, returns false. */
    isSuperAdmin = (): boolean => {
      if (this.props.currentUser == null) {
        return false;
      }

      return RoleUtils.isAdmin(this.props.currentUser.roles);
    }

    /** Returns true if current user has any of given roles. If current user is empty, returns false. */
    allowedRoles = (roles: UserRoleEnum[]): boolean => {
      if (this.props.currentUser == null) {
        return false;
      }

      return RoleUtils.allowedRoles(roles, this.props.currentUser.roles);
    }
  }


  // we're doing redux connect inside because we need to connect wrapper role and not the HOC
  const mapStateToProps = (state: any, ownProps: P): IWithRolesStateProps => ({
    currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  });

  // force type hinting exported component to "P" which is the original wrapped component's props type
  return connect<IWithRolesStateProps, IWithRolesDispatchProps, P>(mapStateToProps)(WithRolesWrapper as any);
};


// ----- exports

export default withRoles;

