import React from 'react';
import { classNames } from '@src/components/common/util/classNames';
import { Tag } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { TagProps } from 'antd/lib/tag';


const CSS_CLASS_BASE = 'lemon-tag__tag';
const CSS_CLASS_SIZE_LARGE = 'lemon-tag__tag--large';
const CSS_CLASS_FILE = 'lemon-tag__tag--fileTag';
const CSS_CLASS_FILE_NEW_TAG = 'lemon-tag__tag--fileNewTag';
const CSS_CLASS_EDUCATION_AREA = 'lemon-tag__tag--educationArea';
const CSS_CLASS_SESSION_SCHEDULED = 'lemon-tag__tag--scheduledSession';
const CSS_CLASS_SESSION_CONFRIMED = 'lemon-tag__tag--confirmedSession';
const CSS_CLASS_SESSION_HELD = 'lemon-tag__tag--heldSession';
const CSS_CLASS_SESSION_CANCELED = 'lemon-tag__tag--canceledSession';
const CSS_CLASS_INFO = 'lemon-tag__tag--info';


export type LemonTagSize = 'base' | 'large';
export type LemonTagType = 'default' | 'fileTag' | 'fileNewTag' | 'educationArea' | 'scheduledSession' | 'confirmedSession' | 'heldSession' | 'canceledSession' | 'unrespondedSession' | 'info';

// -- Prop types
// ----------

export interface ILemonTagOwnProps {
  type: LemonTagType;
  size?: LemonTagSize;
}
type ILemonTagProps = ILemonTagOwnProps & TagProps;


// -- Component
// ----------

/** Ant tag component wrapper which applies stylings according business type map. */
const LemonTag: React.StatelessComponent<ILemonTagProps> = (props) => {
  return (
    <Tag {...props} className={getClassName(props)}>
      {props.children}
    </Tag>
  );
};

function getClassName(props: ILemonTagProps) {
  return classNames({
    [CSS_CLASS_BASE]: true,

    // ----- sizes
    [CSS_CLASS_SIZE_LARGE]: props.size === 'large',

    // ----- types
    [CSS_CLASS_FILE]: props.type === 'fileTag',
    [CSS_CLASS_FILE_NEW_TAG]: props.type === 'fileNewTag',
    [CSS_CLASS_EDUCATION_AREA]: props.type === 'educationArea',
    [CSS_CLASS_SESSION_SCHEDULED]: props.type === 'scheduledSession',
    [CSS_CLASS_SESSION_CONFRIMED]: props.type === 'confirmedSession',
    [CSS_CLASS_SESSION_HELD]: props.type === 'heldSession',
    [CSS_CLASS_SESSION_CANCELED]: props.type === 'canceledSession' || props.type === 'unrespondedSession', // TODO: separate style for unresponded?
    [CSS_CLASS_INFO]: props.type === 'info',

    // from props
    [props.className || '']: true,
  });
}


export default LemonTag;
