import React from 'react';
import { Link } from 'react-router';

import { Row, Popover } from 'antd';
import LemonIcon, { IconSize } from '@src/components/common/image/LemonIcon';
import ITutoringSession from '@src/model/session/TutoringSession';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import IUserDetails from '@src/model/user/UserDetails';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------
export interface ISessionOtherParticipantsMenuOwnProps {
  session: ITutoringSession;
  currentParticipantUser?: IUserDetails; // for filtering participants if any user info has already been displayed outside of this component
  size?: IconSize;
  withLink?: boolean;
}
type ISessionOtherParticipantsMenuProps = ISessionOtherParticipantsMenuOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
/** Component that displays other session participants in multisessions */
class SessionOtherParticipantsMenu extends React.Component<ISessionOtherParticipantsMenuProps> {
  renderParticipants = () => {
    const participants = TutoringSessionModelHelper.getOtherParticipants(this.props.session, this.props.currentParticipantUser);
    return <React.Fragment>
      {participants.map((participant) => {
        return <Row key={participant.user.id}>
          {
            this.props.withLink ?
              <Link to={{ pathname: '/sessions', query: { sessionId: this.props.session.id, participantId: participant.user.id } }} onClick={(e) => e.stopPropagation()}>
                {participant.user.displayName}
              </Link> : participant.user.displayName
          }
        </Row>;
      })}
    </React.Fragment>;
  }

  render() {
    return (
      this.shouldRender() &&
      <Popover title={this.props.translate('SESSION_CALENDAR.OTHER_PARTICIPANTS')} content={this.renderParticipants()} trigger="click" placement="bottom">
        <a onClick={(e) => e.stopPropagation()} title={this.props.translate('SESSION_CALENDAR.OTHER_PARTICIPANTS')}>
          <LemonIcon name="users" size={this.props.size} />
        </a>
      </Popover>
    );
  }

  shouldRender = () => {
    return TutoringSessionModelHelper.getOtherParticipants(this.props.session).length > 1;
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ISessionOtherParticipantsMenuOwnProps>(SessionOtherParticipantsMenu as any);
