import React from 'react';
import { connect } from 'react-redux';

import FileUtils from '@src/components/common/file/FileUtils';
import { publicFolder, rootFolder } from '@src/components/repository/UserFileRepositoryContainer';
import IIdRef from '@src/model/common/IdRef';
import { IPath } from '@src/model/common/Path';
import IFileSystemElement from '@src/model/file//FileSystemElement';
import IFolder, { IFolderCreate } from '@src/model/file/Folder';
import { ICollectionData } from '@src/service/business/common/types';
import { FileListBusinessStore, IFileListFilter } from '@src/service/business/file/fileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FolderSelectModal from '@src/components/repository/folders/FolderSelectModal';

// --
// ----- Prop types
interface IFolderSelectModalContainerOwnProps {
  selectedElements: IFileSystemElement[];
  closeModal: () => void;
  fromFolder: IPath;
  showPublicFolder: boolean;
}
interface IFolderSelectModalContainerStateProps {}
interface IFolderSelectModalContainerDispatchProps {
  fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[], callback: IActionThunkMap) => any;
  fetchFolder: (folderId: string, callback: IActionThunkMap) => void;
  createFolder: (data: IFolderCreate, callback: IActionThunkMap) => void;
  moveRepositoryFile: (folderId: string, data: IFileSystemElement[], callback: IActionThunkMap) => void;
}

// --
// ----- State types
interface IFolderSelectModalContainerState {
  folderList: IFolder[];
  routes: IPath;
  currentFolder: Pick<IPath, 'id' | 'name'>;
}

class FolderSelectModalContainer extends React.Component<IFolderSelectModalContainerOwnProps & IFolderSelectModalContainerStateProps & IFolderSelectModalContainerDispatchProps & IWithLocalizeOwnProps, IFolderSelectModalContainerState> {
  state = {
    folderList: [],
    routes: rootFolder,
    currentFolder: rootFolder,
  };

  componentDidMount = () => {
    // initial list update
    this.updateList();
  };

  componentDidUpdate = (prevProps: IFolderSelectModalContainerStateProps) => {
    // TODO: update list on changes: filter sort, paging
    if (this.props !== prevProps) {
      this.updateList();
    }
  };

  render() {
    return (
      <FolderSelectModal
        onFolderCreate={this.handleFolderCreate}
        onFolderSelect={this.handleFolderSelect}
        routes={this.state.routes}
        folders={this.state.folderList}
        onPageChange={this.handlePageChange}
        closeModal={this.props.closeModal}
        visible={true}
        currentFolder={this.state.currentFolder}
        fromFolder={this.props.fromFolder}
        onSubmit={this.handleModalSubmit}
      />
    );
  }

  handleModalSubmit = (destination: IIdRef<string>) => {
    this.props.moveRepositoryFile(destination.id, this.props.selectedElements, {
      success: () => {
        this.props.closeModal();
      },
    });
  };

  handleFolderSelect = (folder: IFolder | IPath) => {
    if (folder.id !== this.state.currentFolder.id) {
      this.updateList(folder.id);

      if (folder.id === rootFolder.id) {
        this.setState({
          routes: rootFolder,
          currentFolder: rootFolder,
        });
      } else if (folder.id === publicFolder.id) {
        this.setState({
          routes: { ...rootFolder, child: publicFolder },
          currentFolder: publicFolder,
        });
      } else {
        if ('fullPath' in folder) {
          if (folder.fullPath.id === publicFolder.id) {
            this.setState({
              routes: { ...rootFolder, child: folder.fullPath },
              currentFolder: folder,
            });
          } else {
            this.setState({
              routes: { ...folder.fullPath },
              currentFolder: folder,
            });
          }
        } else {
          this.props.fetchFolder(folder.id, {
            success: (response) => {
              if (response.fullPath.id === publicFolder.id) {
                this.setState({
                  routes: { ...rootFolder, child: response.fullPath },
                  currentFolder: response,
                });
              } else {
                this.setState({
                  routes: { ...response.fullPath },
                  currentFolder: response,
                });
              }
            },
          });
        }
      }
    }
  };

  handleFolderCreate = (name: string) => {
    const newFolder: IFolderCreate = { name };
    newFolder.parent = this.state.currentFolder;
    this.props.createFolder(newFolder, {
      success: () => {
        this.updateList();
      },
    });
  };

  // TODO: define paging as the component doesn't show full list from BE
  handlePageChange = (page: number, pageSize?: number) => {
    this.updateList(this.state.currentFolder.id, page - 1, pageSize);
  };

  private updateList(folder: string = this.state.currentFolder.id, page: number = 0, size: number = AppConfigService.getValue('api.collectionDefaultLimit'), sort: string[] = [AppConfigService.getValue('components.repository.sort.name.ascend.id')]) {
    // TODO: replace list params defaults
    this.props.fetchFileList(folder, {}, page, size, sort, {
      success: (data: ICollectionData<IFileSystemElement>) => {
        const folders = data.content.filter((item: IFileSystemElement): item is IFolder => FileUtils.isFolder(item)).filter((value) => !this.props.selectedElements.find(({ id }) => value.id === id));

        if (this.state.currentFolder.id === rootFolder.id && this.props.showPublicFolder) {
          // TODO: implement a "real" folder in the future
          const publicFolderMime = { id: publicFolder.id, name: this.props.translate('FILE_LIST.PUBLIC_FOLDER'), mimeType: 'application/vnd.lemon.folder' } as IFolder;
          folders.unshift(publicFolderMime);
        }

        this.setState({
          folderList: folders,
        });
      },
    });
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by 'type inference' to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IFolderSelectModalContainerOwnProps): IFolderSelectModalContainerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IFolderSelectModalContainerDispatchProps => ({
  fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[], thunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.fetchAuxRepositoryFileList(folderId, listFilter, page, size, sort), thunkMap)),
  fetchFolder: (folderId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.fetchRepositoryFolder({ id: folderId }), thunkMap)),
  createFolder: (data: IFolderCreate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.createRepositoryFolder(data), thunkMap)),
  moveRepositoryFile: (folderId: string, data: IFileSystemElement[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.moveRepositoryFile(folderId, data), thunkMap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IFolderSelectModalContainerOwnProps>(FolderSelectModalContainer as any));
