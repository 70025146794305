import { Observable, of } from 'rxjs';
import { filter, catchError, map, mergeMap } from 'rxjs/operators';

import { IPayloadAction, IIdPayload } from '@src/service/business/common/types';
import { IUserApproval } from './types';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('userApprovalBusinessStore');

// --
// -------------------- Selectors

/** Return list of user approval for displaying to user. */
const getUserApprovalList = (store: any): IUserApproval[] => store.userApprovals;

// -
// -------------------- Actions

const Actions = {
  USER_APPROVAL_SHOW: 'USER_APPROVAL_SHOW',
  USER_APPROVAL_DISMISS: 'USER_APPROVAL_DISMISS',
  USER_APPROVAL_GRANT: 'USER_APPROVAL_GRANT',
};

/** Show user approval to user.  */
const showUserApproval = (userApproval: IUserApproval): IPayloadAction<IUserApproval> => {
  return {
    type: Actions.USER_APPROVAL_SHOW,
    payload: userApproval,
  };
};

/** Dismiss existing user approval. */
const dismissUserApproval = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_APPROVAL_DISMISS,
    payload: {
      id,
    },
  };
};

/** Grant user approval and start service. */
const grantUserApproval = (userApproval: IUserApproval): IPayloadAction<IUserApproval> => {
  return {
    type: Actions.USER_APPROVAL_GRANT,
    payload: userApproval,
  };
};

// -
// -------------------- Side-effects

const grantUserApprovalMessageEffect = (action$: Observable<IPayloadAction<IUserApproval>>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_APPROVAL_GRANT;
    }),

    mergeMap((action) => {
      LOGGER.info(`Granting user approval for service ${action.payload.serviceId}`);

      const payload = action.payload;
      return of(payload.grantCallback()).pipe(
        map(() => payload)
      );
    }),

    map((userApproval) => {
      return dismissUserApproval(userApproval.id);
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error granting user approval', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const userApprovals = (state: IUserApproval[] = [], action: IPayloadAction<IUserApproval>) => {
  // ----- show approval
  if (action.type === Actions.USER_APPROVAL_SHOW) {
    const newItem = action.payload;
    const existing = state.find((item) => item.id === newItem.id);

    return existing ? state : [...state, newItem];
  }
  // ----- dismiss approval
  else if (action.type === Actions.USER_APPROVAL_DISMISS) {
    const removeItem = action.payload;

    return state.filter((item) => item.id !== removeItem.id);
  }

  return state;
};

// --
// ----- Store object

const UserApprovalBusinessStore = {
  actions: {
    showUserApproval, dismissUserApproval, grantUserApproval,
  },

  selectors: {
    getUserApprovalList,
  },

  effects: {
    grantUserApprovalMessageEffect,
  },

  reducers: {
    userApprovals,
  },
};

// --
// ----- Exports

export default UserApprovalBusinessStore;
