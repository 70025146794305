import ITutoringSession from '@src/model/session/TutoringSession';
import { TutoringSessionEndStatusEnum } from '@src/model/session/TutoringSessionEndStatus';
import ITutoringSessionParticipant from '@src/model/session/TutoringSessionParticipant';
import { TutoringSessionParticipantRole } from '@src/model/session/TutoringSessionParticipantRole';
import { TutoringSessionParticipantStatusEnum } from '@src/model/session/TutoringSessionParticipantStatus';
import { TutoringSessionStatusEnum } from '@src/model/session/TutoringSessionStatus';
import { IUserInfo } from '@src/model/user/User';
import IUserDetails from '@src/model/user/UserDetails';
import { getCurrentUser } from '@src/service/business/login/loginBusinessService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

/**
 * Tutoring session model helper functions.
 */
export default class TutoringSessionModelHelper {
  /** Returns tutoring session participant with OWNER role. It presumes that there is only one owner. */
  static getOwner(session: ITutoringSession): ITutoringSessionParticipant | undefined {
    return session.sessionParticipants
      .filter((participant: ITutoringSessionParticipant) => {
        return participant.sessionParticipantRole.id === TutoringSessionParticipantRole.OWNER;
      })
      .shift();
  }

  /** Returns list of tutoring session's participants with PARTICIPANT role. */
  static getParticipants(session: ITutoringSession): ITutoringSessionParticipant[] {
    return session.sessionParticipants.filter((participant: ITutoringSessionParticipant) => {
      return participant.sessionParticipantRole.id === TutoringSessionParticipantRole.PARTICIPANT;
    });
  }

  /** Returns true if given user is participant of give session and is in OWNER role. */
  static isOwner(session: ITutoringSession, user?: IUserInfo | IUserDetails): boolean {
    const targetUser = user || getCurrentUser();
    const owner = TutoringSessionModelHelper.getOwnerUser(session);

    return owner != null && owner.id === targetUser.id;
  }

  /** Convenience method that returns user for owner participant. See TutoringSessionModelHelper.getOwner() for more details. */
  static getOwnerUser(session: ITutoringSession): IUserDetails | undefined {
    const owner = TutoringSessionModelHelper.getOwner(session);

    return owner != null ? owner.user : owner;
  }

  /** Convenience method that returns list of users for session participants. See TutoringSessionModelHelper.getParticipants() for more details. */
  static getParticipantUsers(session: ITutoringSession): IUserDetails[] {
    return TutoringSessionModelHelper.getParticipants(session).map((participant) => participant.user);
  }

  /** Return tutoring session participants for current user */
  static getCurrentParticipant(session: ITutoringSession, user?: IUserInfo | IUserDetails): ITutoringSessionParticipant | undefined {
    const targetUser = user || getCurrentUser();

    return session.sessionParticipants.find((participant) => {
      return participant.user.id === targetUser.id;
    });
  }

  /** Return tutoring session participants other than current user and an optional one */
  static getOtherParticipants(session: ITutoringSession, targetUser?: IUserInfo | IUserDetails): ITutoringSessionParticipant[] {
    const currentUser = getCurrentUser();
    const participants = session.sessionParticipants.filter((participant: ITutoringSessionParticipant) => {
      return participant.user.id !== currentUser.id;
    });

    if (targetUser) {
      return participants.filter((participant: ITutoringSessionParticipant) => {
        return participant.user.id !== targetUser.id;
      });
    } else {
      return participants;
    }
  }

  /** Return first tutoring session participant other than current user and an optional one */
  static getFirstOtherParticipant(session: ITutoringSession, user?: IUserInfo | IUserDetails): ITutoringSessionParticipant | undefined {
    // sort other paricipants arr to ensure picking "first" is more consistent
    return TutoringSessionModelHelper.getOtherParticipants(session, user).sort((u1, u2) => u1.user.id > u2.user.id ? 1 : -1).shift();
  }

  /** Return tutoring session participant users other than current user and an optional one */
  static getOtherParticipantUsers(session: ITutoringSession, user?: IUserInfo | IUserDetails): IUserDetails[] {
    return TutoringSessionModelHelper.getOtherParticipants(session, user).map((participant) => participant.user);
  }

  /** Return first tutoring session participant user other than current user and an optional one */
  static getFirstOtherParticipantUser(session: ITutoringSession, user?: IUserInfo | IUserDetails): IUserDetails | undefined {
    return TutoringSessionModelHelper.getFirstOtherParticipant(session, user)?.user;
  }

  /** Return if userId is in given session */
  static isUserInSession(session: ITutoringSession, userId: string): boolean {
    return TutoringSessionModelHelper.getOtherParticipantUsers(session).some((participant) => {
      return participant.id === userId;
    });
  }

  // ----- Helper methods for detecting session status

  /** Determine by session statuses if session is in confirmed state. */
  static isSessionConfirmed(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.CONFIRMED;
  }

  /** Determine by session statuses if session is currently in progress. */
  static isSessionInProgress(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.IN_PROGRESS;
  }

  /** Determine by session statuses if session is in unconfirmed state. */
  static isSessionUnconfirmed(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.SCHEDULED;
  }

  /** Determine by session statuses if session is in unconfirmed state and participant needs to accept/decline it. */
  static isSessionInvited(session: ITutoringSession, participant?: ITutoringSessionParticipant): boolean {
    const targetParticipant = participant || TutoringSessionModelHelper.getCurrentParticipant(session);
    return TutoringSessionModelHelper.isSessionUnconfirmed(session) && !!targetParticipant && targetParticipant.sessionParticipantStatus.id === TutoringSessionParticipantStatusEnum.INVITED;
  }

  /** Determine by session statuses if session is in unconfirmed state and participant is waiting an answer from other side. */
  static isSessionAccepted(session: ITutoringSession, participant?: ITutoringSessionParticipant): boolean {
    const targetParticipant = participant || TutoringSessionModelHelper.getCurrentParticipant(session);
    return TutoringSessionModelHelper.isSessionUnconfirmed(session) && !!targetParticipant && targetParticipant.sessionParticipantStatus.id === TutoringSessionParticipantStatusEnum.ACCEPTED;
  }

  /** Determine by session statuses if session has been ended successfully. */
  static isSessionSuccessfullyEnded(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.ENDED && session.sessionEndStatus != null && (session.sessionEndStatus.id === TutoringSessionEndStatusEnum.SUCCESSFUL || session.sessionEndStatus.id === TutoringSessionEndStatusEnum.SYSTEM_ENDED);
  }

  /** Determine by session statuses if session has been canceled. */
  static isSessionCanceled(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.ENDED && !TutoringSessionModelHelper.isSessionSuccessfullyEnded(session);
  }

  /** Determine by session statuses if session wasn't responded in time. */
  static isSessionUnresponded(session: ITutoringSession): boolean {
    return session.sessionStatus.id === TutoringSessionStatusEnum.UNRESPONDED;
  }

  /** Determine by session statuses if session wasn't responded in time. */
  static canOpenRoom(session: ITutoringSession): boolean {
    return TutoringSessionModelHelper.isSessionConfirmed(session) || TutoringSessionModelHelper.isSessionInProgress(session);
  }

  /** Can participant be added to session. */
  static canAddParticipants(session: ITutoringSession, maxCount: number): boolean {
    return TutoringSessionModelHelper.isSessionConfirmed(session)
      && session.sessionParticipants.filter((participant) => {
        return [TutoringSessionParticipantStatusEnum.CANCELED, TutoringSessionParticipantStatusEnum.DECLINED].indexOf(participant.sessionParticipantStatus.id) !== -1;
      }).length <= maxCount;
  }

  /** Create link to session participant invitation page. This is not API call, this link to FE page which will then call API. */
  static createSessionInvitationLink(session: ITutoringSession): string {
    return UrlBuilderFactory.createAbsoluteApplicationBuilder()
      .urlPart('sessions')
      .urlPart(session.tutoringRoomName)
      .urlPart('participate')
      .build();
  }
}
