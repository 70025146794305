import React from 'react';

import NumberFormatService from '@src/service/common/numberformat/NumberFormatService';

export interface IWithNumberFormatOwnProps {
  formatNumber: (value: number, format: string) => string;
  formatCurrency: (value: number, format?: string) => string;
  formatBytes: (value: number, format?: string) => string;
  formatPercentage: (value: number, format?: string) => string;
  formatTime: (value: number) => string;
  formatExponential: (value: number, format?: string) => string;
}

/**
 * Higher order component for injecting number format support to components.
 */
const withNumberFormat = <P extends object>(Component: React.ComponentType<P>) =>
  class NumberFormat extends React.Component<Exclude<P, IWithNumberFormatOwnProps>> {
    render() {
      const TypedComponent: React.ComponentType<P & IWithNumberFormatOwnProps> = Component as any;
      return <TypedComponent {
        ...this.props}
        formatNumber={NumberFormatService.formatNumber}
        formatCurrency={NumberFormatService.formatCurrency}
        formatBytes={NumberFormatService.formatBytes}
        formatPercentage={NumberFormatService.formatPercentage}
        formatTime={NumberFormatService.formatTime}
        formatExponential={NumberFormatService.formatExponential}
      />;
    }
  };

export default withNumberFormat;
