import { empty, Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import ApiResponseErrorException, { ApiResponseErrorCode } from '@src/service/api/ApiResponseErrorException';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';

import { IReview, IReviewCreate } from '@src/model/common/Review';
import ITutor from '@src/model/tutor/Tutor';
import { ITutorCalendar } from '@src/model/tutor/TutorCalendar';
import { ITutorDetails } from '@src/model/tutor/TutorDetails';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { getLogger } from '@src/service/util/logging/logger';
import { ITutorListFilter } from './tutorListBusinessStore';

const LOGGER = getLogger('tutorBusinessStore');

// -
// -------------------- Types&Consts

const REVIEW_LIST_FILTER_NAME = 'REVIEW_LIST_FILTER_NAME';

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface ITutorReviewListFilter {
  [key: string]: any;
  // TODO: set specific filter props
}
export interface ITutorReviewPermissionFetchParams {
  objectId: string;
  objectType: string;
}

export interface ITutorReviewPermissionState {
  allowed: boolean;
}
// -
// -------------------- Selectors

const getTutor = (store: any): ITutor => store.tutor;

const getTutorCalendar = (store: any): ITutorCalendar => store.tutorCalendar;

const getTutorDetails = (store: any): ITutorDetails => store.tutorDetails;

const getTutorReviewList = (store: any): ICollectionData<IReview> => store.tutorReviewList;
const getReviewListFilter = (store: any): ITutorReviewListFilter => ListFilterBusinessStore.selectors.getListFilter(store, REVIEW_LIST_FILTER_NAME);
const getReviewPermission = (store: any): ITutorReviewPermissionState => store.tutorReviewPermission;

// -
// -------------------- Actions

const Actions = {
  TUTOR_FETCH: 'TUTOR_FETCH',
  TUTOR_LOAD: 'TUTOR_LOAD',
  TUTOR_CLEAR: 'TUTOR_CLEAR',
  TUTOR_CALENDAR_FETCH: 'TUTOR_CALENDAR_FETCH',
  TUTOR_CALENDAR_UPDATE: 'TUTOR_CALENDAR_UPDATE',
  TUTOR_CALENDAR_LOAD: 'TUTOR_CALENDAR_LOAD',
  TUTOR_CALENDAR_CLEAR: 'TUTOR_CALENDAR_CLEAR',
  TUTOR_DETAILS_FETCH: 'TUTOR_DETAILS_FETCH',
  TUTOR_DETAILS_CREATE: 'TUTOR_DETAILS_CREATE',
  TUTOR_DETAILS_UPDATE: 'TUTOR_DETAILS_UPDATE',
  TUTOR_DETAILS_LOAD: 'TUTOR_DETAILS_LOAD',
  TUTOR_DETAILS_CLEAR: 'TUTOR_DETAILS_CLEAR',
  TUTOR_REVIEW_LIST_FETCH: 'TUTOR_REVIEW_LIST_FETCH',
  TUTOR_REVIEW_LIST_LOAD: 'TUTOR_REVIEW_LIST_LOAD',
  TUTOR_REVIEW_LIST_CLEAR: 'TUTOR_REVIEW_LIST_CLEAR',
  TUTOR_REVIEW_SEND: 'TUTOR_REVIEW_SEND',
  TUTOR_REVIEW_PERMISSION_FETCH: 'TUTOR_REVIEW_PERMISSION_FETCH',
  TUTOR_REVIEW_PERMISSION_LOAD: 'TUTOR_REVIEW_PERMISSION_LOAD',
  TUTOR_REVIEW_PERMISSION_CLEAR: 'TUTOR_REVIEW_PERMISSION_CLEAR',
};

const fetchTutor = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_FETCH,
    payload: params,
  };
};

const loadTutor = (data: ITutor): IPayloadAction<ITutor> => {
  return {
    type: Actions.TUTOR_LOAD,
    payload: data,
  };
};

const clearTutor = (): ILemonAction => {
  return {
    type: Actions.TUTOR_CLEAR,
  };
};

const fetchTutorCalendar = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_CALENDAR_FETCH,
    payload: params,
  };
};

const updateTutorCalendar = (id: string, data: ITutorCalendar): IPayloadAction<IIdDataPayload<ITutorCalendar>> => {
  return {
    type: Actions.TUTOR_CALENDAR_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

const loadTutorCalendar = (data: ITutorCalendar): IPayloadAction<ITutorCalendar> => {
  return {
    type: Actions.TUTOR_CALENDAR_LOAD,
    payload: data,
  };
};

const clearTutorCalendar = (): ILemonAction => {
  return {
    type: Actions.TUTOR_CALENDAR_CLEAR,
  };
};

const fetchTutorDetails = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.TUTOR_DETAILS_FETCH,
    payload: params,
  };
};

const createTutorDetails = (id: string, data: ITutorDetails): IPayloadAction<IIdDataPayload<ITutorDetails>> => {
  return {
    type: Actions.TUTOR_DETAILS_CREATE,
    payload: {
      id,
      data,
    },
  };
};

const updateTutorDetails = (id: string, data: ITutorDetails): IPayloadAction<IIdDataPayload<ITutorDetails>> => {
  return {
    type: Actions.TUTOR_DETAILS_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

const loadTutorDetails = (data: ITutorDetails): IPayloadAction<ITutorDetails> => {
  return {
    type: Actions.TUTOR_DETAILS_LOAD,
    payload: data,
  };
};

const clearTutorDetails = (): ILemonAction => {
  return {
    type: Actions.TUTOR_DETAILS_CLEAR,
  };
};

// const fetchTutorReviewList = (params: ICollectionFetchPayload<ITutorReviewListFilter>): IPayloadAction<ICollectionFetchPayload<ITutorReviewListFilter>> => {
const fetchTutorReviewList = (tutorId: string, listFilter: ITutorListFilter, page: number, size: number): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ITutorReviewListFilter>>> => {
  return {
    type: Actions.TUTOR_REVIEW_LIST_FETCH,
    payload: {
      id: tutorId,
      data: {
        filter: listFilter,
        page,
        size,
        sort: [], // sorting is not used on this list
      },
    },
  };
};

const loadTutorReviewList = (data: ICollectionData<IReview>): IPayloadAction<ICollectionData<IReview>> => {
  return {
    type: Actions.TUTOR_REVIEW_LIST_LOAD,
    payload: data,
  };
};

const clearTutorReviewList = (): ILemonAction => {
  return {
    type: Actions.TUTOR_REVIEW_LIST_CLEAR,
  };
};

const fetchTutorReviewPermision = (params: ITutorReviewPermissionFetchParams): IPayloadAction<ITutorReviewPermissionFetchParams> => {
  return {
    type: Actions.TUTOR_REVIEW_PERMISSION_FETCH,
    payload: params,
  };
};

const loadTutorReviewPermision = (data: ITutorReviewPermissionState): IPayloadAction<ITutorReviewPermissionState> => {
  return {
    type: Actions.TUTOR_REVIEW_PERMISSION_LOAD,
    payload: data,
  };
};

const clearTutorReviewPermision = (): ILemonAction => {
  return {
    type: Actions.TUTOR_REVIEW_PERMISSION_CLEAR,
  };
};

const sendReview = (data: IReviewCreate): IPayloadAction<IReviewCreate> => {
  return {
    type: Actions.TUTOR_REVIEW_SEND,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchTutorEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutor(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching tutor', error);
      return o;
    })
  );
};

const fetchTutorCalendarEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_CALENDAR_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .fetchSubobject(id, 'calendar')
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorCalendar(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching tutor calendar', error);
      return o;
    })
  );
};

const updateTutorCalendarEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ITutorCalendar>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_CALENDAR_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .updateSubobject(id, 'calendar', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorCalendar(data);
    }),

    reportCaughtMessage((error: any) =>
      createApiResponseUserFeedbackError(
        error,
        {
          TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING: 'TUTOR_PROFILE_CALENDAR.EDIT_ERROR.TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING',
        },
        'TUTOR_PROFILE_CALENDAR.ERROR_MESSAGE.TUTOR_CALENDAR_UPDATE'
      )
    ),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating tutor calendar', error);
      return o;
    })
  );
};

const fetchTutorDetailsEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_DETAILS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .fetchSubobject(id, 'details')
        .pipe(
          actionThunk(action),

          stopGlobalProgress(),

          catchError((error: any, o: Observable<any>) => {
            if (error instanceof ApiResponseErrorException && error.code === ApiResponseErrorCode.TUTOR_DETAILS_NOT_FOUND) {
              return empty();
            } else {
              throw error;
            }
          })
        );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorDetails(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching tutor details', error);
      return o;
    })
  );
};

const createTutorDetailsEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ITutorDetails>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_DETAILS_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .createSubobject(id, 'details', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorDetails(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error creating tutor details', error);
      return o;
    })
  );
};

const updateTutorDetailsEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ITutorDetails>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_DETAILS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .updateSubobject(id, 'details', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorDetails(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating tutor details', error);
      return o;
    })
  );
};

const fetchTutorReviewListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ITutorReviewListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_REVIEW_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Tutor')
        .fetchSubentityList(payload.id, 'Review', payload.data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorReviewList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching review list', error);

      return o;
    })
  );
};

const sendReviewEffect = (action$: Observable<IPayloadAction<IReviewCreate>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_REVIEW_SEND;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Review')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_REVIEWS.ERROR_MESSAGE.REVIEW_SEND')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error sending review', error);
      return o;
    })
  );
};

const fetchTutorReviewPermissionEffect = (action$: Observable<IPayloadAction<ITutorReviewPermissionFetchParams>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_REVIEW_PERMISSION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = action.payload;

      return EntityApiServiceRegistry.getService('Review')
        .fetchMethod('permission', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorReviewPermision(data);
    }),

    // TODO: should this result with user error?!
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching review permissions', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const tutor = (state: ITutor | null = null, action: IPayloadAction<ITutor>) => {
  if (action.type === Actions.TUTOR_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_CLEAR) {
    return null;
  }

  return state;
};

const tutorCalendar = (state: ITutorCalendar | null = null, action: IPayloadAction<ITutorCalendar>) => {
  if (action.type === Actions.TUTOR_CALENDAR_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_CALENDAR_CLEAR) {
    return null;
  }

  return state;
};

const tutorDetails = (state: ITutorDetails | null = null, action: IPayloadAction<ITutorDetails>) => {
  if (action.type === Actions.TUTOR_DETAILS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_DETAILS_CLEAR) {
    return null;
  }

  return state;
};

const tutorReviewList = (state: ICollectionData<IReview> | null = null, action: IPayloadAction<ICollectionData<IReview>>) => {
  if (action.type === Actions.TUTOR_REVIEW_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_REVIEW_LIST_CLEAR) {
    return null;
  }

  return state;
};

const tutorReviewPermission = (state: ITutorReviewPermissionState | null = null, action: IPayloadAction<ITutorReviewPermissionState>) => {
  if (action.type === Actions.TUTOR_REVIEW_PERMISSION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.TUTOR_REVIEW_PERMISSION_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const TutorBusinessStore = {
  actions: {
    fetchTutor,
    loadTutor,
    clearTutor,
    fetchTutorCalendar,
    updateTutorCalendar,
    loadTutorCalendar,
    clearTutorCalendar,
    fetchTutorDetails,
    createTutorDetails,
    updateTutorDetails,
    loadTutorDetails,
    clearTutorDetails,
    fetchTutorReviewList,
    loadTutorReviewList,
    clearTutorReviewList,
    sendReview,
    fetchTutorReviewPermision,
    loadTutorReviewPermision,
    clearTutorReviewPermision,
  },

  selectors: {
    getTutor,
    getTutorCalendar,
    getTutorDetails,
    getTutorReviewList,
    getReviewListFilter,
    getReviewPermission,
  },

  effects: {
    fetchTutorEffect,
    fetchTutorCalendarEffect,
    updateTutorCalendarEffect,
    fetchTutorDetailsEffect,
    createTutorDetailsEffect,
    updateTutorDetailsEffect,
    fetchTutorReviewListEffect,
    sendReviewEffect,
    fetchTutorReviewPermissionEffect,
  },

  reducers: {
    tutor,
    tutorCalendar,
    tutorDetails,
    tutorReviewList,
    tutorReviewPermission,
  },
};

// --
// ----- Exports

export default TutorBusinessStore;
