import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('PubSubManager');

// unique counter
let UNIQUE_ID = 0;


/** Describes component message structure. */
export interface IPubSubMessage<T = any> {
  type: string;
  payload: T;
}

export type IPubSubMessageListener = <T>(message: IPubSubMessage<T>) => void;
export type IPubSubMessageUnsubscriber = () => void;


/** Interface for pubsub service implementations */
export interface IPubSubService {
  /** Send message to subscribed listeners. */
  publish: (message: IPubSubMessage) => void;

  /** Subscribe message consumer listener. Returns unsubscribe function. */
  subscribe: <T>(listener: IPubSubMessageListener) => IPubSubMessageUnsubscriber;

  /** Unsubscribe all listeners. */
  unsubscribeAll: () => void;
}


/** Sevice provides generic publish-subscribe functionality. */
export default class PubSubManager implements IPubSubService {

  private name: string;
  private subscribers: { [key: string]: IPubSubMessageListener } = {};

  constructor(name: string) {
    this.name = name;
  }

  /** Send message to subscribed listeners. */
  publish(message: IPubSubMessage) {
    Object.keys(this.subscribers).forEach((key) => {
      let subscriber;
      try {
        subscriber = this.subscribers[key];
        subscriber(message);
      }
      catch (err) {
        LOGGER.error(`Error executing subscriber in ${this.toString()}: ${subscriber}}`);
      }
    });
  }

  /** Subscribe message consumer listener. Returns unsubscribe function. */
  subscribe(listener: IPubSubMessageListener): IPubSubMessageUnsubscriber {
    const nextSubscriberId = UNIQUE_ID++;

    this.subscribers = { ...this.subscribers, [nextSubscriberId]: listener };

    return () => {
      const newSubscribers = {
        ...this.subscribers,
      };
      delete newSubscribers[nextSubscriberId];
      this.subscribers = newSubscribers;
    };
  }

  /** Unsubscribe all listeners. */
  unsubscribeAll() {
    this.subscribers = {};
  }

  toString() {
    return `PubSub@[${this.name}]`;
  }
}
