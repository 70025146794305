import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import { persistReducer, persistStore } from 'redux-persist';

import { createLogger } from 'redux-logger';

import { createStorage } from '@src/service/util/persist/storage';
import { PersistStorageType } from '@src/service/util/persist/types';

// TODO: add redux dev tools?

import AppMessagingBusinessStore from '@src/service/business/common/appMessagingBusinessStore';
import { CollectionBusinessStore } from '@src/service/business/common/collectionBusinessStore';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { FileListBusinessStore } from '@src/service/business/file/fileListBusinessStore';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import MessagingBusinessStore from '@src/service/business/messaging/messagingBusinessStore';
import TutoringRoomBusinessStore from '@src/service/business/room/tutoringRoomBusinessStore';
import DashboardBusinessStore from '@src/service/business/session/dashboardBusinessStore';
import RelatedSessionBusinessStore from '@src/service/business/session/relatedSessionBusinessStore';
import SessionBusinessStore from '@src/service/business/session/sessionBusinessStore';
import TimelineBusinessStore from '@src/service/business/session/timelineBusinessStore';
import StatisticsBusinessStore from '@src/service/business/statistics/statisticsBusinessStore';
import TutorBusinessStore from '@src/service/business/user/tutorBusinessStore';
import { TutorListBusinessStore } from '@src/service/business/user/tutorListBusinessStore';
import UserBusinessStore from '@src/service/business/user/userBusinessStore';
import UserListBusinessStore from '@src/service/business/user/userListBusinessStore';
import UserApprovalBusinessStore from '@src/service/business/userapproval/userApprovalBusinessStore';
import UserSettingsBusinessStore from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

// list of all applied middlewares
const storeMiddleware = [];

const epicMiddleware = createEpicMiddleware();
storeMiddleware.push(epicMiddleware);

const rootEpic = combineEpics(
  // TODO: fix typings - forcing epis to "any" type is just a quick fix because redux'es Action is not assignable to our own IPayloadAction
  ...Object.keys(CollectionBusinessStore.effects).map((key) => (CollectionBusinessStore.effects as any)[key]),
  ...Object.keys(UserFeedbackBusinessStore.effects).map((key) => (UserFeedbackBusinessStore.effects as any)[key]),
  ...Object.keys(UserApprovalBusinessStore.effects).map((key) => (UserApprovalBusinessStore.effects as any)[key]),
  ...Object.keys(AppMessagingBusinessStore.effects).map((key) => (AppMessagingBusinessStore.effects as any)[key]),

  ...Object.keys(LoginBusinessStore.effects).map((key) => (LoginBusinessStore.effects as any)[key]),
  ...Object.keys(TutorListBusinessStore.effects).map((key) => (TutorListBusinessStore.effects as any)[key]),
  ...Object.keys(SessionBusinessStore.effects).map((key) => (SessionBusinessStore.effects as any)[key]),
  ...Object.keys(FileListBusinessStore.effects).map((key) => (FileListBusinessStore.effects as any)[key]),
  ...Object.keys(TutoringRoomBusinessStore.effects).map((key) => (TutoringRoomBusinessStore.effects as any)[key]),
  ...Object.keys(TimelineBusinessStore.effects).map((key) => (TimelineBusinessStore.effects as any)[key]),
  ...Object.keys(UserBusinessStore.effects).map((key) => (UserBusinessStore.effects as any)[key]),
  ...Object.keys(TutorBusinessStore.effects).map((key) => (TutorBusinessStore.effects as any)[key]),
  ...Object.keys(MessagingBusinessStore.effects).map((key) => (MessagingBusinessStore.effects as any)[key]),
  ...Object.keys(StatisticsBusinessStore.effects).map((key) => (StatisticsBusinessStore.effects as any)[key]),
  ...Object.keys(DashboardBusinessStore.effects).map((key) => (DashboardBusinessStore.effects as any)[key]),
  ...Object.keys(UserListBusinessStore.effects).map((key) => (UserListBusinessStore.effects as any)[key]),
  ...Object.keys(RelatedSessionBusinessStore.effects).map((key) => (RelatedSessionBusinessStore.effects as any)[key]),
  ...Object.keys(UserSettingsBusinessStore.effects).map((key) => (UserSettingsBusinessStore.effects as any)[key])
);

const rootReducer = combineReducers<any, any>({
  ...(CollectionBusinessStore.reducers),
  ...(UserFeedbackBusinessStore.reducers),
  ...(UserApprovalBusinessStore.reducers),
  ...(AppMessagingBusinessStore.reducers),
  ...(LoginBusinessStore.reducers),
  ...(ListFilterBusinessStore.reducers),
  ...(TutorListBusinessStore.reducers),
  ...(SessionBusinessStore.reducers),
  ...(FileListBusinessStore.reducers),
  ...(TutoringRoomBusinessStore.reducers),
  ...(TimelineBusinessStore.reducers),
  ...(UserBusinessStore.reducers),
  ...(TutorBusinessStore.reducers),
  ...(MessagingBusinessStore.reducers),
  ...(StatisticsBusinessStore.reducers),
  ...(DashboardBusinessStore.reducers),
  ...(UserListBusinessStore.reducers),
  ...(RelatedSessionBusinessStore.reducers),
  ...(UserSettingsBusinessStore.reducers),
});

// logger
const reduxLoggerEnabled = AppConfigService.getValue('logging.redux.enabled');
if (!!reduxLoggerEnabled) {
  const loggerMiddleware = createLogger(AppConfigService.getValue('logging.redux.config'));
  storeMiddleware.push(loggerMiddleware);
}

// persist
// TODO: storage DB names should be synched with ServiceWorkers
const storage = createStorage(PersistStorageType.IndexedDb, {
  name: 'Lemon Offline Storage',
  storeName: 'lemon_offline_store_db',
});

const persistConfig = {
  key: 'appStore',
  storage,
  // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
  whitelist: ['messagingMessageList', 'userSettingsView'],
};
const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedRootReducer,
  compose(
    applyMiddleware(...storeMiddleware)
  )
);

const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export const getStore = (): Store => {
  return store;
};

export const getPersistor = () => {
  return persistor;
};
