import { NamespaceHelper } from './NamespaceHelper';

/** Utility functions for handling nested config values. */
export class ConfigUtils {

  static joinPath(...parts: string[]): string {
    return NamespaceHelper.joinParts(parts);
  }

  static splitPath(path: string): string[] {
    return NamespaceHelper.splitParts(path);
  }

  static reducePath(state: any, selector: string | string[]) {
    const path: string[] = Array.isArray(selector) ? selector as string[] : NamespaceHelper.splitParts(selector as string);

    // ignore empty paths
    if (!path || path.length === 0) {
      return undefined;
    }

    return path.reduce((current, key, index) => {
      const next = (current !== undefined) ? current[key] : undefined;
      return next;
    },
      state
    );
  }
}
