import IFile from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';
import IFolder from '@src/model/file/Folder';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

export default class FileUtils {
  static isFolder = (arg: IFileSystemElement): arg is IFolder => {
    return arg.mimeType === 'application/vnd.lemon.folder';
  };

  static isFile = (arg: IFileSystemElement): arg is IFile => {
    return arg.mimeType !== 'application/vnd.lemon.folder';
  };

  static isFileImage(file: IFile) {
    return file.mimeType.startsWith('image/');
  }

  static getFileLink = (file: IFile) => {
    return UrlBuilderFactory.buildApiFileUrl(file.id);
  };
}
