import { createHistory, History } from 'history';
import React from 'react';
import { IndexRedirect, Route, Router, useRouterHistory } from 'react-router';

import AppContainer from '@src/components/app/AppContainer';
import AdminLayout from '@src/components/app/layout/AdminLayout';
import AppLayout from '@src/components/app/layout/AppLayout';
import FullPageLayout from '@src/components/app/layout/FullPageLayout';
import { adminRouteGuard } from '@src/components/common/admin/AdminRouteGuard';
import { authRouteGuard } from '@src/components/common/auth/AuthRouteGuard';
// --- statically loaded pages
// import ActivationPage from '@src/components/page/ActivationPage';
// import ActivitiesListPage from '@src/components/page/ActivitiesListPage';
// import AdminReviewListPage from '@src/components/page/AdminReviewListPage';
// import AdminSessionListPage from '@src/components/page/AdminSessionListPage';
// import ChangePasswordPage from '@src/components/page/ChangePasswordPage';
// import DashboardPage from '@src/components/page/DashboardPage';
// import FavouritesPage from '@src/components/page/FavouritesPage';
// import HomePage from '@src/components/page/HomePage';
// import InstructionsPage from '@src/components/page/InstructionsPage';
// import LoginPage from '@src/components/page/LoginPage';
// import PrivacyPolicyPage from '@src/components/page/PrivacyPolicyPage';
// import RegistrationPage from '@src/components/page/RegistrationPage';
// import ResetPasswordPage from '@src/components/page/ResetPasswordPage';
// import ResetPasswordRequestPage from '@src/components/page/ResetPasswordRequestPage';
// import MultisessionViewPage from '@src/components/page/session/MultisessionViewPage';
// import SessionCalendarPage from '@src/components/page/session/SessionCalendarPage';
// import SessionCreateDialog from '@src/components/page/session/SessionCreateDialog';
// import TutoringSessionReviewPage from '@src/components/page/session/TutoringSessionReviewPage';
// import TutorListPage from '@src/components/page/TutorListPage';
// import TutorProfilePage from '@src/components/page/TutorProfilePage';
// import UserFileRepositoryPage from '@src/components/page/UserFileRepositoryPage';
// import UserInfoPage from '@src/components/page/UserInfoPage';
// import UserListPage from '@src/components/page/UserListPage';
// import UserProfilePage from '@src/components/page/UserProfilePage';
// import WelcomePage from '@src/components/page/WelcomePage';

import withPageLazyLoad from '@src/components/page/withPageLazyLoad';
import AppConfigService from '@src/service/common/AppConfigService';
import { initAuthApp, initPublicApp } from '@src/service/common/appInit';
import SessionAddParticipantPage from '@src/components/page/session/SessionAddParticipantPage';

// ----- Lazy loaded routes - webpack will create separate chunks for these routes in order to prevent loading something we don't need right at the start
// picked pages are subject to experimentation in order to determine which ones will gain better performance
const TutoringRoomPage = withPageLazyLoad(React.lazy(() => import('../page/TutoringRoomPage')));
const TutoringWhiteroomPage = withPageLazyLoad(React.lazy(() => import('../page/TutoringWhiteroomPage')));
const UserStatisticsPage = withPageLazyLoad(React.lazy(() => import('../page/UserStatisticsPage')));
const AdminStatisticsPage = withPageLazyLoad(React.lazy(() => import('../page/AdminStatisticspage')));
// --- other pages
const ActivationPage = withPageLazyLoad(React.lazy(() => import('../page/ActivationPage')));
const ActivitiesListPage = withPageLazyLoad(React.lazy(() => import('../page/ActivitiesListPage')));
const AdminReviewListPage = withPageLazyLoad(React.lazy(() => import('../page/AdminReviewListPage')));
const AdminSessionListPage = withPageLazyLoad(React.lazy(() => import('../page/AdminSessionListPage')));
const ChangePasswordPage = withPageLazyLoad(React.lazy(() => import('../page/ChangePasswordPage')));
const DashboardPage = withPageLazyLoad(React.lazy(() => import('../page/DashboardPage')));
const FavouritesPage = withPageLazyLoad(React.lazy(() => import('../page/FavouritesPage')));
const HomePage = withPageLazyLoad(React.lazy(() => import('../page/HomePage')));
const InstructionsPage = withPageLazyLoad(React.lazy(() => import('../page/InstructionsPage')));
const LoginPage = withPageLazyLoad(React.lazy(() => import('../page/LoginPage')));
const PrivacyPolicyPage = withPageLazyLoad(React.lazy(() => import('../page/PrivacyPolicyPage')));
const RegistrationPage = withPageLazyLoad(React.lazy(() => import('../page/RegistrationPage')));
const ResetPasswordPage = withPageLazyLoad(React.lazy(() => import('../page/ResetPasswordPage')));
const ResetPasswordRequestPage = withPageLazyLoad(React.lazy(() => import('../page/ResetPasswordRequestPage')));
const MultisessionViewPage = withPageLazyLoad(React.lazy(() => import('../page/session/MultisessionViewPage')));
const SessionCalendarPage = withPageLazyLoad(React.lazy(() => import('../page/session/SessionCalendarPage')));
const SessionCreateDialog = withPageLazyLoad(React.lazy(() => import('../page/session/SessionCreateDialog')));
const TutoringSessionReviewPage = withPageLazyLoad(React.lazy(() => import('../page/session/TutoringSessionReviewPage')));
const TutorListPage = withPageLazyLoad(React.lazy(() => import('../page/TutorListPage')));
const TutorProfilePage = withPageLazyLoad(React.lazy(() => import('../page/TutorProfilePage')));
const UserFileRepositoryPage = withPageLazyLoad(React.lazy(() => import('../page/UserFileRepositoryPage')));
const UserInfoPage = withPageLazyLoad(React.lazy(() => import('../page/UserInfoPage')));
const UserListPage = withPageLazyLoad(React.lazy(() => import('../page/UserListPage')));
const UserProfilePage = withPageLazyLoad(React.lazy(() => import('../page/UserProfilePage')));
const WelcomePage = withPageLazyLoad(React.lazy(() => import('../page/WelcomePage')));


// configure browser history obj - DO NOT use epxported "react-router/history" obj because we're creating new instance here
const browserHistory: History = useRouterHistory(createHistory)({
  basename: AppConfigService.getValue('app.baseUrl'),
});

/** Exports create browser history instance. This should be used ONLY when withRouter HOCis not available. */
export const historyInstance = () => {
  return browserHistory;
};

const appRouter = (
  <Router key={Math.random()} history={browserHistory}>
    <Route path="/" onEnter={initPublicApp} component={(props) => <AppContainer {...props} />}>
      <IndexRedirect to="/welcome" />

      {/* ---------- public routes ---------- */}

      {/* welcome page requires special parameter, not very slick, but ... it works */}
      <Route component={(props) => <AppLayout {...props} welcomePage={true} />}>
        <Route path="welcome" component={WelcomePage} />
      </Route>

      <Route component={AppLayout}>
        <Route path="login" component={LoginPage} />
        <Route path="resetpasswordrequest" component={ResetPasswordRequestPage} />
        <Route path="resetpassword" component={ResetPasswordPage} />
        <Route path="registration" component={RegistrationPage} />
        <Route path="activation" component={ActivationPage} />
        <Route path="privacy" component={PrivacyPolicyPage} />
      </Route>

      {/* ---------- restricted routes ---------- */}

      <Route onEnter={authRouteGuard}>
        <Route onEnter={initAuthApp}>
          <Route component={FullPageLayout}>
            <Route path="tutoringroom/:tutoringSessionId" component={TutoringRoomPage}>
              <Route path="$session/create/:userId" component={SessionCreateDialog} />
            </Route>
            <Route path="whiteroom/:tutoringSessionId" component={TutoringWhiteroomPage} />
          </Route>
          <Route component={AppLayout}>
            <Route path="home" component={HomePage}>
              {/* example dialog nested route */}
              <Route path="$session/create" component={SessionCreateDialog} />
            </Route>
            <Route path="tutors" component={TutorListPage}>
              <Route path="$session/create/:userId" component={SessionCreateDialog} />
            </Route>
            <Route path="userInfo" component={UserInfoPage} />
            <Route path="repository" component={UserFileRepositoryPage} />
            <Route path="repository/:folderId" component={UserFileRepositoryPage} />
            <Route path="sessions/:participationId/participate" component={SessionAddParticipantPage} />
            <Route path="sessions" component={MultisessionViewPage}>
              <Route path="$session/create/:userId" component={SessionCreateDialog} />
            </Route>
            {/*<Route path="session/:tutoringSessionId" component={TutoringSessionViewPage} />*/}
            <Route path="sessionReview/:tutoringSessionId" component={TutoringSessionReviewPage} />

            <Route path="calendar" component={SessionCalendarPage} />
            <Route path="statistics" component={UserStatisticsPage} />
            <Route path="instructions" component={InstructionsPage} />
            <Route path="tutors/:userId" component={TutorProfilePage}>
              <Route path="$session/create" component={SessionCreateDialog} />
            </Route>
            <Route path="myprofile" component={UserProfilePage} />
            <Route path="favourites" component={FavouritesPage} />
            <Route path="changepassword" component={ChangePasswordPage} />
            <Route path="dashboard" component={DashboardPage} />

            <Route onEnter={adminRouteGuard} path="admin" component={AdminLayout}>
              <IndexRedirect to="users" />
              <Route path="users" component={UserListPage} />
              <Route path="activities" component={ActivitiesListPage} />
              <Route path="sessions" component={AdminSessionListPage} />
              <Route path="statistics" component={AdminStatisticsPage} />
              <Route path="reviews" component={AdminReviewListPage} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Router>
);

export default appRouter;
