import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import NavMenu from '@src/components/login/navmenu/NavMenu';
import { IUserInfo } from '@src/model/user/User';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';

// -- Prop types
// ----------
export interface INavMenuContainerPublicProps {}
interface INavMenuContainerStateProps {
  currentUser: IUserInfo;
}
interface INavMenuContainerDispatchProps {}
type INavMenuContainerProps = INavMenuContainerPublicProps & INavMenuContainerStateProps & INavMenuContainerDispatchProps & WithRouterProps;

interface INavMenuState {
  currentRoutePath: string | undefined;
}

// --
// ----- Component
class NavMenuContainer extends React.Component<INavMenuContainerProps, INavMenuState> {
  state: INavMenuState = {
    currentRoutePath: undefined,
  };

  componentDidMount() {
    this.setState({
      currentRoutePath: this.props.location.pathname,
    });
  }

  componentDidUpdate(prevProps: INavMenuContainerProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        currentRoutePath: this.props.location.pathname,
      });
    }
  }

  render() {
    return (
      <div className="lemon-navMenu__container">
        <NavMenu currentUser={this.props.currentUser} currentRoutePath={this.state.currentRoutePath} />
      </div>
    );
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): INavMenuContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default connect<INavMenuContainerStateProps, INavMenuContainerDispatchProps, INavMenuContainerPublicProps>(mapStateToProps)(withRouter(NavMenuContainer as any));
