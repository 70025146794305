import { Observable, forkJoin } from 'rxjs';

import { messagingServiceInitializer } from '@src/service/common/initialize/initializers/messaginServiceInitializer';
import { videoServiceInitializer } from '@src/service/common/initialize/initializers/videoServiceInitializer';
import { googleAnalyticsInitializer } from './initializers/googleAnalyticsInitializer';

/** Function that aggregates all service for public users initializers into one big observable. */
export const publicServiceInitializer = (): Observable<any> => {
  return forkJoin(
    googleAnalyticsInitializer(),
  );
};

/** Function that aggregates all service for authenticated users initializers into one big observable. */
export const authServiceInitializer = (): Observable<any> => {
  return forkJoin(
    messagingServiceInitializer(),
    videoServiceInitializer()
    // ... add other initializers
  );
};
