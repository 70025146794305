import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import { IRegistrationTokenPayload } from '@src/service/business/messaging/types';

export default class NotificationApiService extends EntityApiService<any> {

  static METHODS = {
    REGISTRATION_TOKEN: 'registrationtoken',
  };

  static create(entityName: string, api: IAbstractEntityApi<any, any>): NotificationApiService {
    return new NotificationApiService(entityName, api);
  }

  /** Register mesaging token on backend service required for communication. */
  registrationToken(token: IRegistrationTokenPayload): Observable<IRegistrationTokenPayload> {
    return this.entityApi.createMethod(this.entityName, NotificationApiService.METHODS.REGISTRATION_TOKEN, token).pipe(
      // check return value
      map((data) => {
        return data.payload;
      })
    );
  }

}
