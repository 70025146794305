import IFileSystemElement from '@src/model/file/FileSystemElement';
import IIdRef from '@src/model/common/IdRef';

export default interface IFile extends IFileSystemElement {
    size: number;
    permissions?: Array<IIdRef<FilePermissionEnum>>;
    type?: IIdRef<SessionFileTypeEnum>;
}


export enum SessionFileTypeEnum {
    TUTOR_PREPARATION = '1',
    STUDENT_PREPARATION = '2',
    HOMEWORK = '3',
    SESSION_SCREENSHOT = '4',
}

export enum FilePermissionEnum {
    VIEW = '1',
}