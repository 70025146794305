import { Observable, of } from 'rxjs';
import { catchError, delay, filter, map, tap } from 'rxjs/operators';

import { IIdPayload, IPayloadAction } from '@src/service/business/common/types';
import { LangUtils } from '@src/service/util/LangUtils';
import { getLogger } from '@src/service/util/logging/logger';
import { dispatchAction } from '@src/service/util/observable/operators';

const LOGGER = getLogger('appMessagingBusinessProvider');

// internal message counter - used to generate unique IDs if none is given
let APP_MESSAGE_COUNTER = 0;
const APP_MESSAGING_AUTOREMOVE_DELAY = 4000; // auto remove default delay (in millies)

/** Describes stored app message structure. */
export interface IAppMessage<P> {
  id: string;
  data: P;
}

/** Describes action payload for reporting user feedback message. */
export interface IAppMessageData<P> {
  /** Messaging unique ID which can be used to reference message later and eg. remove it. */
  id?: string;
  /** Message payload. */
  data?: P;
  /** Autoremove message after some timeout. If true, default timeout is used, if it's a number > 0, that number is used as a timeout. */
  autoRemove?: boolean | number;
}

/** Data structure holding app message and it's source ID. */
export interface IAppMessageSourcePayload<P> {
  sourceId: string;
  message: IAppMessageData<P>;
}
/** Data structure holding app message data and it's source ID. */
export interface IAppMessageSourceDataPayload<P> {
  sourceId: string;
  data: IAppMessageData<P>;
}

// --
// -------------------- Selectors

/** Return list of reported messages. */
const getAppMessages = <P>(sourceId: string, store: any): Array<IAppMessage<P>> => store.appMessagingMessages[sourceId];

// --
// -------------------- Actions

const Actions = {
  APP_MESSAGING_ADD_MESSAGE: 'APP_MESSAGING_ADD_MESSAGE',
  APP_MESSAGING_STORE_MESSAGE: 'APP_MESSAGING_STORE_MESSAGE',
  APP_MESSAGING_REMOVE_MESSAGE: 'APP_MESSAGING_REMOVE_MESSAGE',

  APP_MESSAGING_PROGRESS_STATUS: 'APP_MESSAGING_PROGRESS_STATUS',
};

/** Report user message and send it to feedback display. This action can trigger message side-effects like: autoremove, auto ID generation, ...  */
const addMessage = <P>(sourceId: string, msg: IAppMessageData<P>): IPayloadAction<IAppMessageSourceDataPayload<P>> => {
  return {
    type: Actions.APP_MESSAGING_ADD_MESSAGE,
    payload: {
      data: { ...msg },
      sourceId,
    },
  };
};

/** Store message in store and end it to feedback display. This action does not handle message side-effects. Use "addAppMessage" action for normal usage. */
const storeAppMessage = <P>(sourceId: string, msg: IAppMessage<P>): IPayloadAction<IAppMessageSourcePayload<P>> => {
  return {
    type: Actions.APP_MESSAGING_STORE_MESSAGE,
    payload: {
      message: { ...msg },
      sourceId,
    },
  };
};

/** Remove message from store and remove it or prevent it from displaying. */
const removeMessage = (sourceId: string, id: string): IPayloadAction<IAppMessageSourcePayload<IIdPayload>> => {
  return {
    type: Actions.APP_MESSAGING_REMOVE_MESSAGE,
    payload: {
      sourceId,
      message: { id },
    },
  };
};

// -
// -------------------- Side-effects

const addAppMessagingMessageEffect = (action$: Observable<IPayloadAction<IAppMessageSourceDataPayload<any>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.APP_MESSAGING_ADD_MESSAGE;
    }),

    tap((action) => {
      const msg = action.payload;

      // force message ID
      msg.data.id = msg.data.id != null ? msg.data.id : (++APP_MESSAGE_COUNTER).toString();
    }),

    // prevent repeating of the same message
    dispatchAction((action) => removeMessage(action.payload.sourceId, action.payload.id)),

    map((action) => {
      const msg = action.payload;

      // configure message autoremove
      let actionDelay = -1;
      if (msg.data.autoRemove != null) {
        if (LangUtils.isNumber(msg.data.autoRemove)) {
          actionDelay = msg.data.autoRemove;
        } else {
          actionDelay = APP_MESSAGING_AUTOREMOVE_DELAY;
        }
      }
      if (actionDelay > 0) {
        // create delayed action pipeline
        of(msg)
          .pipe(
            delay(actionDelay),

            dispatchAction(() => {
              return removeMessage(msg.sourceId, msg.data.id!); // we know that data id is not null because we've set it a few steps back
            })
          )
          .subscribe(LangUtils.noopFn);
      }

      // store message
      return storeAppMessage(msg.sourceId, msg.data as Required<IAppMessage<any>>);
    }),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error adding app message', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const appMessagingMessages = (state: { [sourceId: string]: Array<IAppMessageData<any>> } = {}, action: IPayloadAction<IAppMessageSourcePayload<any>>) => {
  // ----- STORE MESSAGE
  if (action.type === Actions.APP_MESSAGING_STORE_MESSAGE) {
    let sourceState = state[action.payload.sourceId];
    // create source messages state if its empty
    if (sourceState == null) {
      sourceState = [];
      state[action.payload.sourceId] = sourceState;
    }

    return {
      ...state,
      // append new message to source state
      [action.payload.sourceId]: [...sourceState, action.payload.message],
    };
  }
  // ----- Remove message
  else if (action.type === Actions.APP_MESSAGING_REMOVE_MESSAGE) {
    // find source state
    const sourceState = state[action.payload.sourceId];
    if (sourceState != null) {
      const messageId = action.payload.message.id;
      // ifnd message index (-1 if not found)
      const msgIndex = sourceState.reduce((accum, curr, idx) => (accum === -1 && curr.id === messageId ? idx : accum), -1);

      if (msgIndex !== -1) {
        return {
          ...state,
          // append new message to source state
          [action.payload.sourceId]: [...sourceState.slice(0, msgIndex), ...sourceState.slice(msgIndex + 1)],
        };
      }
    }
  }

  return state;
};

// --
// ----- Store object

const AppMessagingBusinessStore = {
  actions: {
    addMessage,
    storeAppMessage,
    removeMessage,
  },

  selectors: {
    getAppMessages,
  },

  effects: {
    reportUserFeedbackMessageEffect: addAppMessagingMessageEffect,
  },

  reducers: {
    appMessagingMessages,
  },
};

// --
// ----- Exports

export default AppMessagingBusinessStore;
