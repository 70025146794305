import { classNames } from '@src/components/common/util/classNames';
import AppConfigService from '@src/service/common/AppConfigService';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import React from 'react';

const SPINNER_DELAY = AppConfigService.getValue('app.progressStatusDelay');

// --
// ---------- Prop types

export interface IProgressStatusPublicProps {
  global?: boolean;
  size?: SpinSize;
  tip?: string;
}
type IProgressStatusProps = IProgressStatusPublicProps;

export interface IProgressStatusState {
  showSpinner: boolean;
}

// --
// ---------- Component

export default class ProgressStatus extends React.Component<IProgressStatusProps, IProgressStatusState> {
  static defaultProps: IProgressStatusPublicProps = {
    global: true,
    size: 'large',
  };

  state: IProgressStatusState = {
    showSpinner: false,
  };

  timeoutId: any;

  componentDidMount = () => {
    this.timeoutId = setTimeout(() => {
      this.setState({
        showSpinner: true,
      });
    }, SPINNER_DELAY);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutId);
  };

  render = () => {
    return (
      this.state.showSpinner && (
        <div className={this.getContainerCssClasses()}>
          {this.isGlobal() && <div className="lemon-userFeedback__progressStatusMask" data-test-id="lemon-userFeedback__loadingSpinner" />}
          <Spin size={this.props.size} tip={this.props.tip} />
        </div>
      )
    );
  };

  getContainerCssClasses(): string {
    return classNames({
      'lemon-userFeedback__progressStatusContainer': true,
      'lemon-userFeedback__progressStatusContainer--global': this.isGlobal(),
    });
  }

  isGlobal(): boolean {
    return this.props.global != null ? this.props.global : true;
  }
}
