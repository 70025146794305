import React, { SyntheticEvent } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import moment from 'moment';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISessionIncomingNoticeMessagingMessage, IMessagingMessage, MessagingMessageType, SessionMessageSentMessagingMessage, ISessionCreatedMessagingMessage, ISessionTimeChangeMessagingMessage, ISessionFileMessagingMessage, ISessionEndNoticeMessagingMessage } from '@src/model/messaging/messages';
import AppConfigService from '@src/service/common/AppConfigService';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('NotificationMessageContent');


const VALUE_LIST_SEPARATOR = ';';
const DATETIME_PATTERN_DATE_WITH_TIME = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const DATETIME_PATTERN_TIME = AppConfigService.getValue('dateTimeFormat.time');
const DATETIME_PATTERN_DATE = `${AppConfigService.getValue('dateTimeFormat.date')} ${AppConfigService.getValue('dateTimeFormat.dayOfWeekName')}`;


// -- Prop types
// ----------
export interface INotificationMessageContentPublicProps {
  message: IMessagingMessage;
  /** Enabled by default. Disable if notification is used in an already dismissable container. */
  showCloseButton?: boolean;

  onDismiss?: (id: string) => void;
  onClick?: (id: string) => void;
}
type INotificationMessageContentProps = INotificationMessageContentPublicProps & IWithLocalizeOwnProps & WithRouterProps;


// TODO: protection against long user names (some container with overflow and ellipsis)

// --
// ----- Component
class NotificationMessageContent extends React.Component<INotificationMessageContentProps> {
  render() {
    const notificationContent = this.getMessageContentNodes(this.props.message);

    if (notificationContent == null) {
      return null;
    }

    return (
      <div className="lemon-notificationMenu__notification lemon-notificationMenu__notification--clickable" onClick={this.onNotificationClick}>
        {this.shouldRenderCloseButton() && (
          <LemonIcon
            name="close"
            className="lemon-notificationMenu__closeButton"
            title={this.props.translate('NOTIFICATION_MENU.COMMON.DISMISS_BUTTON_TITLE')}
            onClick={this.onNotificationDismissClick}
          />
        )}

        {notificationContent}
      </div>
    );
  }

  onNotificationDismissClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.onDismiss) {
      this.props.onDismiss(this.props.message.id);
    }
  }

  onNotificationClick = (event: SyntheticEvent) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.message.id);
    }
  }

  shouldRenderCloseButton(): boolean {
    return this.props.showCloseButton == null || this.props.showCloseButton;
  }

  getMessageContentNodes = (message: IMessagingMessage): React.ReactNode => {
    // ----- Session messages

    // SESSION_INCOMING_NOTICE
    if (message.type === MessagingMessageType.SESSION_INCOMING_NOTICE) {
      const typedMessage = message as ISessionIncomingNoticeMessagingMessage;
      const formatedTime = this.formatSessionStartEndToTime(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.students ? this.formatValueList(typedMessage.students) : typedMessage.tutor;
      const incomingPeriod = parseInt(typedMessage.incomingPeriod, 10); // convert

      return (
        <React.Fragment>
          <LemonIcon name="clockCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">
            {incomingPeriod === 0 && (
              <React.Fragment>
                {this.props.translate('NOTIFICATION_MENU.SESSION_INCOMING_NOTICE_NOW.TITLE')}
              </React.Fragment>
            )}
            {incomingPeriod !== 0 && (
              <React.Fragment>
                {this.props.translate('NOTIFICATION_MENU.SESSION_INCOMING_NOTICE.TITLE', { incomingPeriod })}
              </React.Fragment>
            )}
          </div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }

    // SESSION_END_NOTICE
    else if (message.type === MessagingMessageType.SESSION_END_NOTICE) {
      const typedMessage = message as ISessionEndNoticeMessagingMessage;
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const endPeriod = parseInt(typedMessage.endPeriod, 10); // convert

      return (
        <React.Fragment>
          <LemonIcon name="clockCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">
            {this.props.translate('NOTIFICATION_MENU.SESSION_END_NOTICE.TITLE', { endPeriod })}
          </div>
          {/*
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}</div>
          */}
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }

    // SESSION_TIME_CHANGE
    else if (message.type === MessagingMessageType.SESSION_TIME_CHANGE) {
      const typedMessage = message as ISessionTimeChangeMessagingMessage;
      const formatedTime = this.formatSessionStartEndToFullDate(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.creator;

      return (
        <React.Fragment>
          <LemonIcon name="sync" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_TIME_CHANGE.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    // SESSION_CREATED
    else if (message.type === MessagingMessageType.SESSION_CREATED) {
      const typedMessage = message as ISessionCreatedMessagingMessage;
      const formatedTime = this.formatSessionStartEndToFullDate(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.creator;

      return (
        <React.Fragment>
          <LemonIcon name="plusCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_CREATED.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    // SESSION_CONFIRMATION
    else if (message.type === MessagingMessageType.SESSION_CONFIRMATION) {
      const typedMessage = message as ISessionTimeChangeMessagingMessage;
      const formatedTime = this.formatSessionStartEndToFullDate(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.creator;

      return (
        <React.Fragment>
          <LemonIcon name="checkCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_CONFIRMATION.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    // SESSION_CANCELATION
    else if (message.type === MessagingMessageType.SESSION_CANCELATION) {
      const typedMessage = message as ISessionTimeChangeMessagingMessage;
      const formatedTime = this.formatSessionStartEndToFullDate(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.creator;

      return (
        <React.Fragment>
          <LemonIcon name="closeCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_CANCELATION.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    // SESSION_DECLINED
    else if (message.type === MessagingMessageType.SESSION_DECLINED) {
      const typedMessage = message as ISessionTimeChangeMessagingMessage;
      const formatedTime = this.formatSessionStartEndToFullDate(typedMessage.startDateTime, typedMessage.endDateTime);
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const participants = typedMessage.creator;

      return (
        <React.Fragment>
          <LemonIcon name="closeCircle" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_DECLINED.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{formatedTime}, {participants}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }

    // ----- session content messages

    // SESSION_MESSAGE_SENT
    else if (message.type === MessagingMessageType.SESSION_MESSAGE_SENT) {
      const typedMessage = message as SessionMessageSentMessagingMessage;
      const participants = typedMessage.sender || this.props.translate('NOTIFICATION_MENU.COMMON.USER_DEFAULT');
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const content = typedMessage.message;

      return (
        <React.Fragment>
          <LemonIcon name="message" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TITLE')}</div>
          <div className="lemon-notificationMenu__notificationInfo">{participants} {this.props.translate('NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TEXT')}</div>

          <div className="lemon-notificationMenu__notificationContent">{content}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    // SESSION_FILE_ADDED
    else if (message.type === MessagingMessageType.SESSION_FILE_ADDED) {
      const typedMessage = message as ISessionFileMessagingMessage;
      const participants = typedMessage.creator || this.props.translate('NOTIFICATION_MENU.COMMON.USER_DEFAULT');
      const sentDateTime = this.formatNotificationDateTime(typedMessage.sentDateTime);
      const content = this.formatValueList(typedMessage.fileName);

      return (
        <React.Fragment>
          <LemonIcon name="fileText" className="lemon-notificationMenu__notificationIcon" />

          <div className="lemon-notificationMenu__notificationTitle">{this.props.translate('NOTIFICATION_MENU.SESSION_FILE_ADDED.TITLE')}
          </div>
          <div className="lemon-notificationMenu__notificationInfo">
            {participants} {this.props.translate('NOTIFICATION_MENU.SESSION_FILE_ADDED.TEXT')}
          </div>

          <div className="lemon-notificationMenu__notificationContent">{content}</div>
          <div className="lemon-notificationMenu__sentDateTime">{sentDateTime}</div>
        </React.Fragment>
      );
    }
    else {
      LOGGER.warn(`Unhandled messaging notification type "${message.type}"`);
      return;
    }
  }

  /** Display notification send date and time. */
  private formatNotificationDateTime(dateTime: string): string {
    const notificationDateTime = moment(dateTime).format(DATETIME_PATTERN_DATE_WITH_TIME);

    return notificationDateTime;
  }

  /** Display session start/end date as full date and time. */
  private formatSessionStartEndToFullDate(startDateTime: string, endDateTime: string): string {
    const startTime = moment(startDateTime).format(DATETIME_PATTERN_DATE);
    const startEndTime = this.formatSessionStartEndToTime(startDateTime, endDateTime);

    return `${startTime}, ${startEndTime}`;
  }

  /** Display session start/end date as time period (from - to). */
  private formatSessionStartEndToTime(startDateTime: string, endDateTime: string): string {
    const startTime = moment(startDateTime).format(DATETIME_PATTERN_TIME);
    const endTime = moment(endDateTime).format(DATETIME_PATTERN_TIME);

    return `${startTime} - ${endTime}`;
  }

  /** Convert string of values separated by SEPARATOR and prepare them for display. */
  private formatValueList(value: string): string {
    const list = (value || '').split(VALUE_LIST_SEPARATOR);
    return list.join(',');
  }
}

export default withLocalize<INotificationMessageContentPublicProps>(withRouter(NotificationMessageContent as any));
