import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NotificationInfoBarMessagingMessageItem from '@src/components/login/notificationinfo/messaging/NotificationInfoBarMessagingMessageItem';
import { IMessagingMessage } from '@src/model/messaging/messages';
import { IEducationArea } from '@src/model/user/EducationArea';
import { CollectionBusinessStore } from '@src/service/business/common/collectionBusinessStore';

// -- Prop types
// ----------
export interface INotificationInfoBarMessagingMessageItemContainerPublicProps {
  message: IMessagingMessage;
}
interface INotificationInfoBarMessagingMessageItemContainerDispatchProps {}
interface INotificationInfoBarMessagingMessageItemContainerStateProps {
  educationAreaList: IEducationArea[];
}
type INotificationInfoBarMessagingMessageItemContainerProps = INotificationInfoBarMessagingMessageItemContainerPublicProps & INotificationInfoBarMessagingMessageItemContainerStateProps & INotificationInfoBarMessagingMessageItemContainerDispatchProps & IWithLocalizeOwnProps;

// --
// ----- Component
class NotificationInfoBarMessagingMessageItemContainer extends React.Component<INotificationInfoBarMessagingMessageItemContainerProps> {
  render() {
    return <React.Fragment>{this.props.educationAreaList && this.props.message && <NotificationInfoBarMessagingMessageItem educationAreaList={this.props.educationAreaList} message={this.props.message} />}</React.Fragment>;
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): INotificationInfoBarMessagingMessageItemContainerStateProps => ({
  educationAreaList: CollectionBusinessStore.selectors.getCollectionContent(state, 'EducationArea'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): INotificationInfoBarMessagingMessageItemContainerDispatchProps => ({});

export default connect<INotificationInfoBarMessagingMessageItemContainerStateProps, INotificationInfoBarMessagingMessageItemContainerDispatchProps, INotificationInfoBarMessagingMessageItemContainerPublicProps>(mapStateToProps, mapDispatchToProps)(withLocalize(NotificationInfoBarMessagingMessageItemContainer as any));
