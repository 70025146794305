import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import LocalePicker from '@src/components/app/locale/LocalePicker';
import { MenuItemClickEvent } from '@src/components/common/admin/adminmenu/AdminMenu';
import UserSettingsBusinessStore from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { CookieManager } from '@src/service/util/CookieManager';

const localeCookieName = AppConfigService.getValue('cookies.locale.name');
const localeCookie = CookieManager.getCookie(localeCookieName);

// -- Prop types
// ----------
export interface ILocalePickerContainerOwnProps {
  standalone?: boolean;
}

export interface ILocalePickerContainerStateProps {
  userSettingsLocale?: string;
}

export interface ILocalePickerContainerDispatchProps {
  updateLocale: (locale: string) => void;
}
type ILocalePickerContainerProps = ILocalePickerContainerOwnProps & ILocalePickerContainerStateProps & ILocalePickerContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class LocalePickerContainer extends React.Component<ILocalePickerContainerProps> {
  render = () => {
    return <LocalePicker onLocaleChange={this.handleLocaleChange} standalone={this.props.standalone} locale={this.props.userSettingsLocale ? this.props.userSettingsLocale : localeCookie} />;
  };

  handleLocaleChange = (e: MenuItemClickEvent) => {
    this.props.updateLocale(e.key);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILocalePickerContainerOwnProps): ILocalePickerContainerStateProps => ({
  userSettingsLocale: UserSettingsBusinessStore.selectors.getUserSettingByKey(state, 'locale'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILocalePickerContainerDispatchProps => ({
  updateLocale: (locale: string) => dispatch(UserSettingsBusinessStore.actions.createUserSettings('locale', locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ILocalePickerContainerOwnProps>(LocalePickerContainer as any));
