import { Observable, empty } from 'rxjs';

export interface IServiceStatus {
  running: boolean;
}

/** Describes provider implemenntation for specific service. Acts as a facade to various service implementations. */
export interface IServiceStatusProvider<S> {
  serviceInstance(): S;
  requireService(): Observable<IServiceStatus>;
  watchServiceStatus(): Observable<IServiceStatus>;
  getServiceStatus(): IServiceStatus;
  startService(): Observable<IServiceStatus>;
}

/** Provides unified access to controling and checking various background services (eg. messaging, video, connection to server, ...). */
export default class ServiceStatusProvider {

  private providers: { [key: string]: IServiceStatusProvider<any> } = {};

  static instance() {
    if (INSTANCE == null) {
      INSTANCE = new ServiceStatusProvider();
    }
    return INSTANCE;
  }

  registerServiceProvider<S>(id: string, provider: IServiceStatusProvider<S>): void {
    this.providers[id] = provider;
  }

  requireService(id: string): Observable<IServiceStatus> {
    const provider = this.findProvider(id, false);
    if (provider != null) {
      return provider.requireService();
    }
    else {
      return empty();
    }
  }

  requireServiceOrFail(id: string): Observable<IServiceStatus> {
    return this.findProvider(id).requireService();
  }

  watchService(id: string): Observable<IServiceStatus> {
    const provider = this.findProvider(id, false);
    if (provider != null) {
      return provider.watchServiceStatus();
    }
    else {
      return empty();
    }
  }

  watchServiceOrFail(id: string): Observable<IServiceStatus> {
    return this.findProvider(id).watchServiceStatus();
  }

  getServiceStatus(id: string): IServiceStatus {
    return this.findProvider(id).getServiceStatus();
  }

  startService(id: string): Observable<IServiceStatus> {
    const provider = this.findProvider(id, false);
    if (provider != null) {
      return provider.startService();
    }
    else {
      return empty();
    }
  }

  startServiceOrFail(id: string): Observable<IServiceStatus> {
    return this.findProvider(id).startService();
  }

  private findProvider(id: string, failOnMissing: boolean = true) {
    const provider = this.providers[id];
    if (provider == null && failOnMissing) {
      // tslint:disable-next-line:no-string-throw - TODO: replace with proper exception
      throw `Service provider for service "${id}" not found!`;
    }
    return provider;
  }
}

let INSTANCE: ServiceStatusProvider;
