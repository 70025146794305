import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import SessionParticipateContainer from '@src/components/session/common/SessionParticipateContainer';

type ISessionAddParticipantPageProps = WithRouterProps;

const SessionAddParticipantPage = (props: ISessionAddParticipantPageProps) => (
  <SessionParticipateContainer participationId={props.params.participationId} />
);

export default withRouter(SessionAddParticipantPage as any);
