import { RouterState, RedirectFunction } from 'react-router';

import AppConfigService from '@src/service/common/AppConfigService';
// import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import StoreService from '@src/service/business/StoreService';
import { IUserInfo } from '@src/model/user/User';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('AdminRouteGuard');

function isAllowed(): boolean {
  const currentUser: IUserInfo = LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());
  const isAdmin: boolean = !!currentUser.roles.find(item => item.id === UserRoleEnum.ADMINISTRATOR);

  return isAdmin;
}

/** Protect routes that require authenticated user. Function is implemented as "onEnter" router callback. */
export function adminRouteGuard(nextState: RouterState, replace: RedirectFunction, callback?: (error?: any) => any) {
  if (!isAllowed()) {
    LOGGER.info(`Admin Route ${nextState.location.pathname} not allowed.`);

    replace({
      pathname: AppConfigService.getValue('routing.defaultRoute'),
    });
  }

  if (callback) {
    callback();
  }
}
