import React from 'react';

import { notification } from 'antd';
import { IComponentMessage } from '@src/service/util/pubsub/ComponentMessagingService';
import { IMessagingMessage, ISessionMessagingMessage } from '@src/model/messaging/messages';
import ComponentMessagingContext from '@src/components/common/util/ComponentMessagingContext';
import NotificationMessageContent from '@src/components/login/usermenu/NotificationMessageContent';
// tslint:disable-next-line:no-submodule-imports - interface not exported through main module
import { ArgsProps } from 'antd/lib/notification';
import { withRouter, WithRouterProps } from 'react-router';

import ComponentMessagingRules from '@src/service/util/pubsub/ComponentMessagingRules';
import AppConfigService from '@src/service/common/AppConfigService';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('UserNotifications');

const USER_NOTIFICATION_SHOW = AppConfigService.getValue('components.notification.userNotification.show') || false;

// --
// ---------- Prop types

export interface IUserNotificationsPublicProps {
}
type IUserNotificationsProps = IUserNotificationsPublicProps & WithRouterProps;


// --
// ---------- Component

// TODO: could this be handled through user feedback support (UserFeedbackMessageType.NOTIFICATION)?

/** Displays messaging messages as ant's notifications. */
class UserNotifications extends React.PureComponent<IUserNotificationsProps> {

  render() {
    return (
      <ComponentMessagingContext.Consumer listener={this.handleMessage}>
        {() => (
          <React.Fragment />
        )}
      </ComponentMessagingContext.Consumer>
    );
  }

  /** Handle notifications. */
  handleMessage = (message: IComponentMessage<IMessagingMessage>) => {
    // ignore notificaitons if they are disabled
    if (!USER_NOTIFICATION_SHOW) {
      return;
    }

    if (ComponentMessagingRules.isDisplayableMessage(message)) {
      const notificationContent = <NotificationMessageContent message={message.payload} showCloseButton={false} onClick={() => this.handleMessageClick(message)} />;
      const msg = message.payload;

      const notificationProps: ArgsProps = {
        key: msg.id,
        placement: 'topRight',
        message: '', // message is required param, but all we have is content -> description
        description: notificationContent,
        onClick: () => {
          this.handleMessage(message);
        },
      };
      // add testing ID
      (notificationProps as any)['data-test-id'] = 'lemon-userApproval__notification';

      notification.open(notificationProps);
    }
  }

  handleMessageClick(message: IComponentMessage<IMessagingMessage>) {
    const msg = message.payload;

    // force close notification
    notification.close(msg.id);

    // NOTE: all notification menu items are expected to be session notifications ie. they all have sessionId which is used for navigation to session
    const sessionId = (msg as ISessionMessagingMessage).sessionId;
    if (sessionId == null) {
      // is this legal or should it be a warning?
      LOGGER.warn(`Messaging notification "${msg.id}" has no session ID?`);
      return;
    }

    LOGGER.info(`Routing to session ${sessionId} from notification ${msg.id}`);
    this.props.router.push(`/sessions?sessionId=${sessionId}`);
  }
}

export default withRouter<IUserNotificationsPublicProps>(UserNotifications);
