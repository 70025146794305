import React from 'react';

import LemonAvatar, { AvatarSize } from '@src/components/common/image/LemonAvatar';

// -- Prop types
// ----------

// -- Public component props
export interface IAppUserAvatarOwnProps {
  imagePath?: string;
  className?: string;
  size?: AvatarSize;
}
// -- Complete component props
type IAppUserAvatarProps = IAppUserAvatarOwnProps;

// -- Component
// ----------

/** Specialized version of LemonAvatar component used for application user avatars. Defaults invalid image to "avatarFallback" icon. */
export default function AppUserAvatar(props: IAppUserAvatarProps) {
  return <LemonAvatar className={props.className} imagePath={props.imagePath} fallbackIconName="avatarFallback" size={props.size} />;
}
