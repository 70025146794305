import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserFileRepositoryContainer from '@src/components/repository/UserFileRepositoryContainer';
import FileListBusinessStore from '@src/service/business/file/fileListBusinessStore';

import IFile from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';


// -- Prop types
// ----------

interface IAddFileFromRepositoryModalStateProps {
  repositoryFileListSelectedItems: IFileSystemElement[];
}

interface IAddFileFromRepositoryModalDispatchProps {
  clearSelectedItems: () => void;
}

export interface IAddFileFromRepositoryModalOwnProps {
  visible: boolean;
  closeModal: () => void;
  onSubmit: (file: IFile[]) => void;
}
type IAddFileFromRepositoryModalProps = IAddFileFromRepositoryModalOwnProps & IWithLocalizeOwnProps & IAddFileFromRepositoryModalStateProps & IAddFileFromRepositoryModalDispatchProps;

interface IAddFileFromRepositoryModalState {
}


class AddFileFromRepositoryModal extends React.Component<IAddFileFromRepositoryModalProps, IAddFileFromRepositoryModalState> {

  handleModalSubmit = () => {
    this.props.onSubmit(this.props.repositoryFileListSelectedItems as IFile[]);
    this.props.clearSelectedItems();
  }

  handleCancel = () => {
    this.props.closeModal();
    this.props.clearSelectedItems();
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          title={this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_TITLE')}
          className="lemon-modal__modal--lg lemon-repository__sessionAddModal"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          onOk={this.handleModalSubmit}
          okText={this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_OK_BUTTON_TEXT')}
          okButtonProps={{ disabled: !this.props.repositoryFileListSelectedItems.length }}
        >
          <UserFileRepositoryContainer foldersSelectable={false} showActionMenu={false} />
        </Modal>
      </React.Fragment>

    );
  }
}


// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by 'type inference' to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAddFileFromRepositoryModalOwnProps): IAddFileFromRepositoryModalStateProps => ({
  repositoryFileListSelectedItems: FileListBusinessStore.selectors.getRepositoryFileListSelectedItems(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAddFileFromRepositoryModalDispatchProps => ({
  clearSelectedItems: () => dispatch(FileListBusinessStore.actions.clearRepositoryFileListSelectedItems()),
});


// TODO: add component's props types to export with proper type
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IAddFileFromRepositoryModalOwnProps>(AddFileFromRepositoryModal as any));
