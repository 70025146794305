import { BehaviorSubject, of, Observable, Observer, from, forkJoin } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import LemonError from '@src/service/common/LemonError';
import BrowserPermissions from '@src/service/util/permission/BrowserPermissions';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('VideoService');

let INSTANCE: VideoService;
const STARTED_OBSERVABLE = new BehaviorSubject<boolean>(false);
const PERMISSIONS_OBSERVABLE = new BehaviorSubject<boolean | undefined>(undefined);

export default class VideoService {
  static instance() {
    if (INSTANCE == null) {
      INSTANCE = new VideoService();
    }

    return INSTANCE;
  }

  initialize(): Observable<boolean> {
    // anything to initialize?
    return of(true);
  }

  start() {
    LOGGER.info('Starting video service');
    if (INSTANCE == null) {
      throw new LemonError('Video service not initialize. Try calling initialize first.');
    }

    this.requestPermission().pipe(
      tap(() => STARTED_OBSERVABLE.next(true)),

      catchError((err) => {
        LOGGER.error('Error starting video service', err);
        STARTED_OBSERVABLE.next(false);

        return of(err);
      })
    )
      .subscribe();

    return STARTED_OBSERVABLE.asObservable();
  }

  /** Check if video service has already been initialized allowed and connected. */
  watchStarted(): Observable<boolean> {
    return STARTED_OBSERVABLE.asObservable();
  }

  /** Check if video service has already been initialized allowed and connected. */
  isStarted(): boolean {
    return STARTED_OBSERVABLE.getValue();
  }

  /** Check if video service has been allowed by the user. This can be used to show user a message about why and how should notifications be allowed. */
  shouldCheckUserApproval(): Observable<boolean> {
    return this.queryPermission().pipe(
      map((statuses) => {
        // TODO: what to do if only one video permission fails?
        const bothGranted = statuses.reduce((accum, item) => (accum && item.state === 'granted'), true);
        return PERMISSIONS_OBSERVABLE.getValue() == null && !bothGranted || PERMISSIONS_OBSERVABLE.getValue() != null && !PERMISSIONS_OBSERVABLE.getValue();
      })
    );
  }

  // ---------- private

  private queryPermission() {
    return forkJoin([
      from(BrowserPermissions.queryPermission({ name: 'camera' })),
      from(BrowserPermissions.queryPermission({ name: 'microphone' })),
    ]);
  }

  private requestPermission(): Observable<boolean> {
    const observable = Observable.create((observer: Observer<boolean>) => {
      try {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).
          then((stream) => {
            if (stream.getTracks().length < 2) {
              // tslint:disable-next-line:no-string-throw - TODO: add specialized exception class
              throw 'Missing required media track. Maybe some of required permissions are blocked?';
            }
            // we're just asking for permissions, we can close tracks right away
            stream.getTracks().forEach((track) => track.stop());

            observer.next(true);
            observer.complete();
          })
          .catch((err) => {
            observer.error(err);
          });
      }
      catch (err) {
        observer.error(err);
      }
    });

    return observable.pipe(
      map(() => {
        LOGGER.info('Video permissions granted');
        PERMISSIONS_OBSERVABLE.next(true);
      }),

      catchError((err: any) => {
        LOGGER.info('Video permissions error', err);
        PERMISSIONS_OBSERVABLE.next(false);

        throw err;
      })
    );
  }
}

