import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { Row, Col, Typography, Divider } from 'antd';
import * as Scroll from 'react-scroll';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IUserInfo from '@src/model/user/User';
import ITutoringSession from '@src/model/session/TutoringSession';
import AppConfigService from '@src/service/common/AppConfigService';
import SessionStatusTag from '@src/components/session/common/SessionStatusTag';
import SessionRejectionContainer from '../common/SessionRejectionContainer';
import { SessionRejectionFormType } from '@src/components/session/common/SessionRejectionForm';
import { ISessionCalendarListItem, SESSION_CALENDAR_LIST_DATE_KEY_FORMAT } from './types';
import TutoringSessionActions, { IButtonType } from '@src/components/session/view/TutoringSessionActions';
import IFile from '@src/model/file/File';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import ITutoringSessionParticipant from '@src/model/session/TutoringSessionParticipant';
import { IStudentDetails } from '@src/model/user/StudentDetails';
import { UserRoleEnum } from '@src/model/user/UserRole';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import SessionOtherParticipantsMenu from '@src/components/session/common/SessionOtherParticipantsMenu';


const TIME_FORMAT = AppConfigService.getValue('dateTimeFormat.time');

// -- Prop types
// ----------
interface ISessionCalendarListOwnProps {
  currentUser: IUserInfo;
  items: ISessionCalendarListItem[];
  currentSession?: ITutoringSession;
  date: number;
  month: number;
  year: number;
  // showButtons?: boolean;
  buttonTypes?: IButtonType | IButtonType[];
  showWeekName?: boolean;
  scrollToCurrent?: boolean;

  // event handlers
  onSessionConfirm?: (session: ITutoringSession, message?: string) => void;
  onSessionDecline?: (session: ITutoringSession, message?: string) => void;
  onOpenTutoringRoom?: (session: ITutoringSession) => void;
  onAddFileFromRepository?: (sessionId: string, file: IFile[]) => void;
}
type ISessionCalendarListProps = ISessionCalendarListOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface ISessionCalendarListState {
  // session rejection modal props (dialog is shown if show type is set, callbacks are optional, but usefull :-))
  sessionRejectionModalShowType: SessionRejectionFormType | null;
  sessionRejectionModalOnOk?: (message?: string) => void;
  sessionRejectionModalOnCancel?: () => void;
  sessionRejectionModalTutoringSession: ITutoringSession | null;
}

class SessionCalendarList extends React.Component<ISessionCalendarListProps, ISessionCalendarListState> {

  static defaultProps: Partial<ISessionCalendarListProps> = {
    // showButtons: true,
    showWeekName: true,
  };

  constructor(props: ISessionCalendarListProps) {
    super(props);

    this.state = {
      sessionRejectionModalShowType: null,
      sessionRejectionModalTutoringSession: null,
    };
  }

  componentDidUpdate(prevProps: ISessionCalendarListProps) {
    // scroll only on updated date
    if (prevProps != null && this.props.date !== prevProps.date) {
      this.scrollToCurrentDate();
    }
  }

  renderSession = (session: ITutoringSession) => {
    const currentParticipant: ITutoringSessionParticipant | undefined = TutoringSessionModelHelper.getCurrentParticipant(session, this.props.currentUser);
    const otherParticipant: ITutoringSessionParticipant | undefined = TutoringSessionModelHelper.getFirstOtherParticipant(session);
    const otherParticipantUserId: string | undefined = otherParticipant && otherParticipant.user.id;
    const otherParticipantDisplayName: string | undefined = otherParticipant && otherParticipant.user.displayName;
    const studentDetails: IStudentDetails | undefined = otherParticipant && otherParticipant.user.studentDetails;
    const sessionDescription = `${session.educationAreaFullPath.name}${session.educationAreaFullPath.id !== session.educationArea.id ? ` (${session.educationArea.name})` : ''}`;

    return (
      <div key={session.id} className="lemon-sessionCalendar__item" data-test-id={`lemon-sessionCalendar__sessionItem_${session.id}`}>
        <Row type="flex" align="middle" gutter={12} className="lemon-sessionCalendar__itemRow">
          <Col span={this.props.currentSession ? 24 : undefined}>
            <SessionStatusTag session={session} showTooltip={true}>
              {moment(session.startDateTime).format(TIME_FORMAT)} - {moment(session.endDateTime).format(TIME_FORMAT)}
            </SessionStatusTag>
            {this.props.currentSession && this.props.currentSession.id === session.id && this.props.translate('SESSION_CALENDAR.UPDATE_SESSION.CURRENT_SESSION')}
          </Col>

          <Col span={this.props.currentSession ? 24 : undefined}>
            <Row type="flex">
              <Col>
                <Typography.Title level={3} ellipsis={true}>
                  <Link to={{ pathname: '/sessions', query: { sessionId: session.id, participantId: otherParticipantUserId }, state: { session } }} data-test-id={`lemon-sessionCalendar__sessionLink_${session.id}`}>
                    {otherParticipantDisplayName}
                  </Link>
                  &nbsp;
                  <SessionOtherParticipantsMenu session={session} currentParticipantUser={otherParticipant && otherParticipant.user} withLink={true} size="large" />
                    &nbsp;-&nbsp;
                    {sessionDescription} ({session.educationAreaOrderNumber})
                  </Typography.Title>
              </Col>
            </Row>

            {studentDetails &&
              <Row>
                {`${studentDetails.studentClass.name} @ ${studentDetails.studentSchool}`}
              </Row>
            }
          </Col>
        </Row>

        {this.shouldShowButtons() && (
          <Row type="flex" justify="center" className="lemon-sessionCalendar__itemRow">
            <Col offset={4}>
              <TutoringSessionActions
                session={session}
                currentParticipant={currentParticipant}
                buttonTypes={this.props.buttonTypes}
                showButtonText={true}

                onConfirm={() => this.handleSessionConfirm(session)}
                onDecline={() => this.handleSessionDecline(session)}
                onOpenTutoringRoom={() => this.handleOpenTutoringRoomButton(session)}
                onAddFileFromRepository={(file: IFile[]) => this.handleAddFileFromRepository(session.id, file)}
              />
            </Col>
          </Row>
        )}

        <Divider className="lemon-sessionCalendar__itemDivider" />
      </div>
    );
  }

  renderListItem = (item: ISessionCalendarListItem) => {
    return (
      <React.Fragment key={item.dayName}>
        {!this.props.currentSession &&
          <React.Fragment>
            <Row>
              <Col>
                <Typography.Title level={2} className="lemon-sessionCalendar__itemHeaderDate">{item.dayName}</Typography.Title>
              </Col>
            </Row>

            <Divider className="lemon-sessionCalendar__headerDivider" />
          </React.Fragment>
        }
        {item.data.length ?
          item.data.map((session) =>
            this.renderSession(session)
          ) : (
            <Row key={item.dayName} className="lemon-sessionCalendar__item">
              <Col>
                <div className="lemon-sessionCalendar__noSessionInfo">
                  {this.props.translate('SESSION_CALENDAR.EMPTY_DAY')}
                </div>
              </Col>
            </Row>
          )
        }

        {/* item group divider */}
        <Row><Col>&nbsp;</Col></Row>
      </React.Fragment >
    );
  }

  render = () => {
    const calendarList = this.props.items;
    const showWeekName = this.props.showWeekName;

    /** Determine if week header should be displayed. */
    function showWeek(idx: number) {
      // first in the list OR new week
      return showWeekName && (idx === 0 || calendarList[idx - 1].weekName !== calendarList[idx].weekName);
    }

    return (
      <React.Fragment>
        {0 < calendarList.length ?
          <React.Fragment>
            {this.props.currentSession &&
              <Typography.Text>{this.props.translate('SESSION_CALENDAR.UPDATE_SESSION.SESSION_LIST_TITLE')}</Typography.Text>
            }

            {calendarList.map((item, idx) =>
              <Scroll.Element name={item.dateKey} key={item.dateKey}>
                {(showWeek(idx)) && (
                  <Row>
                    <Col>
                      <div className="lemon-sessionCalendar__week"><Typography.Title level={3}>{this.props.translate('SESSION_CALENDAR.WEEK_LABEL')} {item.weekName}</Typography.Title></div>
                    </Col>
                  </Row>
                )}
                {this.renderListItem(item)}
              </Scroll.Element>
            )}
          </React.Fragment>
          :
          (
            /* ----- empty list ----- */
            <Row>
              <Col className="lemon-sessionCalendar__emptyList">
                {this.props.isInRoles([UserRoleEnum.TUTOR]) && (
                  this.props.translate(this.props.currentSession ? 'SESSION_CALENDAR.UPDATE_SESSION.EMPTY_LIST' : 'SESSION_CALENDAR.EMPTY_LIST_TUTOR')
                )}

                {this.props.isInRoles([UserRoleEnum.STUDENT]) && (
                  <div>
                    <div>{this.props.translate(this.props.currentSession ? 'SESSION_CALENDAR.UPDATE_SESSION.EMPTY_LIST' : 'SESSION_CALENDAR.EMPTY_LIST_STUDENT_TEXT')}</div><Link to="/tutors">{this.props.translate('SESSION_CALENDAR.EMPTY_LIST_STUDENT_LINK')}</Link>
                  </div>
                )}
              </Col>
            </Row>
          )
        }

        {this.state.sessionRejectionModalShowType != null && this.state.sessionRejectionModalTutoringSession != null && (
          <SessionRejectionContainer
            tutoringSession={this.state.sessionRejectionModalTutoringSession}
            rejectionType={this.state.sessionRejectionModalShowType}
            onOk={this.state.sessionRejectionModalOnOk}
            onCancel={this.state.sessionRejectionModalOnCancel}
          />
        )}
      </React.Fragment>
    );
  }

  handleSessionConfirm = (session: ITutoringSession) => {
    if (this.props.onSessionConfirm) {
      this.props.onSessionConfirm(session);
    }
  }

  handleSessionDecline = (session: ITutoringSession) => {
    this.openSessionRejectionDialog(
      'decline',
      session,
      // OK
      (message) => {
        if (this.props.onSessionDecline) {
          this.props.onSessionDecline(session, message);
        }
        this.closeSessionRejectionDialog();
      },
      // cancelled
      () => {
        this.closeSessionRejectionDialog();
      }
    );
  }

  handleOpenTutoringRoomButton = (session: ITutoringSession) => {
    if (this.props.onOpenTutoringRoom) {
      this.props.onOpenTutoringRoom(session);
    }
  }

  handleAddFileFromRepository = (sessionId: string, file: IFile[]) => {
    if (this.props.onAddFileFromRepository) {
      this.props.onAddFileFromRepository(sessionId, file);
    }
  }

  /*shouldConfirmSession(session: ITutoringSession) {
    const userParticipant = this.getCurrentParticipant(session);
    // session is only scheduled and current user needs to accept/decline
    return userParticipant && session.sessionStatus.id === TutoringSessionStatusEnum.SCHEDULED && userParticipant.sessionParticipantStatus.id === TutoringSessionParticipantStatusEnum.INVITED;
  }*/

  /** Scroll date list to current date or nearest predecessor if the whole list is not shown. */
  scrollToCurrentDate() {
    if (this.props.scrollToCurrent) {
      const scrollDateKey = this.getScrollDateKey();
      if (scrollDateKey != null) {
        // console.log(`Scrolling session list to date ${scrollDateKey}`);
        Scroll.scroller.scrollTo(scrollDateKey, { spy: false, hashSpy: false, smooth: true, delay: 100, duration: 400, isDynamic: false });
      }
    }
  }

  /** Find date key or nearest predecessor if the whole list is not shown. */
  getScrollDateKey(): string | undefined {
    const currentDateKey = moment.utc({ year: this.props.year, month: this.props.month, date: this.props.date }).format(SESSION_CALENDAR_LIST_DATE_KEY_FORMAT);
    let scrollableItem: ISessionCalendarListItem | undefined;
    this.props.items.find((item) => {
      if (scrollableItem != null && currentDateKey < item.dateKey) {
        return true;
      }
      scrollableItem = item;

      return false;
    });

    return scrollableItem != null ? scrollableItem.dateKey : undefined;
  }

  shouldShowButtons() {
    // return this.props.showButtons;
    return this.props.buttonTypes !== undefined;
  }


  private openSessionRejectionDialog(type: SessionRejectionFormType, session: ITutoringSession, onOk: (message?: string) => void, onCancel: () => void) {
    this.setState({
      sessionRejectionModalShowType: type,
      sessionRejectionModalOnOk: onOk,
      sessionRejectionModalOnCancel: onCancel,
      sessionRejectionModalTutoringSession: session,
    });
  }

  private closeSessionRejectionDialog() {
    this.setState({
      sessionRejectionModalShowType: null,
      sessionRejectionModalOnOk: undefined,
      sessionRejectionModalOnCancel: undefined,
      sessionRejectionModalTutoringSession: null,
    });
  }
}

export default withLocalize(withRoles<ISessionCalendarListOwnProps>(SessionCalendarList as any));
