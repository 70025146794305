import { Tabs, Tooltip } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { renderConfirmedSessionStatusBadge, renderUnconfirmedSessionStatusBadge } from '@src/components/session/common/SessionStatusBadge';
import { ISessionCalendarListStatusFilter, SessionCalendarListFilterStatus } from '@src/service/business/session/sessionBusinessStore';

// -- Types
// ----------

// -- Prop types
// ----------

export interface ISessionCalendarListFilterPublicProps {
  sessionListFilter?: ISessionCalendarListStatusFilter;

  onUpdateSessionList: () => void;
  onChange?: (filter: ISessionCalendarListStatusFilter) => void;
}
type ISessionCalendarListFilterProps = ISessionCalendarListFilterPublicProps & IWithLocalizeOwnProps;

class SessionCalendarListFilter extends React.PureComponent<ISessionCalendarListFilterProps> {
  renderTab(filterStatus: SessionCalendarListFilterStatus) {
    if (filterStatus === SessionCalendarListFilterStatus.ALL) {
      return <h5>{this.props.translate('SESSION_CALENDAR.FILTER_LABEL_ALL')}</h5>;
    } else if (filterStatus === SessionCalendarListFilterStatus.CONFIRMED) {
      return (
        <h5>
          {this.props.translate('SESSION_CALENDAR.FILTER_LABEL_CONFIRMED')}
          &nbsp;&nbsp;
          {renderConfirmedSessionStatusBadge()}
        </h5>
      );
    } else if (filterStatus === SessionCalendarListFilterStatus.UNCONFIRMED) {
      return (
        <h5>
          {this.props.translate('SESSION_CALENDAR.FILTER_LABEL_UNCONFIRMED')}
          &nbsp;&nbsp;
          {renderUnconfirmedSessionStatusBadge()}
        </h5>
      );
    } else if (filterStatus === SessionCalendarListFilterStatus.PAST) {
      return <h5>{this.props.translate('SESSION_CALENDAR.FILTER_LABEL_PAST')}</h5>;
    } else {
      // sanity check
      return <h5>unknown status</h5>;
    }
  }

  renderRefreshButton = () => {
    return (
      <Tooltip title={this.props.translate('SESSION_CALENDAR.REFRESH_BUTTON_TOOLTIP')}>
        <a className="lemon-sessionCalendar__syncButton" onClick={this.props.onUpdateSessionList}>
          <LemonIcon name="sync" />
        </a>
      </Tooltip>
    );
  };

  render() {
    return (
      <Tabs activeKey={this.defaultActiveTab()} onChange={this.handleStatusOnChange} tabBarExtraContent={this.renderRefreshButton()}>
        <Tabs.TabPane tab={this.renderTab(SessionCalendarListFilterStatus.ALL)} key={SessionCalendarListFilterStatus.ALL} data-test-id={`lemon-sessionCalendar__tabs-${SessionCalendarListFilterStatus.ALL}`} />
        <Tabs.TabPane tab={this.renderTab(SessionCalendarListFilterStatus.CONFIRMED)} key={SessionCalendarListFilterStatus.CONFIRMED} data-test-id={`lemon-sessionCalendar__tabs-${SessionCalendarListFilterStatus.CONFIRMED}`} />
        <Tabs.TabPane tab={this.renderTab(SessionCalendarListFilterStatus.UNCONFIRMED)} key={SessionCalendarListFilterStatus.UNCONFIRMED} data-test-id={`lemon-sessionCalendar__tabs-${SessionCalendarListFilterStatus.UNCONFIRMED}`} />
        <Tabs.TabPane tab={this.renderTab(SessionCalendarListFilterStatus.PAST)} key={SessionCalendarListFilterStatus.PAST} data-test-id={`lemon-sessionCalendar__tabs-${SessionCalendarListFilterStatus.PAST}`} />
      </Tabs>
    );
  }

  handleStatusOnChange = (value: string) => {
    const status = SessionCalendarListFilterStatus[value as keyof typeof SessionCalendarListFilterStatus];
    if (this.props.onChange) {
      this.props.onChange({
        status,
      });
    }
  };

  defaultActiveTab() {
    return (this.props.sessionListFilter && this.props.sessionListFilter.status) || SessionCalendarListFilterStatus.ALL;
  }
}

export default withLocalize<ISessionCalendarListFilterPublicProps>(SessionCalendarListFilter as any);
