import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('initialize/util');

// --
// ---------- Simple sheduling interval generator


// defined in milliseconds
const INITIAL_INTERVAL_MIN = 5_000; // 5 sec
const INITIAL_INTERVAL_MAX = 20_000; // 20 sec
const RECURRING_INTERVAL_MIN = 60_000; // 1 min
const RECURRING_INTERVAL_MAX = 120_000; // 2 min


export const generateInitialInterval = () => {
  const generated = randomNumber(INITIAL_INTERVAL_MAX, INITIAL_INTERVAL_MIN);
  LOGGER.debug(`Generated initial interval: ${generated}`);
  return generated;
};

export const generateRecurringInterval = () => {
  const generated = randomNumber(RECURRING_INTERVAL_MAX, RECURRING_INTERVAL_MIN);
  LOGGER.debug(`Generated recurring interval: ${generated}`);
  return generated;
};

function randomNumber(max: number, min: number = 0) {
  // tslint:disable-next-line:no-bitwise - forcing decimal number to int
  return Math.max((Math.random() * max), min) | 0;
}
