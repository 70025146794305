import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';
import { LangUtils } from '@src/service/util/LangUtils';

const CSS_CLASS_BASE = 'lemon-image__avatar';
const CSS_CLASS_SMALL = 'lemon-image__avatar--sm';
const CSS_CLASS_LARGE = 'lemon-image__avatar--lg';
const CSS_CLASS_XLARGE = 'lemon-image__avatar--xl';

export type AvatarSize = 'base' | 'small' | 'large' | 'xlarge';

// -- Prop types
// ----------

// -- Public component props
export interface ILemonAvatarOwnProps {
  iconName?: keyof ILemonApplicationIconSet;
  imagePath?: string;
  fallbackIconName?: string;
  fallbackImagePath?: string;
  className?: string;
  size?: AvatarSize;
}
// -- Complete component props
type ILemonAvatarProps = ILemonAvatarOwnProps & IWithLocalizeOwnProps;

// -- Component state
interface ILemonAvatarState {
  hasImageLoadError: boolean;
}

// -- Component
// ----------

/**
 * Show avatar either as local image, remote URL or icon.
 *
 * Images are displayed as "LemonImage" component. Icons are rendered as "LemonIcon" component and icon is resolved from configured icon set.
 *
 * Component provides fallback if provided image cannot load (ie. invalid URL). Fallback can be another image or icon.
 * Fallback order:
 *  - image - if defined
 *  - icon - if defined or image cannot be rendered
 *  - fallback image - if image nor icon cannot be rendered
 *  - fallback icon - if nothing else can be rendered
 */
class LemonAvatar extends React.Component<ILemonAvatarProps, ILemonAvatarState> {
  constructor(props: ILemonAvatarProps) {
    super(props);

    this.state = {
      hasImageLoadError: false,
    };
  }

  componentDidUpdate(prevProps: ILemonAvatarProps) {
    if (this.props !== prevProps) {
      // reset error state if props have changed
      this.setState({
        hasImageLoadError: false,
      });
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.shouldRenderImage() && <LemonImage className={this.getClassName()} imagePath={this.props.imagePath} onError={this.imageLoadErrorHandler} />}
        {this.shouldRenderIcon(this.props.iconName) && (
          <div className={this.getClassName()}>
            <LemonIcon name={this.props.iconName} />
          </div>
        )}

        {this.shouldRenderFallbackImage() && <LemonImage className={this.getClassName()} imagePath={this.props.fallbackImagePath} />}
        {this.shouldRenderFallbackIcon(this.props.fallbackIconName) && (
          <div className={this.getClassName()}>
            <LemonIcon name={this.props.fallbackIconName as any} />
          </div>
        )}
      </React.Fragment>
    );
  };

  imageLoadErrorHandler = () => {
    this.setState({
      hasImageLoadError: true,
    });
  };

  shouldRenderImage() {
    return !this.state.hasImageLoadError && !LangUtils.isEmpty(this.props.imagePath);
  }

  shouldRenderIcon(iconName: string | undefined): iconName is keyof ILemonApplicationIconSet {
    return !this.shouldRenderImage() && iconName != null;
  }

  shouldRenderFallbackImage() {
    return !LangUtils.isEmpty(this.props.fallbackImagePath) && !this.shouldRenderImage() && !this.shouldRenderIcon(this.props.iconName);
  }

  shouldRenderFallbackIcon(fallbackIconName: string | undefined): fallbackIconName is keyof ILemonApplicationIconSet {
    return !LangUtils.isEmpty(this.props.fallbackIconName) && !this.shouldRenderImage() && !this.shouldRenderIcon(this.props.iconName) && !this.shouldRenderFallbackImage();
  }

  getClassName() {
    return classNames({
      [CSS_CLASS_BASE]: true,
      [CSS_CLASS_SMALL]: this.getSize() === 'small',
      [CSS_CLASS_LARGE]: this.getSize() === 'large',
      [CSS_CLASS_XLARGE]: this.getSize() === 'xlarge',
      [this.props.className || '']: true,
    });
  }

  getSize(): AvatarSize {
    return this.props.size != null ? this.props.size : 'base';
  }
}

export default withLocalize<ILemonAvatarOwnProps>(LemonAvatar as any);
