import { Layout } from 'antd';
import React from 'react';

// import AppFooter from '@src/components/app/layout/AppFooter';
import AppHeader from '@src/components/app/layout/AppHeader';
import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';
import NotificationInfoBarMessagingMessageItemContainer from '@src/components/login/notificationinfo/messaging/NotificationInfoBarMessagingMessageItemContainer';
import NotificationInfoBarContainer from '@src/components/login/notificationinfo/NotificationInfoBarContainer';
import ProfileIncompleteMessageContainer from '@src/components/login/notificationinfo/staticmessages/ProfileIncompleteMessageContainer';

import { IMessagingMessage } from '@src/model/messaging/messages';

const AppLayout: React.SFC = (props: any) => {
  return (
    <Layout className={`lemon-appLayout__layout ${props.welcomePage ? 'lemon-appLayout__layout--welcomeSplash' : ''}`}>
      <Layout.Header className="lemon-appLayout__header">
        <LemonErrorBoundary>
          <AppHeader />
        </LemonErrorBoundary>
      </Layout.Header>

      <Layout.Content className="lemon-appLayout__content">
        <LemonErrorBoundary showDefaultError={true}>
          {/* welcome page is special so don't show notifications there */}
          {!props.welcomePage && (
            <NotificationInfoBarContainer
              renderMessagingMessage={(message: IMessagingMessage) => {
                return <NotificationInfoBarMessagingMessageItemContainer message={message} />;
              }}
              renderStaticMessageList={() => [<ProfileIncompleteMessageContainer key={'ProfileIncompleteMessage'} />]}
            />
          )}

          {props.children}
        </LemonErrorBoundary>
      </Layout.Content>

      {/* <Layout.Footer className="lemon-appLayout__footer">
        <LemonErrorBoundary>
          <AppFooter />
        </LemonErrorBoundary>
      </Layout.Footer> */}
    </Layout>
  );
};

export default AppLayout;
