import LemonError from '@src/service/common/LemonError';

/** Interfaces describes storage as described by "redux-persist". */
export interface PersistStorage {
  getItem: <T>(key: string) => Promise<T>;

  setItem: (key: string, item: any) => Promise<void>;

  removeItem: (key: string) => Promise<void>;
}

/** Local constants that translate to designated storage implementations. */
export enum PersistStorageType {
  IndexedDb, LocalStorage, WebSQL,
}

/** Error that describes any kind of persist storage error. */
export class PersistStorageError extends LemonError {
  constructor(message: string) {
    super(`Persist storage error: ${message != null ? ': ' + message : ''}`);
  }
}
