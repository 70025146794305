import { Dropdown, Menu, Modal } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import IFile, { FilePermissionEnum } from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';

import NameInputForm from '@src/components/repository/common/NameInputForm';

import FileUtils from '@src/components/common/file/FileUtils';
import { hasPermission } from '@src/components/session/view/SessionFileList';
import AppConfigService from '@src/service/common/AppConfigService';
import { getLogger } from '@src/service/util/logging/logger';

const LOGGER = getLogger('FileDropdownMenu');

const NAME_MAX_LENGTH = AppConfigService.getValue('validation.length.file');

const confirm = Modal.confirm;

const firstArrayElement = 0;

// -- Prop types
// ----------

export interface IFileDropdownMenuOwnProps {
  record: IFileSystemElement[];
  canOpen?: boolean;
  canMove?: boolean;
  canRename?: boolean;
  canDelete?: boolean;
  canToggleVisibility?: boolean;
  canAddToWhiteboard?: boolean;
  onRename?: (record: IFileSystemElement, newName: string) => void;
  onDelete?: (record: IFileSystemElement[]) => void;
  onMove?: (record: IFileSystemElement[]) => void;
  onVisibilityToggle?: (record: IFile) => void;
  onAddToWhiteboard?: (file: IFile) => void;
}
type IFileDropdownMenuProps = IFileDropdownMenuOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IFileDropdownMenuState {
  showNameInputModal: boolean;
}

// -- Component
// ----------

/** Describe your component ... */
class FileDropdownMenu extends React.Component<IFileDropdownMenuProps, IFileDropdownMenuState> {
  state = {
    showNameInputModal: false,
  };

  isSingleElement = this.props.record.length === 1;

  getItemLink = (record: IFile) => {
    return this.props.resolveApiPath(`${FileUtils.getFileLink(record)}?filename=${record.name.substring(0, record.name.lastIndexOf('.'))}`);
  };

  showDeleteConfirm = (record: IFileSystemElement[]) => {
    const onDelete = (files: IFileSystemElement[]) => (this.props.onDelete ? this.props.onDelete(files) : LOGGER.error('delete function missing'));
    confirm({
      title: this.props.translate('FILE_LIST.DELETE_CONFIRM_TITLE'),
      okText: this.props.translate('COMMON.ACTION_DELETE'),
      cancelText: this.props.translate('COMMON.ACTION_CANCEL'),
      okType: 'danger',
      maskClosable: true,
      autoFocusButton: 'cancel',
      onOk() {
        onDelete(record);
      },
    });
  };

  menu = (record: IFileSystemElement[]) => {
    const firstElement = this.props.record[firstArrayElement];

    return (
      <Menu className="lemon-repository__menu">
        {this.props.canAddToWhiteboard && this.props.onAddToWhiteboard && this.isSingleElement && FileUtils.isFile(firstElement) && (
          <Menu.Item>
            <a onClick={() => this.props.onAddToWhiteboard && this.props.onAddToWhiteboard(firstElement)}>
              <LemonIcon name="picLeft" />
              &nbsp;{this.props.translate('TUTORING_ROOM.SESSION_FILE_LIST.ADD_FILE_BUTTON_TITLE')}
            </a>
          </Menu.Item>
        )}
        {this.props.canOpen && this.isSingleElement && FileUtils.isFile(firstElement) && (
          <Menu.Item>
            <a href={this.getItemLink(firstElement)} data-test-id={`lemon-fileRepository__fileLink_downloadFile_${firstElement.id}`}>
              <LemonIcon name="download" />
              &nbsp;{this.props.translate('FILE_LIST.ACTIONS_DOWNLOAD_LABEL')}
            </a>
          </Menu.Item>
        )}
        {this.props.canMove && this.props.onMove && (
          <Menu.Item>
            <a onClick={() => (this.props.onMove ? this.props.onMove(record) : LOGGER.error('move function missing'))}>
              <LemonIcon name="export" />
              &nbsp;{this.props.translate('FILE_LIST.ACTIONS_MOVE_LABEL')}
            </a>
          </Menu.Item>
        )}
        {this.props.canRename && this.props.onRename && this.isSingleElement && (
          <Menu.Item>
            <a onClick={() => this.toggleNamingModalVisible()}>
              <LemonIcon name="edit" />
              &nbsp;{this.props.translate('FILE_LIST.ACTIONS_RENAME_LABEL')}
            </a>
          </Menu.Item>
        )}
        {this.props.canDelete && this.props.onDelete && (
          <Menu.Item>
            <a onClick={() => this.showDeleteConfirm(record)}>
              <LemonIcon name="delete" />
              &nbsp;{this.props.translate('FILE_LIST.ACTIONS_DELETE_LABEL')}
            </a>
          </Menu.Item>
        )}
        {this.props.canToggleVisibility && this.props.onVisibilityToggle && this.isSingleElement && FileUtils.isFile(firstElement) && (
          <Menu.Item>
            <a onClick={() => this.props.onVisibilityToggle && this.props.onVisibilityToggle(firstElement)}>
              {hasPermission(firstElement.permissions, FilePermissionEnum.VIEW) ? (
                <React.Fragment>
                  <LemonIcon name="eyeInvisible" />
                  &nbsp;{this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_HIDDEN')}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <LemonIcon name="eye" />
                  &nbsp;{this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_VISIBLE')}
                </React.Fragment>
              )}
            </a>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  handleNameSubmit = (newName: string) => {
    this.props.onRename && this.isSingleElement ? this.props.onRename(this.props.record[firstArrayElement], newName.trim()) : LOGGER.error('rename function missing');
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown overlay={this.menu(this.props.record)} trigger={['click']}>
          {this.props.children}
        </Dropdown>
        {this.state.showNameInputModal && this.isSingleElement && (
          <NameInputForm
            onSubmit={this.handleNameSubmit}
            closeModal={this.toggleNamingModalVisible}
            title={FileUtils.isFile(this.props.record[firstArrayElement]) ? 'FILE_LIST.NAME_INPUT_TITLE_RENAME_FILE' : 'FILE_LIST.NAME_INPUT_TITLE_RENAME_FOLDER'}
            validationLength={NAME_MAX_LENGTH - this.getExtensionLength(this.props.record[firstArrayElement].name)}
            defaultName={this.trimExtension(this.props.record[firstArrayElement].name)}
          />
        )}
      </React.Fragment>
    );
  }

  getExtensionLength = (fileName: string) => {
    const fileNameWithoutExtension = this.trimExtension(fileName);
    return fileName.length - fileNameWithoutExtension.length;
  };

  trimExtension = (fileName: string) => {
    return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
  };

  toggleNamingModalVisible = () => {
    this.setState({
      showNameInputModal: !this.state.showNameInputModal,
    });
  };
}

export default withPathResolver(withLocalize<IFileDropdownMenuOwnProps>(FileDropdownMenu as any));
