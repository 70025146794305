export enum ApiResponseEventStatus {
  SUCCESS = 'SUCCESS', ERROR = 'ERROR',
}

// ----- API responses

/**
 * Type interface for collection data/meta response. See also ICollectionData<T, M>.
 */
export interface ICollectionResponse<T, M> {
  content: T[];
  page: M;
}

/** API response base interface. */
export interface BaseApiResponse {
  status: ApiResponseEventStatus;
}

/** Success API response interface; */
export interface SuccessApiResponse<T> extends BaseApiResponse {
  payload: T;
}

/** Error API response interface; */
export interface ErrorApiResponse extends BaseApiResponse {
  errorMessage: string;
  errorCode: string;
}
