export default interface ITutoringSessionStatus {
  id: TutoringSessionStatusEnum;
  name: string;
  description: string;
}

export enum TutoringSessionStatusEnum {
  SCHEDULED = '1',
  CONFIRMED = '2',
  DECLINED = '3',
  IN_PROGRESS = '4',
  ENDED = '5',
  UNRESPONDED = '6',
}
