import { Button, Col, Dropdown, Form, Input, Menu, Row } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from 'antd/lib/form';
import debounce from 'lodash/debounce';
import React, { SyntheticEvent } from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NameInputForm from '@src/components/repository/common/NameInputForm';
import FileRepositoryUpload from '@src/components/repository/FileRepositoryUpload';
import { isInPublicFolder, isInSearchFolder, publicFolder, rootFolder } from '@src/components/repository/UserFileRepositoryContainer';
import { IPath } from '@src/model/common/Path';
import IFile from '@src/model/file/File';
import { IFileListFilter } from '@src/service/business/file/fileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

const FOLDER_NAME_MAX_LENGTH = AppConfigService.getValue('validation.length.file');

// -- Prop types
// ----------

export interface IFileRepositoryHeaderOwnProps {
  onFolderSelect: (folder: IPath) => void;
  routes: IPath;
  isUploadDisabled: boolean;
  fileListFilter: IFileListFilter;
  onFilterChange: (filter?: IFileListFilter) => void;
  onFolderCreate: (name: string) => void;
  onUploadFile: (fileList: IFile[]) => void;
}
type IFileRepositoryHeaderProps = IFileRepositoryHeaderOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IFileRepositoryHeaderState {
  openModal: boolean;
  searchValue: string;
}

// -- Component
// ----------

/** Header for the Repository */
class FileRepositoryHeaderForm extends React.Component<IFileRepositoryHeaderProps, IFileRepositoryHeaderState> {
  state: IFileRepositoryHeaderState = {
    openModal: false,
    searchValue: '',
  };

  debouncedSearch = debounce((value: string) => {
    if (value) {
      this.props.onFilterChange({ ...this.props.fileListFilter, searchTerm: value });
    } else {
      this.props.onFilterChange();
    }
  }, AppConfigService.getValue(`components.repository.debounceTime`));

  componentDidUpdate(prevProps: IFileRepositoryHeaderProps, prevState: IFileRepositoryHeaderState) {
    if (prevProps.routes !== this.props.routes && this.props.routes.id === rootFolder.id && this.props.routes.child == null) {
      this.props.form.setFieldsValue({ searchValue: '' });
    }
  }

  handleSearchChange = (event: SyntheticEvent) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.debouncedSearch(target.value);
  };

  menuAddResources = () => {
    return (
      <Menu className="lemon-repository__menu">
        <Menu.Item className="lemon-repository__menuItem_newFolder">
          <a data-test-id="lemon-fileList__newFolderButton" onClick={() => this.toggleModalVisible()}>
            <LemonIcon name="folder" /> {this.props.translate('FILE_LIST.NEW_FOLDER_BUTTON')}
          </a>
        </Menu.Item>
        <Menu.Item className="lemon-repository__menuItem_newFile">
          <FileRepositoryUpload handleUploadFile={this.props.onUploadFile}>
            <span data-test-id="lemon-fileList__newFileButton">
              <LemonIcon name="file" /> {this.props.translate('FILE_LIST.UPLOAD_FILE_BUTTON')}
            </span>
          </FileRepositoryUpload>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={8}>
            <Form className="lemon-common__formItems--noMargin">
              <Form.Item>
                {getFieldDecorator('searchValue', {
                  initialValue: '',
                })(<Input allowClear={true} prefix={<LemonIcon name="search" />} onChange={this.handleSearchChange} />)}
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            {!this.props.isUploadDisabled && (
              <Dropdown overlay={this.menuAddResources()} trigger={['click']} placement="bottomRight">
                <Button className="float-right" size="large">
                  {/*<LemonIcon name="plusCircle" />*/}
                  {this.props.translate('FILE_LIST.ADD_NEW_BUTTON')}
                </Button>
              </Dropdown>
            )}
            {!isInPublicFolder(this.props.routes) && (
              <Button className="float-right lemon-fileRepository__fileLink_openFolder_public" size="large" onClick={() => this.props.onFolderSelect(publicFolder)}>
                {this.props.translate('FILE_LIST.PUBLIC_FOLDER')}
              </Button>
            )}
            {(isInPublicFolder(this.props.routes) || isInSearchFolder(this.props.routes)) && (
              <Button className="float-right lemon-fileRepository__fileLink_openFolder_public" size="large" onClick={() => this.props.onFolderSelect(rootFolder)}>
                {this.props.translate('FILE_LIST.PRIVATE_FOLDER')}
              </Button>
            )}
          </Col>
        </Row>

        {this.state.openModal && <NameInputForm onSubmit={this.props.onFolderCreate} closeModal={this.toggleModalVisible} title={'FILE_LIST.NAME_INPUT_TITLE_CREATE_FOLDER'} validationLength={FOLDER_NAME_MAX_LENGTH} />}
      </React.Fragment>
    );
  }

  private toggleModalVisible = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };
}

const FileRepositoryHeader = Form.create<IFileRepositoryHeaderProps>()(FileRepositoryHeaderForm);

export default withLocalize<IFileRepositoryHeaderOwnProps>(FileRepositoryHeader as any);
