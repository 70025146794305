import React from 'react';

import { Badge, Tooltip } from 'antd';
import ITutoringSession from '@src/model/session/TutoringSession';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LocalizeService from '@src/service/localize/LocalizeService';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';

// -- Prop Types
// ----------

enum SessionBadgeColor {
  UNCONFIRMED = 'yellow',
  CONFIRMED = 'green',
  FINISHED = 'gray',
  DECLINED = 'red',
}

export interface ISessionStatusBadgePublicProps {
  session: ITutoringSession;
  showTooltip?: boolean;
}
type ISessionStatusBadgeProps = ISessionStatusBadgePublicProps & IWithLocalizeOwnProps;


// -- Component
// ----------

const SessionStatusBadge = (props: ISessionStatusBadgeProps) => {

  let badgeColor: SessionBadgeColor;
  let tooltipText: string;

  // not yet confirmed
  if (TutoringSessionModelHelper.isSessionUnconfirmed(props.session)) {
    badgeColor = SessionBadgeColor.UNCONFIRMED;
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED');
  }
  // confirmed or in progress
  else if (TutoringSessionModelHelper.isSessionConfirmed(props.session) || TutoringSessionModelHelper.isSessionInProgress(props.session)) {
    badgeColor = SessionBadgeColor.CONFIRMED;
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_CONFIRMED');
  }
  // ended with success
  else if (TutoringSessionModelHelper.isSessionSuccessfullyEnded(props.session)) {
    badgeColor = SessionBadgeColor.FINISHED;
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_FINISHED');
  }
  // Declined(3) or ended w/o success
  else {
    badgeColor = SessionBadgeColor.DECLINED;
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_DECLINED');
  }

  return (
    <Tooltip title={props.showTooltip ? tooltipText : null}>
      <Badge data-test-id={`lemon-sessionStatusBadge__${badgeColor}_session_${props.session.id}`} color={badgeColor} />
    </Tooltip>
  );
};

export default withLocalize<ISessionStatusBadgePublicProps>(SessionStatusBadge as any);


// Helper renderers that displays CONFIRMED session status badge (no questions or sessions asked)

export function renderUnconfirmedSessionStatusBadge(showTooltip?: boolean) {
  return (
    <Tooltip title={showTooltip ? LocalizeService.translate('LEMON_HELPER_TOOLTIP.CALENDAR.UNCONFIRMED_SESSIONS_INFO') : null}>
      <Badge color={SessionBadgeColor.UNCONFIRMED} />
    </Tooltip >
  );
}

export function renderConfirmedSessionStatusBadge(showTooltip?: boolean) {
  return (
    <Tooltip title={showTooltip ? LocalizeService.translate('LEMON_HELPER_TOOLTIP.CALENDAR.CONFIRMED_SESSIONS_INFO') : null}>
      <Badge color={SessionBadgeColor.CONFIRMED} />
    </Tooltip >
  );
}

export function renderEndedSessionStatusBadge() {
  return (
    <Badge color={SessionBadgeColor.FINISHED} />
  );
}
