
export enum MessagingMessageType {
  SESSION_INCOMING_NOTICE = 'SESSION_INCOMING_NOTICE',
  SESSION_END_NOTICE = 'SESSION_END_NOTICE',
  SESSION_TIME_CHANGE = 'SESSION_TIME_CHANGE',
  SESSION_CREATED = 'SESSION_CREATED',
  SESSION_CONFIRMATION = 'SESSION_CONFIRMATION',
  SESSION_CANCELATION = 'SESSION_CANCELATION',
  SESSION_DECLINED = 'SESSION_DECLINED',
  SESSION_ENDED = 'SESSION_ENDED',
  SESSION_MESSAGE_SENT = 'SESSION_MESSAGE_SENT',
  SESSION_FILE_ADDED = 'SESSION_FILE_ADDED',
  SESSION_FILE_REMOVED = 'SESSION_FILE_REMOVED',
  WHITEBOARD_SCREENSHOTS_CREATED = 'WHITEBOARD_SCREENSHOTS_CREATED',
  SESSION_PARTICIPANT_LEFT = 'SESSION_PARTICIPANT_LEFT',
}

// common message interface
export interface IMessagingMessage {
  id: string;
  type: MessagingMessageType;
  sentDateTime: string; // TODO: format datetime
}

// ----- Session messages

// session related messages
export interface ISessionMessagingMessage extends IMessagingMessage {
  sessionId: string;
  startDateTime: string; // TODO: format datetime
  endDateTime: string; // TODO: format datetime
}

// session created
export interface ISessionCreatedMessagingMessage extends ISessionMessagingMessage {
  creator: string;
}

// session incoming notice
export interface ISessionIncomingNoticeMessagingMessage extends ISessionMessagingMessage {
  incomingPeriod: string;
  educationAreaId: string;
  tutor: string | undefined;
  students: string | undefined;
}

// session end notice
export interface ISessionEndNoticeMessagingMessage extends IMessagingMessage {
  sessionId: string;
  endPeriod: string;
}

// session system status change
export interface SessionSystemStatusChangeMessagingMessage extends ISessionMessagingMessage {
}

// session status change
export interface SessionStatusChangeMessagingMessage extends ISessionMessagingMessage {
  creator: string;
}

// session time changed
export interface ISessionTimeChangeMessagingMessage extends ISessionMessagingMessage {
  creator: string;
}

// ----- Session content messages

// session file added
export interface ISessionFileMessagingMessage extends IMessagingMessage {
  sessionId: string;
  fileName: string;
  creator: string;
}

// session message sent
export interface SessionMessageSentMessagingMessage extends IMessagingMessage {
  sessionId: string;
  message: string;
  sender: string;
}

// session screenshot file created
export interface ISessionScreenshotCreatedMessagingMessage extends IMessagingMessage {
  sessionId: string;
}

// ----- Session participant messages

export interface ISessionParticipantMessagingMessage extends IMessagingMessage {
}

export interface ISessionParticipantLeftMessagingMessage extends IMessagingMessage {
  sessionId: string;
  participant: string;
  participantRole: string;
  changeDateTime: string;
}
