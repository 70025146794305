import PubSubManager, { IPubSubService, IPubSubMessage } from '@src/service/util/pubsub/PubSubManager';
import LemonError from '@src/service/common/LemonError';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('ComponentMessagingService');

let INSTANCE: ComponentMessagingService | undefined;
const NAME = 'ComponentMessagingService';


// ----- types

export type IComponentMessage<T> = IPubSubMessage<T>;
export type IComponentMessagePublisher = <T>(message: IComponentMessage<T>) => void;
export type IComponentMessageListener = <T>(message: IComponentMessage<T>) => void;


/** Provides dedicated instance of PubSubManager for component messaging. */
export default class ComponentMessagingService implements IPubSubService {

  private pubSubManager: PubSubManager;

  constructor() {
    if (INSTANCE != null) {
      throw new LemonError(`Instance of "${NAME}" already exists. Use ${NAME}.instance() method.`);
    }

    this.pubSubManager = new PubSubManager(NAME);

    LOGGER.info('Initialized component messaging service');
  }

  static instance(): ComponentMessagingService {
    if (INSTANCE == null) {
      INSTANCE = new ComponentMessagingService();
    }

    return INSTANCE;
  }

  publish<T>(message: IComponentMessage<T>) {
    this.pubSubManager.publish(message);
  }

  subscribe(listener: IComponentMessageListener) {
    return this.pubSubManager.subscribe(listener);
  }

  unsubscribeAll() {
    this.pubSubManager.unsubscribeAll();
  }

}
