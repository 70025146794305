import { Form, Input, Modal } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from 'antd/lib/form';
import React, { SyntheticEvent } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------

export interface INameInputFormOwnProps {
  onSubmit: (name: string) => void;
  closeModal: () => void;
  title: string;
  validationLength: number;
  defaultName?: string;
}
type INameInputFormProps = INameInputFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/** Modal for entering file and folders name on create or rename ... */
class NameInputForm extends React.Component<INameInputFormProps> {
  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err && values.name !== this.props.defaultName) {
        this.props.onSubmit(values.name);
        this.props.form.resetFields();
      }
      this.props.closeModal();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={this.props.translate(this.props.title)}
        visible={true}
        className="lemon-modal__modal lemon-modal__modal--sm"
        onOk={this.handleSubmit}
        onCancel={this.props.closeModal}
        okText={this.props.translate('COMMON.ACTION_ADD')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || !!this.props.form.getFieldError('name') }}
      >
        <Form hideRequiredMark={true} onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [
                { whitespace: true },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: this.props.validationLength, message: this.props.translate('COMMON.FILL_MAX_LENGTH_REACHED') },
                { pattern: RegExp(/((?:^[^\:\.\*\\/"?|<>]*)$)/), message: this.props.translate('FILE_LIST.NAME_INPUT_INVALID_ENTRY') },
              ],
              initialValue: this.props.defaultName,
            })(<Input autoFocus={true} data-test-id={'lemon-fileList__modal__nameInput'} onPressEnter={this.handleSubmit} placeholder={this.props.translate('FILE_LIST.NAME_INPUT_PLACEHOLDER')} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const FolderCreate = Form.create<INameInputFormProps>()(NameInputForm);

export default withLocalize<INameInputFormOwnProps>(FolderCreate as any);
