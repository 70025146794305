import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils from '@src/components/common/file/FileUtils';
import LemonThumbnail from '@src/components/common/image/LemonThumbnail';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import IFile from '@src/model/file/File';
import { Tooltip } from 'antd';
import React from 'react';

const tooltipDelay = 0.7; // time in seconds after which the preview image tooltip is shown

// -- Prop types
// ----------

export interface IFileItemPreviewOwnProps {
  record: IFile;
  title: string;
  showPreviewTooltip?: boolean;
}
type IFileItemPreviewProps = IFileItemPreviewOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IFileItemPreviewState {
  openImagePreviewModal: boolean;
}

// -- Component
// ----------

/** wrapper component to display tooltip and/or modal file preview ... */
class FileItemPreview extends React.Component<IFileItemPreviewProps, IFileItemPreviewState> {
  state: IFileItemPreviewState = {
    openImagePreviewModal: false,
  };

  render() {
    const record = this.props.record;
    const isImage = FileUtils.isFileImage(record);
    return (
      <React.Fragment>
        <Tooltip overlayClassName="lemon-repository__previewImageTooltip" title={isImage && this.props.showPreviewTooltip ? this.getItemImage(record.id) : null} placement="right" mouseEnterDelay={tooltipDelay}>
          {/**  <a> element is used for adding image to whiteboard and for downloading non-image files, href is needed for both! */
          /**   if it is changed here, needs also to be handled in all places where it is used and which require href to function */}
          <a href={FileUtils.getFileLink(record)} onClick={isImage ? this.onFileCellClick : undefined} data-test-id={`lemon-fileRepository__fileLink_previewFile_${record.id}`} className={isImage ? 'cursor-pointer' : 'cursor-default'} title={this.props.title}>
            {this.props.children}
          </a>
        </Tooltip>

        <FilePreviewModal closeModal={this.toggleImagePreviewVisible} record={record} visible={this.state.openImagePreviewModal} />
      </React.Fragment>
    );
  }

  onFileCellClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    this.toggleImagePreviewVisible();
  };

  toggleImagePreviewVisible = () => {
    this.setState({
      openImagePreviewModal: !this.state.openImagePreviewModal,
    });
  };

  getItemImage = (id: string) => {
    return <LemonThumbnail file={this.props.record} fallbackIconName="file" size="xlarge" />;
  };
}

export default withPathResolver(withLocalize<IFileItemPreviewOwnProps>(FileItemPreview as any));
