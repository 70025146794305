import { Button, Col, Form, Input, Row } from 'antd';
// tslint:disable-next-line:no-submodule-imports - form props are not exported through main module
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ITutoringSession from '@src/model/session/TutoringSession';
import { IUserInfo } from '@src/model/user/User';
import IUserDetails from '@src/model/user/UserDetails';
import AppConfigService from '@src/service/common/AppConfigService';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import { getLogger } from '@src/service/util/logging/logger';
import SessionOtherParticipantsMenu from '@src/components/session/common/SessionOtherParticipantsMenu';

const MESSAGE_MAX_LENGTH = AppConfigService.getValue('validation.length.long');

const LOGGER = getLogger('SessionRejectionForm');

// --
// ---------- Types

export type SessionRejectionFormType = 'cancel' | 'decline';

const DATE_FORMAT = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const TIME_FORMAT = AppConfigService.getValue('dateTimeFormat.time');

export interface ISessionRejectionFormSubmitEvent {
  message: string;
}

// --
// ---------- Prop types

export interface ISessionRejectionFormPublicProps {
  tutoringSession: ITutoringSession;
  type: SessionRejectionFormType;
  currentUser: IUserInfo;

  // event handlers
  onFormSubmit?: (value: ISessionRejectionFormSubmitEvent) => void;
  onFormCancel?: () => void;
}
type ISessionRejectionFormProps = ISessionRejectionFormPublicProps & IWithLocalizeOwnProps & FormComponentProps;

class NormalSessionRejectionForm extends React.Component<ISessionRejectionFormProps> {
  render() {
    const sessionDate = moment(this.props.tutoringSession.startDateTime).format(DATE_FORMAT);
    const sessionEndTime = moment(this.props.tutoringSession.endDateTime).format(TIME_FORMAT);
    const sessionStartTime = moment(this.props.tutoringSession.startDateTime).format(TIME_FORMAT);

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="lemon-sessionRejection__container">
        <Form layout="vertical">
          <div className="lemon-sessionRejection__header">
            <LemonIcon name="closeCircle" />
            <br />
            {this.getFormTitle()}
          </div>

          <div className="lemon-sessionRejection__content panel--inverted">
            <Row className="lemon-sessionRejection__sessionStartDateContainer">
              <Col>
                {/* session start date */}
                <div className="lemon-sessionRejection__sessionStartDate">{sessionDate}</div>

                {/* participant avatar */}
                <div className="lemon-sessionRejection__participant">
                  <AppUserAvatar imagePath={this.getOtherParticipantProfileImageUrl()} />
                </div>
              </Col>
            </Row>

            <Row className="lemon-sessionRejection__sessionStartTimeContainer">
              <Col>
                <div className="lemon-sessionRejection__sessionStartTime">
                  {/* session start time */}
                  {sessionStartTime}
                  &nbsp;-&nbsp;
                  {sessionEndTime}
                </div>

                {/* participant name */}
                <div className="lemon-sessionRejection__sessionParticipantName">{this.getOtherParticipantName()}</div>
                &nbsp;
                <span><SessionOtherParticipantsMenu session={this.props.tutoringSession} currentParticipantUser={this.getOtherParticipantUser()} /></span>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label={this.getMessageLabel()}>
                  {getFieldDecorator('message', {
                    rules: [{ max: MESSAGE_MAX_LENGTH, message: this.props.translate('COMMON.FILL_MAX_LENGTH_REACHED') }],
                  })(<Input.TextArea data-test-id="lemon-sessionRejection__messageInput" />)}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Row className="lemon-sessionRejection__footer">
            <Col span={11}>
              <Button data-test-id="lemon-sessionRejection__cancelFormButton" block={true} onClick={this.handleFormCancel}>
                {this.props.translate('COMMON.ACTION_CANCEL')}
              </Button>
            </Col>
            <Col span={11} offset={2}>
              {this.props.type === 'decline' && (
                <Button data-test-id="lemon-sessionRejection__declineSessionButton" type="danger" block={true} onClick={this.handleFormSubmit}>
                  {this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.DECLINE')}
                </Button>
              )}
              {this.props.type === 'cancel' && (
                <Button data-test-id="lemon-sessionRejection__cancelSessionButton" type="danger" block={true} onClick={this.handleFormSubmit}>
                  {this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.CANCEL')}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  handleFormSubmit = (event: SyntheticEvent) => {
    this.props.form.validateFields((err: any, values: any) => {
      if (!err && this.props.onFormSubmit) {
        this.props.onFormSubmit(values);
      }
    });
  };

  handleFormCancel = (event: SyntheticEvent) => {
    if (this.props.onFormCancel) {
      this.props.onFormCancel();
    }
  };

  getOtherParticipantUser(): IUserDetails | undefined {
    return TutoringSessionModelHelper.getFirstOtherParticipantUser(this.props.tutoringSession);
  }

  getOtherParticipantName() {
    const otherParticipant = this.getOtherParticipantUser();

    return otherParticipant != null ? otherParticipant.displayName : '';
  }

  getOtherParticipantProfileImageUrl() {
    const otherParticipant = this.getOtherParticipantUser();

    return otherParticipant != null ? otherParticipant.profileImageUrl : '';
  }

  getMessageLabel() {
    if (this.isCurrentUserTutor()) {
      return this.props.translate('TUTORING_SESSION_REJECTION.STUDENT_MESSAGE_LABEL');
    } else {
      return this.props.translate('TUTORING_SESSION_REJECTION.TUTOR_MESSAGE_LABEL');
    }
  }

  getFormTitle() {
    if (this.isSessionCancel()) {
      return this.props.translate('TUTORING_SESSION_REJECTION.TITLE_CANCEL');
    } else if (this.isSessionDecline()) {
      return this.props.translate('TUTORING_SESSION_REJECTION.TITLE_DECLINE');
    } else {
      LOGGER.warn(`Unknown rejection type: ${this.props.type}`);
      return '';
    }
  }

  isSessionCancel() {
    return this.props.type === 'cancel';
  }

  isSessionDecline() {
    return this.props.type === 'decline';
  }

  isCurrentUserTutor() {
    return TutoringSessionModelHelper.isOwner(this.props.tutoringSession, this.props.currentUser);
  }
}
const SessionRejectionForm = Form.create<ISessionRejectionFormProps>()(NormalSessionRejectionForm as any); // TODO: remove as any when antd Form type get's fixed

export default withLocalize<ISessionRejectionFormPublicProps>(SessionRejectionForm as any);
