import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import AppConfigService from '@src/service/common/AppConfigService';
import { CollectionBusinessStore } from '@src/service/business/common/collectionBusinessStore';
import StoreService from '@src/service/business/StoreService';

/** Helper methods for easier loading collections during app init. */
export default class CollectionHelperService {

  /**
   * Load multiple collections in parallel. Used on app startup.
   * This method uses sideffects outside of store which shouldn't be allowed.
   *
   * TODO: move "loadCollections" side-effect to store
   */
  static loadCollections(modelEntities: string[]): Observable<any> {
    return forkJoin(
      modelEntities.map((entityName) => {
        return EntityApiServiceRegistry.getService(entityName)
          .fetchEntityList({
            limit: AppConfigService.getValue('api.collectionDefaultLimit'),
          });
      })
    ).pipe(
      // TODO: resultList should be Array<ICollectionData> but editor complains, this, temporary "any"
      tap((resultList: any[]) => {
        resultList.forEach((data, idx) => {
          StoreService.dispatchAction(CollectionBusinessStore.actions.loadCollection(modelEntities[idx], data));
        });
      })
    );
  }

}
