import { Drawer, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router';

import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import { MenuMode } from 'antd/lib/menu';

// -- Types&Interfaces
// ----------

const AppNavigationKeys: { [key: string]: string } = {
  Favourites: '/favourites',
  Tutors: '/tutors',
  MyRepository: '/repository',
  MyCalendar: '/calendar',
  Reports: '/statistics',
  Instructions: '/instructions',
};

// -- Prop types
// ----------
export interface INavMenuPublicProps {
  currentUser: IUserInfo;
  currentRoutePath: string | undefined;
}
type INavMenuProps = INavMenuPublicProps & IWithLocalizeOwnProps;

interface INavMenuState {
  activeItem: string | undefined;
  isAdmin: boolean;
  isStudent: boolean;
  isTutor: boolean;
  menuIconVisible: boolean;
}

// --
// ----- Component
class NavMenu extends React.Component<INavMenuProps, INavMenuState> {
  state: INavMenuState = {
    activeItem: undefined,
    isAdmin: false,
    isStudent: false,
    isTutor: false,
    menuIconVisible: false,
  };

  componentDidMount() {
    this.updateUserRolesState();
  }

  componentDidUpdate(prevProps: INavMenuProps) {
    if (this.props.currentUser !== prevProps.currentUser) {
      this.updateUserRolesState();
    }
  }

  showDrawer = (e: any) => {
    this.setState({
      menuIconVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      menuIconVisible: false,
    });
  };

  renderMenu = (mode: Readonly<MenuMode>): React.ReactElement => (
    <Menu selectedKeys={this.getActiveItemKey()} mode={mode}>
      <Menu.Item key={AppNavigationKeys.MyCalendar} data-test-id="lemon-header__itemCalendar">
        <Link to={AppNavigationKeys.MyCalendar}>{this.props.translate('APP_NAVIGATION.MY_CALENDAR_LABEL')}</Link>
      </Menu.Item>

      {(this.state.isTutor || this.state.isStudent) && (
        <Menu.Item key={AppNavigationKeys.Favourites} data-test-id="lemon-header__itemFavourites">
          <Link to={AppNavigationKeys.Favourites}>{this.state.isTutor ? this.props.translate('APP_NAVIGATION.FAVOURITES_STUDENTS_LABEL') : this.props.translate('APP_NAVIGATION.FAVOURITES_TUTORS_LABEL')}</Link>
        </Menu.Item>
      )}

      {this.state.isStudent && (
        <Menu.Item key={AppNavigationKeys.Tutors} data-test-id="lemon-header__itemTutors">
          <Link to={AppNavigationKeys.Tutors}>{this.state.isStudent && this.props.translate('APP_NAVIGATION.ALL_TUTORS_LABEL')}</Link>
        </Menu.Item>
      )}

      {(this.state.isTutor || this.state.isAdmin) && (
        <Menu.Item key={AppNavigationKeys.MyRepository} data-test-id="lemon-header__itemMyRepository">
          <Link to={AppNavigationKeys.MyRepository}>{this.props.translate('APP_NAVIGATION.MY_REPOSITORY_LABEL')}</Link>
        </Menu.Item>
      )}

      {/* {!this.state.isAdmin && (
        <Menu.Item key={AppNavigationKeys.Reports} data-test-id="lemon-header__itemReports">
          <Link to={AppNavigationKeys.Reports}>{this.props.translate('APP_NAVIGATION.REPORTS_LABEL')}</Link>
        </Menu.Item>
      )} */}

      {/* {(this.state.isTutor) && (
        <Menu.Item key={AppNavigationKeys.Instructions} data-test-id="lemon-header__itemInstructions">
          <Link to={AppNavigationKeys.Instructions}>{this.props.translate('APP_NAVIGATION.INSTRUCTIONS_LABEL')}</Link>
        </Menu.Item>
      )} */}
    </Menu>
  );

  render() {

    return (
      <div className="lemon-application__headerAppInfo">
        <Link to={this.getLogoLink()}>
          <LemonImage className="lemon-application__headerAppLogo" title={this.props.translate('APPLICATION.APP_LOGO_TITLE')} imagePath="znak.png" />
        </Link>

        {this.props.currentUser && (
          <React.Fragment>
            {this.renderMenu('horizontal')}
            <LemonIcon name="navMobileMenu" className="lemon-navMenu__mobileMenuIcon" onClick={this.showDrawer} />
            <Drawer
              placement="left"
              closable={false}
              onClose={this.onClose}
              visible={this.state.menuIconVisible}>
              {this.renderMenu('vertical')}
            </Drawer>
          </React.Fragment>
        )}
      </div>
    );
  }

  getLogoLink = () => {
    return AppConfigService.getValue('routing.publicDefaultRoute');
  };

  getActiveItemKey(): string[] | undefined {
    if (this.props.currentRoutePath == null) {
      return [];
    }

    return Object.values(AppNavigationKeys).filter((value) => this.props.currentRoutePath != null && this.props.currentRoutePath.startsWith(value));
  }

  private updateUserRolesState() {
    if (this.props.currentUser != null) {
      this.setState({
        isAdmin: this.props.currentUser.roles.some((item) => item.id === UserRoleEnum.ADMINISTRATOR),
        isStudent: this.props.currentUser.roles.some((item) => item.id === UserRoleEnum.STUDENT),
        isTutor: this.props.currentUser.roles.some((item) => item.id === UserRoleEnum.TUTOR),
      });
    } else {
      this.setState({
        isAdmin: false,
        isStudent: false,
        isTutor: false,
      });
    }
  }
}

export default withLocalize<INavMenuPublicProps>(NavMenu as any);
