import AppMessagingBusinessStore, { IAppMessage, IAppMessageData } from '@src/service/business/common/appMessagingBusinessStore';

const NOTIFICATION_INFO_APP_MESSAGING_SOURCE_ID = 'INFO_BAR_APP_MESSAGING_SOURCE_ID';

/** Describes stored app message structure. */
export interface ISimpleNotificationInfoMessage {
  message: string;
  link?: {
    text: string;
    url: string;
  };
}

export type INotificationInfoAppMessage = IAppMessage<ISimpleNotificationInfoMessage>;

// --
// -------------------- Selectors

/** Return notification info. */
const getMessages = (store: any): INotificationInfoAppMessage[] => AppMessagingBusinessStore.selectors.getAppMessages(NOTIFICATION_INFO_APP_MESSAGING_SOURCE_ID, store);

// -
// -------------------- Actions

/** Store notification info in store. */
const addMessage = (info: IAppMessageData<ISimpleNotificationInfoMessage>) => {
  return AppMessagingBusinessStore.actions.addMessage(NOTIFICATION_INFO_APP_MESSAGING_SOURCE_ID, info);
};

/** Remove notification info from store. */
const removeMessage = (id: string) => {
  return AppMessagingBusinessStore.actions.removeMessage(NOTIFICATION_INFO_APP_MESSAGING_SOURCE_ID, id);
};

// --
// ----- Store object

const NotificationInfoBusinessStore = {
  actions: {
    addMessage,
    removeMessage,
  },

  selectors: {
    getMessages,
  },
};

// --
// ----- Exports

export default NotificationInfoBusinessStore;
