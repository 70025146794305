import React from 'react';

import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

// --
// ----- Prop types

export interface IWithPathResolverOwnProps {
  /** Create URL to image from given file path. File path is calculated relatively against image base path. */
  resolveImagePath: (filePath: string) => string;

  /** Create URL to API resource from given resource path. Resource path is calculated relatively against configured API base path. */
  resolveApiPath: (resourcePath: string) => string;
}

// --
// ----- Component

/** Higher order component for injecting function for resolving in-app and app-to-api URLs (eg. to images or to API calls). */
const withPathResolver = <P extends object>(Component: React.ComponentType<P>) =>
  class WithPath extends React.Component<Exclude<P, IWithPathResolverOwnProps>> {
    render() {
      const TypedComponent: React.ComponentType<P & IWithPathResolverOwnProps> = Component as any;
      return (
        <TypedComponent
          {...this.props}
          resolveImagePath={UrlBuilderFactory.buildImageUrl}
          resolveApiPath={UrlBuilderFactory.buildApiUrl}
        />
      );
    }
  };

// ----- exports

export default withPathResolver;
