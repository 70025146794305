import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { rootFolder } from '@src/components/repository/UserFileRepositoryContainer';
import IIdRef from '@src/model/common/IdRef';
import { IPath } from '@src/model/common/Path';
import IFolder from '@src/model/file/Folder';

import FolderList from '@src/components/repository/folders/FolderList';
import FolderSelectHeader from '@src/components/repository/folders/FolderSelectHeader';
import { Button, Modal } from 'antd';

export interface IFolderSelectModalOwnProps {
  folders: IFolder[];
  routes: IPath;
  visible: boolean;
  currentFolder: IPath;
  fromFolder: IPath;
  closeModal: () => void;
  onFolderSelect: (folder: IFolder | IPath) => void;
  onPageChange: (page: number, pageSize?: number) => void;
  onFolderCreate: (name: string) => void;
  onSubmit: (destination: IIdRef<string>) => void;
}
type IFolderSelectModalProps = IFolderSelectModalOwnProps & IWithLocalizeOwnProps;

interface IFolderSelectModalState {
  selected: IFolder | undefined;
}

class FolderSelectModal extends React.Component<IFolderSelectModalProps, IFolderSelectModalState> {
  state: IFolderSelectModalState = {
    selected: undefined,
  };

  render() {
    // elements can be moved to current opened folder with nothing inside selected, or a specific folder can be selected by clicking on it's row
    const destinationFolder: IPath | IFolder = this.state.selected || this.props.currentFolder;
    const currentFolderSelected = destinationFolder.id === this.props.fromFolder.id;
    const getFooter = () => {
      const folderName = destinationFolder.name !== rootFolder.id ? destinationFolder.name : this.props.translate('FILE_LIST.ROOT_FOLDER_DISPLAY_NAME');
      return (
        <div>
          <span>{currentFolderSelected ? this.props.translate('FILE_LIST.MOVE_TO_FOLDER_FROM_FOLDER_SELECTED_TEXT') : this.props.translate('FILE_LIST.MOVE_TO_FOLDER_SELECTED_NAME_DISPLAY', { folderName })}</span>
          <span className="float-right">
            <Button onClick={this.props.closeModal}>{this.props.translate('COMMON.ACTION_CANCEL')}</Button>
            <Button onClick={() => this.props.onSubmit(destinationFolder)} disabled={currentFolderSelected} type="primary">
              {this.props.translate('FILE_LIST.ACTIONS_MOVE_LABEL')}
            </Button>
          </span>
        </div>
      );
    };
    return (
      <Modal title={this.props.translate('FILE_LIST.MOVE_TO_FOLDER_MODAL_TITLE')} className="lemon-modal__modal--md lemon-repository__folderSelectModal" visible={this.props.visible} onCancel={this.props.closeModal} footer={getFooter()}>
        <FolderSelectHeader onFolderCreate={this.props.onFolderCreate} onFolderSelect={this.onFolderSelect} routes={this.props.routes} />

        <FolderList folders={this.props.folders} onFolderSelect={this.onFolderSelect} onPageChange={this.props.onPageChange} onSelectedChange={this.onSelectedChange} selected={this.state.selected} />
      </Modal>
    );
  }

  onRow = (record: IFolder) => ({
    onClick: () => {
      if (this.state.selected && this.state.selected.id === record.id) {
        this.onSelectedChange(undefined);
      } else {
        this.onSelectedChange(record);
      }
    },
  });

  onFolderSelect = (folder: IFolder | IPath) => {
    this.onSelectedChange(undefined);
    this.props.onFolderSelect(folder);
  };

  onSelectedChange = (record: IFolder | undefined) => {
    this.setState({
      selected: record,
    });
  };
}

export default withLocalize<IFolderSelectModalOwnProps>(FolderSelectModal as any);
