import { Badge, Button, Menu } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NotificationMessageContent from '@src/components/login/usermenu/NotificationMessageContent';
import { IMessagingMessage, ISessionMessagingMessage } from '@src/model/messaging/messages';
import { getLogger } from '@src/service/util/logging/logger';
import {ClickParam} from 'antd/es/menu';


const LOGGER = getLogger('NotificationMenu');

const MENU_ITEM_DISMISS_ALL_KEY = 'DISMISS_ALL@MENU_ITEM_KEY';


// -- Prop types
// ----------
export interface INotificationMenuPublicProps {
  messages: IMessagingMessage[];

  onNotificationDismiss?: (id: string) => void;
  onNotificationDismissAll?: () => void;
}
type INotificationMenuProps = INotificationMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps;


// --
// ----- Component
class NotificationMenu extends React.Component<INotificationMenuProps> {
  render() {
    // TODO: default menu open/close icon appears only in iniline menu - this one is only a quick replacement
    const subMenuTitle = (
      <Badge count={this.props.messages.length > 0 ? <Badge color="transparent" /> : null}>
        <LemonIcon name="bell" className="lemon-notificationMenu__mainIcon" />
      </Badge>
    );

    return (
      <div className="lemon-notificationMenu">
        <Menu mode="horizontal" selectable={false} style={{ width: '100%' }} onClick={this.onNotificationClick} onOpenChange={this.onMenuOpenChange} >
          <Menu.SubMenu data-test-id="lemon-notificationMenu" title={subMenuTitle} key="lemon-notificationMenu__submenu">
            {this.props.messages.length === 0 && (
              <Menu.Item disabled={true}>
                {this.props.translate('NOTIFICATION_MENU.COMMON.LIST_EMPTY')}
              </Menu.Item>
            )}

            {/* ----- Dismiss all notifications item ----- */}
            {this.props.messages.length > 0 && (
              <Menu.Item key={MENU_ITEM_DISMISS_ALL_KEY}>
                <div style={{ textAlign: 'center' }}>
                  <Button type="primary" data-test-id="lemon-notificationMenu__dismissAll">{this.props.translate('NOTIFICATION_MENU.COMMON.DISMISS_ALL_BUTTON_LABEL')}</Button>
                </div>
              </Menu.Item>
            )}

            {/* ----- Notification items ----- */}
            {this.props.messages.map((message) => {
              const menuItemContent = <NotificationMessageContent message={message} showCloseButton={true} onDismiss={this.onNotificationDismissClick} />;
              // if content could no be generated, skip item
              if (menuItemContent == null) {
                return;
              }

              return (
                <Menu.Item data-test-id={`lemon-notificationMenu__message_${message.id}`} key={message.id}>{menuItemContent}</Menu.Item>
              );
            }
            )}
          </Menu.SubMenu>
        </Menu>
      </div>
    );
  }

  onMenuOpenChange = (openKeys: string[]) => {
    // TODO_ handle menu open change
  }

  // TODO: attaching dismiss click handler should be done with some kind of event delegation
  onNotificationDismissClick = (id: string) => {
    if (this.props.onNotificationDismiss) {
      LOGGER.info(`Dismissing notification ${id}`);
      this.props.onNotificationDismiss(id);
    }
  }

  onNotificationClick = (event: ClickParam) => {
    event.domEvent.preventDefault();

    const eventKey = event.key;

    if (MENU_ITEM_DISMISS_ALL_KEY === eventKey) {
      if (this.props.onNotificationDismissAll) {
        LOGGER.info('Dismissing all notifications');
        this.props.onNotificationDismissAll();
      }
    }
    else {
      this.props.messages.filter((msg) => eventKey === msg.id).map((msg) => {
        /*
         * TODO: all notification menu items are considered to be session notifications ie. they all have sessionID which is used for navigation to session
         * When other types of notifications are added, make sure to handle it.
         */
        const sessionId = (msg as ISessionMessagingMessage).sessionId;

        LOGGER.info(`Dismissing notification ${msg.id} for session ${sessionId}`);

        if (this.props.onNotificationDismiss) {
          this.props.onNotificationDismiss(msg.id);
        }

        this.props.router.push(`/sessions?sessionId=${sessionId}`);
      });
    }
  }

}

export default withLocalize<INotificationMenuPublicProps>(withRouter(NotificationMenu as any));
