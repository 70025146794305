import { Button, Calendar, Col, Row, Tooltip } from 'antd';
// tslint:disable-next-line:no-submodule-imports - type not exported through main module
import { CalendarMode } from 'antd/lib/calendar';
import moment, { Moment } from 'moment';
import React from 'react';

import CalendarNavigation from '@src/components/common/calendar/CalendarNavigation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonTag from '@src/components/common/tag/LemonTag';
import SessionCalendarList from '@src/components/session/calendar/SessionCalendarList';
import SessionCalendarListFilter from '@src/components/session/calendar/SessionCalendarListFilter';
import { prepareCalendarList } from '@src/components/session/calendar/sessionCalendarListHelper';
import { ISessionCalendarListItem, SESSION_CALENDAR_LIST_DATE_KEY_FORMAT } from '@src/components/session/calendar/types';
import ITutoringSession from '@src/model/session/TutoringSession';
import IUserInfo from '@src/model/user/User';
import { ISessionCalendarListStatusFilter, SessionCalendarListFilterStatus } from '@src/service/business/session/sessionBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';

// -- Prop Types
// ----------

export interface ISessionCalendarOwnProps {
  currentUser: IUserInfo;
  sessions: ITutoringSession[];
  sessionListFilter?: ISessionCalendarListStatusFilter;
  startDateTime: Moment;

  // event listeners
  onUpdateSessionList: () => void;
  onDateChange?: (month: number, year: number) => void;
  onListFilterChange?: (filter: ISessionCalendarListStatusFilter) => void;
  onSessionConfirm?: (session: ITutoringSession, message?: string) => void;
  onSessionDecline?: (session: ITutoringSession, message?: string) => void;
}
type ISessionCalendarProps = ISessionCalendarOwnProps & IWithLocalizeOwnProps;

interface ISessionCalendarState {
  currentDate: Moment;
  calendarMode: CalendarMode;
  sessionCalendarList: ISessionCalendarListItem[];
}

class SessionCalendar extends React.Component<ISessionCalendarProps, ISessionCalendarState> {
  state: ISessionCalendarState = {
    currentDate: moment(),
    calendarMode: 'month',
    sessionCalendarList: [],
  };

  componentDidMount() {
    this.setState(this.deriveStateFromProps(this.props, null));
  }

  componentDidUpdate(prevProps: ISessionCalendarProps) {
    this.setState(this.deriveStateFromProps(this.props, prevProps));
  }

  deriveStateFromProps(newProps: ISessionCalendarProps, prevProps: ISessionCalendarProps | null) {
    if (newProps !== prevProps) {
      return {
        sessionCalendarList: this.prepareCalendarList(),
        // currentDate: wasOrIsPast ? moment() : this.state.currentDate,
        currentDate: newProps.startDateTime.isSame(this.state.currentDate, 'month') ? this.state.currentDate : moment([newProps.startDateTime.year(), newProps.startDateTime.month(), this.state.currentDate.day()]),
      };
    }

    return null;
  }

  dateCellRender = (date: Moment) => {
    let hasConfirmedSessions = false;
    let hasUnconfirmedSessions = false;
    let hasHeldSessions = false;
    let hasCanceledSessions = false;

    // detect if date has un/confirmed sessions
    this.state.sessionCalendarList.find((item) => {
      const cellDateKey = date.format(SESSION_CALENDAR_LIST_DATE_KEY_FORMAT);

      // find data for cell date
      if (item.dateKey === cellDateKey) {
        // TODO: maybe this could be optimized to avoid checking each session for each status
        item.data.forEach((session) => {
          hasConfirmedSessions = hasConfirmedSessions || TutoringSessionModelHelper.isSessionConfirmed(session) || TutoringSessionModelHelper.isSessionInProgress(session);
          hasUnconfirmedSessions = hasUnconfirmedSessions || TutoringSessionModelHelper.isSessionUnconfirmed(session);
          hasHeldSessions = hasHeldSessions || TutoringSessionModelHelper.isSessionSuccessfullyEnded(session);
          hasCanceledSessions = hasCanceledSessions || TutoringSessionModelHelper.isSessionCanceled(session);
        });

        // finish iteration
        return true;
      }

      return false;
    });

    let dateContent = <React.Fragment>{date.get('date')}</React.Fragment>;
    // the order of "if"s is important because we can show only 1 color so we have to pick more important ones first
    if (hasUnconfirmedSessions) {
      dateContent = (
        <Tooltip title={this.props.translate('LEMON_HELPER_TOOLTIP.CALENDAR.UNCONFIRMED_SESSIONS_INFO')}>
          <LemonTag type="scheduledSession">{dateContent}</LemonTag>
        </Tooltip>
      );
    } else if (hasConfirmedSessions) {
      dateContent = (
        <Tooltip title={this.props.translate('LEMON_HELPER_TOOLTIP.CALENDAR.CONFIRMED_SESSIONS_INFO')}>
          <LemonTag type="confirmedSession">{dateContent}</LemonTag>
        </Tooltip>
      );
    } else if (hasHeldSessions) {
      dateContent = <LemonTag type="heldSession">{dateContent}</LemonTag>;
    } else if (hasCanceledSessions) {
      dateContent = <LemonTag type="canceledSession">{dateContent}</LemonTag>;
    }

    return (
      <div className="ant-fullcalendar-date lemon-sessionCalendar__cellContent">
        <div className="ant-fullcalendar-value">{dateContent}</div>
      </div>
    );
  };

  render = () => {
    return (
      <Row gutter={12} type="flex">
        <Col lg={{ span: 16, order: 1 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} className="lemon-sessionClanedar__tabPanel">
          <div className="panel">
            <SessionCalendarListFilter sessionListFilter={this.props.sessionListFilter} onChange={this.handleListFilterChange} onUpdateSessionList={this.props.onUpdateSessionList} />
            <SessionCalendarList
              currentUser={this.props.currentUser}
              items={this.state.sessionCalendarList}
              date={this.state.currentDate.date()}
              month={this.state.currentDate.month()}
              buttonTypes={['confirm', 'decline']}
              year={this.state.currentDate.year()}
              scrollToCurrent={true}
              onSessionConfirm={this.handleSessionConfirm}
              onSessionDecline={this.handleSessionDecline}
            />
          </div>
        </Col>
        <Col lg={{ span: 8, order: 2 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} className="lemon-sessionCalendar__calendar">
          <div className="panel">
            <CalendarNavigation
              currentDate={this.state.currentDate}
              minDate={this.props.sessionListFilter && this.props.sessionListFilter.status !== SessionCalendarListFilterStatus.PAST ? moment() : undefined}
              maxDate={this.props.sessionListFilter && this.props.sessionListFilter.status === SessionCalendarListFilterStatus.PAST ? moment() : undefined}
              mode={this.state.calendarMode}
              onDateChange={this.handleCalendarDateChange}
              onModeChange={this.handleCalendarModeChange}
              onTodaySelect={this.handleCalendarDateChange}
            />
            <Calendar
              key={this.calendarDateKey()}
              fullscreen={false}
              defaultValue={this.state.currentDate}
              mode={this.state.calendarMode}
              dateFullCellRender={this.dateCellRender}
              validRange={[
                moment(AppConfigService.getValue('components.session.calendar.calendarStartDate')),
                moment()
                  .add(1, 'year')
                  .endOf('year'),
              ]}
              onChange={this.handleCalendarDateChange}
            />
            <div>
              <Button className="lemon-calendarNavigation__todayButton" title={this.getTodayButtonTitle()} block={true} onClick={this.handleTodayClick} data-test-id="lemon-calendarNavigation__todayButton">
                {this.props.translate('CALENDAR_NAVIGATION.TODAY')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  // ----- event handlers

  handleListFilterChange = (filter: ISessionCalendarListStatusFilter) => {
    if (this.props.onListFilterChange) {
      this.props.onListFilterChange(filter);
    }
  };

  handleCalendarDateChange = (newDate?: moment.Moment) => {
    if (newDate != null) {
      const month = newDate.month();
      const year = newDate.year();
      const date = newDate.date();

      if (this.state.currentDate.date() !== date || this.state.currentDate.month() !== month || this.state.currentDate.year() !== year) {
        this.setState({
          currentDate: moment(newDate),
        });

        // trigger date change only on month/year change, date change is only local event
        if (this.state.currentDate.month() !== month || this.state.currentDate.year() !== year) {
          if (this.props.onDateChange != null) {
            this.props.onDateChange(month, year);
          }
        }
      }
    }
  };

  handleCalendarModeChange = (nextMode: CalendarMode) => {
    this.setState({
      calendarMode: nextMode,
    });
  };

  handleSessionConfirm = (session: ITutoringSession, message?: string) => {
    if (this.props.onSessionConfirm) {
      this.props.onSessionConfirm(session, message);
    }
  };

  handleSessionDecline = (session: ITutoringSession, message?: string) => {
    if (this.props.onSessionDecline) {
      this.props.onSessionDecline(session, message);
    }
  };

  handleTodayClick = () => {
    if (this.props.onDateChange) {
      const today = this.today();
      this.handleCalendarDateChange(today);
    }
  };

  getTodayButtonTitle() {
    return this.today().format(`${AppConfigService.getValue('dateTimeFormat.dayOfWeekName')}, ${AppConfigService.getValue('dateTimeFormat.dayOfMonthAndMonthName')}`);
  }

  calendarDateKey() {
    return `${this.state.currentDate.year()}-${this.state.currentDate.month()}-${this.state.currentDate.date()}`;
  }

  // ----- session calendar list methods

  prepareCalendarList() {
    // return prepareCalendarList(this.props.sessions, currentYear, currentMonth);
    return prepareCalendarList(this.props.sessions);
  }

  // ----- private

  private today(): Moment {
    return moment();
  }
}

export default withLocalize<ISessionCalendarOwnProps>(SessionCalendar as any);
