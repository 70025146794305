import numeral from 'numeral';

import AppConfigService from '@src/service/common/AppConfigService';

export default class NumberFormatService {

  /* methods in Numeral.js, TODO: remove this

  locale(key?: string): string;
  localeData(key?: string): NumeralJSLocale;
  localeData(key?: string): NumeralJSLocale;
  register(what: RegisterType, key: string, value: NumeralJSLocale | NumeralJsFormat): NumeralJSLocale | NumeralJsFormat;

  zeroFormat(format: string): void;
	nullFormat(format: string): void;
	defaultFormat(format: string): void;
	clone(): Numeral;
	format(inputString?: string, roundingFunction?: RoundingFunction): string;
	unformat(inputString: string): number;
	value(): number;
	valueOf(): number;
	set (value: any): Numeral;
	add(value: any): Numeral;
	subtract(value: any): Numeral;
	multiply(value: any): Numeral;
	divide(value: any): Numeral;
	difference(value: any): number;
	validate(value: any, culture: any): boolean;
  */

  static register = (what: RegisterType, key: string, value: NumeralJSLocale | NumeralJsFormat): NumeralJSLocale | NumeralJsFormat => {
    return numeral.register(what, key, value);
  }

  /**
   * This function sets the current locale.
   * If no argument is passed in, it will return the current global locale key.
   */
  static locale = (key?: string): string => {
    return numeral.locale(key);
  }

  /**
   * General (and only) formatting method in Numeral.js
   */
  static format = (value: number, format?: string, roundingFunction?: RoundingFunction): string => {
    return numeral(value).format(format, roundingFunction);
  }

  /**
   * Get number from formatted string
   */
  static unformat = (inputString: string): number => {
    return numeral.unformat(inputString);
  }

  /**
   * format value as plain number
   */
  static formatNumber = (value: number, format: string): string => {
    return NumberFormatService.format(value, format);
  }

  /**
   * format value with currency symbol
   * '$' currency format string is added if missing in the format string
   */
  static formatCurrency = (value: number, format: string = AppConfigService.getValue('numberFormat.currency')): string => {
    return NumberFormatService.format(value, format.indexOf('$') < 0 ? `${format} $` : format);
  }

  /**
   * format value (i.e. file size) in bytes
   * automatically returns B, KB, MB, GB or TB
   * 'b' format string is added if missing in the format string
   */
  static formatBytes = (value: number, format: string = AppConfigService.getValue('numberFormat.bytes')): string => {
    return NumberFormatService.format(value, format.indexOf('b') < 0 ? `${format} b` : format);
  }

  /**
   * format value percentage
   */
  static formatPercentage = (value: number, format: string = AppConfigService.getValue('numberFormat.percentage')): string => {
    return NumberFormatService.format(value, format.indexOf('%') < 0 ? `${format}%` : format);
  }

  /**
   * format value as time
   * value represents minutes since midnight
   * use Moment.js instead
   */
  static formatTime = (value: number): string => {
    return NumberFormatService.format(value, AppConfigService.getValue('numberFormat.time'));
  }

  /**
   * format value as time
   * value represents minutes since midnight
   * use Moment.js instead
   */
  static formatExponential = (value: number, format: string = AppConfigService.getValue('numberFormat.exponential')): string => {
    return NumberFormatService.format(value, format.indexOf('e+0') < 0 ? `${format}e+0` : format);
  }

}
