import { IMessagingMessage, MessagingMessageType } from '@src/model/messaging/messages';
import { ComponentMessageType } from '@src/service/util/pubsub/componentMessageTypes';


/**
 * Class contains various messaging helper methods.
 *
 * These methods can be used in various places so, they have been gathered here to avoid duplication (DRY).
 */
export default class MessagingHelper {

  /** Should we store this message in order to display it to user? */
  static isDisplayableMessage(message: IMessagingMessage) {
    if (MessagingMessageType.SESSION_INCOMING_NOTICE === message.type
      || MessagingMessageType.SESSION_END_NOTICE === message.type
      || MessagingMessageType.SESSION_TIME_CHANGE === message.type
      || MessagingMessageType.SESSION_CREATED === message.type
      || MessagingMessageType.SESSION_CONFIRMATION === message.type
      || MessagingMessageType.SESSION_CANCELATION === message.type
      || MessagingMessageType.SESSION_DECLINED === message.type
      || MessagingMessageType.SESSION_MESSAGE_SENT === message.type
      || MessagingMessageType.SESSION_FILE_ADDED === message.type
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  /** Maps MessagingMessageType to ComponentMessageType */
  static mapMessagingTypeToComponentType(messageType: MessagingMessageType) {
    if (MessagingMessageType.SESSION_INCOMING_NOTICE === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_INCOMING_NOTICE;
    }
    else if (MessagingMessageType.SESSION_END_NOTICE === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_END_NOTICE;
    }
    else if (MessagingMessageType.SESSION_TIME_CHANGE === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_TIME_CHANGE;
    }
    else if (MessagingMessageType.SESSION_CREATED === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_CREATED;
    }
    else if (MessagingMessageType.SESSION_CONFIRMATION === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_CONFIRMATION;
    }
    else if (MessagingMessageType.SESSION_CANCELATION === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_CANCELATION;
    }
    else if (MessagingMessageType.SESSION_DECLINED === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_DECLINED;
    }
    else if (MessagingMessageType.SESSION_ENDED === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_ENDED;
    }
    else if (MessagingMessageType.SESSION_MESSAGE_SENT === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_MESSAGE_SENT;
    }
    else if (MessagingMessageType.SESSION_FILE_ADDED === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_FILE_ADDED;
    }
    else if (MessagingMessageType.SESSION_FILE_REMOVED === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_FILE_REMOVED;
    }
    else if (MessagingMessageType.WHITEBOARD_SCREENSHOTS_CREATED === messageType) {
      return ComponentMessageType.MESSAGING_WHITEBOARD_SCREENSHOTS_CREATED;
    }
    else if (MessagingMessageType.SESSION_PARTICIPANT_LEFT === messageType) {
      return ComponentMessageType.MESSAGING_SESSION_PARTICIPANT_LEFT;
    }
    else {
      return null;
    }
  }

}
