import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';

import moment from 'moment';

import { IPayloadAction, ICollectionFetchPayload, ILemonAction } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import ITutoringSession from '@src/model/session/TutoringSession';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { TutoringSessionStatusEnum } from '@src/model/session/TutoringSessionStatus';
import { TutoringSessionEndStatusEnum } from '@src/model/session/TutoringSessionEndStatus';
import AppConfigService from '@src/service/common/AppConfigService';
import { IUserInfo } from '@src/model/user/User';
import IUserDetails from '@src/model/user/UserDetails';
import { ISessionListFilter } from '@src/service/business/session/sessionBusinessStore';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('relatedSessionBusinessStore');

// -
// -------------------- Types&Consts

const BACKEND_DATE_FORMAT = AppConfigService.getValue('dateTimeFormat.backendDateWithTimezone');


// -
// -------------------- Selectors

/** Returns previous tutoring session from store. */
const getPreviousSession = (store: any): ITutoringSession => store.previousTutoringSession;


// -
// -------------------- Actions

const Actions = {
    PREVIOUS_TUTOR_SESSION_FETCH: 'PREVIOUS_TUTOR_SESSION_FETCH',
    PREVIOUS_TUTOR_SESSION_LOAD: 'PREVIOUS_TUTOR_SESSION_LOAD',
    PREVIOUS_TUTOR_SESSION_CLEAR: 'PREVIOUS_TUTOR_SESSION_CLEAR',
};

/** Fetch previous related session. */
const fetchPreviousSession = (session: ITutoringSession, currentUser: IUserInfo | IUserDetails): IPayloadAction<ICollectionFetchPayload<ISessionListFilter>> => {
    const otherParticipant = session.sessionParticipants.find((participant) => participant.user.id !== currentUser.id);

    return {
        type: Actions.PREVIOUS_TUTOR_SESSION_FETCH,
        payload: {
            filter: {
                endDateTime: session.startDateTime, // ended before this one started
                startDateTime: moment(AppConfigService.getValue('components.session.calendar.calendarStartDate')).format(BACKEND_DATE_FORMAT),
                participant: otherParticipant && otherParticipant.user.id,
                // TODO: we should include other non-ended statuses, but API does not support that kind of boolean conditions
                tutoringSessionStatuses: [TutoringSessionStatusEnum.ENDED], // TutoringSessionStatusEnum.CONFIRMED, TutoringSessionStatusEnum.IN_PROGRESS],
                tutoringSessionEndStatuses: [TutoringSessionEndStatusEnum.SUCCESSFUL, TutoringSessionEndStatusEnum.SYSTEM_ENDED],
                educationArea: session.educationArea && session.educationArea.id,
            },
            // default values
            page: 0,
            size: 1,
            // sort by start time desceding so we have the last one on top
            sort: ['startDateTime,desc'],
        },
    };
};

const loadPreviousSession = (data: ITutoringSession): IPayloadAction<ITutoringSession> => {
    return {
        type: Actions.PREVIOUS_TUTOR_SESSION_LOAD,
        payload: data,
    };
};

const clearPreviousSessionData = (): ILemonAction => {
    return {
        type: Actions.PREVIOUS_TUTOR_SESSION_CLEAR,
    };
};


// -
// -------------------- Side-effects

const fetchPreviousSessionEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISessionListFilter>>>, state$: Observable<any>) => {
    return action$.pipe(
        filter((action) => {
            return action.type === Actions.PREVIOUS_TUTOR_SESSION_FETCH;
        }),

        startGlobalProgress(),

        mergeMap((action) => {
            const payload = action.payload;

            return (EntityApiServiceRegistry.getService('TutoringSession')).fetchEntityList(payload).pipe(
                actionThunk(action)
            );
        }),

        stopGlobalProgress(),

        map((data) => {
            // take the first session from the list aka. The Previous Session
            if (data.content.length > 0) {
                return loadPreviousSession(data.content.slice(0, 1).shift());
            }
            // no previous sessions? clean it up
            else {
                return clearPreviousSessionData();
            }
        }),

        reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

        catchError((error: any, o: Observable<any>) => {
            LOGGER.error('Error fetching previous session', error);
            return o;
        })
    );
};


// -
// -------------------- Reducers

const previousTutoringSession = (state: ITutoringSession | null = null, action: IPayloadAction<ITutoringSession>) => {
    if (action.type === Actions.PREVIOUS_TUTOR_SESSION_LOAD) {
        return {
            ...action.payload,
        };
    } else if (action.type === Actions.PREVIOUS_TUTOR_SESSION_CLEAR) {
        return null;
    }

    return state;
};


// --
// -------------------- Business Store

export const RelatedSessionBusinessStore = {
    actions: {
        fetchPreviousSession, loadPreviousSession, clearPreviousSessionData,
    },

    selectors: {
        getPreviousSession,
    },

    effects: {
        fetchPreviousSessionEffect,
    },

    reducers: {
        previousTutoringSession,
    },
};

// --
// ----- Exports

export default RelatedSessionBusinessStore;
