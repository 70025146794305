import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import ITimelineActivity from '@src/model/timeline/TimelineActivity';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole } from '@src/model/user/UserRole';
import { IUserStatus } from '@src/model/user/UserStatus';
import { IUserVerification } from '@src/model/user/UserVerification';
import { IUserVerificationStatus } from '@src/model/user/UserVerificationStatus';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { getLogger } from '@src/service/util/logging/logger';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

const LOGGER = getLogger('userListBusinessStore');

// -
// -------------------- Types&Consts

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface IUserListFilter {
  // [key: string]: any;
  id?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  status?: IUserStatus;
  role?: IUserRole[];
  verifiedStatus?: IUserVerificationStatus;
}

export interface IUserActivityListFilter {
  creator?: string;
  activityType?: string;
  startDate?: string;
  endDate?: string;
  // [key: string]: any;
}

const USER_LIST_FILTER_NAME = 'USER_LIST_FILTER_NAME';

const USER_ACTIVITY_LIST_FILTER_NAME = 'USER_ACTIVITY_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getUserList = (store: any): ICollectionData<IUserInfo> => store.userList;

const getUserListFilter = (store: any): IUserListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_LIST_FILTER_NAME);

const getUserActivityList = (store: any): ICollectionData<ITimelineActivity> => store.userActivityList;

const getUserActivityListFilter = (store: any): IUserActivityListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_ACTIVITY_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  USER_SEARCH_LIST_FETCH: 'USER_SEARCH_LIST_FETCH',
  USER_SEARCH_LIST_LOAD: 'USER_SEARCH_LIST_LOAD',
  USER_SEARCH_LIST_CLEAR: 'USER_SEARCH_LIST_CLEAR',
  USER_STATUS_UPDATE: 'USER_STATUS_UPDATE',
  USER_ACTIVITY_LIST_FETCH: 'USER_ACTIVITY_LIST_FETCH',
  USER_ACTIVITY_LIST_LOAD: 'USER_ACTIVITY_LIST_LOAD',
  USER_ACTIVITY_LIST_CLEAR: 'USER_ACTIVITY_LIST_CLEAR',
  USER_VERIFICATION_UPDATE: 'USER_VERIFICATION_UPDATE',
};

const fetchUserList = (params: ICollectionFetchPayload<IUserListFilter>): IPayloadAction<ICollectionFetchPayload<IUserListFilter>> => {
  return {
    type: Actions.USER_SEARCH_LIST_FETCH,
    payload: params,
  };
};

const loadUserList = (data: ICollectionData<IUserInfo>): IPayloadAction<ICollectionData<IUserInfo>> => {
  return {
    type: Actions.USER_SEARCH_LIST_LOAD,
    payload: data,
  };
};

const clearUserList = (): ILemonAction => {
  return {
    type: Actions.USER_SEARCH_LIST_CLEAR,
  };
};

const storeUserListFilter = (listFilter: IUserListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_LIST_FILTER_NAME, listFilter);
};

const clearUserListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(USER_LIST_FILTER_NAME);
};

const updateUserStatus = (id: string, status: IIdPayload): IPayloadAction<IIdDataPayload<IIdPayload>> => {
  return {
    type: Actions.USER_STATUS_UPDATE,
    payload: {
      id,
      data: status,
    },
  };
};

const fetchUserActivityList = (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<IUserActivityListFilter>> => {
  return {
    type: Actions.USER_ACTIVITY_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadUserActivityList = (data: ICollectionData<ITimelineActivity>): IPayloadAction<ICollectionData<ITimelineActivity>> => {
  return {
    type: Actions.USER_ACTIVITY_LIST_LOAD,
    payload: data,
  };
};

const clearUserActivityList = (): ILemonAction => {
  return {
    type: Actions.USER_ACTIVITY_LIST_CLEAR,
  };
};

const storeUserActivityListFilter = (listFilter: IUserActivityListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_ACTIVITY_LIST_FILTER_NAME, listFilter);
};

const updateUserVerification = (id: string, data: IUserVerification): IPayloadAction<IIdDataPayload<IIdPayload>> => {
  return {
    type: Actions.USER_VERIFICATION_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchUserListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SEARCH_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching user list', error);

      return o;
    })
  );
};

const updateUserStatusEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdPayload>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_STATUS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .updateSubobject(id, 'status', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating tutor details', error);
      return o;
    })
  );
};

const fetchUserActivityListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserActivityListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_ACTIVITY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const params = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .fetchObjectList('activities', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserActivityList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching activity list', error);
      return o;
    })
  );
};

const updateUserVerificationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdDataPayload<IUserVerification>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_VERIFICATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .updateSubobject(id, 'verifications', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating user verification', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const userList = (state: ICollectionData<IUserInfo> | null = null, action: IPayloadAction<ICollectionData<IUserInfo>>) => {
  if (action.type === Actions.USER_SEARCH_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_SEARCH_LIST_CLEAR) {
    return null;
  }

  return state;
};

const userActivityList = (state: ICollectionData<ITimelineActivity> | null = null, action: IPayloadAction<ICollectionData<ITimelineActivity>>) => {
  if (action.type === Actions.USER_ACTIVITY_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_ACTIVITY_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserListBusinessStore = {
  actions: {
    fetchUserList,
    loadUserList,
    clearUserList,
    storeUserListFilter,
    clearUserListFilter,
    updateUserStatus,
    fetchUserActivityList,
    storeUserActivityListFilter,
    loadUserActivityList,
    clearUserActivityList,
    updateUserVerification,
  },

  selectors: {
    getUserList,
    getUserListFilter,
    getUserActivityList,
    getUserActivityListFilter,
  },

  effects: {
    fetchUserListEffect,
    updateUserStatusEffect,
    fetchUserActivityListEffect,
    updateUserVerificationEffect,
  },

  reducers: {
    userList,
    userActivityList,
  },
};

// --
// ----- Exports

export default UserListBusinessStore;
