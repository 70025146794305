import { Globals } from '@src/service/util/Globals';

/** Util methods for accessing app's environment ie. app URL, ... */
export class EnvironmentUtils {

  /** Return apps current URL (protocol://domain:port) */
  static getAppUrl(): string {
    const location = Globals.window?.document?.location;

    if (location != null) {
      return `${location?.protocol}//${location?.host}`;
    }
    else {
      return '';
    }
  }
}
