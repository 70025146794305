import React from 'react';
import { Button, Row, Col, Typography, Tooltip } from 'antd';
// tslint:disable-next-line:no-submodule-imports - type not exported through main module
import { CalendarMode } from 'antd/lib/calendar';
import moment, { Moment } from 'moment';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LocalizeService from '@src/service/localize/LocalizeService';


// -- Prop types
// ----------

export interface ICalendarNavigationOwnProps {
  mode: CalendarMode;
  currentDate: Moment;
  minDate?: Moment;
  maxDate?: Moment;

  // event handlers
  onTodaySelect?: () => void;
  onDateChange?: (date: Moment) => void;
  onModeChange?: (mode: CalendarMode) => void;
}
type ICalendarNavigationProps = ICalendarNavigationOwnProps & IWithLocalizeOwnProps;

interface ICalendarNavigationState {
  displayDate: string | undefined;
}

// -- Component
// ----------

class CalendarNavigation extends React.Component<ICalendarNavigationProps, ICalendarNavigationState> {

  state: ICalendarNavigationState = {
    displayDate: undefined,
  };

  componentDidMount() {
    this.setState(this.deriveStateFromProps(this.props, null));
  }

  componentDidUpdate(prevProps: ICalendarNavigationProps) {
    this.setState(this.deriveStateFromProps(this.props, prevProps));
  }

  deriveStateFromProps(nextProps: ICalendarNavigationProps, prevProps: ICalendarNavigationProps | null): Pick<ICalendarNavigationState, 'displayDate'> | null {
    if (prevProps == null || nextProps !== prevProps) {
      return {
        displayDate: nextProps.currentDate.format('MMMM YYYY.'),
      };
    }

    return null;
  }

  render = () => {
    return (
      <Row type="flex" align="middle" justify="center" className="lemon-calendarNavigation__container">
        <Col>
          <Tooltip title={getPreviousButtonLabel(this.props.mode, this.props.currentDate)}>
            <Button
              className="lemon-calendarNavigation__previousButton"
              disabled={this.props.minDate && this.props.minDate.isSame(this.props.currentDate, 'month')}
              type="link"
              onClick={() => this.handleDateChangeClick(-1, this.props.mode)}
              data-test-id="lemon-calendarNavigation__previousButton"
            >
              <LemonIcon name="left" size="large" />
            </Button>
          </Tooltip>
        </Col>

        <Col className="lemon-calendarNavigation__currentMonthContainer">
          <Typography.Title level={4} className="lemon-calendarNavigation__currentMonth" data-test-id="lemon-calendarNavigation__currentMonth">{this.state.displayDate}</Typography.Title>
        </Col>

        <Col>
          <Tooltip title={getNextButtonLabel(this.props.mode, this.props.currentDate)}>
            <Button
              className="lemon-calendarNavigation__nextButton"
              disabled={this.props.maxDate && this.props.maxDate.isSame(this.props.currentDate, 'month')}
              type="link"
              onClick={() => this.handleDateChangeClick(+1, this.props.mode)}
              data-test-id="lemon-calendarNavigation__nextButton"
            >
              <LemonIcon name="right" size="large" />
            </Button>
          </Tooltip>
        </Col>
        {/* currently disabled */}
        {/*
        <Col span={12}>
          <Button onClick={this.handleModeButtonClick}>{this.getModeButtonLabel()}</Button>
       </Col>
       */}
      </Row>
    );
  }

  handleDateChangeClick = (amount: number, unit: CalendarMode) => {
    if (this.props.onDateChange) {
      const nextDate = moment(this.props.currentDate).add(amount, unit);
      this.props.onDateChange(nextDate);
    }
  }

  handleModeButtonClick = () => {
    const nextMode = this.props.mode === 'year' ? 'month' : 'year';
    if (this.props.onModeChange) {
      this.props.onModeChange(nextMode);
    }
  }

  getModeButtonLabel() {
    if (this.props.mode === 'month') {
      return this.props.translate('CALENDAR_NAVIGATION.MODE_MONTH');
    }
    else if (this.props.mode === 'year') {
      return this.props.translate('CALENDAR_NAVIGATION.MODE_YEAR');
    }
    else {
      return '';
    }
  }

}

export default withLocalize<ICalendarNavigationOwnProps>(CalendarNavigation as any);


/*------ Helper methods for calculating navigation button labels */

export const getPreviousButtonLabel = (mode: CalendarMode, currentDate: Moment) => {
  if (mode === 'month') {
    const prevMonthName = moment(currentDate).add(-1, 'month').format('MMMM');
    return LocalizeService.translate('CALENDAR_NAVIGATION.PREVIOUS_MONTH', { month: prevMonthName });
  }
  else if (mode === 'year') {
    const prevYearName = moment(currentDate).add(-1, 'year').format('YYYY');
    return LocalizeService.translate('CALENDAR_NAVIGATION.PREVIOUS_YEAR', { year: prevYearName });
  }
  else {
    return '';
  }
};

export const getNextButtonLabel = (mode: CalendarMode, currentDate: Moment) => {
  if (mode === 'month') {
    const nextMonthName = moment(currentDate).add(1, 'month').format('MMMM');
    return LocalizeService.translate('CALENDAR_NAVIGATION.NEXT_MONTH', { month: nextMonthName });
  }
  else if (mode === 'year') {
    const nextYearName = moment(currentDate).add(1, 'year').format('YYYY');
    return LocalizeService.translate('CALENDAR_NAVIGATION.NEXT_YEAR', { year: nextYearName });
  }
  else {
    return '';
  }
};

