import React from 'react';

import { Button, message } from 'antd';

import FileRepositoryUpload from '@src/components/repository/FileRepositoryUpload';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IFile from '@src/model/file/File';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import AddFileFromRepositoryModal from '@src/components/session/common/AddFileFromRepositoryModal';
import SessionFileTable from './SessionFileTable';
import { IUserInfo } from '@src/model/user/User';
import { FilePermissionEnum } from '@src/model/file/File';
import IIdRef from '@src/model/common/IdRef';
import LemonIcon from '@src/components/common/image/LemonIcon';


export const hasPermission = (permissions: Array<IIdRef<FilePermissionEnum>> | undefined, id: string) => {
  return permissions ? permissions.some((permission: any) => permission.id === id) : false;
};

// -- Prop types
// ----------

export interface ISessionFileListOwnProps {
  handleUploadFile: (file: IFile[]) => void;
  fileList: IFile[];
  onFileRemove: (file: IFile[]) => void;
  onFileUpdate: (data: IFile) => void;
  currentUser: IUserInfo;
  isCurrentUserOwner: boolean;
}
type ISessionFileListProps = ISessionFileListOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface ISessionFileListState {
  openRepositoryModal: boolean;
}


class SessionFileList extends React.Component<ISessionFileListProps, ISessionFileListState> {

  constructor(props: any) {
    super(props);

    this.state = {
      openRepositoryModal: false,
    };
  }

  isMyFile = (id: string) => {
    return id === this.props.currentUser.id;
  }

  render() {

    const tutorFiles: IFile[] = [];
    const studentFiles: IFile[] = [];
    this.props.fileList.forEach((item: any) => {
      if ((this.props.isCurrentUserOwner && this.isMyFile(item.creator.id)) || (!this.props.isCurrentUserOwner && !this.isMyFile(item.creator.id) && hasPermission(item.permissions, FilePermissionEnum.VIEW))) {
        tutorFiles.push(item);
      } else {
        studentFiles.push(item);
      }
    });

    return (
      <FileRepositoryUpload handleUploadFile={this.props.handleUploadFile} uploadProps={{ openFileDialogOnClick: false }}>
        {this.props.isCurrentUserOwner &&
          <React.Fragment>
            <Button onClick={this.toggleModalVisible}>
              <LemonIcon name="paperClip" />{this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.ADD_FROM_REPOSITORY')}
            </Button>

            <AddFileFromRepositoryModal closeModal={this.toggleModalVisible} onSubmit={this.onModalSubmit} visible={this.state.openRepositoryModal} />
          </React.Fragment>
        }
        <FileRepositoryUpload handleUploadFile={this.props.handleUploadFile}>
          <Button>
            <LemonIcon name="paperClip" />{this.props.translate('TUTORING_SESSION_VIEW.ACTIONS.UPLOAD_FILE')}
          </Button>
        </FileRepositoryUpload>


        {!!tutorFiles.length &&
          <div>
            {this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.TUTOR_FILES_TITLE')}
            <SessionFileTable
              files={tutorFiles}
              onFileDelete={this.props.onFileRemove}
              onFileRename={this.handleRename}
              canEdit={this.props.isCurrentUserOwner}
              onVisibilityToggle={this.props.isCurrentUserOwner ? this.handleVisibilityToggle : undefined}
            />
          </div>}

        {!!studentFiles.length &&
          <div>
            {this.props.isCurrentUserOwner ? this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_FILES_TITLE') : this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_OWN_FILES_TITLE')}
            <SessionFileTable
              files={studentFiles}
              onFileDelete={this.props.onFileRemove}
              onFileRename={this.handleRename}
              canEdit={!this.props.isCurrentUserOwner}
            />
          </div>}
      </FileRepositoryUpload>


    );
  }

  onModalSubmit = (data: IFile[]) => {
    this.props.handleUploadFile(data);
    this.toggleModalVisible();
  }

  handleRename = (data: IFile) => {
    this.props.onFileUpdate(data);
    message.success(data.name);
  }

  handleVisibilityToggle = (data: IFile) => {
    let newFile;
    if (hasPermission(data.permissions, FilePermissionEnum.VIEW)) {
      newFile = { ...data, permissions: [] };
    } else {
      newFile = { ...data, permissions: [{ id: FilePermissionEnum.VIEW }] };
    }
    this.props.onFileUpdate(newFile);
  }

  toggleModalVisible = () => {
    this.setState({
      openRepositoryModal: !this.state.openRepositoryModal,
    });
  }
}

export default withLocalize(withPathResolver<ISessionFileListOwnProps>(SessionFileList as any));
