export default {
  today: 'Danas',
  now: 'Sada',
  backToToday: 'Povratak na danas',
  ok: 'Uredu',
  clear: 'Očisti',
  month: 'Mjesec',
  year: 'Godina',
  timeSelect: 'Izaberi vrijeme',
  dateSelect: 'Izaberi datume',
  monthSelect: 'Izaberi mjesec',
  yearSelect: 'Izaberi godinu',
  decadeSelect: 'Izaberi desetljeće',
  yearFormat: 'YYYY.',
  dateFormat: 'DD.MM.YYYY.',
  dayFormat: 'D',
  dateTimeFormat: 'DD.MM.YYYY. HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Prethodni mjesec (PageUp)',
  nextMonth: 'Sljedeći mjesec (PageDown)',
  previousYear: 'Prethodna godina (Control + left)',
  nextYear: 'Sljedeća godina (Control + right)',
  previousDecade: 'Prethodno desetljeće',
  nextDecade: 'Sljedeće desetljeće',
  previousCentury: 'Prethodno stoljeće',
  nextCentury: 'Sljedeće stoljeće',
};
