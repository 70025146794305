import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonImage from '@src/components/common/image/LemonImage';


export interface ILemonDefaultErrorPublicProps {

}
type ILemonDefaultErrorProps = ILemonDefaultErrorPublicProps & IWithLocalizeOwnProps;


class LemonDefaultError extends React.Component<ILemonDefaultErrorProps> {
  render() {
    return (
      <div className="lemon-error__errorPlaceholderContainer">
        <LemonImage imagePath="sad_lemon.png" />
        <br />
        <div className="lemon-error__errorDesriptionContainer">
          <h2>{this.props.translate('ERROR_PAGE.DEFAULT_ERROR_TITLE')}</h2>
          <h4>{this.props.translate('ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION')}</h4>
        </div>
      </div>
    );
  }
}

export default withLocalize<ILemonDefaultErrorPublicProps>(LemonDefaultError as any);
