import { Button, Dropdown, Menu } from 'antd';
import React from 'react';

import { MenuItemClickEvent } from '@src/components/common/admin/adminmenu/AdminMenu';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';

const locales = AppConfigService.getValue('app.locales');

// -- Prop types
// ----------
export interface ILocalePickerOwnProps {
  onLocaleChange: (event: MenuItemClickEvent) => void;
  locale: string;
  standalone?: boolean;
}

export interface ILocalePickerStateProps {}

export interface ILocalePickerDispatchProps {}
type ILocalePickerProps = ILocalePickerOwnProps & ILocalePickerStateProps & ILocalePickerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Menu component for picking app locale */
class LocalePicker extends React.Component<ILocalePickerProps> {
  renderMenu = () => {
    return (
      <Menu mode={'vertical'} onClick={this.props.onLocaleChange} defaultSelectedKeys={[this.props.locale]}>
        <Menu.SubMenu title={this.props.translate('USER_MENU.CHANGE_LANGUAGE_LABEL')}>{this.renderMenuItems()}</Menu.SubMenu>
      </Menu>
    );
  };

  renderDropdownMenu = () => {
    return (
      <Menu onClick={this.props.onLocaleChange} defaultSelectedKeys={[this.props.locale]}>
        {this.renderMenuItems()}
      </Menu>
    );
  };

  renderMenuItems = () => {
    return locales.map((locale: string) => {
      return <Menu.Item key={locale}>{this.props.translate(`LOCALE_LABEL.${locale.toUpperCase()}`)}</Menu.Item>;
    });
  };

  render = () => {
    return this.props.standalone ? (
      <div className="lemon-loginMenu__loginMenu">
        <Dropdown overlay={this.renderDropdownMenu()}>
          <Button className="lemon-loginMenu__button" type="link">
            {this.props.translate('USER_MENU.CHANGE_LANGUAGE_LABEL')}
          </Button>
        </Dropdown>
      </div>
    ) : (
      <div className="lemon-userMenu__submenu">{this.renderMenu()}</div>
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ILocalePickerOwnProps>(LocalePicker as any);
