import ApiResponseErrorException from '@src/service/api/ApiResponseErrorException';
import LocalizeService from '@src/service/localize/LocalizeService';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { LangUtils } from '@src/service/util/LangUtils';


/** Static UserFeedback user error creator. Simply translates static message key and returns user feedback error object. */
export function createStaticMessageUserFeedbackError(messageKey: string): IUserFeedbackMessagePayload {
  return createUserFeedback([
    () => mapKeyToMessage(messageKey),
  ], UserFeedbackMessageSeverity.ERROR, UserFeedbackMessageType.USER);
}

/** Standard API response error creator. Tries to map API response code to message, first using provided key prefix or translation map, then using default prefix and finaly fallbacks to static message mapper. */
export function createApiResponseUserFeedbackError(error: any, translationKeyMap: string | { [key: string]: string }, fallbackMessageKey: string): IUserFeedbackMessagePayload {
  return createUserFeedback([
    () => mapApiResponseCodeToMessage(error, translationKeyMap),
    () => mapApiResponseCodeToMessage(error, API_RESPONSE_ERROR_MESSAGE_PREFIX),
    () => mapKeyToMessage(fallbackMessageKey),
  ], UserFeedbackMessageSeverity.ERROR, UserFeedbackMessageType.USER);
}

/** Generic UserFeedback object creator. Takes a list of message mapper functions and returns user feedback object with mapped message and given severity/type. */
export function createUserFeedback(mappers: IValueMapper[], severity: UserFeedbackMessageSeverity, type: UserFeedbackMessageType): IUserFeedbackMessagePayload {
  const message: string = valueMapper(mappers) || '<unknown error>';

  return {
    severity,
    type,
    message,
  };
}


// ----- Static message mapper

/** Translates given key and returns message. No special mapping. Can be used for static or fallback messages. */
export function mapKeyToMessage(messageKey: string): string {
  return LocalizeService.translate(messageKey);
}


// ----- Map API response errors

const API_RESPONSE_ERROR_MESSAGE_PREFIX = 'API_RESPONSE_ERROR';

/** Maps API response error code to message using provided code-key translation map. Returns null if message is missing. */
/*export function mapApiResponseCodeToMessage(error: ApiResponseErrorException, codeToKeyMap: { [code: string]: string }): string | null {
  let message = null;

  if ((error instanceof ApiResponseErrorException)) {
    if (codeToKeyMap != null && codeToKeyMap[error.code] != null) {
      if (LocalizeService.hasTranslation(codeToKeyMap[error.code])) {
        message = LocalizeService.translate(codeToKeyMap[error.code]);
      }
    }
  }

  return message;
}*/

/** Maps API response error code to default API response message by prefixing code with default key prefix. Returns null if message is missing. */
export function mapApiResponseCodeToMessage(error: ApiResponseErrorException, translationKeyMap: string | { [key: string]: string }): string | null {
  let message = null;

  if ((error instanceof ApiResponseErrorException)) {
    // try finding prefixed message
    if (LangUtils.isString(translationKeyMap)) {
      const apiMessageKey = `${translationKeyMap}.${error.code}`;
      if (LocalizeService.hasTranslation(apiMessageKey)) {
        message = LocalizeService.translate(apiMessageKey);
      }
    }
    // try mapping message using translation map
    else {
      if (translationKeyMap[error.code] != null && LocalizeService.hasTranslation(translationKeyMap[error.code])) {
        message = LocalizeService.translate(translationKeyMap[error.code]);
      }
    }
  }

  return message;
}


// -------------------- private

/** Describes value mapper function prototype. */
type IValueMapper = (...args: any[]) => any | null;

/** Function takes an array of mapper functions and returns return value of the first function that return non-null value. */
function valueMapper<T>(mappers: IValueMapper[]): T | null {
  return mappers.reduce((value, mapper) => {
    return value == null ? mapper() : value;
  }, null);
}
