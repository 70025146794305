import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map, tap, ignoreElements } from 'rxjs/operators';

import { IIdPayload, IPayloadAction, ILemonAction, IIdDataPayload } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';
import { actionThunk } from '@src/service/util/observable/operators';

import { IUserInfo } from '@src/model/user/User';
import { createStaticMessageUserFeedbackError, createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import StoreService from '@src/service/business/StoreService';
import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import { IChangePassword } from '@src/service/business/login/IPassword';
import IFile from '@src/model/file/File';
import { IParentDetails } from '@src/model/user/ParentDetails';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('userBusinessStore');

// -
// -------------------- Types&Consts

// -
// -------------------- Selectors

const getUser = (store: any): IUserInfo => store.user;

const getParentDetails = (store: any): IParentDetails => store.parentDetails;

// -
// -------------------- Actions

const Actions = {
  USER_FETCH: 'USER_FETCH',
  USER_UPDATE: 'USER_UPDATE',
  USER_LOAD: 'USER_LOAD',
  USER_CLEAR: 'USER_CLEAR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  USER_AVATAR_UPLOAD: 'USER_AVATAR_UPLOAD',
  PARENT_DETAILS_FETCH: 'PARENT_DETAILS_FETCH',
  PARENT_DETAILS_UPDATE: 'PARENT_DETAILS_UPDATE',
  PARENT_DETAILS_LOAD: 'PARENT_DETAILS_LOAD',
  PARENT_DETAILS_CLEAR: 'PARENT_DETAILS_CLEAR',
};

const fetchUser = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_FETCH,
    payload: params,
  };
};

const updateUser = (data: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.USER_UPDATE,
    payload: data,
  };
};

const loadUser = (data: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.USER_LOAD,
    payload: data,
  };
};

const clearUser = (): ILemonAction => {
  return {
    type: Actions.USER_CLEAR,
  };
};

const doChangePassword = (data: IChangePassword): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.CHANGE_PASSWORD,
    payload: data,
  };
};

const uploadUserAvatar = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.USER_AVATAR_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

const fetchParentDetails = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.PARENT_DETAILS_FETCH,
    payload: params,
  };
};

const updateParentDetails = (id: string, data: IParentDetails): IPayloadAction<IIdDataPayload<IParentDetails>> => {
  return {
    type: Actions.PARENT_DETAILS_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

const loadParentDetails = (data: IParentDetails): IPayloadAction<IParentDetails> => {
  return {
    type: Actions.PARENT_DETAILS_LOAD,
    payload: data,
  };
};

const clearParentDetails = (): ILemonAction => {
  return {
    type: Actions.PARENT_DETAILS_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchUserEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return (EntityApiServiceRegistry.getService('User')).fetchEntity(id).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUser(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching user', error);
      return o;
    })
  );
};

const updateUserEffect = (action$: Observable<IPayloadAction<IUserInfo>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('User') as EntityApiService<IUserInfo>).updateEntity(data.id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    tap((data) => {
      const currentUser: IUserInfo = LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());
      if (currentUser.id === data.id) {
        StoreService.dispatchAction(LoginBusinessStore.actions.storeCurrentUser(data));
      }
    }),

    map((data) => {
      return loadUser(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('USER_PROFILE.ERROR_MESSAGE.USER_PROFILE_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating user', error);
      return o;
    })
  );
};

const doChangePasswordEffect = (action$: Observable<IPayloadAction<IChangePassword>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CHANGE_PASSWORD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;
      const data = {
        oldPassword: action.payload.oldPassword,
        newPassword: action.payload.newPassword,
      };

      return (EntityApiServiceRegistry.getService('User') as EntityApiService<IUserInfo>).updateSubobject(id, 'password', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_CALENDAR.ERROR_MESSAGE.TUTOR_CALENDAR_UPDATE')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, {
      BAD_CREDENTIALS: 'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS',
    },
      'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating user', error);

      return o;
    })
  );
};


const uploadUserAvatarEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_AVATAR_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('User')).createSubobject(id, 'avatar', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return LoginBusinessStore.actions.fetchCurrentUser();
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error uploading avatar', error);
      return o;
    })
  );
};

const fetchParentDetailsEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PARENT_DETAILS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return (EntityApiServiceRegistry.getService('User')).fetchSubobject(id, 'parentdetails').pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadParentDetails(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching parent details', error);
      return o;
    })
  );
};

const updateParentDetailsEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IParentDetails>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PARENT_DETAILS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('User')).updateSubobject(id, 'parentdetails', data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadParentDetails(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('USER_PROFILE.ERROR_MESSAGE.PARENT_DETAILS_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error updating parent details', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const user = (state: IUserInfo | null = null, action: IPayloadAction<IUserInfo>) => {
  if (action.type === Actions.USER_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_CLEAR) {
    return null;
  }

  return state;
};

const parentDetails = (state: IParentDetails | null = null, action: IPayloadAction<IParentDetails>) => {
  if (action.type === Actions.PARENT_DETAILS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.PARENT_DETAILS_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserBusinessStore = {
  actions: {
    fetchUser, updateUser, loadUser, clearUser, doChangePassword, uploadUserAvatar,
    fetchParentDetails, updateParentDetails, loadParentDetails, clearParentDetails,
  },

  selectors: {
    getUser, getParentDetails,
  },

  effects: {
    fetchUserEffect, updateUserEffect, doChangePasswordEffect, uploadUserAvatarEffect,
    fetchParentDetailsEffect, updateParentDetailsEffect,
  },

  reducers: {
    user, parentDetails,
  },
};

// --
// ----- Exports

export default UserBusinessStore;
