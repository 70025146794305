import ReactGA, { EventArgs, InitializeOptions, FieldsObject } from 'react-ga';


/**
 * Simple API for Google Analytics ie. ReactGA, to be more pricise.
 * It more or less follows its API but provides a level of control between the caller and GA.
 */

/**
 * Initialize Google Analytics.
 * See: https://github.com/react-ga/react-ga#reactgainitializegatrackingid-options
 */
export function initialize(trackingId: string, options: InitializeOptions) {
  ReactGA.initialize(trackingId, options);
}

/**
 * Set Google Analytics fields.
 * See: https://github.com/react-ga/react-ga#reactgasetfieldsobject
 */
export function setFields(fields: FieldsObject) {
  ReactGA.set(fields);
}

/** Set Google Analytics user ID field. Specialized version of setFields() function. */
export function setUserId(userId: string) {
  setFields({
    userId,
  });
}


/**
 * Track view that is caused by changing URL.
 * https://github.com/react-ga/react-ga#reactgapageviewpath
 */
export function trackPageView(path: string, title?: string): void {
  ReactGA.pageview(path, undefined, title);
}

/**
 * Track views that are opened without changing URL (eg. modal dialogs).
 * https://github.com/react-ga/react-ga#reactgamodalviewmodalname
 */
export function trackModalView(path: string): void {
  ReactGA.modalview(path);
}

/**
 * Track user event.
 *
 * See: https://github.com/react-ga/react-ga#reactgaeventargs
 */
export function trackEvent(event: EventArgs): void {
  ReactGA.event(event);
}
