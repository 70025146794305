import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { Subscription } from 'rxjs';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonTag from '@src/components/common/tag/LemonTag';
import IFileSystemElement from '@src/model/file/FileSystemElement';
import { ITag, ITagCreate } from '@src/model/file/Tag';
import { IFileListTagFilter } from '@src/service/business/file/fileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createEvent, ILemonEvent } from '@src/service/util/event/lemonEvent';
import { createTrackableEvent } from '@src/service/util/event/trackEvent';
import { getLogger } from '@src/service/util/logging/logger';

const LOGGER = getLogger('TagList');

const publicId = 'public';

const Option = Select.Option;

export interface ITagListOwnProps {
  record: IFileSystemElement;
  tagList: ITag[];
  hasPermissionToEdit: boolean;
  onTagAdd: (record: IFileSystemElement, tag: ITag | ITagCreate) => void;
  onTagRemove: (record: IFileSystemElement, tag: ITag) => void;
  onTagSearchEvent: (event?: ILemonEvent<IFileListTagFilter>) => void;
}
type ITagListProps = ITagListOwnProps & IWithLocalizeOwnProps;

interface ITagListState {
  fetchInProgress: boolean;
  showTagInput: boolean;
  newName: string;
}

class TagList extends React.Component<ITagListProps, ITagListState> {
  state = {
    fetchInProgress: false,
    showTagInput: false,
    newName: '',
  };

  debouncedSearch = debounce((value: string) => {
    const filter = { name: value };
    this.handleTagListRefresh(filter);
  }, AppConfigService.getValue(`components.repository.debounceTime`));

  private fetchProgressSubscription: Subscription | null = null;

  componentWillUnmount() {
    // POC of event/action tracking
    // cleanup progress listener subscription
    if (this.fetchProgressSubscription) {
      this.fetchProgressSubscription.unsubscribe();
    }
  }

  handleTagSearch = (value: string) => {
    this.setState({
      newName: value,
    });
    // if previous name-search result was empty then continuing to write would again end with empty result, search is continued when characters are removed thus posibility of results again exists
    if (this.props.tagList.length || value.length < this.state.newName.length) {
      this.debouncedSearch(value);
    }
  };

  render() {
    const recordTagList = this.props.record.tags
      ? this.props.record.tags.map((tag: ITag) => (
        <LemonTag type="fileTag" data-test-id={`lemon-fileRepository__file_${this.props.record.id}_tag_${tag.id}`} key={tag.id} closable={this.props.hasPermissionToEdit && this.props.record.id !== publicId} onClose={() => this.handleRemoveTag(tag)}>
          {tag.name}
        </LemonTag>
      ))
      : [];

    const tagsSelect = (
      <Select
        className="lemon-fileRepository__tagAddInput_select"
        loading={this.state.fetchInProgress}
        notFoundContent={this.state.fetchInProgress ? <Spin size="small" /> : null}
        dropdownMatchSelectWidth={false}
        mode="tags"
        showSearch={true}
        size="small"
        allowClear={true}
        autoFocus={true}
        onChange={this.handleSelect}
        onSearch={this.handleTagSearch}
        onBlur={() => this.handleInputConfirm()}
        defaultActiveFirstOption={false}
        filterOption={false}
        open={this.state.showTagInput}
        onInputKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.keyCode === 13) {
            // Enter key
            event.stopPropagation();
            this.handleInputConfirm();
          }
        }}
        data-test-id={`lemon-fileRepository__tagAddInput_${this.props.record.id}`}
      >
        {this.props.tagList.map((tag: ITag) => (
          <Option key={tag.id} data-test-id={`lemon-fileRepository__file_${this.props.record.id}_tagSelect_${tag.id}`}>
            {tag.name}
          </Option>
        ))}
      </Select>
    );
    return (
      <div>
        {...recordTagList}

        {this.state.showTagInput
          ? { ...tagsSelect }
          : this.props.hasPermissionToEdit &&
          this.props.record.id !== publicId && (
            <LemonTag type="fileNewTag" className="lemon-fileRepository__tagAddInput_button" data-test-id={`lemon-fileRepository__file_${this.props.record.id}_tag_addTag`} onClick={this.toggleTagInput} key={`${this.props.record.mimeType}_${this.props.record.id}_tag_addTag`}>
              {this.props.translate('FILE_LIST.ADD_NEW_TAG_BUTTON_LABEL')}&nbsp;
              <LemonIcon name="plus" />
            </LemonTag>
          )}
      </div>
    );
  }

  handleRemoveTag = (tag: ITag) => {
    LOGGER.info('removed tag', this.props.record, tag);
    this.props.onTagRemove(this.props.record, tag);
  };

  toggleTagInput = () => {
    const newFlag = !this.state.showTagInput;
    if (newFlag) {
      this.handleTagListRefresh({});
    }
    this.setState({
      showTagInput: newFlag,
    });
  };

  handleSelect = (value: string[]) => {
    const newTag = this.props.tagList.find((tag: ITag) => tag.id === value[0]);

    this.handleInputConfirm(newTag);
  };

  handleInputConfirm = (newTag?: ITag) => {
    const existingTag = newTag ? newTag : this.props.tagList.find((tag: ITag) => tag.name === this.state.newName);
    if (existingTag) {
      this.props.onTagAdd(this.props.record, existingTag);
    } else if (this.state.newName) {
      this.props.onTagAdd(this.props.record, { name: this.state.newName });
    }
    this.props.onTagSearchEvent();
    this.setState({
      newName: '',
      showTagInput: false,
    });
  };

  handleTagListRefresh = (filter: IFileListTagFilter) => {
    this.setSubmitInProgress(true);

    const fetchEvent = createTrackableEvent(createEvent(filter));

    this.fetchProgressSubscription = fetchEvent.track().subscribe(
      (data) => {
        this.setSubmitInProgress(false);
      },
      (error) => {
        this.setSubmitInProgress(false);
      }
    );

    this.props.onTagSearchEvent(fetchEvent);
  };

  private setSubmitInProgress(enabled: boolean) {
    this.setState({ fetchInProgress: enabled });
  }
}

export default withLocalize<ITagListOwnProps>(TagList as any);
