import moment, { Moment } from 'moment';

import { ISessionCalendarListItem, SESSION_CALENDAR_LIST_DATE_KEY_FORMAT } from '@src/components/session/calendar/types';
import ITutoringSession from '@src/model/session/TutoringSession';
import AppConfigService from '@src/service/common/AppConfigService';


export interface PrepareCalendarOptions {
  dayNameFormatter?: (day: Moment) => string;
  weekNameFormatter?: (day: Moment) => string;
}

/** Transform session list into more appropriate list for displaying calendar and list. */
export function prepareCalendarList(sessions: ITutoringSession[], options?: PrepareCalendarOptions): ISessionCalendarListItem[] {
  // const sortedList = [...sessions].sort((a: ITutoringSession, b: ITutoringSession) => a.startDateTime > b.startDateTime ? 1 : -1);

  const data = sessions.reduce((accum, session) => {
    const startDateTime = moment(session.startDateTime);
    const dateKey = startDateTime.format(SESSION_CALENDAR_LIST_DATE_KEY_FORMAT);

    let item = accum[dateKey];
    if (accum[dateKey] == null) {
      // format names
      const dayName = options && options.dayNameFormatter ? options.dayNameFormatter(startDateTime) : formatDayName(startDateTime);
      const weekName = options && options.weekNameFormatter ? options.weekNameFormatter(startDateTime) : formatWeekName(startDateTime);

      item = {
        dateKey,
        dayName,
        weekName,
        data: [],
      };
      accum[dateKey] = item;
    }

    item.data.push(session);

    return accum;
  }, {} as Record<string, ISessionCalendarListItem>);

  // convert hash map to sorted list of items
  const dataList = Object.keys(data).sort().map(key => data[key]);

  return dataList;
}

/** Should list item be displayed. Currentyl only items with at least one session are displayed. */
export function shouldShowListItem(item: ISessionCalendarListItem) {
  // show only non empty items
  return item.data.length > 0;
}


// ----- helper functions

function formatDayName(day: Moment) {
  return moment(day).format(AppConfigService.getValue('dateTimeFormat.dateDayOfWeekName'));
}

function formatWeekName(day: Moment): string {
  const currentMonth = day.month();
  const startDay = moment(day).startOf('week');
  const endDay = moment(startDay).add(6, 'day');

  if (startDay.month() !== currentMonth) {
    startDay.month(currentMonth).startOf('month');
  }
  if (endDay.month() !== currentMonth) {
    endDay.month(currentMonth).endOf('month');
  }
  return `${startDay.format(AppConfigService.getValue('dateTimeFormat.date'))} - ${endDay.format(AppConfigService.getValue('dateTimeFormat.date'))}`;
}
