export interface IUserRole {
    id: UserRoleEnum;
    description: string;
    name: string;
}


export enum UserRoleEnum {
    TUTOR = '1',
    STUDENT = '2',
    ADMINISTRATOR = '3',
}
