import { Empty, Table, Tooltip, Typography } from 'antd';
import React from 'react';

import FileDropdownMenu from '@src/components/common/file/FileDropdownMenu';
import FileItemPreview from '@src/components/common/file/FileItemPreview';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { hasPermission } from '@src/components/session/view/SessionFileList';
import IFile from '@src/model/file/File';
import { FilePermissionEnum } from '@src/model/file/File';
import IFileSystemElement from '@src/model/file/FileSystemElement';

// min width taken by a column, lower number is ignored, without a number it would justify space
const minColumnWidth = 30;

export interface ISessionFileTableOwnProps {
  files: IFile[];
  onFileDelete: (fileList: IFile[]) => void;
  onFileRename: (file: IFile) => void;
  canEdit: boolean;
  onVisibilityToggle?: (file: IFile) => any;
}
type ISessionFileTableProps = ISessionFileTableOwnProps & IWithLocalizeOwnProps;

interface ISessionFileTableState { }

class SessionFileTable extends React.Component<ISessionFileTableProps, ISessionFileTableState> {
  render() {
    const columns = [
      {
        key: 'type',
        width: minColumnWidth,
        render: (record: IFile) => {
          return <LemonIcon name="file" />;
          {
            /* TODO: icon theme="outlined"*/
          }
        },
      },
      {
        key: 'visibilityToggle',
        width: minColumnWidth,
        render: (record: IFile) => {
          return this.props.onVisibilityToggle ? (
            <span onClick={() => (this.props.onVisibilityToggle ? this.props.onVisibilityToggle(record) : null)}>
              {hasPermission(record.permissions, FilePermissionEnum.VIEW) ? (
                <Tooltip title={this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_VISIBLE')}>
                  {' '}
                  <LemonIcon name="eye" />
                  {/* TODO: icon theme="filled"*/}
                </Tooltip>
              ) : (
                  <Tooltip title={this.props.translate('TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_HIDDEN')}>
                    <LemonIcon name="eyeInvisible" />
                    {/* TODO: icon theme="filled"*/}
                  </Tooltip>
                )}
            </span>
          ) : (
              <React.Fragment />
            );
        },
      },
      {
        key: 'name',
        width: '30%',
        render: (record: IFile) => (
          <FileItemPreview record={record} showPreviewTooltip={true} title={record.name}>
            <Typography.Text ellipsis={true}>{record.name.substring(0, record.name.lastIndexOf('.')) || record.name}</Typography.Text>
          </FileItemPreview>
        ),
      },
      {
        key: 'description',
        render: (record: IFile) => 'neki opis ili podatak', // TODO: define if anything is shown here
      },
      {
        key: 'action',
        width: minColumnWidth,
        render: (record: IFile) => (
          <FileDropdownMenu record={[record]} onDelete={this.props.canEdit ? this.handleFileDelete : undefined} canDelete={this.props.canEdit} canRename={this.props.canEdit} canMove={false} onRename={this.props.canEdit ? this.handleNameSubmit : undefined} canOpen={true}>
            <LemonIcon name="ellipsis" className="lemon-fileList__dropDownIcon" />
          </FileDropdownMenu>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.props.files}
          showHeader={false}
          rowKey={(record) => `${record.id}`}
          size="small"
          scroll={{ x: true }}
          locale={{
            emptyText: <Empty description={this.props.translate('FILE_LIST.EMPTY_FOLDER_TEXT')} />,
          }}
          pagination={{
            // TODO: define paging as the component doesn't show full list from BE
            hideOnSinglePage: true,
            // total: this.props.files.page.totalElements,
            pageSize: 20,
            // onChange: this.onPageChange,
          }}
        />
      </React.Fragment>
    );
  }

  handleNameSubmit = (record: IFileSystemElement, newName: string) => {
    this.props.onFileRename({ ...record, name: newName } as IFile);
  };

  handleFileDelete = (files: IFileSystemElement[]) => {
    this.props.onFileDelete(files as IFile[]);
  };
}

export default withLocalize<ISessionFileTableOwnProps>(SessionFileTable as any);
