import { Breadcrumb } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IPath } from '@src/model/common/Path';
import { Link } from 'react-router';

export interface IFileListBreadcrumbOwnProps {
  onFolderSelect: (folder: IPath) => void;
  routes: IPath;
  searchResultsCount?: number;
  isRepositoryRoute?: boolean;
}

type IFileListBreadcrumbProps = IFileListBreadcrumbOwnProps & IWithLocalizeOwnProps;

const FileListBreadcrumb = (props: IFileListBreadcrumbProps) => {
  const getRouteElements = (route: IPath): IPath[] => {
    const pathParts: IPath[] = [];
    let part: IPath | null = route;
    do {
      pathParts.push(part);
      part = part.child;
    } while (part != null);

    return pathParts;
  };

  const breadcrumbItems = getRouteElements(props.routes).map((item: IPath) => {
    let routeElement;
    if (item.id === 'root') {
      routeElement = props.isRepositoryRoute ? (
        <Link to={'/repository'} onClick={() => props.onFolderSelect(item)}>
          {' '}
          <LemonIcon name="home" /> {props.translate('FILE_LIST.BREADCRUMB_HOME_LABEL')}
        </Link>
      ) : (
        <a onClick={() => props.onFolderSelect(item)}>
          {' '}
          <LemonIcon name="home" /> {props.translate('FILE_LIST.BREADCRUMB_HOME_LABEL')}
        </a>
      );
    } else if (item.id === 'search') {
      routeElement = props.isRepositoryRoute
        ? (props.searchResultsCount || props.searchResultsCount === 0) && (
            <Link to={'/repository'} onClick={() => props.onFolderSelect(item)}>
              {' '}
              {props.translate('FILE_LIST.TITLE_SEARCH_RESULTS_NAME_LABEL', { items: props.searchResultsCount })}
            </Link>
          )
        : (props.searchResultsCount || props.searchResultsCount === 0) && <a onClick={() => props.onFolderSelect(item)}> {props.translate('FILE_LIST.TITLE_SEARCH_RESULTS_NAME_LABEL', { items: props.searchResultsCount })}</a>;
    } else if (item.id === 'public') {
      routeElement = props.isRepositoryRoute ? (
        <Link to={'/repository/public'} onClick={() => props.onFolderSelect(item)}>
          {' '}
          {props.translate('FILE_LIST.PUBLIC_FOLDER')}{' '}
        </Link>
      ) : (
        <a onClick={() => props.onFolderSelect(item)}> {props.translate('FILE_LIST.PUBLIC_FOLDER')} </a>
      );
    } else {
      routeElement = props.isRepositoryRoute ? <Link to={!props.routes.child ? '' : `/repository/${item.id}`}> {item.name} </Link> : <a onClick={() => props.onFolderSelect(item)}> {item.name} </a>;
    }

    return <Breadcrumb.Item key={item.id}>{routeElement}</Breadcrumb.Item>;
  });

  return <Breadcrumb>{...breadcrumbItems}</Breadcrumb>;
};

export default withLocalize<IFileListBreadcrumbOwnProps>(FileListBreadcrumb as any);
