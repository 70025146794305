import { ConfigProvider } from 'antd';
import moment from 'moment';
import React from 'react';

import UserSettingsBusinessStore from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import NumberFormatService from '@src/service/common/numberformat/NumberFormatService';
import { ANTDLOCALES } from '@src/service/i18n/antd';
import { MESSAGES } from '@src/service/i18n/message';
import LocalizeService from '@src/service/localize/LocalizeService';
import { CookieManager } from '@src/service/util/CookieManager';
import { getLogger } from '@src/service/util/logging/logger';
import { connect } from 'react-redux';

const LOGGER = getLogger('LdLocaleProvider');

const defaultLocale = AppConfigService.getValue('app.defaultLocale');
const localeCookieName = AppConfigService.getValue('cookies.locale.name');
const localeCookieDuration = new Date(Date.now() + AppConfigService.getValue('cookies.locale.duration'));

const localeCookie = CookieManager.getCookie(localeCookieName);

// -- Prop types
// ----------

export interface ILdLocaleProviderOwnProps {}

export interface ILdLocaleProviderStateProps {
  userSettingsLocale?: string;
}

export interface ILdLocaleProviderDispatchProps {}
type ILdLocaleProviderProps = ILdLocaleProviderDispatchProps & ILdLocaleProviderOwnProps & ILdLocaleProviderStateProps;

// -- Component
// ----------

/** Component that handles app locale */
class LdLocaleProvider extends React.Component<ILdLocaleProviderProps> {
  componentDidUpdate = (prevProps: ILdLocaleProviderProps) => {
    if (this.props.userSettingsLocale && prevProps.userSettingsLocale !== this.props.userSettingsLocale) {
      this.setLocalization(this.props.userSettingsLocale);
    }
  };

  componentDidMount = () => {
    // init locale
    if (this.props.userSettingsLocale) {
      this.setLocalization(this.props.userSettingsLocale);
    } else if (localeCookie) {
      this.setLocalization(localeCookie);
    } else {
      this.setLocalization(defaultLocale);
    }
  };

  render = () => {
    return (
      <ConfigProvider key={Math.random()} locale={this.getAntdLocale()}>
        {this.props.children}
      </ConfigProvider>
    );
  };

  getAntdLocale = () => {
    if (this.props.userSettingsLocale && ANTDLOCALES[this.props.userSettingsLocale]) {
      return ANTDLOCALES[this.props.userSettingsLocale];
    } else {
      return ANTDLOCALES[defaultLocale];
    }
  };

  setLocalization = (locale: string) => {
    const loc: string = MESSAGES[locale] ? locale : defaultLocale;
    LOGGER.info(`Loading "${loc}" locale.`);
    if (loc !== localeCookie) {
      CookieManager.setCookie({ name: localeCookieName, value: loc, path: '/', expires: localeCookieDuration });
    }
    LocalizeService.initLocalize(loc, MESSAGES);
    moment.locale(loc);
    NumberFormatService.locale(loc);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILdLocaleProviderOwnProps): ILdLocaleProviderStateProps => ({
  userSettingsLocale: UserSettingsBusinessStore.selectors.getUserSettingByKey(state, 'locale'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILdLocaleProviderDispatchProps => ({});

export default connect<ILdLocaleProviderOwnProps>(mapStateToProps, mapDispatchToProps)(LdLocaleProvider as any);
