import moment, { Moment } from 'moment';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';


const TIMER_INTERVAL = 1000;

// -- Prop types
// ----------

export interface ICountdownClockOwnProps {
  endTime: Moment | string;
  format: string;
}
type ICountdownClockProps = ICountdownClockOwnProps & IWithLocalizeOwnProps;

interface ICountdownClockState {
  remainingTime: number;
}


// -- Component
// ----------

/** Component displays countdown clock. Component depends on client's computer clock being accurate. */
class CountdownClock extends React.PureComponent<ICountdownClockProps, ICountdownClockState> {
  state: ICountdownClockState = {
    remainingTime: 0,
  };

  private timerId: any;

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate(prevProps: ICountdownClockProps) {
    if (!moment(prevProps.endTime).isSame(this.props.endTime) || prevProps.format !== this.props.format) {
      this.stopTimer();
      this.startTimer();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  render = () => {
    return (
      <span>{this.formattedRemainingTime()}</span>
    );
  }

  formattedRemainingTime(): string {
    return moment(this.state.remainingTime).format(this.props.format);
  }


  private startTimer() {
    // reset remaining time on start (in case start has been called subsequently)
    this.setState({
      remainingTime: 0,
    });

    this.updateTimer();

    this.timerId = setInterval(() => {
      this.updateTimer();
    }, TIMER_INTERVAL);
  }

  private stopTimer() {
    if (this.timerId != null) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  private updateTimer() {
    let timeDiff = moment(this.props.endTime).diff(moment());
    if (timeDiff < 0) {
      timeDiff = 0;

      this.stopTimer();
    }

    this.setState({
      remainingTime: timeDiff,
    });
  }
}

export default withLocalize<ICountdownClockOwnProps>(CountdownClock as any);
