import { of, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { InitializeOptions } from 'react-ga';

import AppConfigService from '@src/service/common/AppConfigService';
import * as gaService from '@src/service/util/google/ga/googleAnalyticsService';
import { historyInstance } from '@src/components/app/appRouter';
import { isUserLoggedIn, getCurrentUser } from '@src/service/business/login/loginBusinessService';
import CryptoUtils from '@src/service/util/CryptoUtils';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('googleAnalyticsInitializer');

// add "salt" to each user ID
const HASH_SALT = 'Eip5mi}W';

// service config
const GA_ENABLED: boolean = !!AppConfigService.getValue('tracking.googleAnalytics.enabled');
const GA_TRACKING_ID: string = AppConfigService.getValue('tracking.googleAnalytics.trackingId');
const GA_INIT_OPTIONS: InitializeOptions = AppConfigService.getValue('tracking.googleAnalytics.initializeOptions');

/** Google analytics service initializer function. */
export const googleAnalyticsInitializer = (): Observable<any> => {
  return of(true).pipe(
    tap(() => {
      if (GA_ENABLED) {
        LOGGER.info('Initializing Google Analytics ...');

        gaService.initialize(GA_TRACKING_ID, GA_INIT_OPTIONS);

        // set current user ID
        // we should not set unobfuscated DB user ID
        // https://developers.google.com/analytics/solutions/crm-integration#user_id
        if (isUserLoggedIn()) {
          const currentUser = getCurrentUser();
          CryptoUtils.digest(currentUser.id + HASH_SALT, 'SHA-256')
            .then((digest: string) => {
              gaService.setUserId(digest);
            })
            .catch(err => LOGGER.error('Error digesting user ID', err));
        }

        // configure automatic page tracking
        historyInstance().listen(location => gaService.trackPageView(location.pathname));
      }
      else {
        LOGGER.info('Google Analytics disabled');
      }
    }),

    catchError((err) => {
      LOGGER.error('Error initializing Google Analytics', err);

      // do not break pipeline, just return ok
      return of(false);
    })
  );
};
