import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { InvalidEntityApiReturnValueException } from '@src/service/api/registry/entity/InvalidEntityApiReturnValueException';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import { LangUtils } from '@src/service/util/LangUtils';
import IApiService from '@src/service/util/api/IApiService';
import { ICollectionResponse } from '@src/service/api/model/apiEvent';
import { ICollectionFetchPayload } from '@src/service/business/common/types';

export default class EntityApiService<E, C = any> implements IApiService {

  constructor(
    protected entityName: string,
    protected entityApi: IAbstractEntityApi<any, any>
    // protected entityConverterRegistry: EntityModelConverterRegistry,
  ) {
  }

  // ----- Entity API

  fetchEntity(id: string): Observable<E> {
    return this.entityApi.fetchEntity(this.entityName, id).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('fetchEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  createEntity(body: any): Observable<E> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.createEntity(this.entityName, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('createEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  updateEntity(id: string, body: any): Observable<E> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateEntity(this.entityName, id, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateEntity', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('updateEntity', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteEntity(id: string): Observable<E> {
    return this.entityApi.deleteEntity(this.entityName, id).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteEntity', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateEntityMethod(id: string, method: string, body: any): Observable<E> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateEntityMethod(this.entityName, id, method, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateEntityMethod', 'missing payload');
        }

        if (LangUtils.isEmpty(data.payload.id)) {
          throw new InvalidEntityApiReturnValueException('updateEntityMethod', 'missing ID');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ----- Entity list API

  fetchEntityList(params?: any): Observable<ICollectionResponse<E, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchEntityList(this.entityName, apiParams).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchEntityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          throw new InvalidEntityApiReturnValueException('fetchEntityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createEntityList(body: any[]): Observable<object[]> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityListFromModel(this.entityName, body);

    return this.entityApi.createEntityList(this.entityName, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createEntityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertReturnListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteEntityList(body: object[]): Observable<ICollectionResponse<E, C>> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.deleteEntityList(this.entityName, convertedBody).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ----- Subentity list API

  fetchSubentityList(id: string, subentityName: string, params?: any): Observable<ICollectionResponse<E, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchSubentityList(this.entityName, id, subentityName, apiParams).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchSubentityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          throw new InvalidEntityApiReturnValueException('fetchSubentityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createSubentityList(id: string, subentityName: string, body: any): Observable<object[]> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityListFromModel(this.entityName, body);

    return this.entityApi.createSubentityList(this.entityName, id, subentityName, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubentityList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubentityList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertReturnListToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateSubentityList(id: string, subentityName: string, body: any): Observable<void> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateSubentityList(this.entityName, id, subentityName, convertedBody).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteSubentityList(id: string, subentityName: string, body: any): Observable<void> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.deleteSubentityList(this.entityName, id, subentityName, convertedBody).pipe(
      // map result back to model
      map((data) => {
        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Object API

  fetchObjectList(method: string, params?: any): Observable<ICollectionResponse<E, C>> {
    const apiParams = this.convertEntiyListParamsFromModel(params);

    return this.entityApi.fetchMethod(this.entityName, method, apiParams).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchObjectList', 'missing payload');
        }

        if (!LangUtils.isArray(data.payload.content)) {
          throw new InvalidEntityApiReturnValueException('fetchObjectList', 'not an array');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityListToModel(this.entityName, payload);
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Subobject API

  fetchSubobject(id: string, subobject: string, params?: any): Observable<E> {
    return this.entityApi.fetchSubobject(this.entityName, id, subobject, params).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createSubobject(id: string, subobject: string, body: any): Observable<E> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.createSubobject(this.entityName, id, subobject, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateSubobject(id: string, subobject: string, body: any): Observable<E> {
    // try to covert body from model (if entity has registered converter)
    const convertedBody = this.convertEntityFromModel(this.entityName, body);

    return this.entityApi.updateSubobject(this.entityName, id, subobject, convertedBody).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload);
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  deleteSubobject(id: string, subobject: string, body: object[]): Observable<void> {
    return this.entityApi.deleteSubobject(this.entityName, id, subobject, body).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('deleteSubobject', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- Custom entity API

  fetchMethod(method: string, queryParams?: object): Observable<E> {
    return this.entityApi.fetchMethod(this.entityName, method, queryParams).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchMethod', 'missing payload');
        }

        return data.payload;
      }),

      // check errors
      catchError(this.handleApiErrors)
    );
  }

  fetchNoMethod(queryParams?: object): Observable<E> {
    return this.entityApi.fetchNoMethod(this.entityName, queryParams).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('fetchNoMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  createMethod(method: string, body: object): Observable<E> {
    return this.entityApi.createMethod(this.entityName, method, body).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('createMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  updateMethod(method: string, body: object): Observable<E> {
    return this.entityApi.updateMethod(this.entityName, method, body).pipe(
      // check return value
      map((data) => {
        if (LangUtils.isEmpty(data.payload)) {
          throw new InvalidEntityApiReturnValueException('updateMethod', 'missing payload');
        }

        return data.payload;
      }),

      // process special errors
      catchError(this.handleApiErrors)
    );
  }

  // ---------- protected

  // TODO: implement entity converters and use conversion methods

  protected convertEntityFromModel(entityName: string, entity: any): any {
    // return EntityConverterUtils.convertFromModel(entity, entityName, this.getEntityConverters(entityName));
    return entity;
  }

  protected convertEntityToModel(entityName: string, obj: any): any {
    // return EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName));
    return obj;
  }

  protected convertEntityListFromModel(entityName: string, entityArr: any[]): any[] {
    /*return this.ensureArray(entityArr)
      map((entity) => EntityConverterUtils.convertFromModel(entity, entityName, this.getEntityConverters(entityName)));*/
    return entityArr;
  }

  protected convertEntityListToModel(entityName: string, collection: ICollectionResponse<any, any>): ICollectionResponse<any, any> {
    /*return this.ensureArray(objArr)
      map((obj) => EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName)));*/

    /*
     * Split BE's response into content and page (rest of reponse).
     * Backend's response mixes paging and sorting data on the same level as content, so we will divide it for easier separation.
     */
    // TODO: this should be implemente pluggable, like converters (also a TODO :-))
    const { content, ...page } = collection;

    return { content, page };
  }

  protected convertReturnListToModel(entityName: string, collection: object[]): object[] {
    /*return this.ensureArray(objArr)
      map((obj) => EntityConverterUtils.convertToModel(obj, entityName, this.getEntityConverters(entityName)));*/

    /*
     * Split BE's response into content and page (rest of reponse).
     * Backend's response mixes paging and sorting data on the same level as content, so we will divide it for easier separation.
     */
    // TODO: this should be implemente pluggable, like converters (also a TODO :-))

    return collection;
  }

  protected getEntityConverters(entityName: string): /*AbstractEntityModelConverter<any, any, any>*/ any[] {
    // return this.entityConverterRegistry.getEntityConverters(entityName);
    return [];
  }

  protected ensureArray(arr: any[]): any[] {
    return (arr == null) ? [] : arr;
  }

  /** Backend API has flat parameter list so we have to flatten filter object together with other params. */
  // TODO: this should be implemented pluggable, like converters (also a TODO :-))
  protected convertEntiyListParamsFromModel(params?: ICollectionFetchPayload<any>): Record<string, any> {
    // avoid null references in case params are missing
    if (params == null) {
      return {};
    }

    const { filter, ...rest } = params;

    return {
      ...(filter || {}),
      ...(rest || {}),
    };
  }

  /**
   * Check API repsonse errors and react if required. This is hooked into stream via "catchError" operator
   * and simply rethrows error
   * Currently handles:
   *  - SESSION_EXPIRED - any API call can receive this reponse and should redirect user to login page
   *
   * This is meant to be a centralized place for handlign global API response errors. But this should
   * be configurable from outside, as a list of error handlers.
   */
  private handleApiErrors(error: any, o: Observable<any>) {
    API_ERROR_HANDLERS.reduce((result, handler) => {
      return result ? handler(error) : false;
    }, true);

    // rethrow error for further processing
    // this is partly written like this to satisfy catchError operator prototype, but also to prevent from rethrowing empty errors(?)
    if (error != null) {
      throw error;
    }

    return o;
  }

}


// ---------- API service error handling

/**
 * Describes API service error handler function.
 *
 * @param error {any} - error object
 * @returns {boolean} true if error processing should continue
 */
export type ApiServiceErrorHandler = (error: any) => boolean;

/** List that stores registered API service error handler functions */
const API_ERROR_HANDLERS: ApiServiceErrorHandler[] = [];

/**
 * Registration function for API service error handlers.
 *
 * @param {ApiServiceErrorHandler} handler function
 * @returns {Function} unregistration function - function that removes handler from list of used handlers
 */
export function registerAPiServiceErrorHandler(handler: ApiServiceErrorHandler) {
  API_ERROR_HANDLERS.push(handler);
  const currentIndex = API_ERROR_HANDLERS.length - 1;

  return () => {
    API_ERROR_HANDLERS.splice(currentIndex, 1);
  };
}

