import React from 'react';
import { connect } from 'react-redux';

// import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserApprovalList from '@src/components/common/userapproval/UserApprovalList';
import ProgressStatus from '@src/components/common/userfeedback/ProgressStatus';
import UserMessages from '@src/components/common/userfeedback/UserMessages';
import UserNotifications from '@src/components/login/usermenu/UserNotifications';
// import SupportWidgetContainer from '@src/components/support/SupportWidgetContainer';
import { IUserFeedbackMessage, IUserFeedbackProgressStatus } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { IUserApproval } from '@src/service/business/userapproval/types';
import UserApprovalBusinessStore from '@src/service/business/userapproval/userApprovalBusinessStore';

export interface IAppContainerPublicProps { }
export interface IAppContainerStateProps {
  progressStatus: IUserFeedbackProgressStatus;
  userMessages: IUserFeedbackMessage[];
  userApprovalList: IUserApproval[];
}
export interface IAppContainerDispatchProps {
  grantUserApproval: (event: IUserApproval) => void;
  dismissUserApproval: (id: string) => void;
}
type IAppContainerProps = IAppContainerPublicProps & IAppContainerDispatchProps & IAppContainerStateProps & IWithLocalizeOwnProps;

/** App container, for adding things common to all layouts. */
class AppContainer extends React.Component<IAppContainerProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.children}
        {/* ----- user messages ----- */}
        {this.props.userMessages && this.props.userMessages.length > 0 && <UserMessages messages={this.props.userMessages} />}

        {/* ----- progress status ----- */}
        {this.props.progressStatus && this.props.progressStatus.status > 0 && <ProgressStatus tip={this.props.translate('COMMON.IN_PROGRESS')} />}

        {/* ----- progress status ----- */}
        {this.props.userApprovalList && this.props.userApprovalList.length > 0 && <UserApprovalList items={this.props.userApprovalList} onUserApprovalGrant={this.onUserApprovalGrant} onUserApprovalDismiss={this.onUserApprovalDismiss} />}

        {/* ----- user notifications ----- */}
        <UserNotifications />

        {/* ----- support widget ----- */}
        {/* <LemonErrorBoundary hideErrorOutput={true}>
          <SupportWidgetContainer />
        </LemonErrorBoundary> */}
      </React.Fragment>
    );
  }

  onUserApprovalGrant = (event: IUserApproval) => {
    this.props.grantUserApproval(event);
  };

  onUserApprovalDismiss = (event: IUserApproval) => {
    this.props.dismissUserApproval(event.id);
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAppContainerStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  progressStatus: UserFeedbackBusinessStore.selectors.getGlobalProgressStatus(state),
  userMessages: UserFeedbackBusinessStore.selectors.getMessages(state),
  userApprovalList: UserApprovalBusinessStore.selectors.getUserApprovalList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAppContainerDispatchProps => ({
  grantUserApproval: (userApproval: IUserApproval) => dispatch(UserApprovalBusinessStore.actions.grantUserApproval(userApproval)),
  dismissUserApproval: (id: string) => dispatch(UserApprovalBusinessStore.actions.dismissUserApproval(id)),
});

export default connect<IAppContainerStateProps, IAppContainerDispatchProps, IAppContainerPublicProps>(mapStateToProps, mapDispatchToProps)(withLocalize(AppContainer as any));
