import AppConfigService from '@src/service/common/AppConfigService';
import { Globals } from '@src/service/util/Globals';
import UrlBuilderFactory from './UrlBuilderFactory';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('StaticPageRoutingHelper');

/**
 * Static router - contains static methods for routing to statically defined pages (default, login, error, ...).
 *
 * This routing helper is NOT using React router but browser's location property!
 */
export class StaticPageRoutingHelper {
  /** Used to transfer requested route while temporarily being redirect somewhere else (eg. to login page if authenticated route is requested). */
  static RETURN_ROUTE_URL_PARAMETER = 'rr';

  static routeToApp() {
    const appPath: string = UrlBuilderFactory.createApplicationBuilder().build();

    StaticPageRoutingHelper.route(appPath);
  }

  static routeToPublicDefaultPage() {
    const publicPagePath: string = UrlBuilderFactory.createApplicationBuilder().urlPart(AppConfigService.getValue('routing.publicDefaultRoute')).build();

    StaticPageRoutingHelper.route(publicPagePath);
  }

  static routeToDefaultPage() {
    const defaultPagePath: string = UrlBuilderFactory.createApplicationBuilder().urlPart(AppConfigService.getValue('routing.defaultRoute')).build();

    StaticPageRoutingHelper.route(defaultPagePath);
  }

  static routeToLoginPage() {
    const loginPagePath: string = UrlBuilderFactory.createApplicationBuilder().urlPart(AppConfigService.getValue('routing.loginRoute')).build();

    StaticPageRoutingHelper.route(loginPagePath);
  }

  static routeToErrorPage() {
    const errorPagePath: string = UrlBuilderFactory.createApplicationBuilder().urlPart(AppConfigService.getValue('routing.errorPage')).build();
    LOGGER.warn('Trying to route to error page which is not implemented yet!');

    StaticPageRoutingHelper.route(errorPagePath);
  }

  static route(url: string) {
    if (Globals.window.location.href !== url) { // prevent endless loop
      Globals.window.location.href = url;
    }
  }
}
