import React, { SyntheticEvent } from 'react';

import { notification, Button } from 'antd';
import { IUserApproval } from '@src/service/business/userapproval/types';
import withLocalize from '../localize/withLocalize';
import { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LemonIcon from '@src/components/common/image/LemonIcon';
import AppConfigService from '@src/service/common/AppConfigService';


const USER_APPROVAL_NOTIFICATION_SHOW = AppConfigService.getValue('components.notification.userApprovalNotification.show') || false;


// --
// ---------- Prop types

export interface IUserApprovalListItemPublicProps {
  item: IUserApproval;
  onUserApprovalGrant?: (approval: IUserApproval) => void;
  onUserApprovalDismiss?: (approval: IUserApproval) => void;
}
type IUserApprovalListItemProps = IUserApprovalListItemPublicProps & IWithLocalizeOwnProps;


// --
// ---------- Component

// Ant's notification API is not declarative but imperative so this is not visual component but we're using component's mount lifecyle methods to create/destroy message
class UserApprovalListItem extends React.PureComponent<IUserApprovalListItemProps> {

  componentDidMount() {
    // ignore notifications if they are disabled
    if (!USER_APPROVAL_NOTIFICATION_SHOW) {
      return;
    }

    const grantButton = (
      <Button type="primary" onClick={this.onGrantButtonClick} data-test-id="lemon-userApproval__grantButton">
        {this.props.translate('USER_APPROVAL.GRANT_BUTTON_LABEL')}
      </Button>
    );

    const notificationIcon = (
      <LemonIcon name="unlock" />
    );

    // create appropriate message type once, on component mount
    const notificationProps: any = {
      key: this.props.item.id,
      placement: 'bottomRight', // these notifications are not auto removable so we'll place them at the bottom of the page
      message: this.getApprovalTitle(),
      description: this.getApprovalMessage(),
      duration: 0, // disable automatic removal
      btn: grantButton,
      onClose: this.onApprovalClose,
      icon: notificationIcon,
    };
    notificationProps['data-test-id'] = 'lemon-userApproval__notification';
    notification.open(notificationProps);
  }

  componentWillUnmount() {
    // close notification
    if (this.props.item != null) {
      notification.close(this.props.item.id);
    }
  }

  render() {
    return null;
  }

  onApprovalClose = () => {
    if (this.props.onUserApprovalDismiss) {
      this.props.onUserApprovalDismiss(this.props.item);
    }
  }

  onGrantButtonClick = (event: SyntheticEvent) => {
    if (this.props.onUserApprovalGrant) {
      this.props.onUserApprovalGrant(this.props.item);
    }
  }

  getApprovalTitle = (): string | null => {
    const errorMessageKey = `USER_APPROVAL.APPROVAL_TITLE.${this.props.item.serviceId}`;
    const approvalMessage: string = this.props.translate(errorMessageKey);
    // on missing message, key is retuned
    if (approvalMessage === errorMessageKey) {
      return null;
    }

    return approvalMessage;
  }

  getApprovalMessage = (): string | null => {
    const errorMessageKey = `USER_APPROVAL.APPROVAL_MESSAGE.${this.props.item.serviceId}`;
    const approvalMessage: string = this.props.translate(errorMessageKey);
    // on missing message, key is retuned
    if (approvalMessage === errorMessageKey) {
      return null;
    }

    return approvalMessage;
  }
}

export default withLocalize<IUserApprovalListItemPublicProps>(UserApprovalListItem as any);
