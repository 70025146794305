import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import TutorBusinessStore from '@src/service/business/user/tutorBusinessStore';
import RoleUtils from '@src/service/util/role/RoleUtils';

import ProfileIncompleteMessage from '@src/components/login/notificationinfo/staticmessages/ProfileIncompleteMessage';

import { ITutorCalendar } from '@src/model/tutor/TutorCalendar';
import { ITutorDetails } from '@src/model/tutor/TutorDetails';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';

// -- Prop types
// ----------
export interface IProfileIncompleteMessageContainerPublicProps {}

interface IProfileIncompleteMessageContainerStateProps {
  isUserLoggedIn: boolean;
  currentUser: IUserInfo;
  tutorCalendar: ITutorCalendar;
  tutorDetails: ITutorDetails;
}

interface IProfileIncompleteMessageContainerDispatchProps {
  fetchTutorCalendar: (id: string) => void;
  fetchTutorDetails: (id: string) => void;
}
type IProfileIncompleteMessageContainerProps = IProfileIncompleteMessageContainerPublicProps & IProfileIncompleteMessageContainerStateProps & IProfileIncompleteMessageContainerDispatchProps & IWithLocalizeOwnProps;

interface IProfileIncompleteMessageContainerState {
  tutorCalendar?: ITutorCalendar;
  tutorDetails?: ITutorDetails;
  showMessage: boolean;
}

// --
// ----- Component
class ProfileIncompleteMessageContainer extends React.Component<IProfileIncompleteMessageContainerProps, IProfileIncompleteMessageContainerState> {
  state: IProfileIncompleteMessageContainerState = {
    showMessage: false,
  };

  componentDidMount = () => {
    if (this.isTutor()) {
      // fetch tutor detials and claendar if they're empty (details can be undefined if it hasn't yet been created, so check only NULL)
      if (this.props.tutorCalendar == null || this.props.tutorDetails === null) {
        this.props.fetchTutorCalendar(this.props.currentUser.id);
        this.props.fetchTutorDetails(this.props.currentUser.id);
      } else {
        // not empty, check the profile then
        this.checkProfileComplete(this.props.tutorCalendar, this.props.tutorDetails);
      }
    }
  };

  componentDidUpdate(prevProps: IProfileIncompleteMessageContainerProps, prevState: IProfileIncompleteMessageContainerState) {
    if (this.isTutor()) {
      if (
        // has it changed from last update
        this.props.tutorCalendar !== prevProps.tutorCalendar &&
        // ignore if someone else cleared it from the store
        this.props.tutorCalendar != null &&
        // has it changed since the last we've stored it
        this.props.tutorCalendar !== this.state.tutorCalendar
      ) {
        this.setState({ tutorCalendar: this.props.tutorCalendar });
      }
      if (
        // has it changed from last update
        this.props.tutorDetails !== prevProps.tutorDetails &&
        // ignore if someone else cleared it from the store
        this.props.tutorDetails !== null &&
        // has it changed since the last we've stored it
        this.props.tutorDetails !== this.state.tutorDetails
      ) {
        this.setState({ tutorDetails: this.props.tutorDetails });
      }

      if (
        // if any of variables has changed since the last time
        (this.props.currentUser !== prevProps.currentUser || this.state.tutorCalendar !== prevState.tutorCalendar || this.state.tutorDetails !== prevState.tutorDetails) &&
        // and is not empty (currentUser is not since we checked in isTutor() and tutorDetails can be undefined initially until first created)
        this.state.tutorCalendar != null &&
        this.state.tutorDetails !== null /* NULL is the defualt value so don't use != null here */
      ) {
        this.checkProfileComplete(this.state.tutorCalendar, this.state.tutorDetails);
      }
    }
  }

  render = () => {
    if (this.state.showMessage) {
      return <ProfileIncompleteMessage />;
    } else {
      return null;
    }
  };

  /** Is user logged in and is in TUTOR role. */
  isTutor(): boolean {
    return this.props.currentUser !== null && RoleUtils.isInRoles([UserRoleEnum.TUTOR], this.props.currentUser.roles);
  }

  /** Check if profile is complete and determine if we should show THE message. */
  checkProfileComplete = (tutorCalendar: ITutorCalendar, tutorDetails?: ITutorDetails): void => {
    this.setState({
      showMessage: !this.isProfileComplete(tutorCalendar, tutorDetails),
    });
  };

  /** Verify if profile is to be considered completed. */
  isProfileComplete(tutorCalendar: ITutorCalendar, tutorDetails?: ITutorDetails): boolean {
    const hasCalendarDays = Object.values(tutorCalendar).some((day) => {
      return day.length > 0;
    });
    const hasEducationAreas = tutorDetails != null ? tutorDetails.educationAreaLevels.length > 0 : false;
    const hasHourlyRate = tutorDetails != null ? !!tutorDetails.hourlyRate || tutorDetails.hourlyRate === 0 : false;

    return hasCalendarDays && hasEducationAreas && hasHourlyRate;
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IProfileIncompleteMessageContainerStateProps => ({
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  tutorCalendar: TutorBusinessStore.selectors.getTutorCalendar(state),
  tutorDetails: TutorBusinessStore.selectors.getTutorDetails(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IProfileIncompleteMessageContainerDispatchProps => ({
  fetchTutorCalendar: (id: string) => dispatch(TutorBusinessStore.actions.fetchTutorCalendar({ id })),
  fetchTutorDetails: (id: string) => dispatch(TutorBusinessStore.actions.fetchTutorDetails({ id })),
});

export default connect<IProfileIncompleteMessageContainerStateProps, IProfileIncompleteMessageContainerDispatchProps>(mapStateToProps, mapDispatchToProps)(withLocalize(ProfileIncompleteMessageContainer as any));
