import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map } from 'rxjs/operators';

import { IPayloadAction, ICollectionData, ICollectionFetchPayload, ILemonAction } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ITutor from '@src/model/tutor/Tutor';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { startGlobalProgress, stopGlobalProgress, actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('tutorListBusinessStore');

// -
// -------------------- Types&Consts

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface ITutorListFilter {
  // [key: string]: any;
  educationArea?: number;
  educationLevel?: number;
  minRate?: number;
  maxRate?: number;
  timePeriods?: string[];
  daysOfWeek?: string[];
}

const TUTOR_LIST_FILTER_NAME = 'TUTOR_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getTutorList = (store: any): ICollectionData<ITutor> => store.tutorList;

const getTutorListFilter = (store: any): ITutorListFilter => ListFilterBusinessStore.selectors.getListFilter(store, TUTOR_LIST_FILTER_NAME);

// -
// -------------------- Actions

const Actions = {
  TUTOR_SEARCH_LIST_FETCH: 'TUTOR_SEARCH_LIST_FETCH',
  TUTOR_SEARCH_LIST_LOAD: 'TUTOR_SEARCH_LIST_LOAD',
};

const fetchTutorList = (params: ICollectionFetchPayload<ITutorListFilter>): IPayloadAction<ICollectionFetchPayload<ITutorListFilter>> => {
  return {
    type: Actions.TUTOR_SEARCH_LIST_FETCH,
    payload: params,
  };
};

const loadTutorList = (data: ICollectionData<ITutor>): IPayloadAction<ICollectionData<ITutor>> => {
  return {
    type: Actions.TUTOR_SEARCH_LIST_LOAD,
    payload: data,
  };
};

const storeTutorListFilter = (listFilter: ITutorListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(TUTOR_LIST_FILTER_NAME, listFilter);
};

// -
// -------------------- Side-effects

const fetchTutorListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ITutorListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTOR_SEARCH_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('Tutor')).fetchEntityList(payload).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTutorList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching tutor list', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const tutorList = (state: ICollectionData<ITutor> | null = null, action: IPayloadAction<ICollectionData<ITutor>>) => {
  if (action.type === Actions.TUTOR_SEARCH_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }

  return state;
};

// --
// -------------------- Business Store

export const TutorListBusinessStore = {
  actions: {
    fetchTutorList, loadTutorList,
    storeTutorListFilter,
  },

  selectors: {
    getTutorList, getTutorListFilter,
  },

  effects: {
    fetchTutorListEffect,
  },

  reducers: {
    tutorList,
  },
};

// --
// ----- Exports

export default TutorListBusinessStore;
