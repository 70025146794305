import React, { SuspenseProps } from 'react';

/** Show fallback component until Component has been loaded. Used with lazy loaded components. */
const withLazyLoad = <P extends object>(Component: React.ComponentType<P>, fallback: SuspenseProps['fallback']) =>
  class WithLazyLoad extends React.Component<P> {
    render() {
      return (<React.Suspense fallback={fallback}>
        <Component {...this.props} />
      </React.Suspense>);
    }
  };

export default withLazyLoad;
