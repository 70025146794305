/**
 * Error base class that should be used for all other custom errors.
 * It manually sets message and name properties. Name property is set from:
 *  - name argument or
 *  - constructor.name if it's available, or
 *  - "LemonError"
 */
export default class LemonError extends Error {
  name: string;
  message: string;
  stack?: string;

  constructor(message: string, name?: string) {
    super(message);

    // restore prototype chain - https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, new.target.prototype);

    this.message = message;
    this.name = name || (this.constructor as any).name || 'LemonError';
    this.stack = (new Error()).stack;
  }

}

