import { MethodFactory, Logger, LogLevelNumbers } from 'loglevel';

/**
 * Default log decorator. Adds additional data to log message ie. logger name, timestamp, ...
 */

const methodFactory = (originalFactory: MethodFactory) => (methodName: string, level: LogLevelNumbers, loggerName: string) => {
  const rawMethod = originalFactory(methodName, level, loggerName);

  return (...args: any[]) => {

    rawMethod.apply(null, [
      // logger name
      ...(loggerName != null ? [`[${loggerName}]`] : []),

      // log level - enable if needed
      // ...(methodName != null ? [`[${methodName}]`] : []),

      // logging args
      ...args,
    ]);
  };
};


/**
 * Plugin application function.
 */
export function applyPlugin(rootLogger: Logger) {
  const originalFactory = rootLogger.methodFactory;
  rootLogger.methodFactory = methodFactory(originalFactory);
}
