import localforage from 'localforage';

import { PersistStorage, PersistStorageType, PersistStorageError } from '@src/service/util/persist/types';

let STORAGE_INSTANCE: PersistStorage;

/** Return storage facade. Fails if storage has not been initialized yet. */
export const getStorage = (): PersistStorage => {
  if (STORAGE_INSTANCE == null) {
    throw new PersistStorageError('Storage not initialized. Call createStorage(config) first.');
  }

  return STORAGE_INSTANCE;
};

/** Create and configure storage implementation. Must be called before storage can be used. */
export const createStorage = (type: PersistStorageType, config: any): PersistStorage => {
  if (STORAGE_INSTANCE != null) {
    throw new PersistStorageError('Storage already initialized!');
  }

  STORAGE_INSTANCE = createLocalforageStorage(localforage.createInstance({
    driver: selectPersistStorageType(type),
    ...(config || {}),
  }));

  return getStorage();
};

// ---------- private

/** Creates localforage storage instance. */
const createLocalforageStorage = (storage: any): PersistStorage => ({
  getItem: (key: string) => {
    return new Promise((resolve, reject) => {
      storage.getItem(key).then(resolve, reject);
    });
  },

  setItem: (key: string, item: any) => {
    return new Promise((resolve, reject) => {
      storage.setItem(key, item).then(resolve, reject);
    });
  },

  removeItem: (key: string) => {
    return new Promise((resolve, reject) => {
      storage.removeItem(key).then(resolve, reject);
    });
  },
});

/** Map our storage types to local implementation (localforage). */
const selectPersistStorageType = (type: PersistStorageType) => {
  if (type === PersistStorageType.LocalStorage) {
    return localforage.LOCALSTORAGE;
  }
  if (type === PersistStorageType.IndexedDb) {
    return localforage.INDEXEDDB;
  }
  if (type === PersistStorageType.WebSQL) {
    return localforage.WEBSQL;
  }
  else {
    throw new PersistStorageError(`Unknown storage type: ${type}`);
  }
};

export default createStorage;
