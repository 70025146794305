import StoreService from '@src/service/business/StoreService';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import { createActionThunk } from '@src/service/util/action/thunk';
import { Observable, Observer } from 'rxjs';

export function hasUserToken() {
  return LoginBusinessStore.selectors.hasUserToken();
}
export function isUserLoggedIn() {
  return LoginBusinessStore.selectors.isUserLoggedIn(StoreService.getStore().getState());
}
export function getCurrentUser() {
  return LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());
}

/** Initialize current user and return observable to allow subscriptions. */
export function initializeCurrentUser(): Observable<boolean> {
  return Observable.create((observer: Observer<boolean>): void => {
    StoreService.dispatchAction(
      createActionThunk(
        LoginBusinessStore.actions.fetchCurrentUser(),
        {
          success: (data) => {
            observer.next(data);
            observer.complete();
          },
          error: (err) => {
            observer.next(err);
            observer.complete();
          },
        }
      )
    );
  });
}
