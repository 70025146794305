export default interface ITutoringSessionParticipantStatus {
  id: TutoringSessionParticipantStatusEnum;
  name: string;
  description: string;
}

export enum TutoringSessionParticipantStatusEnum {
  INVITED = '1',
  ACCEPTED = '2',
  DECLINED = '3',
  JOINED = '4',
  LEFT = '5',
  CANCELED = '6',
}
