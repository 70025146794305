import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import IHttpRestClient from '@src/service/api/rest/IHttpRestClient';
import UnknownApiMethodError from '@src/service/api/UnknownApiMethodException';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import AppConfigService from '@src/service/common/AppConfigService';
import modelEntityNameAdapter from '@src/service/api/registry/entity/modelEntityNameAdapter';
import { LangUtils } from '@src/service/util/LangUtils';
import { SuccessApiResponse, ICollectionResponse, ApiResponseEventStatus, ErrorApiResponse } from '@src/service/api/model/apiEvent';
import ApiResponseErrorException from '@src/service/api/ApiResponseErrorException';
import { BaseApiResponse } from '@src/service/api/model/apiEvent';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

export default class RestEntityApi<M, E, C = any> implements IAbstractEntityApi<M, E, C> {

  // private logger: Logger = LoggingService.createLogger("service.api.rest.EntityRestApi");

  constructor(private httpRestClient: IHttpRestClient) {
  }

  // ---------- Entity API

  fetchEntity(entity: M, id: string, queryParams?: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.fetchResource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  createEntity(entity: M, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.createResource(this.resolveBaseUrl(entity), this.resolveResource(entity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  updateEntity(entity: M, id: string, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.updateResource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  deleteEntity(entity: M, id: string): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.deleteResource(this.resolveBaseUrl(entity), this.resolveResource(entity), id).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  updateEntityMethod(entity: M, id: string, method: string, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.updateResourceMethod(this.resolveBaseUrl(entity), this.resolveResource(entity), id, method, body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  // ---------- Entity list API

  fetchEntityList(entity: M, queryParams?: object): Observable<SuccessApiResponse<ICollectionResponse<E, C>>> {
    return this.httpRestClient.fetchResourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  createEntityList(entity: M, body: object): Observable<SuccessApiResponse<object[]>> {
    return this.httpRestClient.createResourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  deleteEntityList(entity: M, body: object[]): Observable<SuccessApiResponse<void>> {
    return this.httpRestClient.deleteResourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  // ---------- Subentity list API

  fetchSubentityList(entity: M, id: string, subentity: M, queryParams?: object): Observable<SuccessApiResponse<ICollectionResponse<E, C>>> {
    return this.httpRestClient.fetchSubresourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), id, this.resolveResource(subentity), queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  createSubentityList(entity: M, id: string, subentity: M, body: object[]): Observable<SuccessApiResponse<object[]>> {
    return this.httpRestClient.createSubresourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), id, this.resolveResource(subentity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  updateSubentityList(entity: M, id: string, subentity: M, body: object[]): Observable<SuccessApiResponse<void>> {
    return this.httpRestClient.updateSubresourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), id, this.resolveResource(subentity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  deleteSubentityList(entity: M, id: string, subentity: M, body: object[]): Observable<SuccessApiResponse<void>> {
    return this.httpRestClient.deleteSubresourceList(this.resolveBaseUrl(entity), this.resolveResource(entity), id, this.resolveResource(subentity), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  // ---------- Subobject API

  fetchSubobject(entity: M, id: string, subobject: M, queryParams?: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.fetchSubresource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, LangUtils.stringify(subobject), queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  createSubobject(entity: M, id: string, subobject: M, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.createSubresource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, LangUtils.stringify(subobject), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  updateSubobject(entity: M, id: string, subobject: M, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.updateSubresource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, LangUtils.stringify(subobject), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  deleteSubobject(entity: M, id: string, subobject: M, body: object): Observable<SuccessApiResponse<void>> {
    return this.httpRestClient.deleteSubresource(this.resolveBaseUrl(entity), this.resolveResource(entity), id, LangUtils.stringify(subobject), body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  // ---------- Method API

  fetchMethod(entity: M, method: string, queryParams?: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.fetchMethod(this.resolveBaseUrl(entity), this.resolveResource(entity), method, queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  fetchNoMethod(entity: M, queryParams?: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.fetchNoMethod(this.resolveBaseUrl(entity), this.resolveResource(entity), queryParams).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  createMethod(entity: M, method: string, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.createMethod(this.resolveBaseUrl(entity), this.resolveResource(entity), method, body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  updateMethod(entity: M, method: string, body: object): Observable<SuccessApiResponse<E>> {
    return this.httpRestClient.updateMethod(this.resolveBaseUrl(entity), this.resolveResource(entity), method, body).pipe(
      tap((data) => {
        this.checkErrorResponse(data);
      })
    );
  }

  // ---------- Custom method API

  customMethod(entity: M, method: string, queryParams?: object[]): Observable<SuccessApiResponse<E>> {
    // implement custom method calls
    throw new UnknownApiMethodError(method, 'REST');
  }

  // ----------- Utility methods

  /** Check API response and throw error in case of error response. */
  checkErrorResponse(response: BaseApiResponse) {
    if (response == null) {
      throw new ApiResponseErrorException('API response is empty. This can happen when request is canceled by the browser.');
    }
    else if (response.status === ApiResponseEventStatus.ERROR) {
      const errorData = response as ErrorApiResponse;
      throw new ApiResponseErrorException(errorData.errorCode, errorData.errorMessage);
    }
  }

  // ---------- Resolve API URL

  // TODO: is this supposed to be here? is this class supposed to be aware of eg. AppConfigService etc.

  private resolveBaseUrl(entity: M): string {
    return UrlBuilderFactory.createApiBuilder()
      .urlPart(AppConfigService.getValue('api.sourceUrl'))
      .build();
  }

  private resolveResource(entity: M): string {
    return modelEntityNameAdapter(LangUtils.stringify(entity));
  }

}
