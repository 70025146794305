import { IBrowserPermissionStatus } from './types';

export type QueryBrowserPermissionRequest = {name: 'notifications' | 'camera' | 'microphone'};

export default class BrowserPermissions {
  /** Query browser permissions. Cross browser support for asking/querying permissions is not so greate so this class should be used to hide differences. */
  static queryPermission(request: QueryBrowserPermissionRequest): Promise<IBrowserPermissionStatus> {
    if (request.name === 'camera' && false) {
      return BrowserPermissions.queryPermissionCamera(request);
    }
    else if (request.name === 'microphone' && false) {
      return BrowserPermissions.queryPermissionMicrophone(request);
    }
    else {
      return BrowserPermissions.queryPermissionDefault(request);
    }
  }

  private static queryPermissionDefault(request: QueryBrowserPermissionRequest): Promise<IBrowserPermissionStatus> {
    return new Promise((resolve, reject) => {
      try {
        // Support for Permissions API exists in Chrome and FF
        const permissions = (navigator as any).permissions; // could not get typings for permissions API, thus using "any"
        if (permissions) {
          permissions.query(request)
            .then(
              (result: any) => {
                resolve(result);
              },
              (err: any) => {
                // ugly error type detection in order to cover cross-browser permission checking
                // Permission querying for 'camera' and 'microphone' is currently available only in Chrome.
                // current error message: "'name' member of PermissionDescriptor 'microphone' is not a valid value for enumeration PermissionName."
                if (err instanceof TypeError && err.message.indexOf('PermissionName') && (request.name === 'camera' || request.name === 'microphone')) {
                  if (request.name === 'camera') {
                    BrowserPermissions.queryPermissionCamera(request)
                      .then(result => resolve(result))
                      .catch(e => reject(e));
                  }
                  else if (request.name === 'microphone') {
                    BrowserPermissions.queryPermissionMicrophone(request)
                      .then(result => resolve(result))
                      .catch(e => reject(e));
                  }
                }
                else {
                  reject(err);
                }
              }
            );
        }
        // others - try something
        else {
          // TODO: support for other types of permission checks
          // return as if nothing has been queried or detected
          resolve({ state: 'default' });
        }
      }
      catch (err) {
        reject(err);
      }
    });
  }

  private static queryPermissionMicrophone(request: QueryBrowserPermissionRequest): Promise<IBrowserPermissionStatus> {
    return navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        return devices.some(device => device.kind === 'audioinput' && device.label !== '');
      })
      .then((result) => {
        const status: IBrowserPermissionStatus = { state: result ? 'granted' : 'default' };
        return status;
      });
  }

  private static queryPermissionCamera(request: QueryBrowserPermissionRequest): Promise<IBrowserPermissionStatus> {
    return navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        return devices.some(device => device.kind === 'videoinput' && device.label !== '');
      })
      .then((result) => {
        const status: IBrowserPermissionStatus = { state: result ? 'granted' : 'default' };
        return status;
      });
  }
  /* initial implementation - remove after a while
  return new Promise((resolve, reject) => {
    try {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          devices.find((device) => {
            if (request.name === 'camera' && device.kind === 'videoinput' && device.label !== '') {
              resolve({ state: 'granted' });
              return true;
            }
            else if (request.name === 'microphone' && device.kind === 'audioinput' && device.label !== '') {
              resolve({ state: 'granted' });
              return true;
            }
            // defualt to default, we cannot know if it's never asked or already blocked
            resolve({ state: 'default' });

            return false;
          });
        })
        .catch((err) => {
          reject(err);
        });
    }
    catch (err) {
      reject(err);
    }
  });*/
}
