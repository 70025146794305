import { INamedPayload, IPayloadAction } from '@src/service/business/common/types';

// -
// -------------------- Types

export interface IListFilterPayload<T> extends INamedPayload {
  name: string;
  listFilter: T;
}

// -
// -------------------- Selectors

const getListFilter = <P>(store: any, name: string): P => store.listFilters[name];

// -
// -------------------- Actions

const Actions = {
  FILTER_STORE: 'FILTER_STORE',
  FILTER_CLEAR: 'FILTER_CLEAR',
};

const storeListFilter = (name: string, listFilter: any): IPayloadAction<IListFilterPayload<any>> => {
  return {
    type: Actions.FILTER_STORE,
    payload: {
      name,
      listFilter,
    },
  };
};

const clearListFilter = (name: string): IPayloadAction<INamedPayload> => {
  return {
    type: Actions.FILTER_CLEAR,
    payload: {
      name,
    },
  };
};

// -
// -------------------- Reducers

const listFilters = (state: { [name: string]: any } = {}, action: IPayloadAction<IListFilterPayload<any>>) => {
  // ----- FILTER_STORE
  if (action.type === Actions.FILTER_STORE) {
    return {
      ...state,
      [action.payload.name]: action.payload.listFilter,
    };
  }
  // ----- FILTER_CLEAR
  else if (action.type === Actions.FILTER_CLEAR) {
    // copy all keys except the one that should be cleared
    const filteredState = Object.keys(state).reduce((accum: Record<string, any>, key) => {
      if (key !== action.payload.name) {
        accum[key] = state[key];
      }
      return accum;
    }, {});
    return filteredState;
  }

  return state;
};

// --
// ----- Store object

const ListFilterBusinessStore = {
  actions: {
    storeListFilter,
    clearListFilter,
  },

  selectors: {
    getListFilter,
  },

  reducers: {
    listFilters,
  },
};

// --
// ----- Exports

export default ListFilterBusinessStore;
