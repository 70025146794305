import { Button, Card } from 'antd';
import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NameInputForm from '@src/components/repository/common/NameInputForm';
import FileListBreadcrumb from '@src/components/repository/FileListBreadcrumb';
import { IPath } from '@src/model/common/Path';
import AppConfigService from '@src/service/common/AppConfigService';

const FOLDER_NAME_MAX_LENGTH = AppConfigService.getValue('validation.length.file');

// -- Prop types
// ----------

export interface IFolderSelectHeaderOwnProps {
  onFolderCreate: (name: string) => void;
  onFolderSelect: (folder: IPath) => void;
  routes: IPath;
}
type IFolderSelectHeaderProps = IFolderSelectHeaderOwnProps & IWithLocalizeOwnProps;

interface IFolderSelectHeaderState {
  openNamingModal: boolean;
}

// -- Component
// ----------

/** Describe your component ... */
class FolderSelectHeader extends React.Component<IFolderSelectHeaderProps, IFolderSelectHeaderState> {
  state = {
    openNamingModal: false,
  };

  render() {
    return (
      <React.Fragment>
        <Card className="lemon-repository__header">
          <Button data-test-id="lemon-fileList__newFolderButton" onClick={() => this.toggleModalVisible()} className="float-right">
            <LemonIcon name="folder" /> {this.props.translate('FILE_LIST.NEW_FOLDER_BUTTON')}
          </Button>

          <FileListBreadcrumb onFolderSelect={this.props.onFolderSelect} routes={this.props.routes} />
        </Card>

        {this.state.openNamingModal && <NameInputForm onSubmit={this.props.onFolderCreate} closeModal={this.toggleModalVisible} title={'FILE_LIST.NAME_INPUT_TITLE_CREATE_FOLDER'} validationLength={FOLDER_NAME_MAX_LENGTH} />}
      </React.Fragment>
    );
  }

  toggleModalVisible = () => {
    this.setState({
      openNamingModal: !this.state.openNamingModal,
    });
  };
}

export default withLocalize<IFolderSelectHeaderOwnProps>(FolderSelectHeader as any);
