import { IComponentMessage } from '@src/service/util/pubsub/ComponentMessagingService';
import { IMessagingMessage, ISessionFileMessagingMessage, SessionMessageSentMessagingMessage, ISessionEndNoticeMessagingMessage, ISessionParticipantLeftMessagingMessage, SessionSystemStatusChangeMessagingMessage } from '@src/model/messaging/messages';
import ITutoringSession from '@src/model/session/TutoringSession';
import { ComponentMessageType } from '@src/service/util/pubsub/componentMessageTypes';
import MessagingHelper from '@src/service/business/messaging/MessagingHelper';


/**
 * Class contains rules for checking messaging message updates for various conditions.
 *
 * These conditions can appear in various components that want to react upon these changes and refresh view in some way.
 * So, they have been gathered here to avoid duplication (DRY).
 */
export default class ComponentMessagingRules {

  /**
   * Has session file list changed.
   *
   * Additionally, if session is given as second parameter then it is checked if that session been changed, otherwise, we return true (probably asking for session list).
   */
  static hasSessionFileListChanged(message: IComponentMessage<IMessagingMessage>, tutoringSession?: ITutoringSession) {
    // ----- session file list change
    if (message.type === ComponentMessageType.MESSAGING_SESSION_FILE_ADDED
      || message.type === ComponentMessageType.MESSAGING_SESSION_FILE_REMOVED
      || message.type === ComponentMessageType.MESSAGING_WHITEBOARD_SCREENSHOTS_CREATED) {
      const typedMessage = message.payload as ISessionFileMessagingMessage;
      if (tutoringSession == null || typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has session timeline list changed. Currently it only reacts on new sent messages.
   *
   * Additionally, if session is given as second parameter then it is checked if that session been changed, otherwise, we return true (probably asking for session list).
   */
  static hasSessionTimelineChanged(message: IComponentMessage<IMessagingMessage>, tutoringSession?: ITutoringSession) {
    if (message.type === ComponentMessageType.MESSAGING_SESSION_MESSAGE_SENT
      || ComponentMessagingRules.hasSessionStatusOrTimeChanged(message)
      || ComponentMessagingRules.hasSessionFileListChanged(message)) {
      const typedMessage = message.payload as SessionMessageSentMessagingMessage;
      if (tutoringSession == null || typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has new session been created or existing ones' status or time changed?
   *
   * Additionally, if session is given as second parameter then it is checked if that session been changed, otherwise, we return true (probably asking for session list).
   */
  static hasSessionStatusOrTimeChanged(message: IComponentMessage<IMessagingMessage>, tutoringSession?: ITutoringSession) {
    if (
      // session status changes
      message.type === ComponentMessageType.MESSAGING_SESSION_CANCELATION
      || message.type === ComponentMessageType.MESSAGING_SESSION_CONFIRMATION
      || message.type === ComponentMessageType.MESSAGING_SESSION_CREATED
      || message.type === ComponentMessageType.MESSAGING_SESSION_DECLINED
      || message.type === ComponentMessageType.MESSAGING_SESSION_ENDED
      // session data changes
      || message.type === ComponentMessageType.MESSAGING_SESSION_TIME_CHANGE) {

      const typedMessage = message.payload as SessionMessageSentMessagingMessage;
      if (tutoringSession == null || typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has session received end notice message?
   *
   * Additionally, if session is given as second parameter then it is checked if that session been changed, otherwise, we return true (probably asking for session list).
   */
  static hasSessionReceivedEndNotice(message: IComponentMessage<IMessagingMessage>, tutoringSession: ITutoringSession) {
    if (message.type === ComponentMessageType.MESSAGING_SESSION_END_NOTICE) {
      const typedMessage = message.payload as ISessionEndNoticeMessagingMessage;
      if (typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has session ended?
   */
  static hasSessionEnded(message: IComponentMessage<IMessagingMessage>, tutoringSession: ITutoringSession) {
    if (message.type === ComponentMessageType.MESSAGING_SESSION_ENDED) {
      const typedMessage = message.payload as SessionSystemStatusChangeMessagingMessage;
      if (typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has session participant left?
   */
  static hasSessionParticipantLeft(message: IComponentMessage<IMessagingMessage>, tutoringSession: ITutoringSession) {
    if (message.type === ComponentMessageType.MESSAGING_SESSION_PARTICIPANT_LEFT) {
      const typedMessage = message.payload as ISessionParticipantLeftMessagingMessage;
      if (typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Has session changed?
   */
  static hasSessionChanged(message: IComponentMessage<IMessagingMessage>, tutoringSession: ITutoringSession) {
    if (message.type === ComponentMessageType.MESSAGING_SESSION_TIME_CHANGE
      || message.type === ComponentMessageType.MESSAGING_SESSION_CONFIRMATION
      || message.type === ComponentMessageType.MESSAGING_SESSION_CANCELATION
      || message.type === ComponentMessageType.MESSAGING_SESSION_DECLINED
      || message.type === ComponentMessageType.MESSAGING_SESSION_ENDED) {
      const typedMessage = message.payload as ISessionParticipantLeftMessagingMessage;
      if (typedMessage.sessionId === tutoringSession.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Is message stored for display and thus, should be displayed.
   */
  static isDisplayableMessage(message: IComponentMessage<IMessagingMessage>) {
    if (message.payload != null) {
      return MessagingHelper.isDisplayableMessage(message.payload);
    }

    return false;
  }
}
