import { Observable } from 'rxjs';
import { filter, mergeMap, catchError, map, ignoreElements } from 'rxjs/operators';

import { IPayloadAction, ILemonAction, ICollectionData, IIdDataPayload, ICollectionFetchPayload } from '@src/service/business/common/types';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import TutoringRoomApiService from '@src/service/api/room/TutoringRoomApiService';
import ITutoringRoomAccessData from '@src/model/room/TutoringRoomAccessData';
import ITutoringSession from '@src/model/session/TutoringSession';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import IFile from '@src/model/file/File';
import AppConfigService from '@src/service/common/AppConfigService';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { StateObservable } from 'redux-observable';
import trackAction from '@src/service/util/observable/operators/trackAction';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('tutoringRoomBusinessStore');

// -
// -------------------- Types&Consts

interface ITutoringRoomJoinRequest {
  tutoringSessionId: string;
}

interface IFileListFilter {
}

export interface IRoomsAvailabilityFilter {
  startDateTime: string;
  endDateTime: string;
}

export interface IRoomsAvailability {
  available: boolean;
}

// -
// -------------------- Selectors

const getTutoringRoomAccessData = (store: any): ITutoringRoomAccessData => store.tutoringRoomAccessData;

const getTutoringRoomSessionFileList = (store: any): ICollectionData<IFile> => store.tutoringRoomSessionFileList;

// -
// -------------------- Actions

const Actions = {
  TUTORING_ROOM_JOIN: 'TUTORING_ROOM_JOIN',
  TUTORING_ROOM_LEAVE: 'TUTORING_ROOM_LEAVE',

  TUTORING_ROOM_ACCESS_DATA_STORE: 'TUTORING_ROOM_ACCESS_DATA_STORE',
  TUTORING_ROOM_ACCESS_DATA_CLEAR: 'TUTORING_ROOM_ACCESS_DATA_CLEAR',

  TUTORING_ROOM_SESSION_FILE_LIST_FETCH: 'TUTORING_ROOM_SESSION_FILE_LIST_FETCH',
  TUTORING_ROOM_SESSION_FILE_LIST_STORE: 'TUTORING_ROOM_SESSION_FILE_LIST_STORE',

  TUTORING_ROOM_AVAILABILITY_FETCH: 'TUTORING_ROOM_AVAILABILITY_FETCH',
};

const joinTutoringRoom = (tutoringSession: ITutoringSession): IPayloadAction<ITutoringRoomJoinRequest> => {
  return {
    type: Actions.TUTORING_ROOM_JOIN,
    payload: {
      tutoringSessionId: tutoringSession.id,
    },
  };
};

const leaveTutoringRoom = (tutoringSession: ITutoringSession): IPayloadAction<ITutoringRoomJoinRequest> => {
  return {
    type: Actions.TUTORING_ROOM_LEAVE,
    payload: {
      tutoringSessionId: tutoringSession.id,
    },
  };
};

const storeTutoringRoomAccessData = (data: ITutoringRoomAccessData): IPayloadAction<ITutoringRoomAccessData> => {
  return {
    type: Actions.TUTORING_ROOM_ACCESS_DATA_STORE,
    payload: data,
  };
};

const clearTutoringRoomAccessData = (): ILemonAction => {
  return {
    type: Actions.TUTORING_ROOM_ACCESS_DATA_CLEAR,
  };
};

const fetchSessionFileList = (tutoringSession: ITutoringSession): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.TUTORING_ROOM_SESSION_FILE_LIST_FETCH,
    payload: {
      id: tutoringSession.id,
      data: {
        filter: {},
        page: 0,
        size: AppConfigService.getValue('api.paging.maxPageSize'),
        sort: [],
      },
    },
  };
};

const storeSessionFileList = (data: ICollectionData<IFile>): IPayloadAction<ICollectionData<IFile>> => {
  return {
    type: Actions.TUTORING_ROOM_SESSION_FILE_LIST_STORE,
    payload: data,
  };
};

const fetchRoomsAvailability = (params: IRoomsAvailabilityFilter): IPayloadAction<IRoomsAvailabilityFilter> => {
  return {
    type: Actions.TUTORING_ROOM_AVAILABILITY_FETCH,
    payload: params,
  };
};

// -
// -------------------- Side-effects

const joinTutoringRoomEffect = (action$: Observable<IPayloadAction<ITutoringRoomJoinRequest>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTORING_ROOM_JOIN;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      LOGGER.info(`Join current user to tutoring room #${payload.tutoringSessionId}`);

      return (EntityApiServiceRegistry.getService('TutoringRoom') as TutoringRoomApiService).joinTutoringRoom(payload.tutoringSessionId).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      if (data != null) {
        return storeTutoringRoomAccessData(data);
      }
      else {
        return clearTutoringRoomAccessData();
      }
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TUTORING_ROOM.ERROR_MESSAGE', 'TUTORING_ROOM.ERROR_MESSAGE.DEFAULT')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error joining tutoring room', error);
      return o;
    })
  );
};

const leaveTutoringRoomEffect = (action$: Observable<IPayloadAction<ITutoringRoomJoinRequest>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTORING_ROOM_LEAVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      LOGGER.info(`Leave current user from tutoring room #${payload.tutoringSessionId}`);

      return (EntityApiServiceRegistry.getService('TutoringRoom') as TutoringRoomApiService).leaveTutoringRoom(payload.tutoringSessionId).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    map((data) => {
      return clearTutoringRoomAccessData();
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TUTORING_ROOM.ERROR_MESSAGE', 'TUTORING_ROOM.ERROR_MESSAGE.LEAVE_ROOM')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error leaving tutoring room', error);
      return o;
    })
  );
};

const fetchSessionFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTORING_ROOM_SESSION_FILE_LIST_FETCH;
    }),

    // file list is sometimes updated in the background and we don't wont to show overlay during session
    // startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return (EntityApiServiceRegistry.getService('TutoringRoom')).fetchSubentityList(payload.id, 'File', payload.data).pipe(
        actionThunk(action)
      );
    }),

    // stopGlobalProgress(),

    map((data) => {
      return storeSessionFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTORING_ROOM.ERROR_MESSAGE.SESSION_FILE_LIST_FETCH')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching tutoring room session files', error);

      return o;
    })
  );
};

const fetchRoomsAvailabilityEffect = (action$: Observable<IPayloadAction<IRoomsAvailabilityFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TUTORING_ROOM_AVAILABILITY_FETCH;
    }),

    mergeMap((action) => {
      const params = action.payload;

      return (EntityApiServiceRegistry.getService('Room')).fetchMethod('availability', params).pipe(
        trackAction(action)
      );
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TUTORING_ROOM.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      LOGGER.error('Error fetching statistics', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const tutoringRoomAccessData = (state: ITutoringRoomAccessData | null = null, action: IPayloadAction<ITutoringRoomAccessData>) => {
  if (action.type === Actions.TUTORING_ROOM_ACCESS_DATA_STORE) {
    return {
      ...action.payload,
    };
  }
  else if (action.type === Actions.TUTORING_ROOM_ACCESS_DATA_CLEAR) {
    return null;
  }

  return state;
};

const tutoringRoomSessionFileList = (state: ICollectionData<IFile> | null = null, action: IPayloadAction<ICollectionData<IFile>>) => {
  if (action.type === Actions.TUTORING_ROOM_SESSION_FILE_LIST_STORE) {
    return action.payload;
  }

  return state;
};

// --
// -------------------- Business Store

const TutoringRoomBusinessStore = {
  actions: {
    joinTutoringRoom, leaveTutoringRoom, clearTutoringRoomAccessData,
    fetchSessionFileList, fetchRoomsAvailability,
  },

  selectors: {
    getTutoringRoomAccessData, getTutoringRoomSessionFileList,
  },

  effects: {
    joinTutoringRoomEffect, leaveTutoringRoomEffect,
    fetchSessionFileListEffect, fetchRoomsAvailabilityEffect,
  },

  reducers: {
    tutoringRoomAccessData, tutoringRoomSessionFileList,
  },
};

// --
// ----- Exports

export default TutoringRoomBusinessStore;
