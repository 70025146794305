const hr = {
  // ---------- Template labels
  'MODULE.LABEL_NAME': 'Template for adding labels',

  // ---------- Test labels
  'TEST.DEMO_LABEL': 'Ovo je testni string ... ',

  // ---------- Locale labels
  'LOCALE_LABEL.HR': 'Hrvatski',
  'LOCALE_LABEL.SL': 'Slovenščina',

  // ---------- Application
  'APPLICATION.APP_NAME': 'Evolve',
  'APPLICATION.APP_LOGO_TITLE': 'Evolve - Lamaro Digital',
  'APPLICATION.APP_DESCRIPTION': 'Platforma za mentoriranje',

  // ---------- Footer
  'APPLICATION_FOOTER.PRIVACY_POLICY': 'Pravila privatnosti',
  'APPLICATION_FOOTER.CONTACT': 'Kontakt',

  // ---------- COMMON ACTIONS
  'COMMON.ACTION_SAVE': 'Spremi',
  'COMMON.ACTION_EDIT': 'Uredi',
  'COMMON.ACTION_OK': 'OK',
  'COMMON.ACTION_CANCEL': 'Odustani',
  'COMMON.ACTION_ADD': 'Dodaj',
  'COMMON.ACTION_ADD_NEW': 'Dodaj novi',
  'COMMON.ACTION_SEARCH': 'Traži',
  'COMMON.ACTION_RESET': 'Reset',
  'COMMON.ACTION_LOGOUT': 'Odjavite se',
  'COMMON.ACTION_LOAD_MORE_ITEMS': 'Prikaži više',
  'COMMON.ACTION_CLOSE': 'Zatvori',
  'COMMON.ACTION_SHOW_MORE_CONTENT': 'prikaži više',
  'COMMON.ACTION_SHOW_LESS_CONTENT': 'sakrij',
  'COMMON.ACTION_DELETE': 'Obriši',
  'COMMON.ACTION_CLEAR': 'Očisti',
  'COMMON.ACTION_CONTINUE': 'Nastavi',
  'COMMON.ACTION_DONE': 'Gotovo',
  'COMMON.ACTION_SUBMIT': 'Pošalji',
  'COMMON.ACTION_RETRY': 'Pokušaj ponovo',
  'COMMON.ACTION_COPY': 'Kopiraj',
  'COMMON.IN_PROGRESS': 'Učitavanje ...',

  'COMMON.FILL_REQUIRED_FIELD': 'Popunite obavezan podatak',
  'COMMON.FILL_MAX_LENGTH_REACHED': 'Molimo skratite unos',
  'COMMON.FILL_ENTER_VALID_PHONE_NUMBER': 'Molimo unesite ispravan broj telefona',
  'COMMON.FILL_MIN_PHONE_LENGTH': 'Telefonski broj bi trebao imati minimalno 8 znamenki',

  // ---------- General Messages
  'GENERAL_MESSAGE.GENERAL_FETCH_ERROR': 'Greška pri dohvatu podataka!',
  'GENERAL_MESSAGE.GENERAL_SEND_ERROR': 'Greška pri slanju podataka!',
  'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR': 'Greška pri spremanju podataka!',
  'GENERAL_MESSAGE.GENERAL_DELETE_ERROR': 'Greška pri brisanju podataka!',

  'GENERAL_MESSAGE.GENERAL_FETCH_SUCCESS': 'Podatci uspješno dohvaćeni.',
  'GENERAL_MESSAGE.GENERAL_SEND_SUCCESS': 'Podatci uspješno poslani.',
  'GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS': 'Podatci uspješno spremljeni.',
  'GENERAL_MESSAGE.GENERAL_DELETE_SUCCESS': 'Podatci uspješno obrisani.',
  'GENERAL_MESSAGE.GENERAL_NOTIFICATION_ERROR': 'Preglednik ne podržava pozadinske poruke zbog čega neće raditi osvježavanje poruka i aktivnosti drugih korisnika na vašim mentorskim satima. Preporučeni preglednici su Chrome i Firefox na Linux, Android ili Windows operativnim sustavima.',

  // ---------- API Messages (API response error messages) - messages that should be valid for every API call that shows API errors
  // 'API_RESPONSE_ERROR.<ERROR_CODE>': '<error message>',

  // ---------- Login Form
  'LOGIN.USERNAME_LABEL': 'Korisničko ime',
  'LOGIN.USERNAME_PLACEHOLDER': 'Unesite korisničko ime',
  'LOGIN.PASSWORD_LABEL': 'Lozinka',
  'LOGIN.PASSWORD_PLACEHOLDER': 'Unesite lozinku',
  'LOGIN.LOGIN_BUTTON_LABEL': 'Prijava',
  'LOGIN.FORGOT_PASSWORD_LABEL': 'Zaboravili ste lozinku',
  'LOGIN.REGISTRATION_LABEL': 'Nemate korisnički račun?',
  'LOGIN.REGISTRATION_LINK': 'Registracija',
  // errors
  'LOGIN.ERROR.GENERAL_ERROR': 'Greška pri prijavi u sustav',
  // API errors
  'LOGIN.ERROR.BAD_CREDENTIALS': 'Neuspješna prijava. Molimo provjerite svoje pristupne podatke i pokušajte ponovo.',

  // ---------- Login Menu
  'LOGIN_MENU.LOGIN_LABEL': 'Prijava',

  // ---------- App navigation
  'APP_NAVIGATION.FAVOURITES_STUDENTS_LABEL': 'Polaznici',
  'APP_NAVIGATION.FAVOURITES_TUTORS_LABEL': 'Moji mentori',
  'APP_NAVIGATION.ALL_TUTORS_LABEL': 'Tražilica mentora',
  'APP_NAVIGATION.INSTRUCTIONS_LABEL': 'Upute',
  // tslint:disable-next-line: no-duplicate-string
  'APP_NAVIGATION.MY_REPOSITORY_LABEL': 'Moji materijali',
  'APP_NAVIGATION.MY_CALENDAR_LABEL': 'Kalendar',
  'APP_NAVIGATION.REPORTS_LABEL': 'Izvještaji',

  // ---------- User Menu
  'USER_MENU.ADMIN_LABEL': 'Administracija',
  'USER_MENU.MY_PROFILE_LABEL': 'Postavke korisničkog računa',
  'USER_MENU.MY_PUBLIC_PROFILE_LABEL': 'Moj javni profil',
  'USER_MENU.CHANGE_PASSWORD_LABEL': 'Promjena lozinke',
  'USER_MENU.LOGOUT_LABEL': 'Odjava',
  'USER_MENU.CHANGE_LANGUAGE_LABEL': 'Promijeni jezik',

  // ---------- Admin Menu
  'ADMIN_MENU.USER_LIST_LABEL': 'Upravljanje korisnicima',
  'ADMIN_MENU.SESSION_LIST_LABEL': 'Pregled sessiona',
  'ADMIN_MENU.STATISTICS_LABEL': 'Statistika sessiona',
  'ADMIN_MENU.ACTIVITIES_LABEL': 'Aktivnosti korisnika',
  'ADMIN_MENU.REVIEW_SESSION_LIST_LABEL': 'Ocjene sata',

  // ---------- Welcome
  'WELCOME.MESSAGE_1': 'Ponudite i pronađite znanje na <span class="lemon-welcome__appLogo">Evolve - Lamaro Digital</span> platformi, digitalnoj mreži znanja koja podržava cjeloživotno obrazovanje vaših zaposlenika.',
  'WELCOME.MESSAGE_2': '',

  // ---------- Admin Activities Menu
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_TIME_CHANGE': 'Promjena termina sata',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CONFIRMATION': 'Mentorski sat potvrđen',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CANCELATION': 'Mentorski sat otkazan',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_DECLINED': 'Mentorski sat odbijen',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_CREATED': 'Novi mentorski sat',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_STARTED': 'Mentorski sat počeo',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_ENDED': 'Mentorski sat završio',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_MESSAGE_SENT': 'Poslana poruka',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_FILE_ADDED': 'Datoteka dodana na sat',
  'ADMIN_ACTIVITIES_FILTER_LABEL.SESSION_FILE_REMOVED': 'Datoteka izbrisana sa sata',
  'ADMIN_ACTIVITIES_FILTER_LABEL.REVIEW_CREATED': 'Nova recenzija mentoru',
  'ADMIN_ACTIVITIES_FILTER_LABEL.ALL_TYPES': 'Sve aktivnosti',
  'ADMIN_ACTIVITIES_FILTER_LABEL.START_DATE_LABEL': 'Datum od',
  'ADMIN_ACTIVITIES_FILTER_LABEL.END_DATE_LABEL': 'Datum do',
  'ADMIN_ACTIVITIES_FILTER_LABEL.ACTIVITY_TYPE_LABEL': 'Tip aktivnosti',
  'ADMIN_ACTIVITIES_LIST.EMPTY_DATA_LABEL': 'Nema aktivnosti',
  'ADMIN_ACTIVITIES_LIST.TIME_COLUMN_TITLE': 'Datum',
  'ADMIN_ACTIVITIES_LIST.TIME_TARGET_USER_COLUMN_TITLE': 'Prema Korisniku',
  'ADMIN_ACTIVITIES_LIST.TIME_CREATOR_COLUMN_TITLE': 'Od korisnika',
  'ADMIN_ACTIVITIES_LIST.ACTIVITY_COLUMN_TITLE': 'Aktivnost',

  // ---------- Change Password
  'CHANGE_PASSWORD.OLD_PASSWORD_LABEL': 'Stara lozinka',
  'CHANGE_PASSWORD.OLD_PASSWORD_PLACEHOLDER': 'Unesite staru lozinku',
  'CHANGE_PASSWORD.NEW_PASSWORD_LABEL': 'Nova lozinka',
  'CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER': 'Unesite novu lozinku',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL': 'Potvrda nove lozinke',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER': 'Unesite ponovno novu lozinku',
  'CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_LABEL': 'Promijeni lozinku',
  'CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Lozinka mora sadržavati minimalno 8 znakova',
  'CHANGE_PASSWORD.PASSWORD_NOT_NEW_ERROR': 'Nova lozinka se mora razlikovati od stare',
  'CHANGE_PASSWORD.PASSWORDS_DIFFERENT_ERROR': 'Nove lozinke nisu identične',
  'CHANGE_PASSWORD.CHANGE_DONE': 'Lozinka uspješno promijenjena',
  'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS': 'Pogrešna stara lozinka',
  'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD': 'Greška prilikom promjene lozinke',

  // ---------- Reset Password & Request
  'RESET_PASSWORD_REQUEST.INFO_TEXT_1': 'Zahtjev za novu lozinku poslan na e-mail adresu',
  'RESET_PASSWORD_REQUEST.INFO_TEXT_2': 'Provjerite Vaše e-mail poruke i slijedite upute',
  'RESET_PASSWORD_REQUEST.EMAIL_LABEL': 'E-mail',
  'RESET_PASSWORD_REQUEST.EMAIL_PLACEHOLDER': 'Unesite Vašu e-mail adresu',
  'RESET_PASSWORD_REQUEST.RESET_PASSWORD_BUTTON_LABEL': 'Pošalji zahtjev za novu lozinku',
  'RESET_PASSWORD_REQUEST.EMAIL_VALIDATION_TEXT': 'Unesite ispravnu e-mail adresu',

  'RESET_PASSWORD.MISSING_TOKEN': 'Neispravan poziv za novu lozinku',
  'RESET_PASSWORD.INVALID_TOKEN': 'Zahtjev za novu lozinku istekao',
  'RESET_PASSWORD.SEND_NEW_REQUEST': 'Pošaljite novi zahtjev',
  'RESET_PASSWORD.RESET_DONE': 'Nova lozinka uspješno aktivirana',
  'RESET_PASSWORD.LINK_TO_LOGIN': 'Prijava',

  // ---------- User registration & activation
  'REGISTRATION.STEP_ONE_TITLE': 'Vrsta korisnika',
  'REGISTRATION.STEP_TWO_TITLE': 'Osobni podaci',
  'REGISTRATION.STEP_THREE_TITLE': 'Podaci za prijavu',
  'REGISTRATION.ROLE_LABEL': 'Želim otvoriti korisnički račun kao',
  'REGISTRATION.ROLE_STUDENT_LABEL': 'Učenik (ili roditelj za djecu mlađu od 16 godina)',
  'REGISTRATION.ROLE_TUTOR_LABEL': 'Mentor',
  'REGISTRATION.USER_TYPE_REQUIRED': 'Odaberite vrstu korisnika',
  'REGISTRATION.BUTTON_PREV_LABEL': 'Prethodni korak',
  'REGISTRATION.BUTTON_NEXT_LABEL': 'Sljedeći korak',
  'REGISTRATION.BUTTON_SUBMIT_LABEL': 'Pošalji zahtjev za korisnički račun',
  'REGISTRATION.CHECK_BOX_TERMS_OF_USE': 'Uvjeti korištenja',
  'REGISTRATION.TUTOR_CHECK_BOX_GDPR': 'Potvrđujem da sam stariji/a od 16 godina i prihvaćam Pravila privatnosti',
  'REGISTRATION.STUDENT_CHECK_BOX_GDPR': 'Potvrđujem da sam stariji/a od 16 godina ili da sam roditelj/skrbnik koji je podatke ispunio za dijete mlađe od 16 godina i prihvaćam Pravila privatnosti',
  'REGISTRATION.INFO_TEXT_1': 'Zahtjev za korisnički račun poslan na e-mail adresu',
  'REGISTRATION.INFO_TEXT_2': 'Provjerite Vaše e-mail poruke i slijedite upute',
  'REGISTRATION.EMAIL_LABEL': 'E-mail adresa kojom ćete potvrditi korisnički račun i prijavljivati se',
  'REGISTRATION.NEW_PASSWORD_LABEL': 'Lozinka',
  'REGISTRATION.CONFIRM_PASSWORD_LABEL': 'Potvrda lozinke',
  'REGISTRATION.NEW_PASSWORD_PLACEHOLDER': 'Unesite lozinku',
  'REGISTRATION.CONFIRM_PASSWORD_PLACEHOLDER': 'Unesite ponovno lozinku',
  'REGISTRATION.USERNAME_ALREADY_IN_USE': 'E-mail adresa se već koristi. Unesite neku drugu e-mail adresu.',
  'ACTIVATION.MISSING_TOKEN': 'Neispravan poziv za aktiviranje korisničkog računa',
  'ACTIVATION.INVALID_TOKEN': 'Zahtjev za aktiviranje korisničkog računa istekao',
  'ACTIVATION.SEND_NEW_REQUEST': 'Kreirajte novi zahtjev',
  'ACTIVATION.ACTIVATION_DONE': 'Korisnički račun uspješno aktiviran',
  'ACTIVATION.TUTOR_LINK': 'Dopunite svoj profil kako bi vas polaznici mogli pronaći kroz pretragu',
  'ACTIVATION.STUDENT_LINK': 'Možete pretraživati mentore',

  // ---------- Tutor List & ListItem
  'TUTOR_LIST_FILTER.FILTER_TITLE': 'Filtriranje liste',
  'TUTOR_LIST_FILTER.RESET_FORM_LABEL': 'Poništi',
  'TUTOR_LIST_FILTER.EDUCATION_AREA_LABEL': 'Predmeti',
  'TUTOR_LIST_FILTER.ANY_EDUCATION_AREA_LABEL': 'Svi predmeti',
  'TUTOR_LIST_FILTER.EDUCATION_LEVEL_LABEL': 'Razina',
  'TUTOR_LIST_FILTER.ANY_EDUCATION_LEVEL_LABEL': 'Sve razine',
  'TUTOR_LIST_FILTER.AVAILABILITY_TITLE': 'Dostupnost mentora',
  'TUTOR_LIST_ITEM.DAYS_OF_WEEK_TITLE_LABEL': 'Dani u tjednu',
  'TUTOR_LIST_ITEM.TIME_RANGES_LABEL': 'Vremenski rasponi',
  'TUTOR_LIST_FILTER.AVAILABILITY_LABEL': 'Dostupnost mentora',
  'TUTOR_LIST_FILTER.ANY_AVAILABILITY_LABEL': 'Bilo koje vrijeme',
  'TUTOR_LIST.TUTORS_FOUND_PREFIX_LABEL': 'Pronađeno',
  'TUTOR_LIST.TUTORS_FOUND_SUFFIX_LABEL': 'mentora s odabranim kriterijima',
  'TUTOR_LIST_SORT.RELEVANCE': 'Po relevantnosti',
  'TUTOR_LIST_SORT.HOURLY_RATE': 'Cijena sata od najmanje',
  'TUTOR_LIST_SORT.HOURLY_RATE_DESCENDING': 'Cijena sata od najveće',
  'TUTOR_LIST_ITEM.SESSIONS_NUMBER_LABEL': 'održanih sati',
  'TUTOR_LIST_ITEM.REVIEWS_NUMBER_LABEL': 'ocjena',
  'TUTOR_LIST_ITEM.HOURLY_RATE_LABEL': 'Cijena sata',
  'TUTOR_LIST_ITEM.DETAILS_LINK_LABEL': 'detalji',
  'TUTOR_LIST_ITEM.CREATE_SESSION_BUTTON_LABEL': 'Zatraži sat',

  // ---------- Tutor profile
  'TUTOR_PROFILE.SESSION_NUMBER_LABEL': '%{sessionNumber} mentorskih sati',
  'TUTOR_PROFILE.AVERAGE_GRADE_LABEL': '%{averageGrade} ocjena',

  // ---------- User List
  'USER_LIST_ITEM.ACTIVATION_LABEL': 'Omogući korisnika',
  'USER_LIST_ITEM.DEACTIVATION_LABEL': 'Onemogući korisnika',
  'USER_LIST_HEADER.FIRST_LAST_NAME': 'Ime i prezime',
  'USER_LIST_HEADER.USER_NAME': 'Korisničko ime',
  'USER_LIST_HEADER.DISPLAY_NAME': 'Nadimak',
  'USER_LIST_HEADER.PROFILE_PICTURE': 'Profilna slika',
  'USER_LIST_HEADER.ACTIONS': 'Akcije',
  'USER_LIST_FILTER.FIRST_NAME': 'Ime',
  'USER_LIST_FILTER.LAST_NAME': 'Prezime',
  'USER_LIST_FILTER.USER_NAME': 'Korisničko ime',
  'USER_LIST_FILTER.ROLES': 'Uloge',
  'USER_LIST_FILTER.ALL': 'Svi',
  'USER_LIST_FILTER.ACTIVE': 'Aktivni',
  'USER_LIST_FILTER.INACTIVE': 'Neaktivni',
  'USER_LIST_FILTER.STATUS': 'Status',
  'USER_LIST_FILTER.VERIFICATION_STATUS': 'Verifikacija',
  'USER_LIST_FILTER.VERIFIED': 'Verificirani',
  'USER_LIST_FILTER.UNVERIFIED': 'Neverificirani',
  'USER_LIST_ITEM.USER_VERIFIED_LABEL': 'Korisnik verificiran',
  'USER_LIST_ITEM.VERIFY_LABEL': 'Verificiraj korisnika',
  'USER_LIST_ITEM.REGISTRATION_DATE_LABEL': 'Datum registracije',

  // ---------- Review Session List
  'REVIEW_SESSION_LIST.CREATOR_LABEL': 'Korisnik',
  'REVIEW_SESSION_LIST.DATE_LABEL': 'Datum',
  'REVIEW_SESSION_LIST.TEXT_LABEL': 'Komentar',
  'REVIEW_SESSION_LIST.GENERIC_GRADE_LABEL': 'Generalna ocjena',
  'REVIEW_SESSION_LIST.AUDIO_QUALITY_LABEL': 'Kvaliteta zvuka',
  'REVIEW_SESSION_LIST.VIDEO_QUALITY_LABEL': 'Kvaliteta videa',

  // ---------- Tutor & User Profile
  'TUTOR_PROFILE_INSTRUCTIONS.MESSAGE': 'Na ovoj stranici možete pronaći upute za korištenje Evolvea, a za bilo koji upit na raspolaganju smo na ',

  'TUTOR_PROFILE.MY_PROFILE_LABEL_PREFIX': 'Za izmjene svog profila uredite svoje',

  'TUTOR_PROFILE_CALENDAR.TITLE': 'RASPORED DOSTUPNOSTI',
  'TUTOR_PROFILE_CALENDAR.DAY_MONDAY': 'Ponedjeljak',
  'TUTOR_PROFILE_CALENDAR.DAY_TUESDAY': 'Utorak',
  'TUTOR_PROFILE_CALENDAR.DAY_WEDNESDAY': 'Srijeda',
  'TUTOR_PROFILE_CALENDAR.DAY_THURSDAY': 'Četvrtak',
  'TUTOR_PROFILE_CALENDAR.DAY_FRIDAY': 'Petak',
  'TUTOR_PROFILE_CALENDAR.DAY_SATURDAY': 'Subota',
  'TUTOR_PROFILE_CALENDAR.DAY_SUNDAY': 'Nedjelja',
  'TUTOR_PROFILE_CALENDAR.NO_SCHEDULE': 'Nema ponuđenog termina',

  'TUTOR_PROFILE_CALENDAR.TIMETABLE_EVENT_TOOLTIP': 'Zatraži termin unutar ovog vremena',

  // errors
  'TUTOR_PROFILE_CALENDAR.ERROR_MESSAGE.TUTOR_CALENDAR_UPDATE': 'Greška pri spremanju kalendara',
  'TUTOR_PROFILE_CALENDAR.EDIT_ERROR.PERIOD_TOO_SHORT': 'Minimalni termin je 45 min',
  'TUTOR_PROFILE_CALENDAR.EDIT_ERROR.TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING': 'Uneseni termini se preklapaju. Molimo unesite termine bez preklapanja',

  'TUTOR_PROFILE_DETAILS.ABOUT_TITLE': 'O MENTORU',
  'TUTOR_PROFILE_DETAILS.ABOUT_ME_LABEL': 'O MENI',
  'TUTOR_PROFILE_DETAILS.ABOUT_ME_PLACEHOLDER': 'Unesite više podataka o sebi',
  'TUTOR_PROFILE_DETAILS.AREAS_AND_LEVELS_LABEL': 'Predmeti i razine',
  'TUTOR_PROFILE_DETAILS.AREAS_AND_LEVELS_PLACEHOLDER': 'Odaberite barem jedan predmet i pripadajuću razinu',
  'TUTOR_PROFILE_DETAILS.EXPERIENCE_PERIOD_LABEL': 'Period',
  'TUTOR_PROFILE_DETAILS.EDUCATION_LABEL': 'OBRAZOVANJE',
  'TUTOR_PROFILE_DETAILS.EDUCATION_NAME_LABEL': 'Zvanje',
  'TUTOR_PROFILE_DETAILS.EDUCATION_NAME_PLACEHOLDER': 'Unesite zvanje',
  'TUTOR_PROFILE_DETAILS.EDUCATION_INSTITUTION_LABEL': 'Naziv obrazovne institucije',
  'TUTOR_PROFILE_DETAILS.EDUCATION_INSTITUTION_PLACEHOLDER': 'Unesite naziv obrazovne institucije',
  'TUTOR_PROFILE_DETAILS.EDUCATION_DESCRIPTION_LABEL': 'Opis školovanja',
  'TUTOR_PROFILE_DETAILS.EDUCATION_DESCRIPTION_PLACEHOLDER': 'Unesite više podataka o školovanju (smjer pohađanja, projekti, suradnje, usavršavanja...)',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_LABEL': 'RADNO ISKUSTVO',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_NAME_LABEL': 'Naziv radnog mjesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_NAME_PLACEHOLDER': 'Unesite naziv radnog mjesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_INSTITUTION_LABEL': 'Naziv poduzeća ili ustanove',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_INSTITUTION_PLACEHOLDER': 'Unesite naziv poduzeća ili ustanove',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_DESCRIPTION_LABEL': 'Opis radnog mjesta',
  'TUTOR_PROFILE_DETAILS.WORK_EXPERIENCE_DESCRIPTION_PLACEHOLDER': 'Unesite više podataka o radnom mjestu (korištene tehnologije, projekti, specijalizacije...)',
  // errors
  'TUTOR_PROFILE_DETAILS.ERROR_MESSAGE.TUTOR_DETAILS_UPDATE': 'Greška pri spremanju podataka o korisniku',

  'TUTOR_PROFILE_REVIEWS.TUTOR_NOT_RATED': 'Ovaj mentor još nije ocijenjen',
  'TUTOR_PROFILE_REVIEWS.SECTION_TITLE': 'RECENZIJE',
  'TUTOR_PROFILE_REVIEWS.ADD_REVIEW_BUTTON': 'Unesi novu recenziju',
  'TUTOR_PROFILE_REVIEWS.BUTTON_DISABLED_TOOLTIP': 'Recenzije mogu unositi samo korisnici koji su održali sat s ovim mentorom',
  'TUTOR_PROFILE_REVIEWS.MODAL.TITLE': 'Nova recenzija',
  'TUTOR_PROFILE_REVIEWS.MODAL.GRADE_LABEL': 'Ocjena',
  'TUTOR_PROFILE_REVIEWS.MODAL.GRADE_VALIDATION_TEXT': 'Ocjena je obavezna',
  'TUTOR_PROFILE_REVIEWS.MODAL.TEXTAREA_LABEL': 'Dodaj komentar uz ocjenu',
  // errors
  'TUTOR_PROFILE_REVIEWS.ERROR_MESSAGE.REVIEW_SEND': 'Greška pri spremanju recenzije mentora. Molimo pokušajte kasnije ponovo.',

  'USER_PROFILE.PUBLIC_PROFILE_LINK_LABEL': 'Pogledajte svoj profil kako ga vide drugi',
  'USER_PROFILE.PERSONAL_DATA_LABEL': 'Osobni podaci',
  'USER_PROFILE.CONTACT_DATA_LABEL': 'Kontakt podaci',
  'USER_PROFILE.CONTACT_DATA_INFO_MESSAGE': 'Ovi podaci nisu vidljivi drugim korisnicima sustava.',
  'USER_PROFILE.PARENT_DATA_LABEL': 'Podaci o roditelju',
  'USER_PROFILE.CALENDAR_LABEL': 'Dostupnost',
  'USER_PROFILE.DETAILS_LABEL': 'Detalji o meni',
  'USER_PROFILE.PAYMENT_DATA_LABEL': 'Podaci za plaćanja',
  'USER_PROFILE.CHANGE_PASSWORD_LABEL': 'Promjena lozinke',
  'USER_PROFILE.EDIT_PERSONAL_DATA_LABEL': 'Uredi osobne podatke',
  'USER_PROFILE.EDIT_CONTACT_DATA_LABEL': 'Uredi kontakt podatke',
  'USER_PROFILE.EDIT_PARENT_DATA_LABEL': 'Uredi podatke o roditelju',
  'USER_PROFILE.EDIT_CALENDAR_INFO_MESSAGE': 'Unesite termine kada ste tipično slobodni. Polaznici vidjet će taj podatak na Vašem profilu, no moći će predložiti i termin izvan navedenih.',
  'USER_PROFILE.EDIT_CALENDAR_LABEL': 'Uredi kalendar',
  'USER_PROFILE.EDIT_DETAILS_INFO_MESSAGE': 'Unesite što više podataka o sebi da bi se što bolje predstavili polaznicima koji traže mentorstvo.',
  'USER_PROFILE.EDIT_DETAILS_LABEL': 'Uredi detalje',

  'USER_PROFILE.DELETE_PERIOD_LABEL': 'Obriši period',
  'USER_PROFILE.ADD_PERIOD_LABEL': 'Dodaj period',
  'USER_PROFILE.DELETE_EDUCATION_LABEL': 'Obriši obrazovanje',
  'USER_PROFILE.ADD_EDUCATION_LABEL': 'Dodaj obrazovanje',
  'USER_PROFILE.DELETE_WORK_EXPERIENCE_LABEL': 'Obriši radno iskustvo',
  'USER_PROFILE.ADD_WORK_EXPERIENCE_LABEL': 'Dodaj radno iskustvo',

  // errors
  'USER_PROFILE.ERROR_MESSAGE.USER_PROFILE_UPDATE': 'Greška pri spremanju podataka o korisniku',
  'USER_PROFILE.ERROR_MESSAGE.PARENT_DETAILS_UPDATE': 'Greška pri spremanju podataka o roditelju',

  'USER_PROFILE_PERSONAL_DATA.FIRST_NAME_LABEL': 'Ime',
  'USER_PROFILE_PERSONAL_DATA.FIRST_NAME_PLACEHOLDER': 'Unesite Vaše ime',
  'USER_PROFILE_PERSONAL_DATA.LAST_NAME_LABEL': 'Prezime',
  'USER_PROFILE_PERSONAL_DATA.LAST_NAME_PLACEHOLDER': 'Unesite Vaše prezime',
  'USER_PROFILE_PERSONAL_DATA.TUTOR_DISPLAY_NAME_LABEL': 'Ime za korisnike',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_DISPLAY_NAME_LABEL': 'Korisničko ime',
  'USER_PROFILE_PERSONAL_DATA.DISPLAY_NAME_PLACEHOLDER': 'Unesite ime koje će biti vidljivo ostalima',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_SCHOOL_LABEL': 'Tvrtka',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_SCHOOL_PLACEHOLDER': 'Unesite naziv tvrtke',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_CLASS_LABEL': 'Odjel',
  'USER_PROFILE_PERSONAL_DATA.STUDENT_CLASS_PLACEHOLDER': 'Odaberite trenutni odjel',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL': 'Spol',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL_M': 'Muško',
  'USER_PROFILE_PERSONAL_DATA.GENDER_LABEL_F': 'Žensko',
  'USER_PROFILE_PERSONAL_DATA.DOB_LABEL': 'Datum rođenja',

  'USER_PROFILE_PERSONAL_DATA.AVATAR.LABEL': 'Korisnička slika',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_TITLE': 'Dovuci novu sliku ili dohvati s računala',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_SAVE_BUTTON': 'Spremi',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_CANCEL_BUTTON': 'Odustani',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.MODAL_UPLOAD_BUTTON': 'Dohvati s računala',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.EDIT_BUTTON': 'Promijeni',
  'USER_PROFILE_PERSONAL_DATA.AVATAR.ERROR_FILE_TYPE': 'Moguće je odabrati samo .jpg ili .png datoteke!',

  'USER_PROFILE_CONTACT_DATA.ADDRESS_LABEL': 'Adresa',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_STREET_LABEL': 'Ulica',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_STREET_PLACEHOLDER': 'Unesite naziv ulice',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_HOME_NUMBER_LABEL': 'Kućni broj',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_HOME_NUMBER_PLACEHOLDER': 'Unesite kućni broj',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_ZIP_LABEL': 'Poštanski broj',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_ZIP_PLACEHOLDER': 'Unesite poštanski broj',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_CITY_LABEL': 'Mjesto',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_CITY_PLACEHOLDER': 'Unesite naziv mjesta',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_COUNTRY_LABEL': 'Država',
  'USER_PROFILE_CONTACT_DATA.ADDRESS_COUNTRY_PLACEHOLDER': 'Unesite naziv države',
  'USER_PROFILE_CONTACT_DATA.EMAIL_LABEL': 'E-mail adresa',
  'USER_PROFILE_CONTACT_DATA.EMAIL_VALIDATION_TEXT': 'Unesite ispravnu e-mail adresu',
  'USER_PROFILE_CONTACT_DATA.EMAIL_PLACEHOLDER': 'Unesite Vašu e-mail adresu',
  'USER_PROFILE_CONTACT_DATA.PHONE_LABEL': 'Telefon',
  'USER_PROFILE_CONTACT_DATA.PHONE_PLACEHOLDER': 'Unesite Vaš broj telefona',

  'USER_PROFILE_PARENT_DATA.PARENT_FIRST_NAME_LABEL': 'Ime roditelja',
  'USER_PROFILE_PARENT_DATA.PARENT_FIRST_NAME_PLACEHOLDER': 'Unesite ime Vašeg roditelja',
  'USER_PROFILE_PARENT_DATA.PARENT_LAST_NAME_LABEL': 'Prezime roditelja',
  'USER_PROFILE_PARENT_DATA.PARENT_LAST_NAME_PLACEHOLDER': 'Unesite prezime Vašeg roditelja',
  'USER_PROFILE_PARENT_DATA.PARENT_EMAIL_LABEL': 'E-mail adresa roditelja',
  'USER_PROFILE_PARENT_DATA.PARENT_EMAIL_PLACEHOLDER': 'Unesite e-mail adresu Vašeg roditelja',

  'USER_PROFILE.CALENDAR_NO_DATA': 'Nema podataka za ovaj dan',

  'USER_PROFILE_DETAILS.HOURLY_RATE_LABEL': 'Cijena sata',
  'USER_PROFILE_DETAILS.HOURLY_RATE_PLACEHOLDER': 'Unesite cijenu sata',
  'USER_PROFILE_DETAILS.SLOGAN_LABEL': 'Slogan',
  'USER_PROFILE_DETAILS.SLOGAN_PLACEHOLDER': 'Unesite Vaš slogan',

  // ---------- SessionCreate Form
  'SESSION_CREATE.TITLE': 'Zahtjev za rezervaciju termina',
  'SESSION_CREATE.BUTTON_PREV_LABEL': 'Prethodni korak',
  'SESSION_CREATE.BUTTON_NEXT_LABEL': 'Sljedeći korak',
  'SESSION_CREATE.BUTTON_SUBMIT_LABEL': 'Pošalji zahtjev za rezervaciju termina',
  'SESSION_CREATE.STEP_ONE_TITLE': 'Tvoj željeni termin',
  'SESSION_CREATE.STEP_TWO_TITLE': 'Što želiš naučiti',
  'SESSION_CREATE.STEP_THREE_TITLE': 'Informacija za mentora',
  'SESSION_CREATE.SESSION_START_DATE_LABEL': 'Datum termina',
  'SESSION_CREATE.SESSION_START_TIME_LABEL': 'Vrijeme termina',
  'SESSION_CREATE.SESSION_DURATION_LABEL': 'Trajanje termina',
  'SESSION_CREATE.DURATION_ONE_HOUR': 'Jedan sat',
  'SESSION_CREATE.DURATION_TWO_HOURS': 'Dva sata',
  'SESSION_CREATE.DESCRIPTION_LABEL': 'Opis sata',
  'SESSION_CREATE.DESCRIPTION_PLACEHOLDER': 'Upišite dodatni opis sata ...',
  'SESSION_CREATE.TUTOR_EDUCATION_AREA_LABEL': 'Predmet',
  'SESSION_CREATE.TUTOR_EDUCATION_AREA_PLACEHOLDER': 'Odaberite predmet',
  'SESSION_CREATE.EDUCATION_AREA_LABEL': 'Tema',
  'SESSION_CREATE.EDUCATION_AREA_PLACEHOLDER': 'Odaberite temu sata',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_LABEL': 'Cilj',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_PLACEHOLDER': 'Odaberite cilj sata',
  'SESSION_CREATE.TUTORING_SESSION_GOAL_DEADLINE_LABEL': 'Rok postizanja cilja',
  'SESSION_CREATE.SYLLABUS_IMAGES_LABEL': 'Slike nastavnog gradiva',
  'SESSION_CREATE.TUTORING_SESSION_DESCRIPTION_LABEL': 'Poruka mentoru',
  'SESSION_CREATE.NO_AVAILABLE_ROOMS_LABEL': 'U željenom terminu nema slobodnih soba. Odaberite neki drugi termin!',

  // messages
  'SESSION_CREATE.MESSAGE.SESSION_CREATED': 'Zahtjev za rezervaciju termina je poslan.',
  // errors
  'SESSION_CREATE.ERROR.MESSAGE_SENDING_FAILED': 'Greška pri slanju poruke, molimo pokušajete poslati poruku ponovo.',
  'SESSION_CREATE.ERROR.ADD_FILES_TO_SESSION_FAILED': 'Greška pri dodavanju datoteka, molimo pokušajte dodati datoteke ponovo.',
  // API errors
  'SESSION_CREATE.ERROR.ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD': 'U odabrano vrijeme već postoji potvrđeni mentorski sat. Molimo probajte odabrati drugo vrijeme.',
  'SESSION_CREATE.ERROR.TUTORING_SESSION_START_END_TIME_NOT_REGULAR': 'Zatraženo vrijeme mentorskog sata nije dozvoljeno.',
  'SESSION_CREATE.ERROR.NO_TUTORING_ROOMS_AVAILABLE_IN_SPECIFIED_PERIOD': 'U zatraženom terminu nema slobodnih soba. Molimo probajte dogovoriti sat u neko drugo vrijeme.',

  // ---------- SessionView
  'TUTORING_SESSION_VIEW.NO_SESSIONS_LABEL': 'Nema sati',
  'TUTORING_SESSION_VIEW.STUDENT_NOT_FOUND': 'Polaznik nije pronađen. Pokušajte pronaći željenog polaznika među ',
  'TUTORING_SESSION_VIEW.STUDENT_NOT_FOUND_LINK': 'polaznicima',
  'TUTORING_SESSION_VIEW.INSTRUCTOR_NOT_FOUND': 'Mentor nije pronađen. Pokušajte pronaći željenog mentora među ',
  'TUTORING_SESSION_VIEW.INSTRUCTOR_NOT_FOUND_LINK': 'mentorima',
  'TUTORING_SESSION_VIEW.NON_EXISTING_TUTORING_SESSION': 'Mentorski sat nije pronađen. Pokušajte pronaći željeni sat u ',
  'TUTORING_SESSION_VIEW.NON_EXISTING_TUTORING_SESSION_CALENDAR': 'svom kalendaru',
  'TUTORING_SESSION_VIEW.UPDATE.SESSION_START_LABEL': 'Termin',
  'TUTORING_SESSION_VIEW.UPDATE.SESSION_START_PLACEHOLDER': 'Odaberite termin',
  'TUTORING_SESSION_VIEW.THEME_LABEL': 'Tema',
  'TUTORING_SESSION_VIEW.DEADLINE_LABEL': 'Rok',
  'TUTORING_SESSION_VIEW.EDUCATION_AREA_LABEL': 'Predmet',
  'TUTORING_SESSION_VIEW.TUTORING_SESSION_GOAL_LABEL': 'Cilj',
  'TUTORING_SESSION_VIEW.ACTIONS.CONFIRM': 'Potvrdi termin',
  'TUTORING_SESSION_VIEW.ACTIONS.DECLINE': 'Odbij termin',
  'TUTORING_SESSION_VIEW.ACTIONS.CANCEL': 'Otkaži termin',
  'TUTORING_SESSION_VIEW.ACTIONS.ABORT': 'Odustani',
  'TUTORING_SESSION_VIEW.ACTIONS.EDIT': 'Promijeni termin',
  'TUTORING_SESSION_VIEW.ACTIONS.EDIT_SAVE': 'Pošalji zahtjev',
  'TUTORING_SESSION_VIEW.MESSAGE': 'Upišite poruku',
  'TUTORING_SESSION_VIEW.TITLE': 'Mentorski sat',
  'TUTORING_SESSION_VIEW_FORM.SAVE_BUTTON_LABEL': 'Spremi',
  'TUTORING_SESSION_VIEW.SESSION_START_LABEL': 'Termin',
  'TUTORING_SESSION_VIEW.ACTIONS.UPLOAD_FILE': 'Dodaj materijal s računala',
  'TUTORING_SESSION_VIEW.ACTIONS.ADD_FROM_REPOSITORY': 'Dodaj iz Mojih materijala',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_ROOM': 'Otvori sobu',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_WHITEROOM': 'Otvori sobu za pisanje na mobitelu',
  'TUTORING_SESSION_VIEW.ACTIONS.OPEN_MOBILE_APP': 'Otvori mobilnu aplikaciju',
  'TUTORING_SESSION_VIEW.ACTIONS.ROOM_OPTIONS_TITLE': 'Održavanje sata',
  'TUTORING_SESSION_VIEW.ACTIONS.PC_OPTIONS_TEXT': 'Na računalu otvorite sobu sljedećim gumbom:',
  'TUTORING_SESSION_VIEW.ACTIONS.MOBILE_OPTIONS_TEXT': 'Ako na satu puno pišete, na mobitelu ili tabletu otvorite sobu za pisanje - u njoj možete pisati po ekranu, ili pisati po papiru, slikati te jednim klikom poslati sliku na ploču',
  'TUTORING_SESSION_VIEW.OPEN_WHITEROOM_SECTION_TITLE': '',
  'TUTORING_SESSION_VIEW.OPEN_MOBILE_APP_SECTION_TITLE': ' ili pišite po papiru, a uz Evolve mobilnu aplikaciju jednostavno slikajte napisano i pošaljite na ploču',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_TIME_CHANGE': '%{name} je zatražio/la promjenu termina %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CONFIRMATION': '%{name} je potvrdio/la mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CANCELATION': '%{name} je otkazao/la mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_DECLINED': '%{name} je odbio/la mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CREATED': '%{name} je zatražio/la mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_STARTED': 'Mentorski sat je počeo',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_ENDED': 'Mentorski sat je završio',
  // tslint:disable-next-line: no-duplicate-string
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_FILE_ADDED': 'Datoteka %{filename} dodana',
  // tslint:disable-next-line: no-duplicate-string
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_MESSAGE_SENT': '%{name} je poslao/la novu poruku',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.REVIEW_CREATED': '%{name} je dodao recenziju',

  // personalized messages for myself

  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_TIME_CHANGE.MYSELF': 'Zatražili ste promjenu termina %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CONFIRMATION.MYSELF': 'Potvrdili ste mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CANCELATION.MYSELF': 'Otkazali ste mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_DECLINED.MYSELF': 'Odbili ste mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_CREATED.MYSELF': 'Zatražili ste mentorski sat %{date} u %{time}',
  'TUTORING_SESSION_VIEW.TIMELINE_ACTION.SESSION_MESSAGE_SENT.MYSELF': 'Poslali ste novu poruku',

  'TUTORING_SESSION_VIEW.TIMELINE_TABS.ACTIVITY_LIST_TITLE': 'Aktivnosti',
  'TUTORING_SESSION_VIEW.TIMELINE_TABS.FILE_LIST_TITLE': 'Datoteke',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_ADDED': 'Datoteka %{filename} dodana',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'TUTORING_SESSION_VIEW.FILE_LIST.SESSION_FILE_UPLOAD_ERROR': 'Greška prilikom dodavanja datoteke %{filename}',
  'TUTORING_SESSION_VIEW.FILE_LIST.TUTOR_OWN_FILES_TITLE': 'Moji materijali pripremljeni za sat',
  'TUTORING_SESSION_VIEW.FILE_LIST.TUTOR_FILES_TITLE': 'Mentorovi materijali',
  'TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_FILES_TITLE': 'Polaznikovi materijali',
  'TUTORING_SESSION_VIEW.FILE_LIST.STUDENT_OWN_FILES_TITLE': 'Moji materijali',
  'TUTORING_SESSION_VIEW.FILE_LIST.HOMEWORK_FILES_TITLE': 'Domaća zadaća',
  'TUTORING_SESSION_VIEW.FILE_LIST.SCREENSHOT_FILES_TITLE': 'Bilješke s dijeljene ploče',
  'TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_VISIBLE': 'Prikaži polazniku',
  'TUTORING_SESSION_VIEW.FILE_LIST.RESOURCE_HIDDEN': 'Sakrij od polaznika',
  'TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_TITLE': 'Označite kućicu kraj željenih datoteka i dodajte ih na mentorski sat',
  'TUTORING_SESSION_VIEW.FILE_LIST.ADD_FORM_REPOSITORY_MODAL_OK_BUTTON_TEXT': 'Dodaj na mentorski sat',
  'TUTORING_SESSION_VIEW.DROP_FILE_HERE': 'Spustite datoteku ovdje',
  // messages
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CONFIRMED': 'Mentorski sat je potvrđen',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_CANCELED': 'Mentorski sat je otkazan',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_SESSION_DECLINED': 'Mentorski sat je odbijen',
  'TUTORING_SESSION_VIEW.INFO_MESSAGE.TUTORING_FEEDBACK_SENT': 'Osvrt je uspješno poslan',
  // errors
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.UNCONFIRMED_TUTORING_SESSION': 'Ulazak u sobu je moguć tek kada svi sudionici potvrde mentoriranje.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.JOIN_TUTORING_SESSION_TOO_EARLY': 'Izgleda da ste malo požurili? :-) Ulazak u sobu je moguć 30 minuta prije dogovorenog početka mentoriranja. Molimo pokušajte ponovo u navedeno vrijeme.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.SESSION_STATUS_ENDED': 'Sat je završio ili je otkazan',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.DEFAULT': 'Došlo je do greške. :-( Molimo pokušajte slanju poruke. Molimo pokušajte ponovo.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.TIMELINE_FETCH': 'Greška pri dohvaćanju aktivnosti mentorskog sata',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.MESSAGE_SEND': 'Greška pri slanju poruke. Molimo pokušajte ponovo.',
  // API errors
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.NON_EXISTING_TUTORING_SESSION': 'Zatraženi mentorski sat nije pronađen',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.FINISHED_TUTORING_SESSION_UPDATE_NOT_POSSIBLE': 'Sat je završio, izmjene nisu više moguće.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.SESSION_RESCHEDULING_NOT_POSSIBLE': 'Promjena vremena mentorskog sata više nije moguća.',
  'TUTORING_SESSION_VIEW.ERROR_MESSAGE.ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD': 'U zatraženo vrijeme postoji već potvrđeni mentorski sat. Molimo probajte pomaknuti sat u neko drugo vrijeme.',

  // ---------- Session Callendar
  'SESSION_CALENDAR.WEEK_LABEL': 'Tjedan',
  'SESSION_CALENDAR.EMPTY_DAY': 'Bez mentorskog sata',
  'SESSION_CALENDAR.EMPTY_LIST_TUTOR': 'Nema sati',
  'SESSION_CALENDAR.EMPTY_LIST_STUDENT_TEXT': 'Nemate mentorskih satova za odabrane datume i statuse. ',
  'SESSION_CALENDAR.EMPTY_LIST_STUDENT_LINK': 'Pronađite mentora za sebe u Tražilici mentora.',
  'SESSION_CALENDAR.FILTER_LABEL_ALL': 'Svi termini',
  'SESSION_CALENDAR.FILTER_LABEL_CONFIRMED': 'Dogovoreni termini',
  'SESSION_CALENDAR.FILTER_LABEL_UNCONFIRMED': 'Na čekanju potvrde',
  'SESSION_CALENDAR.FILTER_LABEL_PAST': 'Arhiva',
  'SESSION_CALENDAR.REFRESH_BUTTON_TOOLTIP': 'Osvježi termine',
  'SESSION_CALENDAR.OTHER_PARTICIPANTS': 'Ostali sudionici',

  'SESSION_CALENDAR.UPDATE_SESSION.CURRENT_SESSION': 'TRENUTNI TERMIN',
  'SESSION_CALENDAR.UPDATE_SESSION.SESSION_LIST_TITLE': 'Na odabrani datum imate sljedeće termine:',
  'SESSION_CALENDAR.UPDATE_SESSION.EMPTY_LIST': 'Nemate mentorskih satova na odabrani datum',

  // ---------- Session Rejection
  'TUTORING_SESSION_REJECTION.TITLE_CANCEL': 'Otkazivanje termina!',
  'TUTORING_SESSION_REJECTION.TITLE_DECLINE': 'Odbijaš zahtjev za rezervaciju termina!',
  'TUTORING_SESSION_REJECTION.TUTOR_MESSAGE_LABEL': 'Poruka za mentora',
  'TUTORING_SESSION_REJECTION.STUDENT_MESSAGE_LABEL': 'Poruka za polaznika',
  // errors
  'TUTORING_SESSION_REJECTION.ERROR.MESSAGE_SENDING_FAILED': 'Greška pri slanju poruke, molimo pokušajete poslati poruku ponovo.',

  // ---------- Session Participate
  'TUTORING_SESSION_PARTICIPATE.TITLE': 'Poziv u mentorski sat',
  'TUTORING_SESSION_PARTICIPATE.ACTIONS.PARTICIPATE': 'Prihvati sat',
  // messages
  'TUTORING_SESSION_PARTICIPATE.INFO_MESSAGE.PARTICIPATION_CONFIRMED': 'Mentorski sat je prihvaćen',
  // errors
  'TUTORING_SESSION_PARTICIPATE.ERROR.GENERAL': 'Prihvaćanje mentorskog sata nije uspjelo',
  'TUTORING_SESSION_PARTICIPATE.ERROR.INVALID_TUTORING_SESSION_JOIN_LINK': 'Mentorski sat nije pronađen',
  'TUTORING_SESSION_PARTICIPATE.ERROR.UNCONFIRMED_TUTORING_SESSION': 'Mentorski sat još nije potvrđen',
  'TUTORING_SESSION_PARTICIPATE.ERROR.ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD': 'U zadano vrijeme već imate dogovoren drugi mentorski sat',
  'TUTORING_SESSION_PARTICIPATE.ERROR.USER_WITHOUT_STUDENT_ROLE': 'Vaša uloga ne dozvoljava prihvaćanje mentorski sata',
  'TUTORING_SESSION_PARTICIPATE.ERROR.USER_ALREADY_TUTORING_SESSION_PARTICIPANT': 'Mentorski sat je već prihvaćen',
  'TUTORING_SESSION_PARTICIPATE.ERROR.TUTORING_SESSION_PARTICIPANTS_MAX_NUMBER_EXCEEDED': 'Mentorski sat je već popunjen',

  // ----------- File List
  'FILE_LIST.BREADCRUMB_HOME_LABEL': 'Baza znanja',
  'FILE_LIST.PUBLIC_FOLDER': 'Javne datoteke',
  'FILE_LIST.PRIVATE_FOLDER': 'Moje datoteke',
  'FILE_LIST.TYPE_LABEL': 'Tip',
  'FILE_LIST.NAME_LABEL': 'Naziv',
  'FILE_LIST.SIZE_LABEL': 'Veličina',
  'FILE_LIST.DATE_LABEL': 'Datum ažuriranja',
  'FILE_LIST.TAGS_LABEL': 'Tagovi',
  'FILE_LIST.TITLE_SEARCH_RESULTS_NAME_LABEL': 'Rezultati pretrage (%{items} rezultata)',
  'FILE_LIST.TITLE_TAGS_LABEL': 'Tagovi',
  'FILE_LIST.TITLE_SIZE_LABEL': 'Veličina',
  'FILE_LIST.TITLE_ACTIONS_LABEL': 'Akcije',
  'FILE_LIST.DELETE_CONFIRM_TITLE': 'Obrisati?',
  'FILE_LIST.ACTIONS_OPEN_LABEL': 'Otvori',
  'FILE_LIST.ACTIONS_DOWNLOAD_LABEL': 'Preuzmi',
  'FILE_LIST.ACTIONS_RENAME_LABEL': 'Preimenuj',
  'FILE_LIST.ACTIONS_DELETE_LABEL': 'Obriši',
  'FILE_LIST.ACTIONS_MOVE_LABEL': 'Premjesti',
  'FILE_LIST.SEARCH_FILES_INPUT_PLACEHOLDER': 'Pretraži datoteke',
  'FILE_LIST.ADD_NEW_BUTTON': 'Dodaj',
  'FILE_LIST.UPLOAD_FILE_BUTTON': 'Nova datoteka',
  'FILE_LIST.NEW_FOLDER_BUTTON': 'Nova mapa',
  'FILE_LIST.EMPTY_FOLDER_TEXT': 'Ova mapa je prazna',
  'FILE_LIST.NAME_INPUT_PLACEHOLDER': 'Unesi ime',
  'FILE_LIST.NAME_INPUT_TITLE_CREATE_FOLDER': 'Dodaj novu mapu',
  'FILE_LIST.NAME_INPUT_TITLE_RENAME_FOLDER': 'Preimenuj mapu',
  'FILE_LIST.NAME_INPUT_TITLE_RENAME_FILE': 'Preimenuj datoteku',
  'FILE_LIST.NAME_INPUT_INVALID_ENTRY': 'Neispravan naziv datoteke',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_ADDED': 'Datoteka %{filename} dodana',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_UPLOAD_ERROR': 'Greška prilikom dodavanja datoteke %{filename}',
  'FILE_LIST.MESSAGE.REPOSITORY_FILE_REMOVED': 'Datoteka %{filename} izbrisana',
  'FILE_LIST.ROOT_FOLDER_DISPLAY_NAME': 'Moji materijali',
  'FILE_LIST.MOVE_TO_FOLDER_SELECTED_NAME_DISPLAY': 'Premjesti u mapu: %{folderName}',
  'FILE_LIST.MOVE_TO_FOLDER_FROM_FOLDER_SELECTED_TEXT': 'Datoteke se već nalaze u ovoj mapi!',
  'FILE_LIST.MOVE_TO_FOLDER_MODAL_TITLE': 'Odaberi mapu',
  'FILE_LIST.ADD_NEW_TAG_BUTTON_LABEL': 'Dodaj',
  'FILE_LIST.TITLE_ALREADY_IN_USE': 'Mapa s nazivom koji ste unijeli vec postoji!',
  'FILE_LIST.MESSAGE.FILE_SIZE_TOO_LARGE': 'Datoteka koju ste unijeli je prevelika. Molimo odaberite datoteku do %{maxSizeFormated}',
  'FILE_LIST.MESSAGE.FILE_NAME_TOO_LONG': 'Ime unesene datoteke je predugačko. Molimo odaberite datoteku s imenom do %{maxNameLenght} znakova',

  // ----------- Tutoring Room
  'TUTORING_ROOM.ACTION_LEAVE_ROOM': 'Završi sat',
  'TUTORING_ROOM.ACTION_PAUSE_ROOM': 'Pauziraj sat',
  'TUTORING_ROOM.ACTION_CREATE_SESSION': 'Dogovori sljedeći sat',
  'TUTORING_ROOM.WAITING_MESSAGE': 'Spajamo se u sobu. Molimo pričekajte trenutak ....',
  'TUTORING_ROOM.WAITING_OTHER_PARTICIPANTS_MESSAGE': 'Čekamo ostale sudionike ....',
  // info messages
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_END_NOTICE': 'Mentorski sat završava za %{endPeriod}min',
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_ENDED.TITLE': 'Sat je završio',
  'TUTORING_ROOM.INFO_MESSAGE.SESSION_ENDED.TEXT': 'Vrijeme trajanja sata je isteklo. Soba će biti automatski zatvorena.',
  'TUTORING_ROOM.INFO_MESSAGE.PARTICIPANT_LEFT.TITLE': 'Druga strana je završila sat',
  'TUTORING_ROOM.INFO_MESSAGE.PARTICIPANT_LEFT.TEXT': 'Korisnik %{participant} je završio sat. Molimo odaberite gumb "Završi sat" kako bi se sat uspješno zatvorio.',
  // errors
  'TUTORING_ROOM.ERROR_MESSAGE.DEFAULT': 'Ups, nismo uspjeli ući u sobu. :-( Molimo pokušajte ponovo malo kasnije.',
  'TUTORING_ROOM.ERROR_MESSAGE.LEAVE_ROOM': 'Dogodila se greška prilikom izlaska iz sobe.',
  'TUTORING_ROOM.ERROR_MESSAGE.SESSION_FILE_LIST_FETCH': 'Greška pri dohvatu datoteka mentorskog sata',
  'TUTORING_ROOM.ERROR_MESSAGE.VIDEO_ROOM': 'Greška kod pokretanja videa',
  // API errors
  'TUTORING_ROOM.ERROR_MESSAGE.NON_EXISTING_TUTORING_SESSION': 'Mentorski sat nije pronađen? :-/',
  'TUTORING_ROOM.ERROR_MESSAGE.UNCONFIRMED_TUTORING_SESSION': 'Ulazak u sobu je moguć tek kada svi sudionici potvrde mentoriranje.',
  'TUTORING_ROOM.ERROR_MESSAGE.JOIN_TUTORING_SESSION_TOO_EARLY': 'Izgleda da ste malo požurili? :-) Ulazak u sobu je moguć nekoliko minuta prije dogovorenog početka mentoriranja. Molimo pokušajte ponovo u navedeno vrijeme.',
  'TUTORING_ROOM.ERROR_MESSAGE.TUTORING_SESSION_ALREADY_FINISHED': 'Mentoriranje je već završene. Ali slobodno možete dogovorite nove. :-)',
  'TUTORING_ROOM.ERROR_MESSAGE.REQUEST_START_END_TIME_NOT_REGULAR': 'Zatraženo vrijeme mentorskog sata nije dozvoljeno.',

  'TUTORING_ROOM.SESSION_FILE_LIST.TITLE': 'Datoteke',
  'TUTORING_ROOM.SESSION_FILE_LIST.ITEM_TITLE': 'Povuci poveznicu na ploču za dodavanje slike na ploču',
  'TUTORING_ROOM.SESSION_FILE_LIST.ADD_FILE_BUTTON_TITLE': 'Dodaj sliku na ploču',
  'TUTORING_ROOM.SESSION_FILE_LIST.OPEN_FILE': 'Otvori datoteku u novom prozoru',

  'TUTORING_ROOM.DRAWER_TRIGGER_TITLE': 'Materijali i bilješke',
  'TUTORING_ROOM.PREVIOUS_SESSION_DIVIDER_TITLE': 'Prethodni sat (%{date})',

  // ---------- Whiteboard room
  'WHITEBOARD_ROOM.FILE_UPLOAD_INFO_TITLE': 'Dodavanje datoteke na ploču',
  'WHITEBOARD_ROOM.FILE_UPLOAD_INFO_DESCRIPTION': 'Smjestite sliku na željeno mjesto na ploči, po potrebi promijeniti veličinu i rotaciju, te kliknite na dugmić "Zalijepi na ploču".',

  // ---------- Whiteboard toolbar
  'WHITEBOARD_TOOLBAR.SELECT_TOOLTIP': 'Odaberi',
  'WHITEBOARD_TOOLBAR.UNDO_TOOLTIP': 'Poništi',
  'WHITEBOARD_TOOLBAR.COLOR_TOOLTIP': 'Odabir boje',
  'WHITEBOARD_TOOLBAR.PEN_TOOLTIP': 'Olovka',
  'WHITEBOARD_TOOLBAR.MARKER_TOOLTIP': 'Marker',
  'WHITEBOARD_TOOLBAR.LINE_TOOLTIP': 'Linija',
  'WHITEBOARD_TOOLBAR.ERASER_TOOLTIP': 'Brisanje',
  'WHITEBOARD_TOOLBAR.PEN_SIZE': 'Debljina olovke',
  'WHITEBOARD_TOOLBAR.SCISSORS_TOOLTIP': 'Rezanje',
  'WHITEBOARD_TOOLBAR.CLEAR_TOOLTIP': 'Očisti',
  'WHITEBOARD_TOOLBAR.RECTANGLE_TOOLTIP': 'Pravokutnik',
  'WHITEBOARD_TOOLBAR.RECTANGLE_FILLED_TOOLTIP': 'Ispunjeni pravokutnik',
  'WHITEBOARD_TOOLBAR.ELLIPSE_TOOLTIP': 'Elipsa',
  'WHITEBOARD_TOOLBAR.ELLIPSE_FILLED_TOOLTIP': 'Ispunjena elipsa',
  'WHITEBOARD_TOOLBAR.TEXT_TOOLTIP': 'Tekst',
  'WHITEBOARD_TOOLBAR.ERASER_SIZE': 'Debljina brisanja',
  'WHITEBOARD_TOOLBAR.STROKE_THICKNESS': 'Debljina linije',
  'WHITEBOARD_TOOLBAR.PLACE_IMAGE': 'Zalijepi na ploču',
  'WHITEBOARD_TOOLBAR.UPLOAD': 'Dodaj',
  'WHITEBOARD_TOOLBAR.UPLOAD_IMAGE': 'Dodaj sliku s računala',
  'WHITEBOARD_TOOLBAR.SEND_USER_POINTER': 'Šalji moj pokazivač ostalim korisnicima',
  'WHITEBOARD_TOOLBAR.SHOW_USER_POINTER': 'Pokaži pokazivače ostalih korisnika',

  // ---------- User approvals
  'USER_APPROVAL.GRANT_BUTTON_LABEL': 'Dodaj dozvole',
  'USER_APPROVAL.APPROVAL_TITLE.MessagingService': 'Dozvole za obavijesti',
  'USER_APPROVAL.APPROVAL_MESSAGE.MessagingService': 'Kako bi vas mogli pravodobno obavještavati o promjenama na vašim mentorstvima molimo vas da dozvolite primanje obavijesti ("Notifications") u vašem pregledniku.',
  'USER_APPROVAL.APPROVAL_TITLE.VideoService': 'Dozvole za kameru i mikrofon',
  'USER_APPROVAL.APPROVAL_MESSAGE.VideoService': 'Kako biste mogli u potpunosti iskoristiti mogućnosti mentoriranja molimo vas da dozvolite pristup kameri i mikrofonu u vašem pregledniku.',

  // ---------- User info notifications
  'USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_LABEL': 'Baza znanja',
  'USER_NOTIFICATION.KNOWLEDGE_REPOSITORY_MESSAGE':
    'Ovdje možete sistematizirati i pohraniti materijale koje koristite na satu. Oni će Vam biti pri ruci tijekom pripreme sata i za vrijeme samog sata. Materijali moraju biti u slikovnom formatu da bi se mogli koristiti na ploči. Među javne datoteke već smo unijeli neke koje bi mogle biti korisne - ako Vam odgovaraju, poslužite se!',

  // ---------- Messaging notifications
  'NOTIFICATION_MENU.COMMON.LIST_EMPTY': 'Trenutno nemate obavijesti',
  'NOTIFICATION_MENU.COMMON.DISMISS_ALL_BUTTON_LABEL': 'Ukloni sve obavijesti',
  'NOTIFICATION_MENU.COMMON.DISMISS_BUTTON_TITLE': 'Ukloni poruku',
  'NOTIFICATION_MENU.COMMON.USER_DEFAULT': 'Korisnik/ca',
  'NOTIFICATION_MENU.SESSION_INCOMING_NOTICE.TITLE': 'Mentorski sat počinje za %{incomingPeriod}min',
  'NOTIFICATION_MENU.SESSION_INCOMING_NOTICE_NOW.TITLE': 'Mentorski sat upravo počinje',
  'NOTIFICATION_MENU.SESSION_END_NOTICE.TITLE': 'Mentorski sat završava za %{endPeriod}min',
  'NOTIFICATION_MENU.SESSION_TIME_CHANGE.TITLE': 'Zahtjev za promjenu termina',
  'NOTIFICATION_MENU.SESSION_CONFIRMATION.TITLE': 'Potvrđen prijedlog termina',
  'NOTIFICATION_MENU.SESSION_CANCELATION.TITLE': 'Otkazan termin',
  'NOTIFICATION_MENU.SESSION_DECLINED.TITLE': 'Odbijen prijedlog termina',
  'NOTIFICATION_MENU.SESSION_CREATED.TITLE': 'Zahtjev za rezervaciju termina',
  'NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TITLE': 'Nova poruka',
  'NOTIFICATION_MENU.SESSION_MESSAGE_SENT.TEXT': 'poslao/la je poruku:',
  'NOTIFICATION_MENU.SESSION_FILE_ADDED.TITLE': 'Nova datoteka',
  'NOTIFICATION_MENU.SESSION_FILE_ADDED.TEXT': 'dodao/la je datoteke:',
  'NOTIFICATION_MENU.INCOMPLETE_PROFILE': 'Da bi vas polaznici mogli pronaći, u profilu popunite detalje o sebi (predmete koje predajete, cijenu sata) i barem jedan termin dostupnosti.',
  'NOTIFICATION_MENU.INCOMPLETE_PROFILE_LINK_TEXT': 'Idite na popunjavanje...',

  // ---------- Notification Info Bar
  'NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.PREFIX_LABEL': 'Mentorski termin započinje za',
  'NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.SUFFIX_LABEL': 'minuta',
  'NOTIFICATION_INFO_BAR.SESSION_IN_PROGRESS_NOTICE': 'Mentorski sat je u tijeku',
  'NOTIFICATION_INFO_BAR.SESSION_INFO.STUDENTS_LABEL': 'Polaznik/ca',
  'NOTIFICATION_INFO_BAR.SESSION_INFO.TUTOR_LABEL': 'Predavač/ica',
  'NOTIFICATION_INFO_BAR.JOIN_SESSION_LABEL': 'Priključi se satu',

  // ---------- Error page

  'ERROR_PAGE.DEFAULT_ERROR_TITLE': 'Ups, nešto se nije dobro prikazalo',
  'ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION': 'Molimo probajte ponovo ili nam javite da pokušamo zajedno riješiti problem. Hvala!',

  // ---------- Calendar navigation

  'CALENDAR_NAVIGATION.TODAY': 'Danas',
  'CALENDAR_NAVIGATION.PREVIOUS_MONTH': 'Prethodni mjesec (%{month})', // tooltip
  'CALENDAR_NAVIGATION.NEXT_MONTH': 'Sljedeći mjesec (%{month})', // tooltip
  'CALENDAR_NAVIGATION.PREVIOUS_YEAR': 'Prethodna godina (%{year})', // tooltip
  'CALENDAR_NAVIGATION.NEXT_YEAR': 'Sljedeća godina (%{year})', // tooltip
  'CALENDAR_NAVIGATION.MODE_YEAR': 'Godina',
  'CALENDAR_NAVIGATION.MODE_MONTH': 'Mjesec',

  // ---------- Dashboard
  'DASHBOARD.UNCONFIRMED_SESSIONS_TITLE': 'Mentorski sati koji čekaju potvrdu',
  'DASHBOARD.UNCONFIRMED_SESSIONS_COUNT': 'Broj intrukcijskih sati koji čekaju potvrdu: {count}',
  'DASHBOARD.UNCONFIRMED_SESSIONS_NONE': 'Nemate intrukcijskih sati koji čekaju potvrdu',
  'DASHBOARD.ACTIVITIES_TITLE': 'Aktivnosti',
  'DASHBOARD.CALENDAR_TITLE': 'Nadolazeći mentorski sati',
  'DASHBOARD.MY_TUTORS_TITLE': 'Moji mentori',
  'DASHBOARD.MY_STUDENTS_TITLE': 'Moji polaznici',
  'DASHBOARD.ADD_FILE_FROM_REPOSITORY_SUCCESS': 'Dodavanje u mentorski sat iz Mojih materijala uspješno provedeno',
  'DASHBOARD.ADD_FILE_FROM_REPOSITORY_ERROR': 'Greška prilikom dodavanja iz Mojih materijala',

  // ---------- Statitics
  'STATISTICS.ROLE_LABEL': 'Statistika kao',
  'STATISTICS.ROLE_SELECT_TUTOR': 'Mentor',
  'STATISTICS.ROLE_SELECT_STUDENT': 'Polaznik',
  'STATISTICS.ROLE_SELECT_ADMINISTRATOR': 'Administrator',
  'STATISTICS.GROUPING_LABEL': 'Prikaz po:',
  'STATISTICS.GROUPING_WEEK': 'Tjedan',
  'STATISTICS.GROUPING_MONTH': 'Mjesec',
  'STATISTICS.GROUPING_YEAR': 'Godina',
  'STATISTICS.TOGGLE_SHOW_EARNINGS_LABEL': 'Prikaži prihode',
  'STATISTICS.EDUCATION_AREA_LABEL': 'Predmet',
  'STATISTICS.ANY_EDUCATION_AREA_LABEL': 'Svi predmeti',
  'STATISTICS.STUDENT_LEVEL_LABEL': 'Razina',
  'STATISTICS.ANY_EDUCATION_LEVEL_LABEL': 'Sve razine škole',
  'STATISTICS.DATA_TITLE_DATE_PERIOD': 'Period',
  'STATISTICS.DATA_TITLE_DATE_FROM': 'Datum od',
  'STATISTICS.DATA_TITLE_DATE_TO': 'Datum do',
  'STATISTICS.DATA_TITLE_HOURS_TOTAL': 'Zatraženi sati',
  'STATISTICS.DATA_TITLE_HOURS_SUCCESSFUL': 'Održani sati',
  'STATISTICS.DATA_TITLE_HOURS_CANCELED': 'Otkazani sati',
  'STATISTICS.DATA_TITLE_HOURS_DECLINED': 'Odbijeni sati',
  'STATISTICS.DATA_TITLE_EARNINGS': 'Prihod',
  'STATISTICS.NO_DATA': 'U odabranom periodu nije održan niti jedan mentorski sat',

  // ---------- Admin Session List
  'ADMIN_SESSION_LIST.DURATION_LABEL': 'Trajanje',
  'ADMIN_SESSION_LIST.DESCRIPTION_LABEL': 'Naziv sata',
  'ADMIN_SESSION_LIST.TUTOR_LABEL': 'Mentor',
  'ADMIN_SESSION_LIST.STUDENTS_LABEL': 'Polaznici',
  'ADMIN_SESSION_LIST.STATUS_LABEL': 'Status',
  'ADMIN_SESSION_LIST.END_STATUS_LABEL': 'End Status',

  // ---------- Favourites View
  'FAVOURITES_VIEW.NAME_TITLE': 'Ime i prezime',
  'FAVOURITES_VIEW.CLASS_TITLE': 'Odjel',
  'FAVOURITES_VIEW.SCHOOL_TITLE': 'Obrazovna institucija',
  'FAVOURITES_VIEW.LAST_SESSION_TITLE': 'Zadnji termin',
  'FAVOURITES_VIEW.STUDENT_NO_DATA_TEXT': 'Još niste radili ni s jednim mentorom. ',
  'FAVOURITES_VIEW.STUDENT_NO_DATA_LINK': 'Pronađite mentora za sebe u Tražilici mentora.',
  'FAVOURITES_VIEW.TUTOR_NO_DATA_TEXT': 'Još niste imali zahtjeva za satom ili održanih satova. Polaznici s kojima ćete imati sat prikazat će se u ovom popisu.',
  'FAVOURITES_FILTER.LABEL': 'Filtriranje',
  'FAVOURITES_FILTER.SUCCESSFUL': 'Zadnji održan sat',
  'FAVOURITES_FILTER.CANCELED': 'Zadnji otkazan sat',
  'FAVOURITES_FILTER.LAST': 'Zadnji sat',

  // ---------- Session Review
  'SESSION_REVIEW_FORM.FORM_TITLE': 'Ocijenite upravo završeni sat',
  'SESSION_REVIEW_FORM.AUDIO_QUALITY_LABEL': 'Kvaliteta zvuka',
  'SESSION_REVIEW_FORM.VIDEO_QUALITY_LABEL': 'Kvaliteta videa',
  'SESSION_REVIEW_FORM.OVERALL_RATING_LABEL': 'Ukupna ocjena sata',
  'SESSION_REVIEW_FORM.GENERAL_COMMENT_LABEL': 'Dodatni komentar',
  'SESSION_REVIEW_FORM.GENERAL_COMMENT_PLACEHOLDER': 'Unesite dodatni komentar',

  // ---------- SessionTutor Feedback
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_LABEL': 'Bilješke o satu',
  'SESSION_TUTOR_FEEDBACK.TUTOR_FEEDBACK_TOOLTIP_TEXT': 'Vaše bilješke vezane za sat koje želite podijeliti s polaznikom',
  'SESSION_TUTOR_FEEDBACK.PRIVATE_FEEDBACK_LABEL': 'Privatne bilješke o satu',
  'SESSION_TUTOR_FEEDBACK.TUTOR_PRIVATE_FEEDBACK_TOOLTIP_TEXT': 'Vaše privatne bilješke o satu (vidljive samo Vama)',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_LABEL_PLACEHOLDER': 'Unesite bilješke o satu',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_ALREADY_SENT_MESSAGE': 'Bilješke su poslane polazniku %{feedbackSentTime}',
  'SESSION_TUTOR_FEEDBACK.FEEDBACK_MISSING': 'Bilješke o satu još nisu unešene',
  'SESSION_TUTOR_FEEDBACK.ACTIONS.SEND_TUTOR_FEEDBACK': 'Pošalji osvrt polazniku e-poštom', // tooltip
  // errors
  'SESSION_TUTOR_FEEDBACK.ERROR_MESSAGE.SEND_TUTOR_FEEDBACK': 'Greška pri slanju osvrta na sat',

  // ---------- Tooltip help messages SESSION_STATUS_UNRESPONDED
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_INVITED': 'Trebate odgovoriti na ovaj zahtjev za satom',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_ACCEPTED': 'Čekate potvrdu ovog zahtjeva za satom',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_CONFIRMED': 'Dogovoreni termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_FINISHED': 'Odrađeni termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_DECLINED': 'Otkazani Termin',
  'LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNRESPONDED': 'Na ovaj zahtjev za satom nije odgovoreno prije početka sata',
  'LEMON_HELPER_TOOLTIP.SESSION.WHITEBOARD_ROOM_BUTTON_DESCRIPTION': 'Ova soba je namijenjena pisanju i crtanju pomoću mobilnog uređaja',
  'LEMON_HELPER_TOOLTIP.SESSION.MOBILE_APP_BUTTON_DESCRIPTION': 'Preuzmite našu aplikaciju za mobilne uređaje!',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.OWN_FILES_DESCRIPTION': 'Materijali koje ste dodali za korištenje na mentorskom satu',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.TUTOR_FILES_DESCRIPTION': 'Materijali koje je mentor dodao za korištenje na mentorskom satu',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.STUDENT_FILES_DESCRIPTION': 'Materijali koje je polaznik dodao za korištenje na mentorskom satu',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.HOMEWORK_FILES_DESCRIPTION': 'Materijali dodani kao domaća zadaća za polaznika nakon mentorskog sata',
  'LEMON_HELPER_TOOLTIP.FILE_LIST.SCREENSHOT_FILES_DESCRIPTION': 'Bilješke koje su zapisane na ploču prilikom održavanja mentorskog sata',
  'LEMON_HELPER_TOOLTIP.CALENDAR.UNCONFIRMED_SESSIONS_INFO': 'Imate termine na čekanju potvrde',
  'LEMON_HELPER_TOOLTIP.CALENDAR.CONFIRMED_SESSIONS_INFO': 'Imate dogovorene termine',
  'LEMON_HELPER_TOOLTIP.FILES.ADD_NEW_RESOURCE': 'Dodaj novi materijal',
  'LEMON_HELPER_TOOLTIP.ROOM.PAUSE_BUTTON_DESCRIPTION': 'Privremeno izađite iz sobe bez završavanja sata',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_MUTE_ON': 'Isključi video',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_MUTE_OFF': 'Uključi video',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_DISABLED': 'Kamera isključena',
  'LEMON_HELPER_TOOLTIP.ROOM.AUDIO_CONTROL_MUTE_ON': 'Isključi mikrofon',
  'LEMON_HELPER_TOOLTIP.ROOM.AUDIO_CONTROL_MUTE_OFF': 'Uključi mikrofon',
  'LEMON_HELPER_TOOLTIP.ROOM.AUDIO_DISABLED': 'Mikrofon nije dostupan',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_FULL_ON': 'Povećaj prikaz videa',
  'LEMON_HELPER_TOOLTIP.ROOM.VIDEO_CONTROL_FULL_OFF': 'Smanji prikaz videa',

  // ---------- Onboarding
  'ONBOARDING_TOOLTIP.COMMON.BACK_BUTTON': 'Prethodno',
  'ONBOARDING_TOOLTIP.ROOM.WELCOME_TITLE': 'Kako koristiti učionicu?',
  'ONBOARDING_TOOLTIP.ROOM.WELCOME_CONTENT': 'Pred Vama se nalazi ploča po kojoj možete pisati. Sve što napišete vidi i drugi sudionik mentorskog sata.',
  'ONBOARDING_TOOLTIP.ROOM.TOOLS_TITLE': 'Pisanje i brisanje',
  'ONBOARDING_TOOLTIP.ROOM.TOOLS_CONTENT': 'Ovdje se nalaze olovka, gumica i ostali alati za pisanje.',
  'ONBOARDING_TOOLTIP.ROOM.MULTIPAGE_TITLE': 'Stranice',
  'ONBOARDING_TOOLTIP.ROOM.MULTIPAGE_CONTENT': 'Sa "+" dodajte novu virtualnu stranicu. Na stranicama ne trebate "štedjeti", dodajte ih koliko Vam treba.',
  'ONBOARDING_TOOLTIP.ROOM.ZOOM_PAN_TITLE': 'Zoom i pomicanje',
  'ONBOARDING_TOOLTIP.ROOM.ZOOM_PAN_CONTENT': 'Po ploči se možete pomicati u svim smjerovima, te povećati ili smanjiti pregled ploče.',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_CLOSED_TITLE': 'Materijali i bilješke...',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_CLOSED_CONTENT': '...nalaze se na dohvat ruke.',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_OPEN_TITLE': 'Bilješke',
  'ONBOARDING_TOOLTIP.ROOM.DRAWER_OPEN_CONTENT': 'U bilješke možete zapisati svoj osvrt na sat i planove za sljedeći sat. Možete ga poslati i polazniku!',
  'ONBOARDING_TOOLTIP.ROOM.FILES_TITLE': 'Dijeljenje materijala',
  'ONBOARDING_TOOLTIP.ROOM.FILES_CONTENT': 'Slike dodajte na ploču tako da ih povučete na nju, pozicionirate sliku na željeno mjesto i pritisnete "Zalijepi na ploču". Ona je tada vidljiva i drugom sudioniku mentorskog sata.',
  'ONBOARDING_TOOLTIP.ROOM.VIDEO_CHAT_TITLE': 'Video i poruke',
  'ONBOARDING_TOOLTIP.ROOM.VIDEO_CHAT_CONTENT': 'Kad se drugi sudionik poveže, vidjet ćete ga/ju preko kamere, a možete i razmjenjivati poruke za vrijeme sata.',

  // ---------- Session participant invitations
  'SESSION_PARTICIPANT_INVITATION.TITLE': 'Pozovi dodatne sudionike',
  'SESSION_PARTICIPANT_INVITATION.DESCRIPTION': 'Pošaljite ovaj link drugim polaznicima kako bi mogli sudjelovati u dogovorenom satu. Napomena: ukupni broj sudionika na jednom satu, uključujući mentora, može biti najviše %{count}.',
};

export default hr;
