export default interface ITutoringSessionEndStatus {
  id: TutoringSessionEndStatusEnum;
  name: string;
  description: string;
}

export enum TutoringSessionEndStatusEnum {
  SUCCESSFUL = '1',
  SYSTEM_ENDED = '2',
  CANCELED = '3',
  NEVER_STARTED = '4',
}
