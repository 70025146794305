import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { IServiceStatusProvider, IServiceStatus } from '@src/service/status/ServiceStatusProvider';
import MessagingService from '@src/service/service/messaging/MessagingService';
import StoreService from '@src/service/business/StoreService';
import UserApprovalBusinessStore from '@src/service/business/userapproval/userApprovalBusinessStore';
import { ServiceStatusProviderType } from '@src/service/status/types';
import { getLogger } from '@src/service/util/logging/logger';


const LOGGER = getLogger('MessagingServiceStatusProvider');

/** Provides simple provider to controlling messaging service. */
export default class MessagingServiceStatusProvider implements IServiceStatusProvider<MessagingService> {

  static create() {
    return new MessagingServiceStatusProvider();
  }

  serviceInstance(): MessagingService {
    return MessagingService.instance();
  }

  requireService(): Observable<IServiceStatus> {
    return Observable.create((observer: Observer<IServiceStatus>) => {
      MessagingService.instance().shouldCheckUserApproval()
        // TODO: API should also return info if check has already been performed and REJECTED
        .subscribe(
          (check) => {
            if (check) {
              StoreService.dispatchAction(UserApprovalBusinessStore.actions.showUserApproval({
                id: ServiceStatusProviderType.MessagingService,
                serviceId: ServiceStatusProviderType.MessagingService,
                grantCallback: () => {
                  this.startService();
                },
              }));
            }
            else {
              LOGGER.info('Messaging approval not required');
              if (!this.getServiceStatus().running) {
                this.startService();
              }
            }
          },
          (err) => observer.error,
          () => observer.complete()
        );
      // console.warn('Displaying notification blocked by user. Notifications should be allowed manually.');

      MessagingService.instance().watchStarted()
        .subscribe(
          (started) => observer.next({ running: started }),
          (error) => observer.error(error),
          () => observer.complete()
        );
    });
  }

  watchServiceStatus(): Observable<IServiceStatus> {
    return MessagingService.instance().watchStarted().pipe(
      map((started) => ({ running: started }))
    );
  }

  getServiceStatus(): IServiceStatus {
    return {
      running: MessagingService.instance().isStarted(),
    };
  }

  startService(): Observable<IServiceStatus> {
    return MessagingService.instance().start().pipe(
      map(() => ({ running: true }))
    );
  }
}
