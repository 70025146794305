import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '../path/withPathResolver';

// path starts with protocol prefix
const FULL_URI_MATCHER = new RegExp('^(?:[A-Za-z]+:)?//');
// path starts with relative path dleiemiters (. or /)
const RELATIVE_PATH_MATCHER = new RegExp('^[./]');

// -- Prop types
// ----------

// -- Public component props
export interface ILemonImageOwnProps {
  imagePath?: string;
  thumbnail?: boolean;
  title?: string;
  className?: string;

  // events
  onError?: () => void;
}
// -- Complete component props
type ILemonImageProps = ILemonImageOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component state
interface ILemonImageState {}

// -- Component
// ----------

/**
 * Component for displaying images using HTML "img" element. Also, component provides "onError" event handler in case image cannot be loaded.
 * Can also display a thumbnail by passing an argument in the thumbnail prop.
 *
 * Image path resolving:
 * * path starts with protocol prefix (eg. https://<path> or //<path>) - literal path is used
 * * path starts with absolute or relative path delimiters (eg. ./ or / or ../) - path is resolved as application URL
 * * path without relative path delimiters (eg. only filename) - path is resolved against image root
 *
 * See "withPathResolver" HOC for more info.
 */
class LemonImage extends React.Component<ILemonImageProps, ILemonImageState> {
  render = () => {
    return (
      <React.Fragment>
        <img className={this.props.className || ''} src={this.resolveImagePath(this.props.imagePath!)} title={this.props.title} onError={this.imageLoadErrorHandler} />
      </React.Fragment>
    );
  };

  imageLoadErrorHandler = () => {
    if (this.props.onError) {
      this.props.onError();
    }
  };

  resolveImagePath(imagePath: string) {
    // URL contains protocol delimiter and maybe schema, no need to resolve
    if (FULL_URI_MATCHER.test(imagePath)) {
      return imagePath;
    }
    // url contains path delimiters (/) or relative
    else if (RELATIVE_PATH_MATCHER.test(imagePath)) {
      if (this.props.thumbnail) {
        return this.props.resolveApiPath(`${imagePath}/thumbnail`);
      } else {
        return this.props.resolveApiPath(imagePath);
      }
    } else {
      return this.props.resolveImagePath(imagePath);
    }
  }
}

export default withLocalize<ILemonImageOwnProps>(withPathResolver(LemonImage as any));
