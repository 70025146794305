const hr = {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mlrd.',
    trillion: 'bil.',
  },
  ordinal: () => {
    return '.';
  },
  currency: {
    symbol: 'kn',
  },
};

export default hr;
