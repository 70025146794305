import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import ITutoringRoomAccessData from '@src/model/room/TutoringRoomAccessData';

export default class TutoringRoomApiService extends EntityApiService<any> {

  static METHODS = {
    JOIN: 'join',
    LEAVE: 'leave',
  };

  static create(entityName: string, api: IAbstractEntityApi<any, any>): TutoringRoomApiService {
    return new TutoringRoomApiService(entityName, api);
  }

  /** Join participant to tutoring room for given tutoring sesion. */
  joinTutoringRoom(tutoringSessionId: string): Observable<ITutoringRoomAccessData> {
    return this.entityApi.updateEntityMethod(this.entityName, tutoringSessionId, TutoringRoomApiService.METHODS.JOIN, {}).pipe(
      // check return value
      map((data) => {
        return data.payload;
      })
    );
  }

  /** Leave participant from tutoring room for given tutoring sesion. */
  leaveTutoringRoom(tutoringSessionId: string): Observable<any> {
    return this.entityApi.updateEntityMethod(this.entityName, tutoringSessionId, TutoringRoomApiService.METHODS.LEAVE, {}).pipe(
      // check return value
      map((data) => {
        return data.payload;
      })
    );
  }

}
