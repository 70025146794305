import React from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import ITutoringSession from '@src/model/session/TutoringSession';
import LemonTag, { LemonTagType } from '@src/components/common/tag/LemonTag';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import { Tooltip } from 'antd';


// -- Prop Types
// ----------

export interface ISessionStatusTagPublicProps {
  session: ITutoringSession;
  showTooltip?: boolean;
}
type ISessionStatusTagProps = ISessionStatusTagPublicProps & IWithLocalizeOwnProps;


// -- Component
// ----------

const SessionStatusTag: React.StatelessComponent<ISessionStatusTagProps> = (props) => {

  let tagType: LemonTagType;
  let tooltipText: string;

  // not yet confirmed, current user needs to do some action
  if (TutoringSessionModelHelper.isSessionInvited(props.session)) {
    tagType = 'scheduledSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_INVITED');
  }
  // not yet confirmed, current user is waiting for responce from other participant
  else if (TutoringSessionModelHelper.isSessionAccepted(props.session)) {
    tagType = 'scheduledSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNCONFIRMED_ACCEPTED');
  }
  // confirmed or in progress
  else if (TutoringSessionModelHelper.isSessionConfirmed(props.session) || TutoringSessionModelHelper.isSessionInProgress(props.session)) {
    tagType = 'confirmedSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_CONFIRMED');
  }
  // ended with success
  else if (TutoringSessionModelHelper.isSessionSuccessfullyEnded(props.session)) {
    tagType = 'heldSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_FINISHED');
  }
  // unresponded
  else if (TutoringSessionModelHelper.isSessionUnresponded(props.session)) {
    tagType = 'unrespondedSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_UNRESPONDED');
  }
  // Declined(3) or ended w/o success
  else {
    tagType = 'canceledSession';
    tooltipText = props.translate('LEMON_HELPER_TOOLTIP.SESSION.SESSION_STATUS_DECLINED');
  }

  return (
    <Tooltip title={props.showTooltip ? tooltipText : null}>
      <LemonTag data-test-id={`lemon-sessionStatusBadge__${tagType}_session_${props.session.id}`} type={tagType}>
        {props.children}
      </LemonTag>
    </Tooltip>
  );
};

export default withLocalize<ISessionStatusTagPublicProps>(SessionStatusTag as any);
