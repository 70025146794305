import React from 'react';

// tslint:disable-next-line:no-import-side-effect
import '@src/assets/style/app.less';

/** Main application shell. Can be used for intializing various resources (eg. context). */
export default class App extends React.Component {
  render() {
    return (
      <div className="lemon-app__container">
        {this.props.children}
      </div>
    );
  }
}
