import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import ComponentMessagingContext, { IComponentMessage } from '@src/components/common/util/ComponentMessagingContext';
import SessionCalendar from '@src/components/session/calendar/SessionCalendar';
import { IMessagingMessage } from '@src/model/messaging/messages';
import ITutoringSession from '@src/model/session/TutoringSession';
import IUserInfo from '@src/model/user/User';
import IUserDetails from '@src/model/user/UserDetails';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import { LoginBusinessStore } from '@src/service/business/login/loginBusinessStore';
import SessionBusinessStore, { ISessionCalendarListStatusFilter, ISessionListFilter, SessionCalendarListFilterStatus } from '@src/service/business/session/sessionBusinessStore';
import { INewMessage } from '@src/service/business/session/timelineBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import TutoringSessionModelHelper from '@src/service/model/session/TutoringSessionModelHelper';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { getLogger } from '@src/service/util/logging/logger';
import ComponentMessagingRules from '@src/service/util/pubsub/ComponentMessagingRules';

const LOGGER = getLogger('SessionCalendarContainer');

// -- Prop types
// ----------
interface ISessionCalendarOwnProps { }
// combine props base with already provided WithRouterProps
interface ISessionCalendarStateProps {
  currentUser: IUserInfo;
  sessionList: ITutoringSession[];
  sessionListFilter?: ISessionCalendarListStatusFilter;
}
interface ISessionCalendarDispatchProps {
  fetchSessionList: (data: ICollectionFetchPayload<ISessionListFilter>) => any;
  storeSessionListFilter: (listFilter: ISessionCalendarListStatusFilter) => any;
  confirmSession: (id: string, message: INewMessage | undefined, thunkMap: IActionThunkMap) => any;
  declineSession: (id: string, message: INewMessage | undefined, thunkMap: IActionThunkMap) => any;
  clearSessionList: () => void;
}
type ISessionCalendarContainerProps = ISessionCalendarOwnProps & ISessionCalendarStateProps & WithRouterProps & ISessionCalendarDispatchProps;

interface ISessionCalendarContainerState {
  startDateTime: Moment;
  endDateTime: Moment;
}

// -- Component
// ----------
class SessionCalendarContainer extends React.Component<ISessionCalendarContainerProps, ISessionCalendarContainerState> {
  state: ISessionCalendarContainerState = {
    startDateTime: moment().startOf('month'),
    endDateTime: moment().endOf('month'),
  };

  componentDidMount = () => {
    // initialize session list filter
    this.initialSessionListFilter();

    this.fetchSessionList();
  };

  componentWillUnmount = () => {
    // clear current session list data from store
    this.props.clearSessionList();
  };

  render = () => {
    return (
      <ComponentMessagingContext.Consumer listener={this.handleSessionChangeNotification}>
        {() => (
          <SessionCalendar
            sessions={this.props.sessionList ? this.props.sessionList : []}
            currentUser={this.props.currentUser}
            sessionListFilter={this.props.sessionListFilter}
            startDateTime={this.state.startDateTime}
            onDateChange={this.handleDateChange}
            onListFilterChange={this.handleListFilterChange}
            onSessionConfirm={this.handleSessionConfirm}
            onSessionDecline={this.handleSessionDecline}
            onUpdateSessionList={this.fetchSessionList}
          />
        )}
      </ComponentMessagingContext.Consumer>
    );
  };

  handleDateChange = (month: number, year: number) => {
    // this.fetchSessionList(month, year);
    const startDate: moment.Moment = moment([year, month]);
    this.setState(
      {
        startDateTime: startDate,
        endDateTime: moment(startDate).endOf('month'),
      },
      // refetch state on state update
      () => this.fetchSessionList()
    );
  };

  handleListFilterChange = (filterUpdate: ISessionCalendarListStatusFilter) => {
    this.updateSessionListFilter(filterUpdate);

    const prevStatusPast: boolean = this.props.sessionListFilter !== undefined && this.props.sessionListFilter.status === SessionCalendarListFilterStatus.PAST;
    if ((filterUpdate.status === SessionCalendarListFilterStatus.PAST || prevStatusPast) && !moment().isSame(this.state.startDateTime, 'month')) {
      const now: Moment = moment();
      this.handleDateChange(now.month(), now.year());
    }
  };

  handleSessionConfirm = (session: ITutoringSession, message?: string) => {
    const newMessage = this.createNewMessage(session, message);
    this.props.confirmSession(session.id, newMessage, {
      success: () => {
        // refresh list on session confirm
        this.fetchSessionList();
      },
    });
  };

  handleSessionDecline = (session: ITutoringSession, message?: string | undefined) => {
    const newMessage = this.createNewMessage(session, message);
    this.props.declineSession(session.id, newMessage, {
      success: () => {
        // refresh list on session decline
        this.fetchSessionList();
      },
    });
  };

  /** Handle notifications on session changes. */
  handleSessionChangeNotification = (message: IComponentMessage<IMessagingMessage>) => {
    if (ComponentMessagingRules.hasSessionStatusOrTimeChanged(message)) {
      this.updateSessionList();
    }
  };

  // --------- private

  private createNewMessage(session: ITutoringSession, message: string | undefined): INewMessage | undefined {
    let newMessage: INewMessage | undefined;
    if (message) {
      const otherParticipant = this.getOtherParticipantUser(session);
      if (otherParticipant) {
        newMessage = { messageBody: message, receiverId: otherParticipant.id };
      } else {
        LOGGER.warn('Cannot find other session participant from session', session.id);
      }
    }

    return newMessage;
  }

  private getOtherParticipantUser(session: ITutoringSession): IUserDetails | undefined {
    return TutoringSessionModelHelper.getFirstOtherParticipantUser(session);
  }

  private updateSessionListFilter(filterUpdate: ISessionCalendarListStatusFilter) {
    this.props.storeSessionListFilter({
      ...(this.props.sessionListFilter || {}),
      ...(filterUpdate || {}),
    });
  }

  private updateSessionList() {
    this.fetchSessionList();
  }

  private fetchSessionList = () => {
    this.props.fetchSessionList({
      filter: {
        startDateTime: this.state.startDateTime.format(),
        endDateTime: this.state.endDateTime.format(),
      },
      page: 0,
      size: AppConfigService.getValue('api.collectionDefaultLimit'),
      sort: [AppConfigService.getValue('components.sessionCalendar.defaultSortOrder')],
    });
  };

  private initialSessionListFilter() {
    this.updateSessionListFilter({
      status: SessionCalendarListFilterStatus.ALL,
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISessionCalendarOwnProps): ISessionCalendarStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  sessionList: SessionBusinessStore.selectors.getSessionCalendarList(state),
  sessionListFilter: SessionBusinessStore.selectors.getSessionCalendarListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISessionCalendarDispatchProps => ({
  fetchSessionList: (params: ICollectionFetchPayload<ISessionListFilter>) => dispatch(SessionBusinessStore.actions.fetchSessionList(params)),
  storeSessionListFilter: (listFilter: ISessionCalendarListStatusFilter) => dispatch(SessionBusinessStore.actions.storeSessionCalendarListFilter(listFilter)),
  confirmSession: (id: string, message: INewMessage | undefined, thunkMap: IActionThunkMap) => dispatch(createActionThunk(SessionBusinessStore.actions.confirmSession({ id }, message), thunkMap)),
  declineSession: (id: string, message: INewMessage | undefined, thunkMap: IActionThunkMap) => dispatch(createActionThunk(SessionBusinessStore.actions.declineSession({ id }, message), thunkMap)),
  clearSessionList: () => dispatch(SessionBusinessStore.actions.clearSessionList()),
});

// TODO: improve component public props API when using third party HOCs
// type assertion to 'any' to because 'withRouter' doesn't do type reduction so injected props types are appearing in public props API
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SessionCalendarContainer) as any);
