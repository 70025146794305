import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IfUserRoles from '@src/components/common/role/IfUserRoles';
import CountdownClock from '@src/components/common/timer/CountdownClock';
import NotificationInfoBarListItem from '@src/components/login/notificationinfo/NotificationInfoBarListItem';
import { IMessagingMessage, ISessionIncomingNoticeMessagingMessage, MessagingMessageType } from '@src/model/messaging/messages';
import { IEducationArea } from '@src/model/user/EducationArea';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import EducationAreaModelHelper from '@src/service/model/user/EducationAreaModelHelper';
import { getLogger } from '@src/service/util/logging/logger';

const LOGGER = getLogger('NotificationInfoBarMessagingMessageItem');

const TIME_FORMAT = AppConfigService.getValue('dateTimeFormat.time');
const SHORT_TIME_FORMAT = AppConfigService.getValue('dateTimeFormat.shortTime');

// --
// ---------- Prop types

export interface INotificationInfoBarMessagingMessageItemPublicProps {
  message: IMessagingMessage;
  educationAreaList: IEducationArea[];
}
type INotificationInfoBarMessagingMessageItemProps = INotificationInfoBarMessagingMessageItemPublicProps & IWithLocalizeOwnProps;

// --
// ---------- Component

/** Collect notification info bar message lists and pass them to list component. */
class NotificationInfoBarMessagingMessageItem extends React.Component<INotificationInfoBarMessagingMessageItemProps> {
  render() {
    const message = this.props.message;

    // sanity check
    if (message.type !== MessagingMessageType.SESSION_INCOMING_NOTICE) {
      LOGGER.warn(`Notification info bar messaging message type "${message.type}" not supported!`);
      return null;
    }

    const sessionIncomingMessage = message as ISessionIncomingNoticeMessagingMessage;

    const educationArea: IEducationArea | undefined = EducationAreaModelHelper.getAreaById(sessionIncomingMessage.educationAreaId, this.props.educationAreaList);
    const educationAreaName = educationArea ? educationArea.name : '';
    const sessionStartTime = moment(sessionIncomingMessage.startDateTime).format(TIME_FORMAT);
    const sessionEndTime = moment(sessionIncomingMessage.endDateTime).format(TIME_FORMAT);

    // check session status and show appropriate message accordingly, or no message at all if session has finished
    const isSessionIncoming = moment().isBefore(sessionIncomingMessage.startDateTime);
    const isSessionInProgress = moment().isBetween(sessionIncomingMessage.startDateTime, sessionIncomingMessage.endDateTime, undefined, '[]');

    // show message for incoming sessions or sessions in progress
    if (isSessionIncoming || isSessionInProgress) {
      return (
        <NotificationInfoBarListItem>
          <Row>
            <Col span={10}>
              <LemonIcon name="clockCircle" size="xlarge" />
              &nbsp;
              {/* ----- incoming session ----- */}
              {isSessionIncoming && (
                <React.Fragment>
                  {this.props.translate('NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.PREFIX_LABEL')}
                  &nbsp;
                  <span className="lemon-notificationInfoBar__timer">
                    <CountdownClock endTime={sessionIncomingMessage.startDateTime} format={SHORT_TIME_FORMAT} />
                  </span>
                  &nbsp;
                  {this.props.translate('NOTIFICATION_INFO_BAR.SESSION_INCOMING_NOTICE.SUFFIX_LABEL')}
                </React.Fragment>
              )}
              {/* ----- session in progress ----- */}
              {isSessionInProgress && <React.Fragment>{this.props.translate('NOTIFICATION_INFO_BAR.SESSION_IN_PROGRESS_NOTICE')}</React.Fragment>}
            </Col>
            <Col span={14} className="text-right">
              {/* display tutor */}
              <IfUserRoles roles={[UserRoleEnum.STUDENT]} fallback="">
                {this.props.translate('NOTIFICATION_INFO_BAR.SESSION_INFO.TUTOR_LABEL')}: &nbsp;
                <span className="lemon-notificationInfoBar__participantName">{sessionIncomingMessage.tutor}</span>
                &nbsp;
              </IfUserRoles>
              {/* display students */}
              <IfUserRoles roles={[UserRoleEnum.TUTOR]} fallback="">
                {this.props.translate('NOTIFICATION_INFO_BAR.SESSION_INFO.STUDENTS_LABEL')}: &nbsp;
                <span className="lemon-notificationInfoBar__participantName">{sessionIncomingMessage.students}</span>
                &nbsp;
              </IfUserRoles>
              ({educationAreaName && <span>{educationAreaName}&nbsp;-&nbsp;</span>}
              <span>
                {sessionStartTime}
                &nbsp;-&nbsp;
                {sessionEndTime}
              </span>
              ) &nbsp;
              {<Link to={`/tutoringroom/${sessionIncomingMessage.sessionId}`}>{this.props.translate('NOTIFICATION_INFO_BAR.JOIN_SESSION_LABEL')}</Link>}
            </Col>
          </Row>
        </NotificationInfoBarListItem>
      );
    } else {
      // session has finished, notification should be dismissed in notification menu
      return null;
    }
  }
}

export default withLocalize<INotificationInfoBarMessagingMessageItemPublicProps>(NotificationInfoBarMessagingMessageItem as any);
